import styled, { ThemeProvider } from 'styled-components';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { useDispatch, useSelector } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';
import { buttonThemes } from "globalConstants/Themes";

const UnitSwitch = (props) => {
  const dispatch = useDispatch();
  const changeUnits = (evt, units) => {
    dispatch(ReducersIndex.uiReducers.setUnits(units));
    evt.preventDefault();
  };
  const units = useSelector(state => state.ui.units)

  return (
    <div className={`hstack gap-1 text-nowrap me-3 ${!props.nomargin && 'mt-4'}`}>
      {['feet', 'meters'].map((item, index) =>
        <ThemeProvider key={`item${index}`} theme={buttonThemes.units}>
          <UnitSwitch.Wrapper checked={units === item} onClick={(e) => changeUnits(e, item)}>
            <Label htmlFor={`unitswitch-${item}`} type={'radio'} value={item} >{item.toUpperCase()}</Label>
            <Input id={`unitswitch-${item}`} name={`unitswitch-${item}`} type={'radio'} isButton value={item} />
          </UnitSwitch.Wrapper>
        </ThemeProvider>
      )}
    </div>
  )
};

UnitSwitch.Wrapper = styled.div.attrs(props => ({
}))`
  background-color: ${props => props.theme.background};
  color: ${props => props.checked ? 'var(--app-red)' : 'var(--app-medium-gray)'};
  font-weight: bold;
  `;


export default UnitSwitch;

