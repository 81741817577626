import modals from "toolConstants/classroomDesigner/modals";
import menus from "toolConstants/classroomDesigner/menus";

import actions, {conditions, actionDispatchers} from "toolConstants/classroomDesigner/actions";
import initialState from "toolConstants/classroomDesigner/initalState";
import {containerCondition, containerElements, areaCondition, areaElements, otherElements} from "toolConstants/classroomDesigner/views";
import {resetArea} from "toolConstants/classroomDesigner/logic";

import constants from "toolConstants/classroomDesigner/constants";
import validate from "toolConstants/classroomDesigner/validation"


const CD = {
  initialState: initialState,
  modals: modals,
  menus: menus,

  actions: actions,
  actionConditions: conditions,
  actionDispatchers: actionDispatchers,


  // containerCondition: containerCondition,
  // containerElements: containerElements, 
  // areaCondition: areaCondition,
  areaElements: areaElements, 
  // otherElements: otherElements, 

  resetArea: resetArea,
  validate: validate,

  constants: constants,
};

export default CD;
