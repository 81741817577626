import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ReducersIndex from "reduxModules/ducks";
import { parseDistance } from "utilities/format";
import { getConditionResult } from "reduxModules/ducks/container";
import { getDrawingBox } from "reduxModules/ducks/ui";
import { values } from "lodash";

const Overlaps = (props) => {
  const areasArray = values(useSelector(state => state.container.areas));
  const areas = areasArray.map(area => ({ name: area.name, ...area.location, ...area.dimensions }));
  
  const {length, width} = useSelector(state => state.container.dimensions);

  const {bbox, scale, svgbox} = useSelector(getDrawingBox);

  const currentOverlaps = useSelector(state => state.ui.overlaping);

  const overlaps = [];
  const overlaping = []; 

  areas.forEach((r1, i) => {
    areas.slice(i + 1).forEach((r2) => {
      const ck1 = parseDistance(r2.left) >= parseDistance(r1.left + r1.length)
      const ck2 = parseDistance(r2.left + r2.length) <= parseDistance(r1.left)
      const ck3 = parseDistance(r2.top) >= parseDistance(r1.top + r1.width)
      const ck4 = parseDistance(r2.top + r2.width) <= parseDistance(r1.top)
      if (!(ck1 || ck2 || ck3 || ck4)) {
          overlaping.push(`${r1.name} is overlaping ${r2.name}`); //    [r1.name] = r2.name 
          var x = Math.max(r1.left, r2.left) * scale;
          var y = Math.max(r1.top, r2.top) * scale;
          var h = Math.min(r1.top+r1.width, r2.top+r2.width) * scale - y;
          var w = Math.min(r1.left+r1.length, r2.left+r2.length) * scale - x;
          overlaps.push({ x: x + bbox.x0, y: y + bbox.y0, h: h, w: w });
      };
    });
    const ck5 = parseDistance(r1.left + r1.length) > length;
    const ck6 = parseDistance(r1.top + r1.width) > width;
    if (ck5 || ck6) {
      overlaping.push(`${r1.name} is outside the Warehouse`)
      var x = r1.left * scale;
      var y = r1.top * scale;
      var h = (r1.top + r1.width) * scale - y;
      var w = (r1.left + r1.length) * scale - x;
      if (ck6) overlaps.push({ x: x + bbox.x0, y: bbox.y1, h: svgbox.height - bbox.height - bbox.y0 , w: w }); // bellow ck6
      if (ck5) overlaps.push({ x: bbox.x1, y: y + bbox.y0 , h: h , w: svgbox.width - bbox.width - bbox.x0 }); // right ck5
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if(JSON.stringify(currentOverlaps) !== JSON.stringify(overlaping)) dispatch(ReducersIndex.uiReducers.setOverlaping(overlaping));
  });

  const visible = useSelector(state => getConditionResult(state, {...props.show}));

 
  return (
    visible && (overlaps.length > 0) &&
    <Overlaps.Group >
      {overlaps.map((box, index) => <Overlaps.Rect key={`ov-${index}`} {...box} />)}
    </Overlaps.Group>
  );
};


Overlaps.Group = styled.g.attrs(props => ({
  id: 'overlaps'
}))`
  fill: var(--app-red);
  opacity: .4
`;

Overlaps.Rect = styled.rect.attrs(props => ({
  width: Math.max(8, props.w),
  height: Math.max(8, props.h) ,
  x: props.x - (props.w <=6 ? 3 : 0),
  y: props.y - (props.h <=6 ? 3 : 0),
  rx: 3,
}))`
`;

export default Overlaps;