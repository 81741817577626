/**
 * Validation module for the Conference Room Designer.
 * This module contains functions to validate various aspects of the room design and equipment setup.
 * @module conferenceRoomDesignerValidation
 */
import toolIndex from "toolConstants";

import ReducersIndex from "reduxModules/ducks";
import { inRange, side } from 'utilities/mathematic.js';
import { toFeet, distanceToString } from "utilities/format";
import { indexOf, round, findKey } from "lodash";

/*
ERROR CODES 
1000: Area Dims
1010: Area Ceiling Height

1100: Mic Style
1110: Pendant Height
*/


/**
 * Object containing validation functions for different aspects of the room design.
 * Each function is called from the errorMiddleware and receives dispatch and state as parameters.
 * @type {Object.<string, function>}
 */
const validate = {
  /**
   * Validates the room dimensions.
   * @function
   * @param {function} dispatch - The Redux dispatch function.
   * @param {Object} state - The current Redux state.
   * 
   * @description
   * Checks if the room length and width are within the allowed range.
   * Dispatches an error action if dimensions are invalid, with specific error codes and parameters.
   */
  dimensions: (dispatch, state) => {
    const { units } = state.ui
    const isMeter = units === 'meters';
    const { length, width } = (state.container.areas[state.container.activeTab]).dimensions;
    const { area: { min, max }, tool: { areaName: name } } = toolIndex[state.container.currentTool].constants;
    const e1 = inRange(length, min, max);
    const e2 = inRange(width, min, max);
    const errorCode = 1000;
    const errorParams = [...(e1 ? ['length'] : []), ...(e2 ? ['width'] : [])];
    if (e1 || e2) {
      if (e1 === e2 || e1 === 0 || e2 === 0) {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `${name} Dimensions can't be ${(e1 || e2) < 0 ? 'smaller' : 'larger'} than ${isMeter ? ((e1 || e2) < 0 ? min.toFixed(1) : max.toFixed(1)) : ((e1 || e2) < 0 ? toFeet(min) : toFeet(max))} ${isMeter ? 'Meters' : 'Feet'} `
        }))
      } else {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `${name} Dimensions must be between ${isMeter ? min.toFixed(1) + ' and ' + max.toFixed(1) : toFeet(min) + ' and ' + toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
        }))
      };
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }));
    };
  },

  /**
   * Validates the ceiling height.
   * @function
   * @param {function} dispatch - The Redux dispatch function.
   * @param {Object} state - The current Redux state.
   * 
   * @description
   * Checks if the ceiling height is within the allowed range.
   * Dispatches an error action if the height is invalid.
   * Also triggers validation for microphones and speakers as ceiling height affects them.
   */
  ceilingHeight: (dispatch, state) => {
    const { units } = state.ui;
    const isMeter = units === 'meters';
    const { ceilingHeight: { min: ceilingHeight } } = state.container.areas[state.container.activeTab];
    const { ceiling: { min, max }, tool: { areaName: name } } = toolIndex[state.container.currentTool].constants;
    const e1 = inRange(ceilingHeight, min, max);
    const errorCode = 1010;
    const errorParams = e1 ? ['min'] : [];
    if (e1) {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
        params: errorParams,
        msg: `${name} Ceiling can't be ${e1 < 0 ? 'lower' : 'higher'} than ${isMeter ? e1 < 0 ? min.toFixed(1) : max.toFixed(1) : e1 < 0 ? toFeet(min) : toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
      }))
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
      validate.microphones(dispatch, state); 
      validate.speakers(dispatch, state);
    }
  },

  /**
   * Validates the microphone setup.
   * @function
   * @param {function} dispatch - The Redux dispatch function.
   * @param {Object} state - The current Redux state.
   * 
   * @description
   * Checks if the microphone height and model are appropriate for the room acoustics and ceiling height.
   * Adjusts microphone settings if needed and dispatches error or info actions accordingly.
   */
  microphones: (dispatch, state) => {
    const { units, isModalOpen, info } = state.ui;
    const isMeter = units === 'meters';
    const { microphones: { model, maxHeight, height }, acoustics: currentAcoustics, ceilingHeight: { min: ceilingHeight } } = state.container.areas[state.container.activeTab];
    const micModel = model.replace('mic', '_mic');
    const { equipment: { microphones: { [micModel]: { micData } } }, acoustics, area: { talkerHeight } } = toolIndex[state.container.currentTool].constants;
    const max = round(side(micData[indexOf(acoustics, currentAcoustics)], 1) + talkerHeight, 4);
    // Update microphone height if required
    if (max !== maxHeight) dispatch(ReducersIndex.container.setMicrophones({ maxHeight: max }));
    if ((micModel === 'ceiling_mic' && height !== ceilingHeight) || (height > ceilingHeight && height <= max)) { 
      dispatch(ReducersIndex.container.setMicrophones({ height: ceilingHeight }))};
    // Check for errors
    const e1 = micModel === 'ceiling_mic' && ceilingHeight > max;
    const e2 = micModel === 'pendant_mic' && height > max;
    const hasInfo = findKey(info, { 'code': 1100 });

    // Helper function to create message object
    const createMessage = (code, msg) => ({ code, msg });

    // Helper function to dispatch message based on modal state
    const dispatchMessage = (message) => {
      const action = isModalOpen ?
        ReducersIndex.uiReducers.setInfo :
        ReducersIndex.uiReducers.setError;
      dispatch(action({
        ...message,
        reset: !isModalOpen
      }));
    };

    if (e1 || e2) {
      if (e1) {
        const message = createMessage(1100, 'Microphone is too high to pick up any talkers at given room acoustics.\nChanging microphone style to Pendant.');
        dispatchMessage(message);
        dispatch(ReducersIndex.container.setMicrophones({ model: 'pendantmic' }));
      };

      if (e2) { 
        if (!hasInfo) {
        const message = createMessage(1110, `Microphone is too high to pick up any talkers at given room acoustics.\nAdjusting Pendant microphone height to ${distanceToString(max, isMeter)}.`);
        dispatchMessage(message);
        }
        dispatch(ReducersIndex.container.setMicrophones({ height: max }));
      };
    };
  },

  /**
   * Validates the room acoustics.
   * @function
   * @param {function} dispatch - The Redux dispatch function.
   * @param {Object} state - The current Redux state.
   * 
   * @description
   * Clears any existing info messages and re-validates the microphone setup as acoustics affect microphone performance.
   */
  acoustics: (dispatch, state) => {
    dispatch(ReducersIndex.uiReducers.setInfo()); // clears any errors that might be displayed in the modals 
    validate.microphones(dispatch, state); // this only affects ceiling and maybe pendants 
  },

  /**
   * Validates the room acoustics.
   * @function
   * @param {function} dispatch - The Redux dispatch function.
   * @param {Object} state - The current Redux state.
   * 
   * @description
   * Clears any existing info messages and re-validates the microphone setup as acoustics affect microphone performance.
   */
  speakers: (dispatch, state) => {
    const { speakers: {model, height }, ceilingHeight: { min: ceilingHeight } } = state.container.areas[state.container.activeTab];
    const { equipment: { speakers: { [model]: { isCeilingSpkr = false, isBarSpkr = false } } }} = toolIndex[state.container.currentTool].constants;
    if (isCeilingSpkr && height !== ceilingHeight) {
      dispatch(ReducersIndex.container.setSpeakers({ height: ceilingHeight }))
    }
  },


}




export default validate;





