
import PlainText from 'components/atoms/PlainText';
import BulletList from 'components/atoms/BulletList';
import Spinner from 'components/atoms/Spinner';
import { TextInput, NumberInput, DoubleNumberInput } from 'components/molecules/EntryInput';
import Button from 'components/molecules/Button';
import UnitSwitch from 'components/molecules/UnitSwitch';
import {DimensionInput, PositionInput, RangeInput} from 'components/molecules/DimensionInput';
import { SelectButtons, ToggleButtons, ToggleSelect, FamilyButtons } from 'components/molecules/ButtonGroup';
import MeasurementInput from 'components/molecules/MeasurementInput';
import Slider from 'components/atoms/Slider';
import Selector from 'components/molecules/Selector';
import { GroupLabel} from 'components/atoms/Label';
import CheckBox from 'components/molecules/CheckBox';
import {ErrorContent, ValueContent} from 'components/molecules/DynamicContent';
import Carousel from 'components/organisms/Carousel';
import Video from 'components/organisms/Video';
import Output from 'components/molecules/Output';
import Fader from 'components/atoms/Fader';



// modal fields that might be required
export const ComponentsIndex = {
  plainText: PlainText,
  bulletList: BulletList,
  spinner: Spinner,
  textInput: TextInput,
  numberInput: NumberInput,
  doubleNumberInput: DoubleNumberInput,
  button: Button,
  unitSwitch: UnitSwitch,
  dimensionInput: DimensionInput,
  positionInput: PositionInput,
  rangeInput: RangeInput,
  selectButtons: SelectButtons,
  toggleButtons: ToggleButtons,
  toggleSelect: ToggleSelect,
  familyButtons: FamilyButtons,
  measurementInput: MeasurementInput,
  slider: Slider,
  selector: Selector,
  groupLabel: GroupLabel,
  checkBox: CheckBox,
  errorContent: ErrorContent,
  valueContent: ValueContent,
  carousel: Carousel,
  video: Video,
  output: Output,
  fader: Fader,
};

