// This file returns an object with the initial state for a classroom 
import constants from "toolConstants/classroomDesigner/constants";
import { keys } from "lodash";


const initialState = {
  area: (state) => {
    return ({
      id: "",
      name: "",
      areaType: "classroom",
      // storageReady: false,
      dimensions: { length: 12.192, width: 9.144 },
      location: { left: 0, top: 0 },
      // openCeiling: false,
      // snapToGrid: false,
      ceilingHeight: { min: 2.8956, max: 2.8956 },


      // NEW CODE
      // fixme: repeat from room
      areaOrientation: 'lengthwise',

      door: {
        side: 3,
        scale: [1,1],
        angle: 0,
        x: null,
        y: null,
      },
      
      ceilingTiles: {
        tile: "no_ceiling_tiles",
        qty: { x: 0, y: 0 },
        transform: { x: 0, y: 0 },
        checked: []
      },





      // marker: Do I need to initialize row, tablesPerRow and totalTables here?
      tables: {
        shape: 'tablet',
        ...constants.tables.tablet.default,
        // this values are for testing - these initialize at 0
        rows: 2,
        tablesPerRow: 4,
        totalTables: 8,
        // todo
        // backtovalues: holds a copy of the initial calculation for undo purposes
      },

      instructor: {
        shape: 'lectern'
      },

      // "instructor": {
      //   "shape": "teacherdesk", 
      //   "tl": 0.6096, 
      //   "w": 1.524, 
      //   "instructorTransform": {
      //     "scaleX": 1, 
      //     "scaleY": 1, 
      //     "shear": 0, 
      //     "rotate": 90, 
      //     "translateX": 730.4146984773523, 
      //     "translateY": 180.8433360380285, 
      //     "originX": 0, 
      //     "originY": 0, 
      //     "a": 6.123233995736766e-17, 
      //     "b": 1, 
      //     "c": -1, 
      //     "d": 6.123233995736766e-17, 
      //     "e": 730.4146984773523, 
      //     "f": 180.8433360380285}},



      acoustics: 'GOOD',

      // fixme: repeat from room
      microphones: {
        model: 'ceilingmic',
        coverage: {
          full_room: false,
          teacher: true,
          students: true,
        },
        height: 2.8956,
        // fixme: jumpPoints should be an empty array - values are calculated with mics 
        coverageDensity: {
          value: 4,
          jumpPoints: [2, 4, 8]
        }
      },

      speakers: {
        // todo:define defasult speaker model??
        model: keys(constants.equipment.speakers)[0],
        coverage: {
          full_room: false,
          focused: true,
        },
      },


      // mountingHeight: { min: 0, max: 0 },
      // surroundingWalls: {
      //   top: true,
      //   right: true,
      //   bottom: true,
      //   left: true,
      // },
      // areaUsage: "",
      // storageOrientation: "horizontal",
      // uprightHeightFt: 16,
      // pickingAisles: 0,
      // primaryAisles: '',
      // connectorAisles: '',
      // rackFill: 80,
      // aislesArray: [],
      // rackArrays: {
      //   xArray: [],
      //   yArray: []
      // },
      // backgroundNoise: 'noisemedium',
      // noiseLevel: 65,
      // materials: {
      //   walls: 'CMU_Unpainted',
      //   ceiling: 'Metal_Deck',
      // },
      // coverage: {
      //   picking_aisles: true,
      //   primary_aisles: false,
      //   connector_aisles: false,
      //   general: false,
      //   none: false,
      // },
      // systemPrimaryUsage: 'pagingbgm',
      // spl: 0,
      // sti: 0.6,
      // budget: 'Standard',

      // speakerAiming: 'aimAuto',
      // // marker 
      // speakerModel: null,
      // directAwtdSpl: 0,
      // directAwtdSplStd: 0,
      // totalAwtdSpl: 0,
      // totalAwtdSplStd: 0,
      // stiWithNoiseMasking: 0,
      // totalPriceMsrp: 0,
      // speakerLayout: [],

      // speakers: [],
      // directAwtdSplLegendMax: 0,
      // directAwtdSplLegendMin: 0,
      // totalAwtdSplLegendMax: 0,
      // totalAwtdSplLegendMin: 0,


      // recalculate: true,
      // remap: false,

      // customized: {
      //   systemPrimaryUsage: false,
      //   budget: false,
      //   qty: false, // this will reset coverage but only UI
      //   speakerModel: false,
      // }
    }
    )
  }
};

export default initialState;