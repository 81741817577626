import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import bubbles from 'components/molecules/BubbleContent';

const Bubble = (props) => {
  const currentAction = useSelector(state => state.ui.action);
  const Element = bubbles[currentAction]?.element;
  const hasContent = !useSelector(state => state.ui.bubbleContent);

  // console.log("currentAction: ", currentAction, Element, hasContent, Element && hasContent);
  // fixme: delete this hidden={Element && hasContent} from the wrapper to show the bubble
  // fixme: for some reason it's jumpy
  return (
    <ToolBubble {...props} hide={hasContent}>
      {Element && <Element />}
    </ToolBubble>
  )
};

const ToolBubble = styled(Tooltip).attrs(props => ({
  float: true,
  offset: 7.5,
  delayShow: 100,
  className: "custom-tooltip",
}))`
  &.custom-tooltip {
    visibility: ${props => props.hide ? 'hidden' : 'visible'};
    background-color: var(--app-dark-gray);
    color: #fff;
    opacity: .8;
    z-index: 10;
    padding: 0;
    padding: 4px 8px;
  }
`;

export default Bubble;