import modals from "toolConstants/warehouseDesigner/modals";
import menus from "toolConstants/warehouseDesigner/menus";
import actions, {conditions, actionDispatchers} from "toolConstants/warehouseDesigner/actions";
import initialState from "toolConstants/warehouseDesigner/initalState";
import {containerCondition, containerElements, areaCondition, areaElements, otherElements} from "toolConstants/warehouseDesigner/views";
import {setArea, resetArea} from "toolConstants/warehouseDesigner/logic";
import reportDocument from "toolConstants/warehouseDesigner/reportDocument";
import constants from "toolConstants/warehouseDesigner/constants";
import validate from "toolConstants/warehouseDesigner/validation"

const WD = {
  initialState: initialState,
  modals: modals,
  menus: menus,
  reportDocument: reportDocument,

  actions: actions,
  actionConditions: conditions,
  actionDispatchers: actionDispatchers,
  containerCondition: containerCondition,
  containerElements: containerElements,
  areaCondition: areaCondition,
  areaElements: areaElements, 
  otherElements: otherElements,

  constants: constants,
  setArea: setArea,
  resetArea: resetArea,
  validate: validate,
};

export default WD;