
const Actions = {
  measure: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth='1.5' strokeMiterlimit='10'>
      <path d="m18 38l20-20-6-6-20 20zm2-2l-4-4m6 2l-2-2m4 0l-4-4m6 2l-2-2m4 0l-4-4m6 2l-2-2m4 0l-4-4m6 2l-2-2m4 0l-4-4" />
    </g>,

  block: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth='2' strokeMiterlimit='0'>
      <path d="m34 34a12.5 12.5 0 0 1-17.7-17.7l17.7 17.7a12.5 12.5 0 0 0-17.7-17.7" />
    </g>,

  bulkedit: ({ state }) =>
    // geometricpresition
    <g stroke={state.icon_stroke} fill='none' strokeWidth='2' strokeMiterlimit='0' shapeRendering="geometricPrecision">
      <path d="m24 11v8m0 12v8m-14-14h8m12 0h8m-4 2v8h-8m-4 0h-8v-8m0-4v-8h8m4 0h8v8" fill="none" />
      <path d="m26.6 13.7l-2.6-2.6-2.6 2.6v-2.2l2.6-2.6 2.6 2.6zm0 5l-2.6 2.6-2.6-2.6v-2.2l2.6 2.6 2.6-2.6zm0 17.6l-2.6 2.6-2.6-2.6v2.2l2.6 2.6 2.6-2.6z
      m0-5l-2.6-2.6-2.6 2.6v2.2l2.6-2.6 2.6 2.6zm8.7-3.76l2.6-2.6-2.6-2.6h2.2l2.6 2.6-2.6 2.6zm-5 0l-2.6-2.6 2.6-2.6h2.2l-2.6 2.6 2.6 2.6z
      m-17.6 0l-2.6-2.6 2.6-2.6h-2.2l-2.6 2.6 2.6 2.6zm5 0l2.6 -2.6-2.6-2.6h-2.2l2.6 2.6-2.6 2.6z" stroke="none" fill={state.icon_stroke} />
    </g>,

  move: ({ state, center = false, transform = '' }) =>
    <g className='action' id='move' stroke={state.icon_stroke} fill='none' strokeWidth='2' strokeMiterlimit='10' transform={`${transform} ${center ? 'translate(-25 -25)' : ''}`}>
      <circle cx="25" cy="25" r="23" fill={center ? state.border_fill : 'none'} stroke={center ? state.border_stroke : 'none'} />
      <path d="m25 11v7m0 14v7m-14-14h7m14 0h7" />
      <path d="m27.6 13.7l-2.6-2.6-2.6 2.6v-2.2l2.6-2.6 2.6 2.6zm0 22.6l-2.6 2.6-2.6-2.6v2.2l2.6 2.6 2.6 -2.6zm8.7-8.76l2.6-2.6-2.6-2.6h2.2l2.6 2.6-2.6 2.6z
      m-22.6 0l-2.6-2.6 2.6-2.6h-2.2l-2.6 2.6 2.6 2.6z" fill={state.icon_stroke} stroke="none" />
      <circle r="4.5" cy="25" cx="25" />
    </g>,

  rotate: ({ state, center = false, transform = '' }) =>
    <g className='action' id='rotate' stroke={state.icon_stroke} fill='none' strokeWidth='2' strokeMiterlimit='10' transform={`${transform} ${center ? 'translate(-25 -25)' : ''}`}>
      <circle cx="25" cy="25" r="23" fill={center ? state.border_fill : 'none'} stroke={center ? state.border_stroke : 'none'} />
      <path d="M15.8 33.2a12.2 12.2 0 10-2.4-13.2 M19.4 19.8h-6.5v-6.6" fill="none" stroke={state.icon_stroke} />
    </g>,

  add: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth='2' strokeMiterlimit='0'>
      <path d="m12 24h26m-13 13v-26" />
    </g>,

  remove: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth='2' strokeMiterlimit='0'>
      <path d="m12 24h26" />
    </g>,

  ghostchair: ({ state, arms = false }) =>
    <g stroke={state.icon_stroke} fill='none' shapeRendering="geometricPrecision">
      <path d="m101 158.5c37.36737 0 31.80172-24.68478 31.80172-24.68478l-3.51085-24.57592c-1.5377-14.00256-13.81205-13.97534-13.81205-13.97534l-12.682585 0
      m0-37.3c13.71679 0 21.55496 1.18389 21.55496 1.18389c10.4781 1.27915 7.47075 11.56674 7.47075 11.56674c-0.10886 1.10224-1.75542 8.45052-2.21809 9.79771
      c-1.21111 3.51085-3.48363 5.89223-6.58624 7.18499c-2.24531 0.93895-4.58587 1.59213-6.99447 1.59213c-0.47628 0-0.93895 0-1.41522 0l-13.54 0"
        transform="scale(.24)" strokeWidth="6" strokeDasharray="4" />
      <path d="m70 38.5c10.08 0.16 15.82 1.12 15.82 1.12c3.63 0.5 5.03 2.43 5.49 4.34c0.32-1.29 1.45-7.4-5.42-8.35c0 0-5.75-0.96-15.83-1.11m0.5 29.7h2.985v-2.51h-2.985"
        transform="scale(.35)" strokeWidth="2" strokeDasharray="2" />
      <path d="m101 95.2l-12.682585 0c-15.60829 2.38139-16.4792 16.16622-16.4792 16.16622c-0.46267 3.97351-3.44281 24.3854-3.44281 24.3854
      c-1.49687 13.40381 7.98785 18.7109 7.98785 18.7109c6.99447 3.97351 22.82049 3.97351 22.82049 3.97351m1-69.2  l-13.54 0c-0.46267 0-0.93895 0-1.41522 0
      c-2.4086 0-4.74917-0.65318-6.99447-1.59213c-3.10261-1.30636-5.37513-3.67414-6.58624-7.18499c-0.46267-1.34718-2.10923-8.69546-2.21809-9.79771
      c0 0-3.00735-10.28759 7.48436-11.56674c0 0 10.9816-1.18389 24.71199-1.18389m-1-20v135" transform="scale(.24)" strokeWidth="6" />
      <path d="m70.5 34.8c-10.08-0.16-18.17 0.58-18.17 0.58c-6.9 0.74-5.96 6.88-5.68 8.18c0.52-1.89 1.98-3.78 5.62-4.17c0 0 8.09-0.74 18.17-0.59
      m-2.085  25.59 h-2.985v-2.51h2.985" transform="scale(.35) rotate(-1  50 50)" strokeWidth="2" />
      {arms && <path d="m52.12,47.92l-5.2,-19.8c-1.72,-6.11 -4.51,-5.75 -5.62,-5.55c-2.9,0.52 -2.48,3.24 -2.25,6.85l2.43,19.32c0.56,3.22 2.73,5.76 5.67,5.53c2.94,-0.22 5.17,-3.07 4.97,-6.35z
      m-16,-9l2.63,0.04l-0.76,-6.08l-2.61,0.04l0.74,6z" strokeWidth="2" strokeDasharray="2" transform="scale(.415) rotate(-1 50 50) translate(42 35)" />}
      {arms && <path d="m-19.4,46l5.81,-19.62c1.91,-6.06 4.69,-5.61 5.79,-5.38c2.88,0.61 2.38,3.31 2.03,6.92l-3.02,19.24c-0.66,3.2 -2.91,5.67 -5.84,5.35c-2.93,-0.32 -5.07,-3.23 -4.77,-6.51z
        m14.28,-5.42l-2.63,-0.04l0.95,-6.06l2.6,0.12l-0.92,5.98z" strokeWidth="2" transform="scale(.415) rotate(-1 50 50) translate(42 35)" />}
    </g>,

  armedghostchair: (state) => Actions.ghostchair({ ...state, arms: true }),

  listen: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth="1.5" strokeMiterlimit="10">
      <path d="m15 19l0-1c1.5-11 19-8 15 4l-4 11c-4 8-11 3-11-1m3-3a4.5 5 0 0 0 0-10c1-8 11-4.8 9.6 1l-4 11m9-8q3-4 0-8m2-2q4.5 6 0 12" />
    </g>,

  undo: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_stroke} strokeWidth='1' strokeMiterlimit='0'>
      <path d="m14 17l8-8v4.5c15 0 14 15 14 17c0-7.5-5-11-14-10v4.5l-8-8z" />
      <path d="m-33 -34l8-8v4.5c15 0 14 15 14 17c0-7.5-5-11-14-10v4.5l-8-8z" transform="scale(-1, -1)" />
    </g>,

  ghostdock: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth='1.5' strokeMiterlimit='0'  >
      <path d="m24.5 7.5v34m0 -4h-18v-5h5v5l13-13-13-13h13m-13 0v26" />
      <path d="m24.5 37.5h18v-5h-5v5l-13-13 13-13h-13m13 0v26" strokeDasharray='2' />
    </g>,

  zoom: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth='1.5' strokeMiterlimit='0'>
      <path d="m13 13a1 1 0 0 1 14 14l1.5-1.5 10 10a1 1 0 0 1-3 3l-10-10 1.5-1.5a1 1 0 0 1-14-14m2 5.5v-3.5h3.5m3 0h3.5v3.5m0 3v3.5h-3.5m-3 0h-3.5v-3.5" />
    </g>,

  position: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_stroke} strokeWidth='1' strokeMiterlimit='0'>
      <path d="m15 34a10 5 0 1 0 20 0a10 5 0 1 0 -20 0m-5 0h30m-15 10v-10" fill='none' fillRule="nonzero" />
      <path d="m25 34l-7-15a8 8 0 1 1 14 0l-7 15m0 -25a6 6 0 1 1 0 12a6 6 0 1 1 0-12z" fillRule="evenodd" />
    </g>,

  level: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_stroke} strokeWidth='1' strokeMiterlimit='0'>
      <path d="m7 32h15a3 3 0 0 1 6 0h15v2a4 4 0 0 1-4 4h-28a4 4 0 0 1-4-4v-2z" />
      <path d="m7 32v-16a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v16" fill='none' />
      <path d="M 30.49 18.22A 13 13 0 0 0 13.74 23.5" fill='none' strokeWidth='2' />
      <path d="M 36.26 23.5A 13 13 0 0 0 32.46 19.35" fill='none' strokeWidth='2' />
      <path d="m26 30l4.6-9" strokeWidth='2' />
    </g>,

  distance: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_stroke} strokeWidth='1' strokeMiterlimit='0'>
      <path d='m4 25 l15 5c-5-5-5-5 0-10l-15 5 h42 l-15 5c5-5 5-5 0-10l15 5 m-42 -8 v16 m42 0 v-16' />
    </g>,


};

export const ActionText = {
  measure: () => "Click and drag to measure distance",
  block: () => "Click ceiling tiles to block/unblock them",
  bulkedit: () => "Click and drag to move tables or change space between them",
  move: (device = 'microphone') => `Click and drag a ${device} to move it`,
  add: (device = 'microphone') => `Click anywhere to add a ${device}`,
  remove: (device = 'microphone') => `Click a ${device} to delete it`,
  ghostchair: () => "Click a chair to toggle it",
  armedghostchair: () => ActionText.ghostchair(),
  listen: (device = 'microphone') => `Click anywhere to hear what the ${device} will sound like`,
  ghostdock: () => "Click a loading dock to toggle it",
  undo: (device = 'microphone') => `Click to undo all ${device} customization`,
  zoom: () => "Click to toggle zoom view",
  position: (device = 'microphone') => `Mouse over a ${device} to get positioning details`,
  level: () => "Show plot level at mouse position",
  distance: (device = 'microphone') => `Show distance to closest ${device}`
}

export default Actions;
export const { move: Move, rotate: Rotate } = Actions


