import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';
import UnitInput from 'components/atoms/UnitInput';
import { getIsMeter } from 'reduxModules/ducks/ui';
import { toFeet, toMeter, distanceToString, distanceToNumber } from 'utilities/format';
import { getCurrentValue2 } from 'reduxModules/ducks/container';
import { round, isObject, get } from 'lodash';

const DistanceInput = (props) => {
  const isMeter = useSelector(getIsMeter);
  const spinStep = isMeter ? 0.1 : 0.25;
  const rounder = isMeter ? 1 : 2;

  let currentValue = useSelector(state => getCurrentValue2(state, props.dispatcher));
  // currentValue = isObject(currentValue) ? get(currentValue, props.name) : currentValue;
  // console.log("currentValue: ", currentValue);
  const [value, setValue] = useState(''); // numerical value in meters 
  const [valueString, setValueString] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    // setValue(currentValue ? toFeet(currentValue[props.name], isMeter) : '')
    
    setValue(currentValue ? toFeet(get(currentValue, props.name), isMeter) : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMeter, currentValue]);


  useEffect(() => {
    const meterValue = toMeter(value, isMeter)
    setValueString(distanceToString(meterValue, isMeter));
    if (props.dispatcher && value !== '' && currentValue[props.name] !== meterValue) 
    // dispatch(ReducersIndex.container[props.dispatcher]({ [props.name]: meterValue }));
    dispatch(ReducersIndex.container[props.dispatcher]({ ...{}, [props.name]: meterValue } ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleKeyDown = (evt) => {
    const key = evt.key;
    if (!['Delete', 'Backspace', 'Tab'].includes(key) && !(isMeter ? /^[0-9.]+$/ : /^[0-9 '"-.]+$/).test(key)) evt.preventDefault()
    const currentValue = evt.target.value
    switch (key) {
      case 'Enter':
      case 'Tab':
        setValue(distanceToNumber(currentValue, isMeter));
        break;
      case 'ArrowUp':
        setValue(round(value + spinStep, rounder));
        break;
      case 'ArrowDown':
        setValue(round(value - spinStep, rounder));
        break;
      default:
        break;
    };
  };

  const handleBlur = (evt) => {
    setValue(distanceToNumber(evt.target.value, isMeter));
  }

  props = {
    ...props,
    spinStep: spinStep,
    value: value,
    setValue: setValue,
    valueString: valueString,
    setValueString: setValueString,
    handleKeyDown: handleKeyDown,
    handleBlur: handleBlur,
  };

  return <UnitInput {...props} />
};

export default DistanceInput;