import ReducersIndex from "reduxModules/ducks";
import constants from "./constants";

/**
 * Object containing available actions for different views in the Conference Room Designer.
 * @constant
 * @type {Object.<string, Object.<string, string[]>>}
 */
const actions = {
  floorplan: {
    area: [],
  },
  ceiling: {
    area: ['block']
  },
  furnishing: {
    area: ['armedghostchair']
  },
  acoustics: {
    area: ['position', 'level', 'distance', 'move', 'add', 'remove', 'listen']
  },
  microphones: {
    area: ['position', 'distance', 'move', 'add', 'remove', 'listen']
  },
  speakers: {
    area: ['position', 'level', 'move', 'add', 'remove']
  },
  camera: {
    area: ['position', 'distance', 'move']
  },
  equipmentlist: {}
};

/**
 * Conditions for enabling certain actions based on the current state.
 * These conditions are passed from the actionBar component to the buttonGroup component,
 * which uses the getAvailableActions selector to determine which actions should be visible.
 * @constant
 * @type {Object.<string, {parameter: string[], check: function}>}
 */
export const conditions = {
  /**
   * Condition for the 'block' action.
   * Enabled when ceiling tiles are present.
   * @param {Array} tiles - The ceiling tiles state.
   * @returns {boolean} True if ceiling tiles are present, false otherwise.
   */
  block: { parameter: ['ceilingTiles'], check: ([tiles]) => tiles.tile !== 'no_ceiling_tiles' },

  /**
   * Condition for the 'armedghostchair' action.
   * Enabled when a table shape is defined.
   * @param {Object} table - The table state.
   * @returns {boolean} True if a table shape is defined, false otherwise.
   */
  armedghostchair: { parameter: ['table'], check: ([table]) => table.shape },

  /**
   * Condition for the 'level' action.
   * Enabled when the current device is 'speakers'.
   * @param {string} device - The current device.
   * @returns {boolean} True if the current device is 'speakers', false otherwise.
   */
  level: { parameter: ['currentDevice',], check: ([device]) => device === 'speakers' },

  /**
   * Condition for the 'distance' action.
   * Enabled when the current device is not 'speakers'.
   * @param {string} device - The current device.
   * @returns {boolean} True if the current device is not 'speakers', false otherwise.
   */
  distance: { parameter: ['currentDevice',], check: ([device]) => device !== 'speakers' },

  /**
   * Condition for the 'move' action.
   * The availability of the 'move' action depends on the current device and the specific microphone model.
   * 
   * @param {string} device - The current device ('microphones', 'speakers', 'cameras', or others).
   * @param {string} model - The current microphone model (only relevant when device is 'microphones').
   * @returns {boolean} True if the 'move' action should be enabled, false otherwise.
   * 
   * @description
   * - For microphones: Enabled when the microphone is not a table microphone.
   */
  move: {
    parameter: ['currentDevice', 'microphones.model'], check: ([device, model]) => {
      const isTableMic = constants.equipment.microphones[model.replace('mic', '_mic')].isTableMic ?? false
      return device === 'microphones' ? !isTableMic : true;
    }
  },

  /**
   * Condition for the 'add' action.
   * The availability of the 'add' action depends on the current device and, for microphones, the specific model.
   * 
   * @param {string} device - The current device ('microphones', 'speakers', 'cameras', or others).
   * @param {string} model - The current microphone model (only relevant when device is 'microphones').
   * @returns {boolean} True if the 'add' action should be enabled, false otherwise.
   * 
   * @description
   * - For microphones: Enabled when the microphone is not a bar or table microphone.
   * - For speakers: Enabled when the speaker is not a bar type.
   * - For cameras: Always disabled.
   * - For other devices: Always enabled.
   */
  add: {
    parameter: ['currentDevice', 'microphones.model'], check: ([device, model]) => {
      const isNotBar = !constants.equipment.microphones[model.replace('mic', '_mic')].isBarMic ?? false
      switch (device) {
        case 'microphones':
          const isNotTable = !constants.equipment.microphones[model.replace('mic', '_mic')].isTableMic ?? false;
          return isNotBar && isNotTable
        case 'speakers':
          return isNotBar
        case 'cameras':
          return false
        default:
          return true
      };
    }
  },

  /**
   * Condition for the 'remove' action.
   * The availability of the 'remove' action depends on the current device and, for microphones, the specific model.
   * 
   * @param {string} device - The current device ('microphones', 'speakers', 'cameras', or others).
   * @param {string} model - The current microphone model (only relevant when device is 'microphones').
   * @returns {boolean} True if the 'remove' action should be enabled, false otherwise.
   * 
   * @description
   * - For microphones: Enabled when the microphone is not a bar or table microphone.
   * - For speakers: Enabled when the speaker is not a bar type.
   * - For cameras: Always disabled.
   * - For other devices: Always enabled.
   */
  remove: {
    parameter: ['currentDevice', 'microphones.model'], check: ([device, model]) => {
      const isNotBar = !constants.equipment.microphones[model.replace('mic', '_mic')].isBarMic ?? false
      switch (device) {
        case 'microphones':
          const isNotTable = !constants.equipment.microphones[model.replace('mic', '_mic')].isTableMic ?? false;
          return isNotBar && isNotTable
        case 'speakers':
          return isNotBar
        case 'cameras':
          return false
        default:
          return true
      };
    }
  },

  /**
   * Condition for the 'listen' action.
   * Enabled when the current device is 'microphones'.
   * @param {string} device - The current device.
   * @returns {boolean} True if the current device is 'microphones', false otherwise.
   */
  listen: { parameter: ['currentDevice',], check: ([device]) => device === 'microphones' },
};

/**
 * Action dispatchers for specific actions in the Conference Room Designer.
 * @constant
 * @type {Object.<string, function>}
 */
export const actionDispatchers = {
  /**
   * Dispatcher for the armedghostchair action.
   * Toggles the ghost chair status when a chair is clicked.
   * @param {Event} evt - The event object.
   * @param {function} dispatch - The Redux dispatch function.
   * @param {Object} mouse - The mouse state object (unused in this function).
   */
  armedghostchair: (evt, dispatch, mouse) => {
    const chair = evt.target.closest('[class=chair]');
    const chairId = chair?.id;
    const isGhosted = parseInt(chair?.getAttribute('stroke-dasharray')) !== 0;
    if (chairId) dispatch(ReducersIndex.container.setTable({ chair: chairId, isGhosted }));
  },





  /**
   * Dispatcher for the add device action.
   * Adds a new device to the room at the specified mouse position.
   * @param {Event} evt - The event object (not used directly in the function but may be required by the caller)
   * @param {Function} dispatch - The Redux dispatch function to trigger actions
   * @param {Object} mouse - The current mouse position
   * @param {Object} drawingBox - Information about the drawing area
   * @param {Object} drawingBox.bbox - The bounding box of the drawing area
   * @param {string} device - The type of device to add (e.g., 'speaker', 'microphone')
   */
  add: (evt, dispatch, mouse, drawingBox, device) => {
    const { bbox: { x0, y0 }, scale } = drawingBox
    const location = {
      x: (mouse.x - x0) / scale,
      y: (mouse.y - y0) / scale
    }
    dispatch(ReducersIndex.container.addDevice({ device, location }))
  },

  /**
   * @function remove
   * @description Removes a device from the room based on the clicked element.
   * @param {Event} evt - The click event object
   * @param {Function} dispatch - The Redux dispatch function to trigger actions
   * @param {Object} mouse - The current mouse position (not used in this function but included for consistency with add)
   * @param {Object} drawingBox - Information about the drawing area (not used in this function but included for consistency with add)
   * @param {string} device - The type of device to remove (e.g., 'speaker', 'microphone')
   */
  remove: (evt, dispatch, mouse, drawingBox, device) => {
    const target = evt.target.closest(`[class=${device}]`)?.id;
    if (target) {
      const [area, _, deviceId] = target.split('-');
      dispatch(ReducersIndex.container.removeDevice({ device, id: deviceId }));
    };
  },


};

export default actions;
