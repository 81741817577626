import { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/molecules/Button";
import { buttonThemes } from "globalConstants/Themes";
import ReducersIndex from 'reduxModules/ducks';
import { getZoom } from "reduxModules/ducks/ui";

const ZoomButton = (props) => {
  const useTheme = buttonThemes["actionGroup"];
  const currentValue = useSelector(getZoom);
  const [buttonActive, setButtonActive] = useState();
  useEffect(() => {
    setButtonActive(currentValue)
  }, [currentValue])

  const dispatch = useDispatch()
  const handleClick = (evt) => {
    setButtonActive(!buttonActive);
    dispatch(ReducersIndex.uiReducers.setZoom());
  };
  const mouseEnter = props.handleEnter ? (e) => props.handleEnter(e, 'zoom') : null;
  return (
    <ThemeProvider theme={{ background: useTheme[buttonActive ? 'checked' : 'unchecked'] }} >
      <ZoomButton.Wrapper className="me-1" onMouseEnter={mouseEnter} onMouseLeave={props.handleLeave} >
        <Button theme={useTheme} name={'zoom'} state={buttonActive ? 'button_selected' : 'button_disabled'} onClick={handleClick} />
      </ZoomButton.Wrapper>
    </ThemeProvider>
  );
};

ZoomButton.Wrapper = styled.div.attrs((props) => ({}))`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.color};
  height: 36px
`;

export default ZoomButton;