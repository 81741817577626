import { distanceToString } from "utilities/format";
import constants from "toolConstants/warehouseDesigner/constants";
import { get, values, upperCase, capitalize, startCase, findKey } from 'lodash'

const reportDocument = {
  metadata: {
    title: 'Warehouse Designer Report',
    author: 'Biamp',
    subject: 'Warehouse Designer results report',
    creator: 'Biamp'
  },

  body: [
    {
      id: 'header',
      content: [
        {
          element: 'header',
          content: 'currentTool'
        },
        {
          element: 'headerSubTitle',
          content: 'project.name'
        },
      ]
    },
    {
      id: 'project',
      content: [
        {
          element: 'stringField',
          label: 'Design Company',
          content: 'project.company'
        },
        {
          element: 'stringField',
          label: 'Design Engineer',
          content: 'project.designer'
        },
        {
          element: 'valueField',
          label: 'Date Generated',
          content: new Date().toString().split(/\(/g)[0]
        },
        {
          element: 'linkField',
          label: 'Design Location',
          content: window.location.href,
        }
      ]
    },
    {
      id: 'container',
      content: [
        {
          element: 'title',
          content: 'name',
        },
        {
          element: 'subTitle',
          content: 'WAREHOUSE DETAILS',
        },
        {
          element: 'valueField',
          label: 'Warehouse Dimensions',
          content: (state) => `${distanceToString(state.dimensions.length, state.isMeter)} x ${distanceToString(state.dimensions.width, state.isMeter)}`
        },
        {
          element: 'stringField',
          label: 'Roof Type',
          content: 'roofType'
        },
        {
          element: 'valueField',
          label: (state) => `Roof Height${get(state, 'roofType') !== 'flat' ? ' Range' : ''}`,
          content: (state) => `${distanceToString(state.roofHeight.min, state.isMeter)} ${state.roofType !== 'flat' ? 'to ' + distanceToString(state.roofHeight.max, state.isMeter) : ''}`
        },
        {
          element: 'valueField',
          label: (state) => `${state.roofGrid ? 'Roof Grid Spacing' : ''}`,
          content: (state) => `${state.roofGrid ? distanceToString(state.roofGridSpacing.x, state.isMeter) + ' x ' + distanceToString(state.roofGridSpacing.y, state.isMeter) : ''}`
        },
        {
          element: 'valueField',
          label: (state) => `${state.roofGrid ? 'Roof Grid Offset' : ''}`,
          container: (state) => `${state.roofGrid ? distanceToString(state.roofGridOffset.dx, state.isMeter) + ' x ' + distanceToString(state.roofGridOffset.dy, state.isMeter) : ''}`
        },
        {
          element: 'stringField',
          label: 'Ceiling Material',
          content: 'materials.ceiling'
        },
        {
          element: 'stringField',
          label: 'Wall Material',
          content: 'materials.walls'
        }
      ]
    },
    {
      id: 'container-layout',
      content: [
        {
          element: 'subTitle',
          content: 'SPEAKER COVERAGE'
        },
        {
          element: 'plot',
          content: (state) => state,
          consts: ['container.baySize', 'area.storage', 'devices']
        },
      ]
    },
    {
      id: 'equipmentList',
      wrap: true,
      content: [
        {
          element: 'subTitle',
          content: 'WAREHOUSE EQUIPMENT LIST'
        },
        {
          element: 'table',
          content: (state) => state,
          consts: ['equipmentLists.summary']
        }
      ]
    },
  ],

  areas: (state) => values(state.areas).reduce((result, area) => {
    if (!area.coverage.none && area.speakers) {
      result.push(
        {
          id: area.id,
          content: [
            {
              element: 'title',
              content: area.name,
            },
            {
              element: 'subTitle',
              content: `${upperCase(area.areaType)} DETAILS`,
            },
            {
              element: 'valueField',
              label: `${capitalize(area.areaType)} Dimensions: `,
              content: `${distanceToString(area.dimensions.length, state.isMeter)} x ${distanceToString(area.dimensions.width, state.isMeter)}`
            },
            {
              element: 'valueField',
              label: `${capitalize(area.areaType)} Location: `,
              content: `${distanceToString(area.location.left, state.isMeter)} - ${distanceToString(area.location.top, state.isMeter)}`
            },
            {
              element: 'valueField',
              label: `${area.openCeiling ? '' : capitalize(area.areaType) + ' Ceiling Height: '}`,
              content: `${area.openCeiling ? '' : distanceToString(area.ceilingHeight.min, state.isMeter)}`
            },
            {
              element: 'valueField',
              label: 'Mounting Height: ',
              content: `${distanceToString(area.mountingHeight.min, state.isMeter)} to ${distanceToString(area.mountingHeight.max, state.isMeter)}`
            },
            {
              element: 'stringField',
              label: 'Area Usage: ',
              content: `${constants.area.tooltips[area.areaUsage]}`
            },
            {
              element: 'valueField',
              label: `${area.areaUsage === 'palletRack' ? 'Picking Aisles: ' : ''}`,
              content: `${area.areaUsage === 'palletRack' ? area.pickingAisles : ''}`
            },
            {
              element: 'valueField',
              label: `${area.areaUsage === 'palletRack' ? 'Primary Aisles: ' : ''}`,
              content: `${area.areaUsage === 'palletRack' ? area.primaryAisles : ''}`
            },
            {
              element: 'valueField',
              label: 'Noise Level: ',
              content: `${area.noiseLevel} dBA`
            },
            {
              element: 'stringField',
              label: `${values(area.surroundingWalls).some(x => x) ? 'Wall Material: ' : ''}`,
              content: `${values(area.surroundingWalls).some(x => x) ? startCase(area.materials.walls) : ''}`
            },
            {
              element: 'stringField',
              label: `${area.openCeiling ? '' : 'Ceiling Material: '}`,
              content: `${area.openCeiling ? '' : startCase(area.materials.ceiling)}`
            },
            {
              element: 'stringField',
              label: 'Area Coverage: ',
              content: `${!area.customized.qty ? startCase((findKey(area.coverage, (key) => key) ?? 'custom')) : ''}`
            },
            {
              element: 'stringField',
              label: 'System Primary Usage: ',
              content: `${area.systemPrimaryUsage ? constants.area.tooltips[area.systemPrimaryUsage] : ''}`
            },
            {
              element: 'stringField',
              label: 'Budget: ',
              content: `${area.budget}`
            },
          ]
        },
        {
          id: `${area.id}-layout`,
          content: [
            {
              element: 'subTitle',
              content: 'SPEAKER COVERAGE'
            },
            {
              element: 'plot',
              content: (state) => ({ ...state, areas: [state.areas[area.id]] }),
              consts: ['container.baySize', 'area.storage', 'devices']
            },
          ]
        },
        {
          id: 'equipmentList',
          wrap: true,
          content: [
            {
              element: 'subTitle',
              content: `${upperCase(area.name)} EQUIPMENT LIST`
            },
            {
              element: 'table',
              content: (state) => ({ ...state, areas: [state.areas[area.id]] }),
              consts: ['equipmentLists.positioningDetail']
            }
          ]
        },
      )
    }
    return result

  }, []),

};

export default reportDocument;