import toolIndex from "toolConstants";
import ReducersIndex from 'reduxModules/ducks';
import { capitalize, findLast, isEmpty, keys, toPairs, pick } from "lodash";
import { getAreaList, getAvailableArea } from "reduxModules/ducks/container";

const connectorMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  let store = getState();

  // Set Active Tab to latest added area or container
  const setTab = () => {
    if (keys(tab).length === 0) {
      ({ id: tab.id, name: tab.name } = findLast(store.container.areas));
    };
    dispatch(ReducersIndex.uiReducers.updateTab(tab));
    dispatch(ReducersIndex.uiReducers.setActiveTab(tab.id));
  };

  // Pre-action items: These needs to run before the reducers are executed
  switch (true) {
    case action.type === ReducersIndex.uiReducers.closeModal.type && action.payload === 'tutorial':
      if (store.ui.modalReopen) dispatch(ReducersIndex.uiReducers.openModal(store.ui.modalReopen));
      break;
    case action.type === ReducersIndex.uiReducers.openModal.type && action.payload === 'tutorial':
      const firstTimer = store.ui.isFirstTimeUser
      const currentModal = store.ui.modals.find(modal => modal.modalName !== 'tutorial' && modal.modalOpen)?.modalName;
      const target = toolIndex[store.ui.tool]?.modals['launch'].target;
      dispatch(ReducersIndex.uiReducers.setModalReopen(firstTimer ? target : currentModal));
      if (currentModal) dispatch(ReducersIndex.uiReducers.closeModal(currentModal));
      break;

    case action.type === ReducersIndex.uiReducers.setAllowAddArea.type:
      // console.log('pass here ', action.payload);
      if (action.payload === undefined) {
        const areas = getAreaList(store);
        action.payload = areas.at(-1) === store.container.activeTab && areas.length < toolIndex[store.ui.tool].constants.tool.maximumAreas && !isEmpty(getAvailableArea(store.container));
      };
      break;

    case action.type === ReducersIndex.container.setLoadingDocks.type:
      const id = action.payload;
      const hasArea = id in store.container.areas;
      const currentState = store.container.loadingDocks[id]; // defines if we're adding or deleting
      const name = `${capitalize(id).replace('_', ' ')}`;
      dispatch(ReducersIndex.container.setAreaToUpdate({ id: id, name: name, areaType: 'dock' }));

      if (store.ui.isModalOpen) break
      if (currentState && hasArea) {
        dispatch(ReducersIndex.uiReducers.openModal('removeDockArea'));
      } else if (!currentState && !hasArea) {
        dispatch(ReducersIndex.uiReducers.openModal('addDockArea'));
      } else {
        dispatch(ReducersIndex.container.setAreaToUpdate(null));
      };

      break;

    case action.type === ReducersIndex.container.deleteArea.type:
      // Remove the tab after deleting and navigate to warehouse 
      const areaId = store.container.areaToUpdate.id;
      dispatch(ReducersIndex.uiReducers.deleteTab(areaId));
      dispatch(ReducersIndex.uiReducers.setActiveTab(store.ui.tabs[0].id));
      break;

    case action.type === ReducersIndex.container.setSpeakerModel.type:
      const newSpeaker = action.payload;
      const currentSpeaker = store.container.areas[store.container.activeTab].speakerModel;
      if (currentSpeaker && newSpeaker && newSpeaker !== currentSpeaker) {
        dispatch(ReducersIndex.uiReducers.openModal('changeSpeaker'));
        const customParameters = toolIndex[store.ui.tool].constants.customSettings.speaker.model;
        dispatch(ReducersIndex.container.setCustomized(customParameters));
      };
      break;


    default:
      break;
  }

  // Execute the reducer and get the state before proceding 
  next(action);

  store = getState();
  const tab = {};
  const toolConstants = toolIndex[store.ui.tool]?.constants.tool;
  // Post-action items: Theses actions happen after the reducer is executed as they require current state
  const areas = keys(store.container.areas);

  switch (true) {

    case action.type === ReducersIndex.uiReducers.setTool.type:
      if (toolConstants.containerName) {
        tab.id = `_${toolConstants.containerName.toLowerCase()}`;
        tab.name = toolConstants.containerName;
      }
    // fall through
    case action.type === ReducersIndex.container.addArea.type:
      dispatch(ReducersIndex.uiReducers.setAllowAddArea(areas.length < toolConstants.maximumAreas));
      setTab();
      break;

    case action.type === ReducersIndex.uiReducers.modalForward.type:
      if (store.ui.modalForward) {
        // todo: this is warehouse Specific so it shoukld live somewhere else 
        if (areas.length < 1) {
          const docks = store.container.loadingDocks;
          for (const [dock, value] of toPairs(docks)) {
            if (value) {
              const name = `${capitalize(dock).replace('_', ' ')}`;
              dispatch(ReducersIndex.container.setAreaToUpdate({ id: dock, name: name, areaType: 'dock' }))
              dispatch(ReducersIndex.container.addArea({ areaToAdd: 'areaToUpdate' }));
            };
          };
          dispatch(ReducersIndex.container.addArea());
        } else {
          const key = areas[areas.findIndex(k => k === store.container.activeTab) + 1];
          dispatch(ReducersIndex.uiReducers.setActiveTab(key));
        };
      } else {
        dispatch(ReducersIndex.uiReducers.setAllowAddArea(false));
        const index = areas.indexOf(store.container.activeTab);
        dispatch(ReducersIndex.uiReducers.setActiveTab(index <= 0 ? store.ui.tabs[0].id : areas[index - 1]))
      };
      break;



    case action.type === ReducersIndex.container.setDimensions.type:
    case action.type === ReducersIndex.container.setLoadingDocks.type:
    case action.type === ReducersIndex.container.deleteArea.type:
      if (toolConstants.container) {
        dispatch(ReducersIndex.uiReducers.setAllowAddArea(!isEmpty(getAvailableArea(store.container))));
      };
      if (keys(store.container.areas).length < 1 && store.ui.exitToRoom) {
        dispatch(ReducersIndex.container.addArea());
        dispatch(ReducersIndex.uiReducers.openModal('area'));
      }
      const currentTable = store.container.areas[store.container.activeTab]?.table;
      if (currentTable?.shape) {
        dispatch(ReducersIndex.container.setTable(pick(currentTable, ['shape', 'size'])));
      };

      break



    default:
      // next(action)
      break;

  }


};




export default connectorMiddleware;
// This is listening to everything !