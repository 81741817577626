
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import SplashScreen from 'components/organisms/SplashScreen';
import Page from 'components/templates/Page';
import ModalManager from 'components/services/ModalManager';
import ToolContext from 'components/services/ToolContext';
import ReducersIndex from 'reduxModules/ducks';
import { camelCase } from 'lodash';

import Load from 'components/services/Load';
import isEmpty from 'lodash/isEmpty';

// NOTE: tHE COMMENTED OUT STUFF DOES ADD A SLICE TO THE STORE BUT NOT INSIDE EXISTING SLICE BUT AS ANOTHER SLICE ALTOGETHER
// import ceiling, {ceilingReducers} from 'reduxModules/ducks/ceiling';
// import { useStore } from 'react-redux';
// import { createAction } from '@reduxjs/toolkit';

const Main = ({ tool }) => {
  const redirect = tool.constants.tool.goto;
  if (redirect) window.location.href = redirect;

  // // new code
  // const store = useStore()
  // // console.log("store: ", tool);
  // if(tool.constants.tool.fullName === 'Conference Room Designer' ) {
  //   store.injectReducer('ceiling', ceiling)
  
  // }

  // console.log("Redu: ", ReducersIndex);



  // const c = useStore()
  // console.log("c.injectReducer(): ", blah);
  // c.injectReducer('setBlah', createAction(blah.setBlah))
  // new code end

  const [splashOpen, setSplashOpen] = useState(true);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [firstTimeUser, setFirstTimeUser] = useState(true);
  const isFirstTimeUser = useSelector(state => state.ui.isFirstTimeUser);
  const { search } = useLocation();
  const target = tool.modals['launch'].target;
  const cookieName = tool.constants.tool.cookie;

  const [cookies, setCookie] = useCookies([cookieName]);

  const dispatch = useDispatch();

  

  
  const toolName = camelCase(tool.constants.tool.fullName) //useSelector(state => state.ui.tool);
  const [name, setName] = useState(toolName);

  useEffect(() => {
    // const name = ReducersIndex.uiReducers.setTool(tool.name)
    // console.log("tool: ", tool, name, toolName);
    // dispatch(name);
    // setName(name)

    dispatch(ReducersIndex.uiReducers.setTool(toolName));
    setName(toolName)
    // eslint-disable-next-line
  }, [toolName]);

  // Deal with the cookies thing
  useEffect(() => {
    if (!isEmpty(cookies) && cookieName in cookies) {
      dispatch(ReducersIndex.uiReducers.setIsFirstTimeUser(false));
      setFirstTimeUser(false);
    } 
  }, [cookies])

  useEffect(() => {
    if (!isFirstTimeUser) {
      const date = new Date();
      const today = date.getDate();
      date.setMonth(date.getMonth() + 6);
      if (date.getDate() !== today) {
        date.setDate(0)
      };
      setCookie(cookieName, true, {expires : date})
    }
  }, [isFirstTimeUser] )

  const closeSplash = (e) => {
    setSplashOpen(false);
    setProjectLoaded(true);
    dispatch(ReducersIndex.uiReducers.openModal(firstTimeUser ? 'tutorial' : target));
  };

  const headerRef = useRef(null);
  const [top, setTop] = useState(0);
  useEffect(() => {
    if (headerRef.current) {
      const bbox = headerRef.current.getBoundingClientRect()
      setTop(bbox.bottom);
    }
  }, [splashOpen, projectLoaded]);


  return (
    <div className='flex-grow-1 container-fluid p-2'>
      <ToolContext.Provider value={tool}>
        {name && <Load params={search} setProjectLoaded={setProjectLoaded} setSplashOpen={setSplashOpen} target={target} setFirstTimeUser={setFirstTimeUser}/>}
        {splashOpen && !projectLoaded && <SplashScreen closeSplash={closeSplash} />}
        {(!splashOpen && projectLoaded) && <Page ref={headerRef} showChild={!firstTimeUser}/>}
        <ModalManager top={top}/>
      </ToolContext.Provider>
    </div>
  )
};

export default Main;