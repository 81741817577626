import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getReload } from "reduxModules/ducks/ui";
import isBoolean from "lodash/isBoolean";



const useSetURLparams = () => {
  const baseURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
  const savekey = useSelector(state => state.ui.savekey);
  const ver = useSelector(state => state.ui.revision);
  const reload = useSelector(getReload);
  let saveURL, searchParams
  if (savekey) {
    searchParams = new URLSearchParams({ savekey: savekey, ver: ver })
    if (isBoolean(reload) && reload) searchParams.delete('ver')
    saveURL = `${baseURL}?${searchParams}`;
  } else {
    saveURL = baseURL
  };

  useEffect(() => {
    window.history.pushState({ path: saveURL }, '', saveURL);
    // eslint-disable-next-line
  }, [searchParams]);
}

export default useSetURLparams;
