/**
 * @module ActionBar
 * @description A component that renders a bar of action buttons for the Conference Room Designer.
 */

import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'components/molecules/ButtonGroup';
import ToolContext from 'components/services/ToolContext';
import ZoomButton from 'components/molecules/ZoomButton';
import UndoButton from 'components/molecules/UndoButton';
import ReducersIndex from 'reduxModules/ducks';
import { ActionText } from 'globalConstants/Icons/Actions';
import { getCurrentView } from 'reduxModules/ducks/ui';

import { getArea, getAreaProperty2, getAvailableActions, getCurrentValue2 } from 'reduxModules/ducks/container';
import { has, some, trimEnd } from 'lodash';

/**
 * @function ActionBar
 * @description Renders a bar of action buttons based on the current view and context.
 * @param {Object} props - The component props
 * @returns {JSX.Element} The rendered ActionBar component
 */
const ActionBar = (props) => {
  // State and selector hooks
  const currentView = useSelector(getCurrentView); // active menu
  const showing = useSelector(state => getCurrentValue2(state, 'showing')); // container or area 
  const { actions: act, actionConditions: conditions } = useContext(ToolContext);
  const items = ['measure'].concat(act?.[currentView]?.[showing] ?? []); // list of possible actions to show 
  const actions = useSelector(state => getAvailableActions(state, { items: items, conditions: conditions }));
  const currentDevice = useSelector(state => getCurrentValue2(state, 'currentDevice'));

  const dispatch = useDispatch();
  const button = useSelector(state => state.ui.action)
  const [buttonActive, setButtonActive] = useState(button);
  const [toolTip, setToolTip] = useState('');
  const [delay, setDelay] = useState('0s');

  // Effect to sync buttonActive with Redux state
  useEffect(() => {
    if (items.includes(button)) setButtonActive(button)
    if (!button) dispatch(ReducersIndex.uiReducers.setAction(buttonActive));
  }, [button])

  // Event handlers
  const handleEnter = (evt, item) => {
    setToolTip(ActionText[item](trimEnd(currentDevice, 's')));
    setDelay('0s');
  };

  const handleLeave = () => setDelay('.5s');

  const handleClick = (evt, item) => {
    setButtonActive(item);
    setDelay('2.5s');
    dispatch(ReducersIndex.uiReducers.setAction(item));
  };

  // Helper functions
  const getState = (item) => buttonActive === item ? 'button_selected' : 'button_disabled';
  const checked = (item) => buttonActive === item;



  // Context and selectors for conditional rendering
  const hasContainer = useContext(ToolContext).constants.tool.containerName
  const inArea = useSelector(getArea);
  const showUndo = useSelector(state => {
    const customized = getAreaProperty2(state, 'customized');
    return has(customized, currentDevice) ? customized[currentDevice] : some(customized);
  });

  props = {
    ...props,
    items: actions,
    handleClick: handleClick,
    handleEnter: handleEnter,
    handleLeave: handleLeave,
    getState: getState,
    checked: checked,
  };

  return (
    <ActionBar.Wrapper >
      {hasContainer && inArea && <ZoomButton {...props} />}
      <ButtonGroup theme={'actionGroup'} {...props} />
      {showUndo && <UndoButton {...props} />}
      <ActionBar.ToolTip delay={delay}>{toolTip}</ActionBar.ToolTip>

    </ActionBar.Wrapper>
  )
};

/**
 * @component ActionBar.Wrapper
 * @description Styled component for the ActionBar wrapper
 */
ActionBar.Wrapper = styled.div.attrs(props => ({
  className: 'my-1 hstack'
}))`
`;

/**
 * @component ActionBar.ToolTip
 * @description Styled component for the ActionBar tooltip
 */
ActionBar.ToolTip = styled.div.attrs(props => ({
  className: 'ms-2'
}))`
  color: var(--app-red);
  opacity: ${props => props.delay === '0s' ? 1 : 0};
  transition: ${props => `${props.delay === '0s' ? '0.2s' : '0.5s'} opacity ease ${props.delay}`};
`;

export default ActionBar;