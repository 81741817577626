import { Fragment } from 'react';
import { View, Image } from '@react-pdf/renderer';
import { isNull, values } from 'lodash';

const Canvas = ({ content }) => {
  const { inContainer, areas: innerAreas, scale } = content;
  const areas = [...values(innerAreas).map(area => ({
    id: area.id,
    ...area.location,
    ...area.dimensions,
    aisles: area.aislesArray
  }))];

  return (
    <Fragment>
      {areas.map((area, index) => {
        const id = `${area.id}-heatmap`;
        const anchor = {
          position: 'absolute',
          top: inContainer ? area.top * scale : 0,
          left: inContainer ? area.left * scale : 0,
          width: area.length * scale,
          height: area.width * scale,
        };

        const children = document.getElementById(`${id}`).getElementsByTagName('canvas')

        return (
          <View key={index} style={anchor} >
            {Array.from(children).map((child, jndex) => {
              const id = child.id;
              const regex = /(?<type>[a-z]+)(?<aisleIndex>\d+)/g;
              const { type = null, aisleIndex = null } = regex.exec(id.split(' ').pop())?.groups || {};
              const aisle = ((type && aisleIndex) ? area.aisles[+aisleIndex] : { top: 0, left: 0, length: 0, width: 0 });
              const style = {
                position: 'absolute',
                top: 1 + aisle.top * scale + !isNull(type),
                left: 1 + aisle.left * scale + !isNull(type),
                width: aisle.length ? aisle.length * scale : anchor.width,
                height: aisle.width ? aisle.width * scale : anchor.height
              };

              return (
                <View key={jndex} style={style} >
                  <Image src={child.toDataURL("image/png")} />
                </View>
              )
            })}
          </View>
        )
      })}
    </Fragment>
  )
};

export default Canvas;


