// this is currently only being used in useExport - which need to change!
import { round, toNumber, floor } from 'lodash';

export const toMeter = (value, isMeter = false) => round((isMeter ? value : (value || 0) * 0.3048 || 0), 4);
export const toFeet = (value, isMeter = false) => isMeter ? value : round((value || 0) / 0.3048, 2) || 0;

export const distanceToString = (value, isMeter = true) => {
  if (isMeter) {
    return `${value ? round(toNumber(value), 1) + ' m' : '0 m'}`;
  } else {
    value = round(toNumber(value) / 0.3048, 2);
    let feet = floor(value);
    let inches = round(12 * (value - feet));
    feet += inches === 12 ? 1 : 0;
    inches = inches === 12 ? 0 : inches;
    return `${value ? (feet ? feet + "'" : "") + ((feet && inches) ? " " : "") + (inches ? inches + '"' : "") : "0'"}`
  }
};

export const distanceToNumber = (value, isMeter = true) => {
  if (!value) return 0
  if (isMeter) {
    return parseFloat(value);
  } else {
    const hasInch = value.indexOf('"');
    const splits = value.split(/['" -]/).filter(Boolean).map(x => toNumber(x.match(/^(([0-9]+)?(\.)?([0-9]+)?)$/)?.[0])).flat();
    return splits.length === 1 ? splits[0] / (hasInch > 0 ? 12 : 1) : splits[0] + splits[1] / 12;
  };
};

export const parseDistance = (value) => round(value, 4)