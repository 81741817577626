const AreaUsage = {
  area_usage: ({ state }) =>
    <g stroke={state.icon_stroke} fill='none' strokeWidth='1.5' strokeMiterlimit='0' transform='translate(2.5 2.5) scale(.9)'>
      <path d="m13.3 28.2a12.2 12.2 0 001 2.6l-1.32 1.665a1.4 1.4 0 00.102 1.8l2.3 2.3a1.4 1.4 0 001.8.1l1.6-1.3a12 12 0 002.7 1.1
        l.2 2.1a1.4 1.4 0 001.4 1.2h3.2a1.4 1.4 0 001.3-1.2l.2-2a12.1 12.1 0 002.9-1.2l1.6 1.3a1.4 1.4 0 001.8-.1l2.3-2.3a1.4 1.4 0 00.1-1.8
        l-1.2-1.6a11.9 11.9 0 001.2-2.8l1.9-.2a1.4 1.4 0 001.2-1.35v-3.2a1.4 1.4 0 00-1.2-1.35l-1.9-.2a12 12 0 00-1.1-2.8l1.2-1.5
        a1.4 1.4 0 00-.1-1.8l-2.3-2.3a1.4 1.4 0 00-1.8-.1l-1.45 1.1a12 12 0 00-3-1.2l-.2-1.8a1.4 1.4 0 00-1.35-1.2h-3.2a1.4 1.4 0 00-1.35 1.2
        l-.2 1.8a12 12 0 00-3 1.3l-1.5-1.2a1.4 1.4 0 00-1.8.1l-2.3 2.3a1.4 1.4 0 00-.1 1.8l1.3 1.6a11.7 11.7 0 00-1.1 2.8l-2 .23
        a1.4 1.4 0 00-1.2 1.35v3.2a1.4 1.4 0 001.2 1.35zm11.7-10.7a7.3 7.3 0 11-7.3 7.3 7.3 7.3 0 017.3-7.3z"   />
    </g>,

  palletRack: ({ state }) =>
    <g >
      <path d='m8 12v30v-2l1 -1h32l1 1v2v-13l-1 -1h-32l-1 1v-11l1-1h32l1 1v-6v17' stroke={state.icon_stroke} fill='none' />
      <path d='m10 8h2l1 1h2l1 -1h2 v7h-8zm11 0h2l1 1h2l1 -1h2 v7h-8zm11 0h2l1 1h2l1 -1h2 v7h-8z
       m-22 11h2l1 1h2l1 -1h2 v7h-8zm11 0h2l1 1h2l1 -1h2 v7h-8zm11 0h2l1 1h2l1 -1h2 v7h-8z
       m-22 11h2l1 1h2l1 -1h2 v7h-8zm11 0h2l1 1h2l1 -1h2 v7h-8zm11 0h2l1 1h2l1 -1h2 v7h-8z' stroke='none' fill={state.icon_fill} />
    </g>,

  pallet: ({ state }) =>
    <g fill={state.icon_fill} strokeWidth='1' strokeMiterlimit='0'>
      <path d='m12 20h2l1 1h2l1-1h2v7h-8zm9 0h2l1 1h2l1-1h2v7h-8zm9 0h2l1 1h2l1-1h2v7h-8z
    m-18 8.5h2l1 1h2l1-1h2v7h-8zm9 0h2l1 1h2l1-1h2v7h-8zm9 0h2l1 1h2l1-1h2v7h-8z
    m-18 8.5h26a1 1 0 0 1 0 2h-26a1 1 0 0 1 0-2m1 2v2h4v-2zm10 0v2h4v-2zm10 0v2h4v-2z' />
    </g>,

  automatedHandling: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='1' strokeMiterlimit='0'>
      <path d='m10 28h2l1 1h2l1-1h2v7h-8zm11-6h2l1 1h2l1-1h2v7h-8zm11 6h2l1 1h2l1-1h2v7h-8z
        m-8-22h2v6a2 2 0 0 1 2 2 l7 7-5 5 0-1.5 2-3.5-7-7-7 7 2 3.5 0 1.5-5-5 7-7a2 2 0 0 1 2-2
        m-15 25.75a1 1 0 0 1 0 4 1 1 0 0 1 0-4m10 0a1 1 0 0 1 0 4 1 1 0 0 1 0-4
        m11 0a1 1 0 0 1 0 4 1 1 0 0 1 0-4m11 0a1 1 0 0 1 0 4 1 1 0 0 1 0-4
        ' stroke='none' />
      <path d='m8 37h34a1 1 0 0 1 0 5.5h-34a1 1 0 0 1 0-5.5' fill='none' />
    </g>,

  manualHandling: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='1' strokeMiterlimit='0'>
      <path d='m10 28h2l1 1h2l1-1h2v7h-8zm11 0h2l1 1h2l1-1h2v7h-8zm11 0h2l1 1h2l1-1h2v7h-8z
      m-23 9.75a1 1 0 0 1 0 4 1 1 0 0 1 0-4m10 0a1 1 0 0 1 0 4 1 1 0 0 1 0-4
                 m11 0a1 1 0 0 1 0 4 1 1 0 0 1 0-4m11 0a1 1 0 0 1 0 4 1 1 0 0 1 0-4' stroke='none' />
      <path d='m8 37h34a1 1 0 0 1 0 5.5h-34a1 1 0 0 1 0-5.5m3-10c.5-6 11.5-6 12 0 m-6-11a1 1 0 0 1 0 5 1 1 0 0 1 0 -5
      m10 11c.5-6 11.5-6 12 0 m-6-11a1 1 0 0 1 0 5 1 1 0 0 1 0 -5' fill='none' />
    </g>,

  trafficArea: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='1' strokeMiterlimit='0'>
      <path d='m29 11v28h-3a1 1 0 1 0-6.5 0h-3a1 1 0 1 0-6.5 0h-2v-6h4v-2a1 1 0 0 1-4 0a1 1 0 0 1 4 0
      v-7h7l5 8h-2l-4 -6h-4.5v6 h10.5l1.2 2h1.5v-23zm3 4h2l1 1h2l1 -1h2 v7h-8zm0 8h2l1 1h2l1 -1h2 v7h-8z' stroke='none' />
      <path d='m30.25 17v15h12 m-21.5 7.5a1 1 0 0 1 4 0a1 1 0 0 1 -4 0m-9.5 0a1 1 0 0 1 4 0a1 1 0 0 1 -4 0' fill='none' strokeWidth='1.5' />
    </g>,
};

export default AreaUsage;