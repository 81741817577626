import Header from "components/sections/Header";
import { HeaderSubTitle, Title, SubTitle } from "components/sections/Titles";
import { StringField, LinkField, ValueField } from "components/sections/Fields";
import Plot from "components/sections/Plot";
import Table from "components/sections/Table";

const reportElements = {
  header: Header,
  headerSubTitle: HeaderSubTitle,
  title: Title,
  subTitle: SubTitle,
  stringField: StringField,
  linkField: LinkField,
  valueField: ValueField,
  plot: Plot,
  table: Table,
};

export default reportElements;