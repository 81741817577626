// This are reducers to add dynamically to the store
import { createSelector, current } from "@reduxjs/toolkit";
import { createCachedSelector } from 're-reselect';
import toolIndex from "toolConstants";
import { isEmpty } from "lodash";
import { getActiveTab, getAreaProperty } from "./container";

const classroomSlice = {
  reducers: {

    // todo: can this be one setter in room? beware of the 's'
    setTables: (state, action) => {
      const currentTables = state.areas[state.activeTab].tables;
      let defaultTables = toolIndex[state.currentTool].constants.tables[action.payload.shape]?.default ?? {};

      if (!isEmpty(defaultTables)) defaultTables = {...defaultTables, rows: 2, tablesPerRow: 4, totalTables: 8} // reset stuff
      const newTables = { ...action.payload, ...defaultTables };
      // if (currentTable.shape === 'round') newTable.width = newTable.length;
      state.areas[state.activeTab].tables = { ...currentTables, ...newTables }
    },

    setInstructor: (state, action) => {
      const currentInstructor = state.areas[state.activeTab].instructor;
      const defaultTable = toolIndex[state.currentTool].constants.instructor[action.payload.shape]?.default ?? {};
      const newTable = { ...action.payload, ...defaultTable };
      state.areas[state.activeTab].instructor = { ...currentInstructor, ...newTable }
    }

    // setAreaOrientation: (state, action) => {
    //   state.areas[state.activeTab].areaOrientation = action.payload
    // },

    // setCeilingTiles: (state, action) => {
    //   state.areas[state.activeTab].ceilingTiles = { ...state.areas[state.activeTab].ceilingTiles, ...action.payload }
    // },

    // setTable: (state, action) => {
    //   const currentTable = state.areas[state.activeTab].table;
    //   const defaultTable = toolIndex[state.currentTool].constants.tables[action.payload.shape]?.default ?? {};
    //   const newTable = { ...action.payload, ...defaultTable };
    //   if (currentTable.shape === 'round') newTable.width = newTable.length;
    //   state.areas[state.activeTab].table = { ...currentTable, ...newTable }
    // },

    // setMicrophones: (state, action) => {
    //   const currentMics = state.areas[state.activeTab].microphones;
    //   const newMic = { ...action.payload };
    //   if ('model' in newMic) {
    //     newMic.height = toolIndex[state.currentTool].constants.equipment.microphones[newMic.model.replace('mic', '_mic')].height(state);
    //     newMic.coverage = ['ceilingmic', 'pendantmic'].includes(newMic.model) ?
    //       state.areas[state.activeTab].microphones.coverage ?? 'focused' : null;
    //   };
    //   state.areas[state.activeTab].microphones = { ...currentMics, ...newMic }
    //   // todo: Setting mic to videobar set camera to false
    //   // todo: audio/videobar change family to Parle
    // },

    // setAcoustics: (state, action) => {
    //   state.areas[state.activeTab].acoustics = action.payload
    // },

    // setSpeakers: (state, action) => {
    //   const currentSpkrs = state.areas[state.activeTab].speakers;
    //   const newSpeaker = { ...action.payload };
    //   state.areas[state.activeTab].speakers = { ...currentSpkrs, ...newSpeaker }
    // },

    // setCameras: (state, action) => {
    //   const currentCameras= state.areas[state.activeTab].cameras;
    //   const newCamera = { ...action.payload };
    //   state.areas[state.activeTab].cameras = { ...currentCameras, ...newCamera }
    // },

    // setProductFamily: (state, action) => {
    //   state.areas[state.activeTab].productFamily = action.payload
    // },

  },

}

export const getTotalStudents = createSelector(
  state => getAreaProperty(state, 'tables'),
  // todo: calculate total students
  // CD: counts the ghost chair arrays that are false
  // new implementation
  // Get the total number of chairs and substract the ghosted chairs

  (tables) => {
    // console.log("tab: ", tables);
    return 'TBD'
  }
)



export default classroomSlice