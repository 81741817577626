import styled from "styled-components";
import Conveyor from "components/elements/storageElements/conveyors/Conveyor";

const Split = ({ ver = true, x = 0, y = 0, full = true, lin = 2, lsplit = 3, lcont = 2, lout = 2, flipH = false, flipV = false }) => {
  const wth = full ? 1.45 : .75; // with of the rolls 
  const width = 2 * wth + .5; // double width is used for the split 
  const length = lin + lsplit + Math.max(lcont, lout);
  const transform = `${ver ? '' : `rotate(${ver ? 0 : 90}) translate(${y - x}, -${y + x + width}) `} 
  scale(${1 - 2 * flipV}, ${1 - 2 * flipH}) translate(-${flipV * (2 * x + length)}, ${flipH * (- 2 * y - width)})`;

  return (
    <Split.Group transform={transform}>
      <Conveyor x={x} y={y} full={full} length={lin} />
      <Conveyor x={x + lin} y={y} full={full} length={lsplit} double={true} />
      <Conveyor x={x + lin + lsplit} y={y} full={full} length={lcont} />
      <Conveyor x={x + lin + lsplit} y={y + 1.1 + full * .7} full={full} length={lout} />
    </Split.Group>
  )
};

Split.Group = styled.g.attrs(props => ({
  transform: props.transform
}))`
  stroke-width: 1;
`;

export default Split;