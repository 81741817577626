const Navigation = {
  floor_plan: ({ state }) =>
    <g fill="none" stroke={state.icon_stroke} >
      <path d="m13 28v9h9m6 0h9v-9m0-6v-9h-9m-6 0h-9v9" />
    </g>,

  roof: ({ state }) =>
    <g stroke={state.icon_stroke} fill="none" strokeWidth='1.5' strokeMiterlimit='0'>
      <path d="m25 20l15 10m-3-1.5h-24m-3 1.5l15-10v7" fill='none' stroke={state.icon_stroke} />
    </g>,

  ceiling: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_fill} strokeMiterlimit='0'>
      <path d="m12 12h24v24h-24z" fill="none" />
      <circle cx="24" cy="24" r="4.5" fill={state.icon_stroke} />
    </g>,

  furnishing: ({ state }) =>
    <g stroke={state.icon_stroke} fill="none" strokeWidth='1.5' strokeMiterlimit='0'>
      <path d="m13.5 23.5v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-7l-5-5h-4a2 2 0 0 0-2 2z" />
      <path d="m17.5 21.5h2l5 5v4h8a2 2 0 0 0 2-2v-11c-4-6-13-6-17 0v5z" />
    </g>,

  acoustics: ({ state }) =>
    <g stroke={state.icon_stroke} fill="none" strokeWidth='1.5' strokeMiterlimit='0'>
      <path d="m12.5 23h1.15a1.15 1.15 0 0 1 1.15 1.15v2a1.15 1.15 0 0 0 2.3 0v-6a1.15 1.15 0 0 1 2.3 0v10a1.15 1.15 0 0 0 2.3 0v-16a1.15 1.15 0 0 1 2.3 0v20a1.15 1.15 0 0 0 2.3 0v-16a1.15 1.15 0 0 1 2.3 0v10a1.15 1.15 0 0 0 2.3 0v-6a1.15 1.15 0 0 1 2.3 0v2a1.15 1.15 0 0 0 1.15 1.15h1.15" />
    </g>,

  microphones: ({ state }) =>
    <g strokeWidth='1.5' strokeMiterlimit='0'>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g stroke={state.icon_stroke} fill="none">
        <path d="m20 40.2h10m-5-1v-4m8.4-8.2c-2 9.6-14.8 9.6-16.8 0m3-11.6a5.4 5.4 0 0 1 10.8 0v10a5.4 5.4 0 1 1-10.8 0v-10z" />
        <path d="m29.4 15h-5.4m0 2h5.4m0 2h-5.4m0 2h5.4m0 2h-5.4m0 2h5.4" strokeWidth='1' />
      </g>
    </g>,

  speakers: ({ state }) =>
    <g strokeWidth='1.5' strokeMiterlimit='0'>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g stroke={state.icon_stroke} fill="none">
        <path d="m26.7 12v25.9l-9.2-7.4h-7.7v-11.2h7.7zm7 2.6a25.3 25.3 0 0 1 0 20.8m-3.4-2.7a22 22 0 0 0 0-15.4m6.7-5.4a29.4 29.4 0 0 1 0 26.2" />
      </g>
    </g>,

  equipment_list: ({ state }) =>
    <g stroke={state.icon_stroke} fill="none" strokeWidth='1.5' strokeMiterlimit='0'>
      <path d="m11.5 12.5h24v24h-24v-24m4 6h2m3 0h10m0 4h-10m-3 0h-2m0 4h2m3 0h10m0 4h-10m-3 0h-2" />
    </g>,

  camera: ({ state, center = true }) =>
    <g strokeWidth='1.5' strokeMiterlimit='0' transform={center ? 'translate(-24 -24)' : ''}>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g stroke={state.icon_stroke} fill="none">
        <path d="m14 16h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-10a3 3 0 0 1-3-3v-10a3 3 0 0 1 3-3z" />
        <path d="m30.5 21.5l8-5v16l-8-5v-6z" />
      </g>
    </g>,

  design_priority: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='1' strokeMiterlimit='0'>
      <path d="m31.5 15h8v2h-8zm-4 9h12v2h-12zm-4 9h16v2h-16zm-3 0a1 1 0 0 1 0-16h3v3l5-4-5-4v3h-3a1 1 0 0 0 0 20" />
    </g>,


};

export default Navigation;