import ControlBar from "globalConstants/Icons/ControlBar";
import LoadingDocks from "globalConstants/Icons/LoadingDocks";
import Roof from "globalConstants/Icons/Roof";
import SystemUsage from "globalConstants/Icons/SystemUsage";
import Noise from "globalConstants/Icons/Noise";
import Walls from "globalConstants/Icons/Walls";
import AreaUsage from "globalConstants/Icons/AreaUsage";
import Coverage from "globalConstants/Icons/Coverage";
import Family from "globalConstants/Icons/Family";
import Actions from "globalConstants/Icons/Actions";
import Navigation from "globalConstants/Icons/Navigation";
import Devices from "globalConstants/Icons/Devices";
import Tutorial from "globalConstants/Icons/Tutorial";
// new code
import Furniture from "globalConstants/Icons/Furniture";
import Orientation from "globalConstants/Icons/Orientation";


const icons = {
  ...ControlBar,
  ...Navigation,
  ...Actions,
  ...Roof,
  ...LoadingDocks,
  ...SystemUsage,
  ...AreaUsage,
  ...Walls,
  ...Noise,
  ...Coverage,
  ...Family,
  ...Devices,
  ...Tutorial,
  ...Furniture,
  ...Orientation,
}

export default icons;