

const areaElements = [
  {
    element: 'floorplan',
    active: { parameter: ['activeTab', 'currentView'], check: ([activeTab, view, area]) => area === activeTab && view === 'floorplan' }  // add view === 'floorplan if show only on floorplan
  },
  {
    element: 'lineDimension',
    show: { parameter: ['activeTab', 'currentView'], check: ([activeTab, view, area]) => area === activeTab && view === 'floorplan' },
  },
  {
    element: 'door',
    active: { parameter: ['currentView'], check: ([view]) => view === 'floorplan' }
  },
  {
    element: 'ceiling',
    show: { parameter: ['ceilingTiles'], check: ([tiles]) => tiles.qty.x * tiles.qty.y !== 0},
    active: { parameter: ['currentView'], check: ([view]) => view === 'ceiling'}
  },
]
//   {
//     element: 'speakers',
//     show: {parameter: ['currentView', 'errorState'], check: ([view, error]) => !error && !['floorplan', 'roof', 'areausage'].includes(view)},
//     active: {parameter: ['activeTab'], check: ([activeTab, area]) => area === activeTab }
//   },

// ];

// const otherElements = []
//   {
//     element: 'overlaps',
//     show: { parameter: ['zoom'], check: ([zoom]) => !zoom },
//   }
// ]

export { areaElements };