import { useSelector } from "react-redux";
import { ComponentsIndex } from 'globalConstants/ComponentIndex';
import { getCurrentValue } from "reduxModules/ducks/container";
import { values } from "lodash";


const DynamicContent = (props) => {
  const errors = useSelector(state => values(state.ui.error).map(err => err.msg)).flat();
  const Element = ComponentsIndex[errors.length <= 1 ? 'plainText' : 'bulletList'];
  return <Element content={errors.length <= 1 ? errors.pop() : errors} />
};

const ErrorContent = (props) => DynamicContent(props);

const ValueContent = (props) => {
  const currentValue = useSelector(state => getCurrentValue(state, props.parameter));
  const content = props.content.join(currentValue)
  const Element = ComponentsIndex.plainText;
  return <Element content={content} />
};

export {ErrorContent, ValueContent};
export default DynamicContent;