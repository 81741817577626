import { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch } from "react-redux";
import Button from "components/molecules/Button";
import { buttonThemes } from "globalConstants/Themes";
import ReducersIndex from 'reduxModules/ducks';

const UndoButton = (props) => {
  const useTheme = buttonThemes["actionGroup"];
  const [buttonActive, setButtonActive] = useState(false);

  const dispatch = useDispatch();
  const handleClick = (evt) => dispatch(ReducersIndex.container.setCustomized());
  const handleDown = (evt) => setButtonActive(true);
  const handleUp = (evt) => setButtonActive(false);
  const mouseEnter = props.handleEnter ? (e) => props.handleEnter(e, 'undo') : null;

  return (
    <ThemeProvider theme={{ background: useTheme[buttonActive ? 'checked' : 'unchecked'] }} >
      <UndoButton.Wrapper className="me-1" onMouseEnter={mouseEnter} onMouseLeave={props.handleLeave} onMouseDown={handleDown} onMouseUp={handleUp}>
        <Button theme={useTheme} name={'undo'} state={buttonActive ? 'button_selected' : 'button_disabled'} onClick={handleClick} />
      </UndoButton.Wrapper>
    </ThemeProvider>
  );
};

UndoButton.Wrapper = styled.div.attrs((props) => ({
  className: 'ms-1'
}))`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.color};
  height: 36px
`;

export default UndoButton;