const LoadingDocks = {
  left_docks: ({ state, right }) =>
    <path d={`m${right ? '45' : '5'} 5v40`} strokeWidth='3' strokeDasharray='7 4' stroke={state.icon_stroke} shapeRendering={'crispEdges'} />,

  top_docks: ({ state, bottom }) =>
    <path d={`m0 ${bottom ? '42' : '8'}h50`} strokeWidth='3' strokeDasharray='7 3.75' stroke={state.icon_stroke} shapeRendering={'crispEdges'} />,
  // eslint-disable-next-line
  right_docks: ({ state }) => <LoadingDocks.left_docks state={state} right />,
  // eslint-disable-next-line
  bottom_docks: ({ state }) => <LoadingDocks.top_docks state={state} bottom />,

};

export default LoadingDocks;