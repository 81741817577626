// fixme 
const containerCondition = {parameter: ['errorState'], check: ([error]) => {
  return true

}}

const containerElements = [
  {
    element: 'floorplan',
    active: { parameter: ['tabs', 'activeTab', 'currentView'], check: ([tabs, activeTab, view]) => tabs.at(0).id === activeTab && view === 'floorplan' }
  },
  {
    element: 'loadingDocks',
    show: { parameter: ['zoom', 'tabs', 'activeTab'], check: ([zoom, tabs, activeTab]) => !zoom || tabs.at(0).id === activeTab }, // delete zoom to hide in areas 
    active: { parameter: ['tabs', 'activeTab', 'currentView'], check: ([tabs, activeTab, view]) => tabs.at(0).id === activeTab && view === 'floorplan' }
  },
  {
    element: 'roof',
    show: { parameter: ['tabs', 'activeTab' , 'currentView'], check: ([tabs, activeTab, view]) => tabs.at(0).id === activeTab && view === 'roof' },
  },
  {
    element: 'roofgrid',
    show: { parameter: ['zoom', 'tabs', 'activeTab'], check: ([zoom, tabs, activeTab]) => !zoom || tabs.at(0).id === activeTab },
    active: { parameter: ['tabs', 'activeTab', 'currentView', 'exitToRoom'], check: ([tabs, activeTab, view, exit]) => tabs.at(0).id === activeTab && view === 'roof' }

  },
  {
    element: 'lineDimension',
    show: { parameter: ['tabs', 'activeTab', 'currentView', 'action'], check: ([tabs, activeTab, view, action]) => tabs.at(0).id === activeTab && view === 'floorplan' && action !== 'ghostdock' },
  }
];

const areaCondition = { parameter: ['zoom', 'tabs', 'activeTab'], check: ([zoom, tabs, activeTab]) => zoom && tabs.at(0).id !== activeTab };

const areaElements = [
  {
    element: 'floorplan',
    active: { parameter: ['activeTab'], check: ([activeTab, area]) => area === activeTab}  // add view === 'floorplan if show only on floorplan
  },
  {
    element: 'loadingDocks',
    show: { parameter: ['zoom', 'activeTab'], check: ([zoom, activeTab, area]) => zoom && area === activeTab }, // delete zoom to hide in areas 
  },
  {
    element: 'lineDimension',
    show: { parameter: ['activeTab', 'currentView', 'zoom'], check: ([activeTab, view, zoom, area]) => area === activeTab && view === 'floorplan' && zoom },
  },
  {
    element: 'storage',
    // show: { parameter: ['areas', 'activeTab', 'storageReady'], check: ([areas, activeTab, storageReady, area]) => {
    //   console.log("area: ", area, activeTab, storageReady);
    //   return ((area === activeTab && storageReady) || areas[area].storageReady)
    // }
    // },
    active: {parameter: ['activeTab', 'currentView'], check: ([activeTab, view, area]) => area === activeTab && view === 'areausage' }
  },
  {
    element: 'horns',
    show: {parameter: ['currentView', 'errorState'], check: ([view, error]) => !error && !['floorplan', 'roof', 'areausage'].includes(view)},
    active: {parameter: ['activeTab'], check: ([activeTab, area]) => area === activeTab }
  },

];

const otherElements = [
  {
    element: 'overlaps',
    show: { parameter: ['zoom'], check: ([zoom]) => !zoom },
  }
]

export { containerCondition, containerElements, areaCondition, areaElements, otherElements};