import styled from "styled-components";
import { useSelector } from "react-redux";
import { getScale } from "reduxModules/ducks/ui";

const FortyFive = ({ver= true, x = 0, y = 0, flipH = false, flipV = false, cap = false }) => {
  const scale = useSelector(getScale);
  // Rails
  const rails = `m${x} ${y}h.12q.18 0 .36 .18l.12 .12h${cap ? .75 : .15}v.21h-${cap ? .75 : .15}l-.206-.206q-.15-.15-.3-.15h-.09z
  m0 .75h.12q.18 0 .36 .18l.27 .27v.19h-.15v-.16l-.19-.18q-.17-.15-.3-.15h-.11zm1.35-.45v1.09h.15v-1.09z`
  // Rollers 
  const rollers = `m${x + 1.35} ${y + (cap ? 0.64 : 0.42)}h-.6${`m0 .12 h.6m0 .12h-.6`.repeat(cap ? 2 : 3)}m0 .12h.6 
  m-.68-.15v-.6m-.12-.05v.54m-.12-.12v-.54m-.12-.11v.57m-.12-.04v-.58m-.12-.02v.59`;
  const midRolls = `m${x + 1.35} ${y + (cap ? 0.7 : 0.48)}h-.6${`m.6 .24h-.6`.repeat(cap ? 2 : 3)}m-.14-.15v-.54m-.24-.22v.54m-.24-.08v-.58`;
  const showMids = scale >= 30; //scale limit to show/hide midRoll lines
  // Transformation
  const transform = `${ver ? '' : `rotate(${ver ? 0 : 90}) translate(${y - x}, -${y + x + 1.39})`} 
  scale(${1 - 2 * flipH}, ${1 - 2 * flipV}) translate(-${flipH * (2 * x + 1.53)}, -${flipV * (2 * y + 1.39)})`;

  return (
    <FortyFive.Group transform={transform} >
      <FortyFive.Path path={rails} />
      <FortyFive.Path path={rollers} showMids={showMids} />
      {showMids && <FortyFive.Path path={midRolls} showMids={showMids} dash={true} />}
    </FortyFive.Group>
  )
}

FortyFive.Group = styled.g.attrs(props => ({
  transform: props.transform
}))`
  stroke-width: 1;
  shape-rendering: geometricprecision
`;

FortyFive.Path = styled.path.attrs(props => ({
  d: props.path,
}))`
  vector-effect: non-scaling-stroke;
  fill: ${props => props.fill ? '#fff' : 'none'};
  stroke-width: ${props => props.showMids && (props.showMids ? 1 : .5)};
  stroke-dasharray: ${props => props.dash ? '6 1.5 1 1.5' : ''};
  shape-rendering: crispedges;
`;

export default FortyFive;