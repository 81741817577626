import styled from 'styled-components';

const PlainText = (props) => {
  return (<PlainText.Wrapper {...props}>{props.content}</PlainText.Wrapper>)
};

PlainText.Wrapper = styled.div.attrs(props => {
  return ({
    className: props.className,
    as: props.type 
  })
})`
  font-weight: ${props => props.bold && 'bold'};
  white-space: ${props => props.content?.includes('\n') ? 'pre-wrap' : 'normal'};
  margin-bottom: 0;
  color: ${props => props.color};
  font-size: ${props => `${props.size}px`}
`;

const Error = ({msg}) => {
  const props = {
    className: 'text-center p-2',
    type: 'p',
    size: 16,
    color: 'var(--app-red)'
  };
  return <PlainText {...props} content={msg} />
};

const Info = ({msg}) => {
  const props = {
    className: 'text-center p-2',
    type: 'p',
    size: 16,
    color: 'blue'
  };
  return <PlainText {...props} content={msg} />
};

export default PlainText;
export {Error, Info};
