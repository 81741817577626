/**
 * @module color
 * @description Provides utilities for color conversion and gradient generation for various plot types.
 */
import { round } from "lodash";

/**
 * Converts HSL color values to RGB.
 * @function
 * @param {number} h - The hue value (0 to 1).
 * @param {number} [s=1] - The saturation value (0 to 1).
 * @param {number} [l=0.5] - The lightness value (0 to 1).
 * @param {number} [a=1] - The alpha (opacity) value (0 to 1).
 * @returns {number[]} An array of [r, g, b] values (0 to 255).
 */
const hslToRgba = (h, s = 1, l = 0.5, a = 1) => {
  const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  let r = hue2rgb(p, q, h + 1 / 3);
  let g = hue2rgb(p, q, h);
  let b = hue2rgb(p, q, h - 1 / 3);
  return [round(r * 255), round(g * 255), round(b * 255), a]; 
};

/**
 * Object containing gradient generation functions for different plot types.
 * @type {Object.<string, function>}
 */
export const gradients = {
  /**
   * Generates color gradients for Sound Pressure Level (SPL) plots.
   * @function
   * @param {Object} plotProps - The plot properties.
   * @param {number} [plotProps.maxLevel=110] - The maximum SPL value.
   * @param {number} [plotProps.minLevel=60] - The minimum SPL value.
   * @param {number} [plotProps.target=90] - The target SPL value.
   * @param {number} [plotProps.accuracy=10] - The accuracy of the gradient.
   * @returns {Array} the gradientRGBA array.
   */
  SPL: (plotProps) => {
    const { maxLevel = 110, minLevel = 60, target = 90, accuracy = 10 } = plotProps;
    const gradientRGBA = {};
    const hue_start = 250; // 180 cyan change to 250 for blue
    const hue_yellow = 60;
    const hue_end = 0; // red
    const m1 = (hue_yellow - hue_start) / (target - minLevel);
    const b1 = 60 - m1 * target;
    const m2 = (hue_end - hue_yellow) / (maxLevel - target);
    const b2 = 60 - m2 * target;
    for (let i = minLevel; i <= maxLevel; i += 1 / accuracy) {
      const index = round(i * accuracy); // index is decibel*10
      const hue = i <= target ? i * m1 + b1 : i * m2 + b2;
      gradientRGBA[index] = hslToRgba(hue / 360);
    };
    return gradientRGBA
  },

  /**
   * Generates color gradients for Speech Transmission Index (STI) plots.
   * @function
   * @param {Object} plotProps - The plot properties.
   * @param {number} [plotProps.maxLevel=1] - The maximum STI value.
   * @param {number} [plotProps.minLevel=0] - The minimum STI value.
   * @param {number} [plotProps.accuracy=1000] - The accuracy of the gradient.
   * @returns {Array} the gradientRGBA array.
   */  
  STI: (plotProps) => {
    const { maxLevel = 1, minLevel = 0, accuracy = 1000 } = plotProps;;
    const gradientRGBA = {};
    const hue_end = 120; // stop at green
    const shift = .2; // move yellow to the left
    for (let i = minLevel; i <= maxLevel; i += 1 / accuracy) {
      const index = round(i * accuracy)
      const hue = Math.min(hue_end, index * (hue_end / accuracy) / (maxLevel - shift));
      gradientRGBA[index] = hslToRgba(hue / 360);
    };
    return gradientRGBA
  },

  /**
   * Generates color gradients for microphone distance plots.
   * @function
   * @param {Object} plotProps - The plot properties.
   * @param {number} [plotProps.maxLevel=6] - The maximum distance value.
   * @param {number} [plotProps.minLevel=0] - The minimum distance value.
   * @param {number} [plotProps.target=2.4755] - The target distance value.
   * @param {number} [plotProps.accuracy=100] - The accuracy of the gradient.
   * @returns {Array} the gradientRGBA array.
   */  
  micDistance: (plotProps) => {
    const { maxLevel = 6, minLevel = 0, target = 2.4755, accuracy = 100 } = plotProps;
    const hue = 124;
    const fade = .5; // mic fade distance
    const gradientRGBA = {};
    let opacity = .8;
    for (let i = minLevel; i <= maxLevel * accuracy; i++) {
      const dist = i / accuracy;
      if (dist > target) {
        const slope = (1 / fade) / target; // fade slope
        opacity = Math.max(0, ((target - dist) * slope) + .8);
      }
      gradientRGBA[i] = hslToRgba(hue / 360, undefined, undefined, opacity);
    };
    return gradientRGBA
  },

  /**
   * Generates color gradients for relative average level plots.
   * @function
   * @param {Object} plotProps - The plot properties.
   * @param {number} [plotProps.maxLevel=6] - The maximum distance value.
   * @param {number} [plotProps.minLevel=0] - The minimum distance value.
   * @param {number} [plotProps.accuracy=100] - The accuracy of the gradient.
   * @returns {Array} the gradientRGBA array.
   */    
  relativeAverage: (plotProps) => {
    const { maxLevel = 6, minLevel = -12, accuracy = 100 } = plotProps;
    const gradientRGBA = {};
    for (let avg = minLevel; avg <= maxLevel; avg += 1/accuracy) {
      const index = Math.round(avg * accuracy);
      let hue = 0;
      switch (true) {
        case avg <= -2:
          hue = Math.max(120, Math.min(-26 * avg + 66, 240));
          break;
        case avg <= 2:
          hue = (-61 * (avg + 4.5)) / 6 + 131
          break
        case avg <= 5:
          hue = Math.min(Math.max(0, 462 - 194 * avg + 20.4 * avg * avg), 65);
          break;
        default:
          hue = 0;
      };
      gradientRGBA[index] = hslToRgba(hue / 360);
    };
    return gradientRGBA
  }, 

  /**
   * Generates color gradients for camera distance plots.
   * @function
   * @param {Object} plotProps - The plot properties.
   * @param {number} [plotProps.maxLevel=6] - The maximum distance value.
   * @param {number} [plotProps.minLevel=0] - The minimum distance value.
   * @param {number} [plotProps.target=2.4755] - The target distance value.
   * @param {number} [plotProps.accuracy=100] - The accuracy of the gradient.
   * @returns {Array} the gradientRGBA array.
   */  
  cameraCoverage: (plotProps) => {
    const { maxLevel = 9, minLevel = 0, target = 5, accuracy = 100 } = plotProps;
    const hue = 220;
    const fade = 1
    const gradientRGBA = {};
    let opacity = .6;
    for (let i = minLevel; i <= maxLevel * accuracy; i++) {
      const dist = i / accuracy;
      if (dist > target) {
        const slope = (1 / fade) / target; // fade slope
        opacity = Math.max(0, ((target - dist) * slope) + .6);
      };
      gradientRGBA[i] = hslToRgba(hue / 360, undefined, undefined, opacity);
    };
    return gradientRGBA
  },
}

