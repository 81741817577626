import Label from 'components/atoms/Label';
import DistanceInput from 'components/molecules/DistanceInput';

const Dims = (props) => {
  const placeholder = props.ids.length > 1;
  const [first, second] = props.ids;

  return (
    <div className={`${props.isNav ? 'vstack' : 'hstack'} gap-1 text-nowrap align-items-start ${props.className}`}>
      {props.label && <Label htmlFor={props.name} type={'text'} >{props.label}</Label>}
      <div className='hstack gap-2'>
        <DistanceInput name={first} placeholder={placeholder} {...props} />
        {second && <span>{props.spacer}</span>}
        {second && <DistanceInput name={second} placeholder={placeholder} {...props} />}
      </div>
    </div>
  )
};

const DimensionInput = (props) => {
  return Dims({ ...props, spacer: 'X' })
};

const PositionInput = (props) => {
  return Dims({ ...props}) //, allowZero: true
};

const RangeInput = (props) => {
  return Dims({ ...props })
};

export default Dims;
export { DimensionInput, PositionInput, RangeInput };