import styled from 'styled-components';

const BulletList = (props) => {
  const {content, ...rest} = props
  return (
    <BulletList.Wrapper {...rest}>
      {content.map((Child, index) => <BulletList.Line key={`line-${index}`}>{Child}</BulletList.Line>)}
    </BulletList.Wrapper>
  )
};

BulletList.Wrapper = styled.ul.attrs(props => ({
  className: 'm-0'
}))`
  background: #fff
`;

BulletList.Line = styled.li.attrs(props => ({
}))`
  background: #fff;
  color: var(--app-dark-gray)
`;

export default BulletList;