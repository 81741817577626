import styled, {css, keyframes} from "styled-components";
import { useSelector } from "react-redux";
import { useState, Fragment, useRef, useEffect } from "react";
import { getIsMeter, getScale } from "reduxModules/ducks/ui";
import { distanceToString } from "utilities/format";


const MeasuringTape = (props) => {
  const scale = useSelector(getScale);
  const isMeter = useSelector(getIsMeter);
  const {x: x0, y: y0} = props.anchor;
  const {x: x1, y: y1} = props.target;

  const [measuring, setMeasuring] = useState(false)
  useEffect(() => {
    props.mouseActive ? setMeasuring(props.mouseActive) : setTimeout(() => setMeasuring(false), 350)
  }, [props.mouseActive])
  
  // Positioning
  let angle = (Math.atan2((y1 - y0), (x1 - x0)) * 180 / Math.PI);
  let flip = false;
  if (Math.abs(angle) > 90) {
    angle = -(180 - Math.abs(angle)) * Math.sign(angle);
    flip = true;
  };

  // Value

  const distance = distanceToString(Math.hypot((x0 - x1), (y0 - y1))/ scale, isMeter);

  // References for animation
  const distLabelRef = useRef();
  const [adjustment, setAdjustment] = useState(0);
  useEffect(() => {
    if  (distLabelRef.current) setAdjustment(-distLabelRef.current.clientWidth)
  }, [distance]);

  const lineLengthRef = useRef();
  const [length, setLength] = useState();
  useEffect(() => {
    if (lineLengthRef.current) setLength(lineLengthRef.current.getTotalLength())
  }, [distance]);

  const newProps = {
    ...props,
    x0: x0,
    y0: y0,
    x1: x1,
    y1: y1,
    angle: angle,
    flip: flip,
    adjustment: adjustment,
    length: length,
    animate: measuring && !props.mouseActive
  }

  return (
    <Fragment>
      {measuring && <MeasuringTape.Measuringtape  {...newProps} />}
      {measuring && <MeasuringTape.Tapemeasure ref={lineLengthRef} len={lineLengthRef} {...newProps} />}
      {props.mouseActive && <MeasuringTape.Distance ref= {distLabelRef} {...newProps} >{distance}</MeasuringTape.Distance>}
    </Fragment>
  )
}

const lineAnimation = (length) => {
  const animation = keyframes`
   0%{stroke-dashoffset: 0;}
   100%{stroke-dashoffset: ${length};}
  `;
  return css`
    animation: ${animation};
    animation-duration: 200ms;
    animation-iteration-count:1;
    animation-timing-function: linear;
  `
};

// const tapeAnimation = (anchor, rotate) => {
//   // Note: When angle is diferent than zero and non-flipped, the center is transposed for whatever reason. 
//   const x = anchor.x ;
//   const y = anchor.y ;
//   const animation = keyframes`
//    0%{transform: translate(${x}px, ${y}px) rotate(0deg) }
//    100%{transform: translate(${x}px, ${y}px) rotate(${rotate}deg) }
//   `;
//   return css`
//     transform-box: fill-box;
//     ${'' /* transform-origin: center; */}
//     transform-origin: 50% 50%;
//     animation: ${animation};
//     animation-duration: 500ms;
//     animation-delay: 200ms;
//     animation-iteration-count:1;
//     animation-timing-function: linear;
//   `
// };

MeasuringTape.Measuringtape = styled.path.attrs(props => ({
  d: "M0,0 m-10,-3 a7,7 0 0 1 0 -14 a7,7 0 0 1 0 14 m10,3 v-12 a8,8 1 0 0 -8 -8 h-12 v20 h20 z",
  transform: `translate(${props.x0} ${props.y0}) rotate(${props.angle}) scale(${props.flip ? -1 : 1} 1) `
}))`
  stroke: #000;
  opacity: ${props => props.mouseActive ? 1 : 0};
  transition: ${props => props.mouseActive ? null : 'opacity 50ms linear 300ms'};
  ${'' /* ${props => props.animate ? tapeAnimation(props.anchor, (props.target.x < props.anchor.x ? -720 : 720)) : null} */}
`;

MeasuringTape.Tapemeasure = styled.path.attrs(props => ({
  d: `M ${props.x0} ${props.y0} L ${props.x1} ${props.y1}`,
  style: {
    strokeDasharray: props.length,
  }
}))`
  stroke: #000;
  stroke-dashoffset: 0;
  shape-rendering: geometricPrecision;
  opacity: ${props => props.mouseActive ? 1 : 0};
  transition: ${props => props.mouseActive ? null : 'opacity 10ms linear 190ms'};
  ${props => props.animate ? lineAnimation(props.length) : null}

`;

MeasuringTape.Distance = styled.text.attrs(props => ({
  transform: `translate(${props.x1} ${props.y1}) rotate(${props.angle}) translate(${props.flip ? props.adjustment - 20 : 5} 0)`
}))`
  font-size: 16pt;
  text-anchor: left
`;
export default MeasuringTape
