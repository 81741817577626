import modals from "toolConstants/conferenceroomDesigner/modals";
import menus from "toolConstants/conferenceroomDesigner/menus";
import actions, {conditions, actionDispatchers}  from "toolConstants/conferenceroomDesigner/actions";
import initialState from "toolConstants/conferenceroomDesigner/initalState";
import { areaElements } from "toolConstants/conferenceroomDesigner/views";
import {resetArea} from "toolConstants/conferenceroomDesigner/logic";
import constants from "toolConstants/conferenceroomDesigner/constants";
import validate from "toolConstants/conferenceroomDesigner/validation"

/**
 * Conference Room Designer configuration and utilities.
 * @type {Object}
 */
const CRD = {
  /**
   * Initial state for the Conference Room Designer.
   * @type {Object}
   */
  initialState: initialState,
  /**
   * Modal configurations for the Conference Room Designer.
   * @type {Object}
   */
  modals: modals,
  /**
   * Menu configurations for the Conference Room Designer.
   * @type {Object}
   */
  menus: menus,
  /**
   * Available actions for the Conference Room Designer.
   * @type {Object}
   */
  actions: actions,  
  /**
   * Conditions for enabling actions in the Conference Room Designer.
   * @type {Object}
   */
  actionConditions: conditions,
  /**
   * Action dispatchers for the Conference Room Designer.
   * @type {Object}
   */
  actionDispatchers: actionDispatchers,
  /**
   * UI elements configuration for areas in the Conference Room Designer.
   * @type {Object}
   */
  areaElements: areaElements, 
  /**
   * Function to reset an area in the Conference Room Designer.
   * @type {Function}
   */
  resetArea: resetArea,
  /**
   * Validation functions for the Conference Room Designer.
   * @type {Object}
   */
  validate: validate,
  /**
   * Constants used throughout the Conference Room Designer.
   * @type {Object}
   */
  constants: constants,
}; 

export default CRD;

