import { useState } from "react";

const useFetch = (url, payload = {}) => {
  const [connecting, setConnecting] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleClick = (evt) => {
    setConnecting(true);
    setResponse(null);
    setError(null);

    const retrieve = async (payload) => {
      const response = await fetch(url, {
        "method": "POST",
        "headers": {
          "content-type": "text/plain",
          "accept": "*/*"
        },
        "body": JSON.stringify(payload),
      });
      if (response.ok) {
        setResponse(await response.text())
      } else {
        setError(await response.text())
      }
      setConnecting(false)
    };

    retrieve(payload);
    
  };

  const fetchData = () => handleClick()

  return { connecting, response, error, handleClick, fetchData };
};

export default useFetch;
