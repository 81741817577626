import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getBoundingBox, getDrawingBox , getSVGBoundingBox} from "reduxModules/ducks/ui";
import { isInside } from "utilities/geometric";

// Keeps track of mouse position while inside the SVG container
// Ref flag used when the mouse position is required for moving
// all flag allows mouse position outside the perimeter of the room/area
// scaled flag returns coordintes in real world units 
const useMousePosition = (ref=false, all = true ) => {
  const { bbox, scale } = useSelector(getDrawingBox);
  const {top, left} = useSelector(getSVGBoundingBox); 
  const [mousePos, setMousePos] = useState({});
  const mouseRef = useRef({x: 0, y: 0})

  useEffect(() => {
    const handleMouseMove = (event) => {
      let x = event.clientX - left;
      let y = event.clientY - top;
      const isIn = isInside([x, y], bbox) || all;
      // x /= scaled ? scale : 1
      // y /= scaled ? scale : 1
      setMousePos(isIn ? { x: x, y: y } : null);
      mouseRef.current = isIn ? { x: x, y: y } : null
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  });

  return ref ? mouseRef.current : mousePos;
};

export default useMousePosition;