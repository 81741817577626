import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  scale: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: '5',
  },
  label: {
    width: 'auto',
    paddingTop: '2'
  },
  image: {
    height: '10',
    width: '350',
    marginBottom: '2',
    borderRadius: '2'
  }
})

const Scale = () => {
  const image = document.getElementById('scale');
  return (
    <View style={styles.scale} >
      <Text style={styles.label} >Direct SPL: </Text>
      <Image style={styles.image} src={image.toDataURL("image/png")} />
      <Text style={styles.label} > dBA</Text>
    </View>
  )
};

export default Scale;

