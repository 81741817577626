import styled from "styled-components";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';
import Label from "components/atoms/Label"; 
import { getCurrentValue, getCurrentValue2 } from "reduxModules/ducks/container";

const Slider = (props) => {
  // fixme: cleanup
  // const currentValue = useSelector(state => getCurrentValue(state, props.dispatcher));
  const currentValue = useSelector(state => getCurrentValue2(state, props.dispatcher, props.name))
  // console.log("props: ", props, currentValue);
  const [checked, setChecked] = useState();  
  useEffect(() => {
    //const current = props.name ? (currentValue[props.name] === undefined ? currentValue : currentValue[props.name]) : currentValue;
    setChecked(currentValue)
    // setChecked(currentValue); 
  }, [currentValue]);
  const dispatch = useDispatch();
  const handleClick = () => {
    // setChecked(!checked);

    if (props.dispatcher) {
      const newValue = props.name ? { ...{}, [props.name]: !checked } : !checked;
      if (props.dispatcher in ReducersIndex.container)  dispatch(ReducersIndex.container[props.dispatcher](newValue));
      // The below dispatcher is for add another area flag which is UI only 
      if (props.dispatcher in ReducersIndex.uiReducers) dispatch(ReducersIndex.uiReducers[props.dispatcher](newValue));
    };
    setChecked(!checked);
  };
  return (
    <div className={`d-flex flex-wrap gap-1 ${props.className}`}>
      {props.label && <Label htmlFor={props.name} type={'text'}>{props.label}</Label>}
      <Slider.Outer onClick={handleClick} isNav={props.isNav}>
        <Slider.Inner animate={props.animate} isNav={props.isNav} checked={checked} />
          <Slider.Switch checked={checked}/>
      </Slider.Outer>
    </div>
  )
};

export default Slider;

Slider.Outer = styled.div.attrs(props => ({
}))`
  position: relative;
  display: inline-block;
  width: 50px;
  height: ${props => props.isNav ? '26px' : '28px'}
`;

Slider.Inner = styled.div.attrs(props => ({
}))`
  position: absolute;
  cursor: pointer;
  width: 48px;
  height: 26px;
  top: ${props => props.isNav ? '0px' : '2px'};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.checked ? 'var(--app-red)' : 'var(--app-light-gray)'};
  border-radius: 34px;
  transition: ${props => props.animate ? '.4s' : 'none'};
  border: 1px solid var(--app-dark-gray);
  &:focus {
    outline: none;
  }
`;

Slider.Switch = styled.span.attrs(props => ({
}))`
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: ${props => props.checked ? '#fff' : 'var(--app-medium-gray)'};
  border-radius: 50%;
  transition: ${props => props.animate ? '.4s' : 'none'};
  transform: translateX(${props => props.checked ? '20px' : '0px'});
`
