import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import ReducersIndex from 'reduxModules/ducks';
import isEmpty from 'lodash/isEmpty';


const Video = (props) => {
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef();
  const { modalOpen: tutorialOPen } = useSelector(state => state.ui.modals.find(modal => modal.modalName === 'tutorial'))


  useEffect(() => {
    const player = playerRef.current.getInternalPlayer();
    if (tutorialOPen) {
      if (!isEmpty(player)) player.seekTo(0, true);
    } else {
      if (!isEmpty(player)) player.stopVideo();
    }
  }, [tutorialOPen])

  const dispatch = useDispatch();
  useEffect(() => {
    if (playing) {
      const interval = setInterval(async () => {
        const totalTime = await playerRef.current.getInternalPlayer().getDuration();
        const elapsedTime = await playerRef.current.getInternalPlayer().getCurrentTime(); // this is a promise. dont forget to await
        if (elapsedTime >= totalTime * .2) {
          dispatch(ReducersIndex.uiReducers.setIsFirstTimeUser(false))
        }
      }, 100); // 100 ms refresh. 
      return () => {
        clearInterval(interval);
      };
    }

  }, [playing]);

  const handleStateChange = (evt) => {
    setPlaying(evt.target.playerInfo.playerState === 1)
  }

  const opts = {
    width: '640',
    height: '360',
    frameborder: '0',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      modestbranding: 1,
      showinfo: 0,
    },
  };

  return (
    <div className="d-flex justify-content-center embed-responsive embed-responsive-16by9" >
      <YouTube ref={playerRef} videoId="8xbovbi715E" opts={opts} onStateChange={(e) => handleStateChange(e)} />
    </div>
  )
}


export default Video;
