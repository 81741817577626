import modals from "toolConstants/classroomDesigner/modals";
import constants from "toolConstants/classroomDesigner/constants";
import { keys, values } from "lodash";

// console.log("modals: ", modals);
const menus = {
  floor_plan: {
    area: [...modals.classroom.body.slice(1, -2)],
  },
  ceiling: {
    area: [
      modals.classroom.body[4],
      {
        condition: { parameter: ['ceilingTiles'], check: ([tiles]) => tiles.tile !== 'no_ceiling_tiles'},
        element: 'doubleNumberInput',
        label: 'Number of Tiles',
        range: {min: 1},
        ids: ['qty.x', 'qty.y'],
        dispatcher: 'setCeilingTiles'
      }
    ]
  },
  furnishing: {
    area: [
      { ...modals.furniture.body[0], theme: 'furnitureGroupNav' },
      ...modals.furniture.body.slice(1, -1),

      {
        condition: { parameter: ['instructor'], check: ([instructor]) => instructor.shape && instructor.shape === 'desk' },
        element: 'dimensionInput',
        label: 'Table Dimensions',
        ids: ['length', 'width'],
        dispatcher: 'setInstructor'
        // todo table dimension validation
        // errorCode: 1000,
      },

    ]

    // todo table dimensions for teacheers desk
  },
  acoustics: {
    area: [{ ...modals.devices.body[3] }],
  },
  microphones: {
    area: [
      {
        ...modals.devices.body[0],
        labels: false,
        bubbles: {
          ceiling_mic: 'Ceiling Microphone',
          pendant_mic: 'Pendant Microphone',
        }
      },
      { 
        ...modals.devices.body[1] 
      },
      {
        ...modals.devices.body[2],
        labels: false,
        theme: 'buttonGroupBigNav',
        bubbles: {
          full_room: 'Full Room',
          teacher: 'Teacher Area',
          students: 'Students Area'
        }
      },
      {
        // todo: Check if there's a way to maintain density when teacher & students are selected (i.e. when using focused)
        condition: { parameter: ['microphones'], check: ([microphones]) => values(microphones.coverage).filter(x => x).length ===1 && microphones.coverageDensity.jumpPoints.length >= 2 },
        element: 'fader',
        label: 'Coverage Density',
        name: 'coverageDensity',
        range: 'jumpPoints',
        dispatcher: 'setMicrophones'
      },
    ],
  },
  speakers: {
    area: [
      {
        ...modals.devices.body[4],
        labels: false,
        theme: 'buttonGroupBigNav',
        bubbles: {
          full_room: 'Full Room',
          focused: 'Focused'
        }
      },
      {
        element: 'selector',
        label: 'Speaker Model',
        name: 'model',
        placeHolder: 'Select speaker',
        items: keys(constants.equipment.speakers),
        // todo
        dispatcher: 'setSpeakers'
      },
    ],
  },
  equipment_list: {
    area: []
  }

}

export default menus;