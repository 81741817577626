// Just geometric calculations 
import { isArray, max, round } from "lodash";

// given a bounding box, returns the rectangle coordinates
export const getRectangle = ({ x0, y0, x1, y1, right, bottom, left = 0, top = 0, length = 0, width = 0 }) => {
  return [x0 ?? left, y0 ?? top, x1 ?? right ?? (left + length), y1 ?? bottom ?? (top + width)]
};

// Check if a coordinate pair [x,y] is inside a rectangle 
export const isInside = (test = [], box) => {
  const rect = isArray(box) ? box : getRectangle(box);
  return test.reduce((acc, val, index) => {
    const [min, max] = index % 2 === 0 ? [rect[0], rect[2]] : [rect[1], rect[3]];
    return acc && val >= min && val <= max;
  }, true);
};

// given an array of 4 points [x0. y0. x1, y1], returns the area
export const getSurface = (points) => Math.abs(points[0] - points[2]) * Math.abs(points[1] - points[3]);

// Calculate overlaps and jump points. Given an area an the radius of a circle, it returns a list of qty changes and what's the overlap for those changes 
export const getOverlaps = (rect = { length: 0, width: 0 }, radius) => {
  const overlaps = [];
  const jumpPoints = [];
  let currentValue = 0;

  const MIN_OVERLAP = 1.4142; // no-gap spacing
  const MAX_OVERLAP = 2; // center to center overlap
  const OVERLAP_STEP = 0.01;

  const { length, width } = rect;
  for (let ov = MIN_OVERLAP; ov <= MAX_OVERLAP; ov += OVERLAP_STEP) {
    const xQty = max([1, round(length / radius / ov)]);
    const yQty = max([1, round(width / radius / ov)]);
    const qty = xQty * yQty;

    if (qty !== currentValue) {
      currentValue = qty;
      jumpPoints.push(qty);
      overlaps.push(round(ov, 4));
    }
  }

  return {
    jumpPoints: jumpPoints.reverse(),
    overlaps: overlaps.reverse()
  };
}

// Calculate device coordinates given coverage box, x&y quantitities, x&y spacing returns an array with the coordinates of every device 
export const calculateCoordinates = (model, coverageBox, qty, spacing) => {

  // note: there might be an issue here. Old tool placed end tables agais the wall so the first speaker was adjuted a bit 

  const startX = coverageBox.left + (coverageBox.length - (qty.x - 1) * spacing.x) / 2;
  const startY = coverageBox.top + (coverageBox.width - (qty.y - 1) * spacing.y) / 2;

  const devArray = Array.from({ length: qty.x * qty.y }, (_, index) => {
    const x = startX + (index % qty.x) * spacing.x;
    const y = startY + Math.floor(index / qty.x) * spacing.y;
    const location = {x, y}
    return {modelName: model, location };
  });








  return devArray
}

// COnditions
// Device === speaker
// Device xCount === 1
// EndTable
