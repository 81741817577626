import styled from "styled-components";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReducersIndex from "reduxModules/ducks";
import constants from "toolConstants/warehouseDesigner/constants";
import { getDrawingBox, getScale } from "reduxModules/ducks/ui";
import { ceil, floor, round } from "lodash";

const Racks = ({ active, area }) => {
  const scale = useSelector(getScale);
  const { beam, pickSize, depth } = constants.area.storage;


  const length = area.dimensions.length - 3 / scale;
  const width = area.dimensions.width - 3 / scale;
  const hor = area.storageOrientation === 'horizontal';
  const { pickingAisles, primaryAisles, connectorAisles, aislesArray } = area;



  // Estimate qty to fill room
  // console.log("connectorAisles: ", connectorAisles); marker
  let xQty = pickingAisles && !hor ? pickingAisles : Math.floor(length / (hor ? beam : pickSize)) - primaryAisles * hor * 2 - connectorAisles* !hor * 2;
  let yQty = pickingAisles && hor ? pickingAisles : Math.floor(width / (hor ? pickSize : beam)) - primaryAisles * !hor * 2 - connectorAisles * hor * 2;
  // console.log("xQty: ", xQty, yQty);
  // Set the rack units 
  const unit = hor ? width / yQty : length / xQty;
  const pick = unit - depth;
  // Estimate how many beans in the inner stacks
  let primaryStacks = primaryAisles !== '' ? primaryAisles - 1 : parseInt((hor ? xQty : yQty) / 12) - ((hor ? xQty : yQty) % 12 <= 10 ? 1 : 0);
  // calculate the racks on the borders and adjust as necesary
  // fixme 
  let racksStack = primaryAisles !== '' ? round((hor ? xQty : yQty) / (primaryAisles || 1)) - 0 : 10;

  // new code: fails when xQty < primaryStacks * racksStack 
  if ((hor ? xQty : yQty) <= primaryStacks * racksStack ) racksStack --;

  let primaryOuters = (hor ? xQty : yQty) - (primaryStacks >= 0 ? primaryStacks * racksStack : 0);

  // console.log("primaryStacks: ", primaryStacks); // these are the inside blocks
  // console.log("rackStacks: ", racksStack); // this is how many racks per block
  // console.log("primaryOuters: ", primaryOuters); // this is the rest total -> each side is half

  if (primaryOuters % 2 !== 0) {
    primaryOuters--;
    hor ? xQty-- : yQty--;
    if (primaryStacks === 1) {
      racksStack++;
      hor ? xQty++ : yQty++;
    };
  };
  primaryOuters = (primaryOuters === 0) ? racksStack : primaryOuters * 0.5;

  
  // Estimate how many connectors marker
  // console.log("xQty: ", xQty, yQty);
  // console.log("con calc: ", parseInt((hor ? yQty : xQty) / 10), ((hor ? yQty : xQty) % 10 <= 2 ? 1 : 0))

  //let connectors = connectorAisles !== '' ? connectorAisles - 1 : parseInt((hor ? yQty : xQty) / 10) //- ((hor ? yQty : xQty) % 10 <= 2 ? 1 : 0);


  // Calculate the racks betwen connectors 
  //marker fixing it 
  let connectors = -1
  let connectorStacks;
  // console.log("connectors: ", connectors);
  //let connectorStacks = (hor ? yQty : xQty) - 1;

  if (connectors < 0) {
    connectorStacks = (hor ? yQty : xQty) - 1;
    connectors = 0;
  } 
  else if (connectors === 0) { // marker why? 
    // console.log("xQty: ", xQty, yQty);
    // console.log("the calc ", yQty % 2 === 0 ? 1 : 2)
    hor ? yQty -= (yQty % 2 === 0 ? 1 : 2) : xQty -= (xQty % 2 === 0 ? 1 : 2);
    
    connectorStacks = 0;
  } 
  else {
    hor ? yQty -= connectorAisles : xQty -= connectorAisles;
    const each = ceil((hor ? yQty : xQty) / ((connectorAisles + 1) || 1));
    const sides = (hor ? yQty : xQty) - each * connectors;
    const corrected = floor(sides - 1) | 1;
    connectorStacks = parseInt(((hor ? yQty : xQty) - corrected) / connectors);
    const adjQty = connectorStacks * connectors + corrected;
    hor ? yQty = adjQty : xQty = adjQty;
  };
  // Calculate the racks outside the connectors
  const connectorOuters = parseInt((hor ? yQty : xQty) - connectorStacks * connectors);
  // console.log("final: ", xQty, yQty, connectors+1); marker
  // console.log("prim: ", primaryOuters, primaryStacks, racksStack, primaryStacks);
  // console.log('varray: ', connectorOuters, connectors, connectorStacks)




  // Generate the rack arrays 
  const hArray = [primaryOuters * (primaryStacks >= 0 ? 1 : 2), ...Array(Math.max(0, primaryStacks)).fill(racksStack), primaryStacks >= 0 ? primaryOuters : 0];
  // note: this is to solve even numbes and no conectors 
  const outerAdj = (connectors === 0) * (connectorOuters%2 === 0)

  const vArray = [connectorOuters + outerAdj, ...Array(connectors >= 0 ? connectors : 0).fill(connectorStacks * 2), connectorOuters - outerAdj];
  // console.log("vArray: ", vArray); //marker
  // console.log("hArray: ", hArray);

  let xArray = [];
  let yArray = [];
  let aArray = [];

  if (hArray[0] > 0 && vArray[0] > 0) {
    // Calculate the aisle space, number of aisles and margin to surroung walls if necesary
    const aisleSpace = hor ? length - xQty * beam : width - yQty * beam;
    const aisles = primaryStacks >= 0 ? aisleSpace / (primaryStacks + 1) : 0;
    const margin = (aisleSpace - (aisles * (primaryStacks + 1))) / 2;
    const connectorSpace = connectorAisles ? (hor ? (width - yQty * unit) : (length - xQty * unit)) / connectorAisles : 0;
    // console.log(aisleSpace, aisles, margin, connectorSpace)

    let count = 0;
    for (let i = 0; i < hArray.length; i++) {
      const length = hArray[i];
      count += hArray[i - 1] || 0;
      const space = i === 0 ? margin : count * beam + (i) * aisles;
      const tempArray = [];
      for (let index = 0; index < length; index++) {
        tempArray.push(index * beam + space);
      };
      xArray = [...xArray, ...tempArray];
    };
    count = 0
    for (let i = 0; i < vArray.length; i++) {
      const length = vArray[i];
      count += vArray[i - 1] || 0;
      const space = i === 0 ? 0 : parseInt((count - 1) / 2) * unit + (count - 1) % 2 * pick + connectorSpace * i;

      const tempArray = [];
      for (let index = 0; index < length; index++) {
        tempArray.push(parseInt((index + (i > 0)) / 2) * unit + (index + (i > 0)) % 2 * pick + space);
      }
      yArray = [...yArray, ...tempArray];
    }
    // Flip if necesary
    if (!hor) [xArray, yArray] = [yArray, xArray];

    // create the aisles Array
    const cons = [];
    let sum = 0;
    for (let i = 0; i < vArray.length - 1; i++) {
      sum += parseInt(vArray[i] / 2);
      if (connectorSpace > 0) cons.push(sum);
    };

    let tempArray = hor ? yArray : xArray;
    for (let i = 0; i < tempArray.length; i++) {
      if (i % 2 === 0) {
        const e = tempArray[i];
        aArray.push({
          type: cons.includes(i / 2) ? 'connector' : 'picking',
          left: hor ? 0 : e + depth,
          top: hor ? e + depth : 0,
          length: hor ? length : xArray[i + 1] - e - depth,
          width: hor ? yArray[i + 1] - e - depth : width
        });
      };
    };
    if (hArray.slice(-1)[0] !== 0) {
    sum = 0;
    for (let i = 0; i < hArray.length - 1; i++) {
      sum += hArray[i];
      aArray.push({
        type: 'primary',
        left: hor ? xArray[sum - 1] + beam : 0,
        top: hor ? 0 : yArray[sum - 1] + beam,
        length: hor ? xArray[sum] - xArray[sum - 1] - beam : length,
        width: hor ? width : yArray[sum] - yArray[sum - 1] - beam
      });
    };
  }

    // console.log("aArray: ", aArray); // marker
  };

  // console.log("check: ", aArray.reduce((acc, curr) => curr.type === 'picking' ? acc + 1 : acc, 0))

  // const picks = aArray.reduce((acc, curr) => curr.type === 'picking' ? acc + 1 : acc, 0)
  // console.log("picks: ", picks); marker
  const dispatch = useDispatch();
  useEffect(() => { // useEffect prevents error while rendering
    if (pickingAisles === 0 && xQty && yQty) dispatch(ReducersIndex.container.setPickingAisles((hor ? yQty : xQty)));
    //if (pickingAisles === 0) dispatch(ReducersIndex.container.setPickingAisles(picks));
    if (primaryAisles === '') dispatch(ReducersIndex.container.setPrimaryAisles(primaryStacks + 1));
    // marker not dispatching here
    //if (connectorAisles === '') dispatch(ReducersIndex.container.setConnectorAisles(connectors ));
    // aislesArray.length !== aArray.length
    // fixme use compare from utils 
    // console.time('comp')
    const co = JSON.stringify(aislesArray) !== JSON.stringify(aArray)

    if (aArray.length > 0 && co) dispatch(ReducersIndex.container.setAislesArray({area:area.id, array: aArray}));

    // new code: saving the arrays for PDF export 
    if (xArray.length > 0 && yArray.length > 0) dispatch(ReducersIndex.container.setRackArrays({area: area.id, arrays:{xArray: xArray, yArray: yArray}}))

    // console.timeEnd('comp')
// eslint-disable-next-lines
  }, [pickingAisles, primaryAisles, aislesArray, hor]);
  // deps [pickingAisles, xQty, yQty, hor, primaryAisles, primaryStacks, connectorAisles, connectors, aArray, aislesArray.length, dispatch]

  const props = {
    id: area.id,
    active: active,
    scale: scale,
    origin: area.location,
    ver: hor,
    xArray: xArray,
    yArray: yArray
    

  }

  const racksOk = xArray.length > 0 && yArray.length > 0 && !xArray.includes(NaN) && !yArray.includes(NaN);

  return (
    <Fragment>
      {racksOk && <StorageRacks {...props} />}
    </Fragment>
  )

};

const StorageRacks = (props) => {

  const {bbox, zoom} = useSelector(getDrawingBox);

  const { left, top } = props.origin;
  const x0 = bbox.x0 + left * props.scale * (!zoom);
  const y0 = bbox.y0 + top * props.scale * (!zoom);

  return (
    <StorageRacks.Group id={`${props.id}-storageracks`} active={props.active}>
      {props.yArray.map((y, i) =>
        props.xArray.map((x, j) =>
          <StorageRacks.Path key={`rack-${i}-${j}`} id={`rack-${j}-${i}`} {...props} y={y0 + y * props.scale} x={x0 + x * props.scale} />
        )
      )}
    </StorageRacks.Group>
  )
};

StorageRacks.Group = styled.g.attrs(props => ({
  id: props.id,
}))`
    fill: none;  
    stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-custom-gray)'};
    stroke-width: 1;
    shape-rendering: crispedges;
  `;

StorageRacks.Path = styled.path.attrs(props => {
  const { beam, depth } = constants.area.storage;
  const short = depth * props.scale; // 48"
  const long = beam * props.scale // 96"
  return (
    {
      d: `m${props.x + 1.5} ${props.y + 1.5}h${props.ver ? long : short}v${props.ver ? short : long}h${- (props.ver ? long : short)}z`,
    }
  )
})`
`;

export default Racks

