import styled from "styled-components";
import constants from "toolConstants/warehouseDesigner/constants";

const Staging = ({ active, area }) => {

  const length = area.dimensions.length;
  const width = area.dimensions.width;
  // const hor = area.storageOrientation === 'horizontal';

  let xQty = Math.floor(length / 1.2192); // 4'
  let yQty = Math.floor(width / 1.2192) ;

  console.log("staging: ", xQty, yQty);

  // // Set the rack units 
  // const unit = hor ? width / yQty : length / xQty;
  // const pick = unit - depth;
  // // Estimate how many beans in the inner stacks
  // let primaryStacks = primaryAisles !== '' ? primaryAisles - 1 : parseInt((hor ? xQty : yQty) / 12) - ((hor ? xQty : yQty) % 12 <= 10 ? 1 : 0);
  // // calculate the racks on the borders and adjust as necesary
  // let racksStack = primaryAisles !== '' ? Math.round((hor ? xQty : yQty) / (primaryAisles || 1)) - 2 : 10;
  // let primaryOuters = (hor ? xQty : yQty) - (primaryStacks >= 0 ? primaryStacks * racksStack : 0);
  // if (primaryOuters % 2 !== 0) {
  //   primaryOuters--;
  //   hor ? xQty-- : yQty--;
  //   if (primaryStacks === 1) {
  //     racksStack++;
  //     hor ? xQty++ : yQty++;
  //   };
  // };
  // primaryOuters = (primaryOuters === 0) ? racksStack : primaryOuters * 0.5;


  

  return (
    <Staging.Group id={`${area.id}-staging`} active={active}>

    </Staging.Group>
  )

  
}


Staging.Group = styled.g.attrs(props => ({
  id: props.id,
}))`
    fill: none;  
    stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-custom-gray)'};
    stroke-width: 1;
    shape-rendering: crispedges;
  `;

Staging.Path = styled.path.attrs(props => {
  const { beam, depth } = constants.storage;
  const short = depth * props.scale; // 48"
  const long = beam * props.scale // 96"
  return (
    {
      d: `m${props.x + 1.5} ${props.y + 1.5}h${props.ver ? long : short}v${props.ver ? short : long}h${- (props.ver ? long : short)}z`,
    }
  )
})`
`;


export default Staging