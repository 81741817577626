import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Label from 'components/atoms/Label';
import Select from 'components/atoms/Select';
import ReducersIndex from 'reduxModules/ducks';
import { getCurrentValue } from 'reduxModules/ducks/container';

const Selector = (props) => {
  const { parameter = null, filter = (arg, items) => items } = props.filter ?? {};
  const args = useSelector(state => parameter?  getCurrentValue(state, parameter) : null);
  const items = filter(args, props.items);
  const currentValue = useSelector(state => props.dispatcher ? getCurrentValue(state, props.dispatcher) : items[0]);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  
  useEffect(() => {
    const current = props.name ? (currentValue[props.name] === undefined ? currentValue : currentValue[props.name]) : currentValue;
    setValue(current);
    // eslint-disable-next-line
  }, [currentValue]);

  const handleChange = (evt) => {
    setValue(evt.target.value);
    const newValue = props.name ? { ...{}, [props.name]: evt.target.value } : evt.target.value
    if (props.dispatcher) dispatch(ReducersIndex.container[props.dispatcher](newValue));
  };

  return (
    <div className={`d-flex flex-wrap gap-1 ${props.className}`}>
      {props.label && <Label htmlFor={props.name} type={'select'}>{props.label}</Label>}
      <Select {...props} items={items} value={value} onChange={(e) => handleChange(e)} />
    </div>
  )
};

export default Selector;
