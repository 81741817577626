
import initialState from "toolConstants/conferenceroomDesigner/initalState";

export const resetArea = (container, parameter = null) => {
  const activeArea = container.areas[container.activeTab];
  const initState = initialState.area();

  // reset the ceiling tiles
  const { tile } = activeArea.ceilingTiles;
  const ceilingTiles = initState.ceilingTiles;
  activeArea.ceilingTiles = {...ceilingTiles, tile: tile};

}