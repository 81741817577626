import styled from "styled-components";
import { Fragment } from "react";
import { storageElements } from "components/elements/storageElements";
import { useSelector } from "react-redux";
import { getConditionResult } from "reduxModules/ducks/container";
import { getDrawingBox } from "reduxModules/ducks/ui";
import constants from "toolConstants/warehouseDesigner/constants";

const Storage = (props) => {
  const area = props.children;
  const usage = area.areaUsage;
  const Element =  storageElements[usage];
  const usageLabel = constants.area.tooltips[usage];
  const {bbox, scale} = useSelector(getDrawingBox);
  const { left = 0, top = 0 } = area?.location || {};
  const {length = 0, width=0} = area?.dimensions || {};

  // fixme I can get storageready here because I'm pasing the whole area
  const visible = area.storageReady;
  const active = useSelector(state => getConditionResult(state, {...props.active, area: area.id}));

  const objProps = {
    x: bbox.x0 + left * scale,
    y: bbox.y0 + top * scale,
    width: length * scale,
    height: width * scale
  };

  return (
    visible && <Fragment>
      {Element && <Element active={active} area={area} />}
      {!Element && <foreignObject {...objProps}><UsageLabel active={active} ver={length * scale <= 75}>{usageLabel}</UsageLabel></foreignObject>}
    </Fragment>
  )
};

const UsageLabel = styled.p.attrs(props => ({
  className: `m-0 d-flex align-items-center justify-content-center text-center text-${props.ver ? 'nowrap' : 'wrap'}`
}))`
  height: inherit;
  color: ${props => props.active ? 'var(--app-red)' : 'var(--app-dark-gray)'};
  transform: ${props => `rotate(${props.ver ? '-90' : '0'}deg)`}
`;

export default Storage

