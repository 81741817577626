import styled from "styled-components";

const AppHeader = ({ children }) => {
  const logoClick = () => {
    window.open('https://www.biamp.com', '_blank');
  };

  const stoneClick = () => {
    window.open('https://support.biamp.com', '_blank');
  };

  return (
    <AppHeader.Wrapper>
      <AppHeader.Upper>
        <AppHeader.Stone onClick={stoneClick} >
          <span style={{ fontSize: '1.5rem', color: '#74777a', lineHeight: '24px' }}>CORNERSTONE</span>
          <span style={{ fontSize: '1rem', color: '#000', lineHeight: '16px', fontFamily: 'Gotham-light' }}>Technical Support Knowledge Base</span>
        </AppHeader.Stone>
        <AppHeader.Logo onClick={logoClick} />
      </AppHeader.Upper>
      <AppHeader.Title title={children} />
    </AppHeader.Wrapper>
  )
};

AppHeader.Wrapper = styled.header.attrs(props => ({
  className: 'vstack',
}))`
  border-bottom: 1px solid var(--app-red);
  flex-grow: 0
`;

AppHeader.Upper = styled.div.attrs(props => ({
  className: 'hstack d-flex justify-content-between'
}))``

AppHeader.Stone = styled.div.attrs(props => ({
  className: 'vstack mt-3 ms-5'
}))`
  cursor: pointer
`

AppHeader.Logo = styled.img.attrs(props => ({
  className: 'float-end ms-auto me-5 mt-3',
  src: 'images/Biamp_Logo.png',
  alt: 'Biamp Logo'
}))`
  height: 40px;
  cursor: pointer
`;

AppHeader.Title = ({ title }) => <h2 className='text-center'>{title}</h2>

export default AppHeader;