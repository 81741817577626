import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';
import Label from 'components/atoms/Label';
import UnitInput from 'components/atoms/UnitInput';
import { getCurrentValue } from 'reduxModules/ducks/container';

import { round, toNumber } from 'lodash';

const MeasurementInput = ({ spinStep = 1, ...props }) => {
  const { min = -Infinity, max = +Infinity } = props?.range || {}
  const toString = useCallback((value) => `${value ? `${round(value)} ${props.unit}` : ""}`, [props.unit])

  const currentValue = useSelector(state => getCurrentValue(state, props.dispatcher));
  const [value, setValue] = useState('');
  const [valueString, setValueString] = useState("");
  const dispatch = useDispatch();

  const updateValue = (value) => {
    setValue(Math.max(min, Math.min(value, max)));
  };

  useEffect(() => {
    if (typeof currentValue === 'number') setValue(currentValue);
  }, [currentValue])

  useEffect(() => {
    setValueString(toString(value));
    if (props.dispatcher) dispatch(ReducersIndex.container[props.dispatcher](value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toString, value]);

  const handleKeyDown = (evt) => {
    const key = evt.key
    if (!['Delete', 'Backspace', 'Tab'].includes(key) && !/^[0-9.]+$/.test(key)) evt.preventDefault()
    const currentValue = evt.target.value
    switch (key) {
      case 'Enter':
      case 'Tab':
        updateValue(toNumber(currentValue));
        break;
      case 'ArrowUp':
        updateValue(round(value + spinStep));
        break;
      case 'ArrowDown':
        updateValue(round(value - spinStep));
        break;
      default:
        break;
    };
  };

  const handleBlur = (evt) => {
    updateValue(parseFloat(evt.target.value));
  }

  props = {
    ...props,
    spinStep: spinStep,
    value: value,
    setValue: updateValue,
    valueString: valueString,
    setValueString: setValueString,
    handleKeyDown: handleKeyDown,
    handleBlur: handleBlur
  };

  return (
    <div className={`d-flex flex-wrap gap-1 ${props.className}`}>
      {props.label && <Label htmlFor={props.name} type={'text'}>{props.label}</Label>}
      <UnitInput {...props} />
    </div>
  )
};

export default MeasurementInput;