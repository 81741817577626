import { Fragment } from "react";

const Furniture = {
  // Conference Room Tables
  rectangle: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <path d="M2 12h46v26H2z" />
    </g>,

  racetrack: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <rect width="46" height="26" rx="13" x="2" y="12" />
    </g>,

  boat: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <path d="M48.2 33.5a67.1 67.1 0 01-45.7 0v-17.6a67.1 67.1 0 0145.688 0z" />
    </g>,

  round: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <circle cx="25" cy="25" r="13" />
    </g>,

  bullet: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <path d="M2.5 11.9h32.9a12.8 12.8 0 0112.8 12.8v0A12.8 12.8 0 0135.363 37.5H2.5z" />
    </g>,

  keystone: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <path d="M44.2 32.7L6.1 37.5a3.6 3.6 0 01-3.6-3.6V15.7a3.6 3.6 0 013.6-3.6l38 4.8a3.6 3.6 0 013.6 3.6v8.6a3.6 3.6 0 01-3.6 3.6z" />
    </g>,

  ushape: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <path d="M9 12h28a5 5 0 0 1 5 5v16a5 5 0 0 1-5 5h-28v-10h22v-6h-22z" />
    </g>,

  vshape: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <path d="M39.2 32.7l-30 4.8v-9l20-3.2v-1l-20-3.2v-9l30 4.8a3.6 3.6 0 013.6 3.6v8.6a3.6 3.6 0 01-3.6 3.6z" />
    </g>,
    
  // Classroom Tables
  Chair: ({ className, id, state = null, arms = false, size = 0, x=0, y=0, a=0 }) =>
    <Fragment>
      <g className={className} id={id} fill={state.border_fill} fillOpacity={state.opacity ?? 1} stroke={state.icon_stroke} strokeDasharray={state.dash ?? 0} strokeWidth='.75' strokeMiterlimit='10' shapeRendering='geometricprecision' 
      transform={`translate(${x}, ${y}) rotate(${a}) scale(${size ? size / 31.18 : 1} ${size ? size / 30.11 : 1}) translate(${size ? -30.11 : 0}, ${size ? -25.2: 0})` }>
        <path d="m49.32863,84.5c37.36737,0 31.80172,-24.68478 31.80172,-24.68478l-3.51085,-24.57592c-1.5377,-14.00256 -13.81205,-13.97534 -13.81205,-13.97534l-25.36517,0
        c-15.60829,2.38139 -16.4792,16.16622 -16.4792,16.16622c-0.46267,3.97351 -3.44281,24.3854 -3.44281,24.3854c-1.49687,13.40381 7.98785,18.7109 7.98785,18.7109
        c6.99447,3.97351 22.82049,3.97351 22.82049,3.97351l0.00002,0.00001zm-22.34422,-96.79318c0,0 10.9816,-1.18389 24.71199,-1.18389c13.71679,0 21.55496,1.18389 21.55496,1.18389
        c10.4781,1.27915 7.47075,11.56674 7.47075,11.56674c-0.10886,1.10224 -1.75542,8.45052 -2.21809,9.79771c-1.21111,3.51085 -3.48363,5.89223 -6.58624,7.18499
        c-2.24531,0.93895 -4.58587,1.59213 -6.99447,1.59213c-0.47628,0 -0.93895,0 -1.41522,0l-26.79401,0c-0.46267,0 -0.93895,0 -1.41522,0c-2.4086,0 -4.74917,-0.65318 -6.99447,-1.59213
        c-3.10261,-1.30636 -5.37513,-3.67414 -6.58624,-7.18499c-0.46267,-1.34718 -2.10923,-8.69546 -2.21809,-9.79771c0,0 -3.00735,-10.28759 7.48436,-11.56674l-0.00001,0z"
          transform="scale(.3) rotate(90 50 50) translate(34 -34)" />
        <path d="m20.23115,-5c0,0 8.09,-0.74 18.17,-0.59c10.08,0.16 15.82,1.12 15.82,1.12c3.63,0.5 5.03,2.43 5.49,4.34c0.32,-1.29 1.45,-7.4 -5.42,-8.35
        c0,0 -5.75,-0.96 -15.83,-1.11c-10.08,-0.16 -18.17,0.58 -18.17,0.58c-6.9,0.74 -5.96,6.88 -5.68,8.18c0.52,-1.89 1.98,-3.78 5.62,-4.17z 
        m13.1, 24.5 l5.97,0l0.04,-2.51l-5.97,0l-0.04,2.51z"  transform="scale(.415) rotate(89 50 50) translate(24.6 -.7)" />

        {arms && <path d="m52.12,47.92l-5.2,-19.8c-1.72,-6.11 -4.51,-5.75 -5.62,-5.55c-2.9,0.52 -2.48,3.24 -2.25,6.85l2.43,19.32c0.56,3.22 2.73,5.76 5.67,5.53c2.94,-0.22 5.17,-3.07 4.97,-6.35z
        m-71.95,-1.12l5.81,-19.62c1.91,-6.06 4.69,-5.61 5.79,-5.38c2.88,0.61 2.38,3.31 2.03,6.92l-3.02,19.24c-0.66,3.2 -2.91,5.67 -5.84,5.35c-2.93,-0.32 -5.07,-3.23 -4.77,-6.51z
        m14.28,-5.42l-2.63,-0.04l0.95,-6.06l2.6,0.12l-0.92,5.98zm43.56,1.18l2.63,0.04l-0.76,-6.08l-2.61,0.04l0.74,6z" transform="scale(.415) rotate(89 50 50) translate(44.6 -.7)" />}
      </g>
    </Fragment>,

  tablet: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <Furniture.Chair state={state} />
      <path d="m8,12l11,1l10,2c2,1 1,4 -1,4l-6,.2a2,2 0 0 0 -2,2l-.5,5a2,2 0 0 1 -2,2l-10.5,-.8a2,2 0 0 1 -2,-2.5l1,-10.9a2,2 0 0 1 2,-2z" />
    </g>,

  desk: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <Furniture.Chair state={state} />
      <rect x="5" y="12" width="18" height="26" rx="2" />
    </g>,

  arch: ({ state }) =>
    <g fill={state.border_fill} stroke={state.icon_stroke} strokeWidth='1' strokeMiterlimit='10' shapeRendering='geometricprecision' >
      <path d="m2,22 c11,-9 36,-9 46,0 l-7,11 c-10,-5.5 -22,-5.5 -32,0 l-7,-11z" />
    </g>,

};

export default Furniture;
export const { Chair } = Furniture

// Classroom rectangle is d="M4 18h42v15h-42z"
// Classroom round is <circle cx="25" cy="25" r="10" />
// classroom racetrack is <rect width="40" height="22" rx="11" x="4" y="15" />
