import { forwardRef } from 'react';
import styled from 'styled-components';

const Canvas = forwardRef((props, ref) => {
  return (
    <Canvas.Wrapper ref={ref} {...props} />
  )
});

Canvas.Wrapper = styled.canvas.attrs(props => ({
  style: {
    top: props.legend ? 'auto' : `${props.top}px`,
    left: props.legend ? 'auto' : `${props.left || 0}px`,
    width: props.legend ? '-webkit-fill-available' : `${props.width}px`,
    height: `${props.legend ? 20 : props.height}px`,
  }
}))`
  position: ${props => props.legend ? 'relative' : 'absolute'};
  border: ${props => props.legend ? '1px solid var(--app-custom-gray)' : 'none'};
  border-radius: ${props => props.legend ? '4px' : '0px'};
  z-index: -1;
`;

export default Canvas