// import { useRef, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import icons from 'globalConstants/Icons';
import Bubble from 'components/atoms/Bubble';
import IconSprite from 'components/atoms/IconSprite';
import { iconThemes } from 'globalConstants/Themes';
import { useDispatch } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';

const Button = (props) => {
  const hasIcon = (props?.useIcon ?? true) && props?.name in icons;
  const editable = props.editable;
  const label = props.label && props.label.toString().replace('_', ' ');
  const bubble = props.bubble ?? '';
  const vertical = props.theme?.vertical ?? false;
  const upperCase = props.theme?.uppercase ?? false

  const dispath = useDispatch();
  const handleEnter = (evt) => dispath(ReducersIndex.uiReducers.setBubbleContent(bubble));
  const handleLeave = (evt) => dispath(ReducersIndex.uiReducers.setBubbleContent(false));

  return (
    <ThemeProvider theme={props.theme || {}}>
      <Button.Wrapper className={props.className} state={iconThemes[props.state]} editable={editable}
        onClick={props.onClick} onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp} // for info
        onMouseEnter={handleEnter} onMouseLeave={handleLeave}
        data-tooltip-id={props.name} data-tooltip-content={bubble} data-tooltip-place={props.place || 'top'}
        data-bs-target={props.target} data-bs-slide-to={props.slideTo} data-bs-slide={props.slide}>
        <Input type={props.type || 'button'} name={props.name} />
        <Label htmlFor={props.name} type={props.type || 'button'} hasIcon={hasIcon} label={props.label} editable={editable}>
          {props.label && editable && <span className={'align-items-center text-truncate'} >{label}</span>}
          {hasIcon && <IconSprite state={props.state} icon={props.name} size={props.theme?.iconSize || '34px'} scale={props.theme?.iconScale || '1'} usage={props.usage} />}
          {props.label && !editable && <span className={`
          d-inline-flex align-items-center 
          ${hasIcon ? `w-100 ms-${vertical ? '0 justify-content-center' : '2'} ` : 'juistify-content-center'}
          ${upperCase ? 'text-uppercase' : ''}`} >{label}</span>}
        </Label>
      </Button.Wrapper>
      {props.bubble && <Bubble id={props.name} />}
    </ThemeProvider>
  )
}

// 'd-flex align-items-center w-100 text-truncate'

export default Button;
//justify-content-${props.editable ? 'start' : 'center'}
Button.Wrapper = styled.div.attrs(props => {
  return ({
    className: `d-flex align-items-center `,
  })
})`
  background-color: ${props => props.state?.background || props.theme.background};
  color: ${props => props.state?.color || props.theme.color};
  font-weight: bold;
  font-size: ${props => props.theme.size};
  border: ${props => props.state?.border || props.theme.border};
  border-right: ${props => props.theme.borderRight};
  border-radius: ${props => props.theme.radius};
  height: ${props => props.theme.maxHeight || '100%'};
  min-height: ${props => props.theme.maxHeight || '30px'};
  width: ${props => props.theme.width};
  padding: ${props => props.theme.padding};
  max-width: ${props => props.theme.maxWidth};
  justify-content: ${props => props.theme.justify || (props.editable ? 'start' : 'center')};
  :hover {
    max-width: ${props => props.editable && 'fit-content'};
  };
  :hover .text-truncate {
    overflow: visible;
    text-overflow: unset;
    white-space: unset;
  }
  `;

// const SVGWrapper = styled.svg.attrs(props => ({
//   xmlns: "http://www.w3.org/2000/svg",
//   viewBox: "0 0 50 50",
//   transform: props.transform
// }))`
//   height: ${props => props.size};
//   min-width: ${props => props.size}
// `;

// const IconSprite = ({ state, icon, size, scale=1, usage }) => {
//   const Icon = icons[icon];
//   const racks = usage === 'palletRacks';
//   return (
//     <SVGWrapper size={size} transform={`scale(${scale})`} ><Icon state={iconThemes[state]} racks={racks} /></SVGWrapper>

//   )
// }

// https://stackoverflow.com/questions/11671100/scale-path-from-center

