import styled from "styled-components";
import { useSelector } from "react-redux";
import { getScale } from "reduxModules/ducks/ui";

const Ninety = ({ x = 0, y = 0, full = false, flipH = false, flipV = false }) => {
  const scale = useSelector(getScale);
  const adj = full * .7;
  // Rails
  const rails = `m${x} ${y}h.2a${2.2 + adj} ${2.2 + adj} 0 0 1 ${2.05 + adj} ${2.05 + adj}v.2h-.15v-.2a${2.1 + adj} ${2.1 + adj} 0 0 0 -${1.9 + adj} -${1.9 + adj}h-.2z
  m0 ${.9 + adj}a1.35 1.35 0 0 1 1.35 1.35h.15a1.5 1.5 0 0 0 -1.5 -1.5z`
  // Rollers 
  let rollers = `m${x + .06} ${y + .15}v${.6 + adj}m.12 0v-${.6 + adj}`;
  let midRolls = `m${x + .12} ${y + .15}v${.6 + adj}`;
  for (let alpha = Math.PI / 18; alpha < Math.PI / 2; alpha += Math.PI / 18) {
    const xv = Math.cos(alpha);
    const yv = Math.sin(alpha);
    rollers += `M${x + (1.5 * xv) - (.06 * yv)} ${y + (2.25 + adj) - (1.5 * yv) - (.06 * xv)}l ${(.6 + adj) * xv} ${-(.6 + adj) * yv}m${.12 * yv} ${.12 * xv}l${-(.6 + adj) * xv} ${(.6 + adj) * yv}`;
    midRolls += `M${x + (1.5 * xv)} ${y + (2.25 + adj) - (1.5 * yv)}l ${(.6 + adj) * xv} ${-(.6 + adj) * yv}`;
  };
  rollers += `m1.18 1.3h${.6 + adj}m0 .12h-${.6 + adj}`;
  midRolls += `m${full ? 1.015 : 1.15} ${1.96 + adj} h${.6 + adj}`;
  const showMids = scale >= 30; //scale limit to show/hide midRoll lines
  // Transformation
  const transform = `scale(${1 - 2 * flipH}, ${1 - 2 * flipV}) translate(-${flipH * (2 * x + 2.25 + adj)}, -${flipV * (2 * y + 2.25 + adj)})`;

  return (
    <Ninety.Group transform={transform} >
      <Ninety.Path path={rails} rail/>
      <Ninety.Path path={rollers} showMids={showMids} />
      {showMids && <Ninety.Path path={midRolls} showMids={showMids} dash={true} />}
    </Ninety.Group>
  )
};

Ninety.Group = styled.g.attrs(props => ({
  transform: props.transform
}))`
  stroke-width: 1;
  
`;

Ninety.Path = styled.path.attrs(props => ({
  d: props.path,
}))`
  vector-effect: non-scaling-stroke;
  fill: ${props => props.fill ? '#fff' : 'none'};
  stroke-width: ${props => props.showMids && (props.showMids ? 1 : .5)};
  stroke-dasharray: ${props => props.dash ? '6 1.5 1 1.5' : ''};
  shape-rendering: ${props => props.rail ? `geometricprecision` : `crispedges`};
`;

export default Ninety;