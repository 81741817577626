
export const buttonThemes = {
  // Tool buttons 
  // These are fixed themes: prettty much defines the styling for some specific components or even object. 
  // it adds specificity 

  // This are all nav buttons that have different stylings - look different
  nav_red: {
    background: 'var(--app-red)',
    color: '#fff',
    labelWidth: 'auto'
  },
  nav_black: {
    background: '#000',
    color: '#fff',
    labelAlign: 'start'
  },
  nav_gray: {
    background: 'var(--app-light-gray)',
    color: '#000',
    borderRight: '1px solid #000',
    labelAlign: 'start',
    maxWidth: '100px',
  },
  nav_light: {
    color: 'var(--app-custom-gray)',
  },

  // particulat styling for unit switch
  units: {
    labelWidth: 'auto',
    color: 'var(--app-medium-gray)',
  },

  // styling for button groups 
  buttonGroupDef: {
    labelAlign: 'center',
    labelWidth: '100%',
    // border: '1px solid var(--app-dark-gray)',
    background: 'transparent',
    height: '34px',
    checked: 'var(--app-red)',
    unchecked: 'var(--app-light-gray)'
  },

  // this is for coverage in modals
  buttonGroupBig: {
    iconSize: '50px',
  },

  // This is for coverage in menus (currently only in WD)???
  buttonGroupBigNav: {
    iconSize: '38px',
    width: '40px',
    justify: 'start'
  },

  // This is for table shapes
  furnitureGroup: {
    iconSize: '90px',
    checked: 'none',
    unchecked: 'none',
  },

    // This is for table shapes
    furnitureGroupNav: {
      iconSize: '50px',
      checked: 'none',
      unchecked: 'none',
    },

  // fpr device icons in modals 
  deviceGroup: {
    iconSize: '50px',
    checked: 'none',
    unchecked: 'none',
    // vertical: true,
    // labelWidth: '110PX',
  },

  // acoustic buttons (text buttons) 
  acousticGroup: {
    width: '82px',
    size: '16px',
    checked: 'var(--app-red)',
    unchecked: 'var(--app-light-gray)',
    label_checked: '#fff',
    label_unchecked: 'var(--app-medium-gray)',
    uppercase: true
  },

  // Family buttons (extra large text)
  familyGroup: {
    width: '200px',
    size: '44px',
    checked: 'var(--app-red)',
    unchecked: 'var(--app-light-gray)',
    label_checked: '#fff',
    label_unchecked: 'var(--app-medium-gray)',
  },


  navGroup: {
    iconScale: 1.2,
    justify: 'start',
    checked: 'var(--app-red)',
    unchecked: '#fff',
    label_checked: '#fff',
    label_unchecked: 'var(--app-medium-gray)',
  },

  actionGroup: {
    background: 'transparent',
    height: '36px',
    width: '64px',
    checked: 'var(--app-red)',
    unchecked: 'var(--app-medium-gray)',
    iconScale: '1.2'
  },

  dotGroup: {
    height: '8px',
    maxWidth: '8px',
    maxHeight: '8px',
    border: '4px',
    radius: '50%',

  }
}

// these define the states on multi-state components
// Currently there are 5 or 6 states defines: selected, active, disabled, disabled-dim, outline, outline-active, hidden
export const iconThemes = {
  'button_selected': {
    'border_stroke': 'var(--app-dark-gray)',
    'border_fill': 'var(--app-red)',
    'icon_stroke': '#fff',
    'icon_fill': '#fff',
    border: '1px solid var( --app-dark-gray)',
  },

  'button_active': {
    'border_stroke': 'var(--app-red)',
    'border_fill': 'var(--app-light-gray)',
    'icon_stroke': 'var(--app-dark-gray)',
    'icon_fill': ' var(--app-dark-gray)',
    border: '1px solid transparent'

  },

  'button_disabled': {
    'border_stroke': 'var(--app-medium-gray)',
    'border_fill': 'var(--app-medium-gray)',
    'icon_stroke': '#fff',
    'icon_fill': '#fff',
  },

  'button_disabled-dim': {
    'border_stroke': 'var(--app-custom-gray)',
    'border_fill': 'var(--app-custom-gray)',
    'icon_stroke': '#fff',
    'icon_fill': '#fff',
  },

  // 'button_outline': {
  //   'border_stroke': '#fff',
  //   'border_fill': '#fff',
  //   'icon_stroke': 'var(--app-medium-gray)',
  //   'icon_fill': 'var(--app-medium-gray)',
  // },

  // 'button_outline_active': {
  //   'border_stroke': '#fff',
  //   'border_fill': '#fff',
  //   'icon_stroke': 'var(--app-red)',
  //   'icon_fill': 'var(--app-red)',
  // },

  // 'button_hidden': {
  //   fill: 'none',
  // },

  'nav_active': {
    'border_stroke': 'none',
    'border_fill': 'none',
    'icon_stroke': '#fff',
    'icon_fill': '#fff',
  },

  'nav_inactive': {
    'border_stroke': 'none',
    'border_fill': 'none',
    'icon_stroke': 'var(--app-medium-gray)',
    'icon_fill': 'var(--app-medium-gray)',
  },

  'coverage_active': {
    'border_stroke': 'var(--app-red)',
    'border_fill': 'var(--app-red)',
    'icon_stroke': '#fff',
    'icon_fill': '#fff',
    'color': 'var(--app-red)'
  },

  'coverage_inactive': {
    'border_stroke': 'var(--app-medium-gray)',
    'border_fill': 'var(--app-medium-gray)',
    'icon_stroke': '#fff',
    'icon_fill': '#fff',
  },

  'furniture_active': {
    'border_stroke': 'var(--app-red)',
    'border_fill': 'var(--app-red)',
    'icon_stroke': 'var(--app-dark-gray)',
    // 'icon_fill': '#fff',
    // 'color': 'transparent'
  },
  'furniture_inactive': {
    'border_stroke': 'var(--app-medium-gray)',
    'border_fill': 'none',
    'icon_stroke': 'var(--app-medium-gray)',
    // 'icon_fill': '#fff',
  },

  'device_selected': {
    'border_stroke': 'var(--app-dark-gray)',
    'border_fill': 'var(--app-red)',
    'icon_stroke': '#fff',
    'icon_fill': '#fff',
    border: '1px solid transparent',
  },

  'acoustic_inactive': {
    border: '1px solid var(--app-dark-gray)',
  },

  'dot_selected': {
    background: 'var(--app-red)',
  },
  'dot_active': {
    background: 'var(--app-light-gray)',
  }

}

// this theme only aplies to the modals 
export const modalThemes = {
  splash: {
    labelAlign: 'start',
    labelWidth: '28%',
    lineHeight: '32px',
  },
  default: {
    // header
    headerPadding: '16px 24px',
    headerBackground: '#000',
    headerColor: '#fff',
    // Body
    background: '#fff',
    bodyPadding: '24px 24px 0px 24px',
    // Body items
    labelAlign: 'start',
    labelWidth: 'auto',
    inputBackground: 'var(--app-light-gray)',
    // Footer
    footerBackground: 'var(--app-light-gray)',
  },
  tutorial: {
    // header
    headerPadding: '16px 24px',
    headerBackground: '#fff',
    headerColor: '#000',
    headerAlign: 'center',
    headerBorder: 'var(--app-light-gray)',

    // Body
    background: '#fff',
    bodyPadding: '24px 24px 0px 24px',
    // Body items
    // labelAlign: 'start',
    // labelWidth: 'auto',
    // inputBackground: 'var(--app-light-gray)',
    // Footer
    footerBackground: 'var(--app-light-gray)',
    footerMargin: '0.5rem!important'
  }
};

