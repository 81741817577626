import { Document, Page, StyleSheet, Font, View } from '@react-pdf/renderer';
import reportElements from 'components/sections';

import GothamBook from '../../globalConstants/fonts/GothamBook.ttf'
import GothamBold from '../../globalConstants/fonts/GothamBold.ttf'
import GothamLight from '../../globalConstants/fonts/GothamLight.ttf'
import GothamMedium from '../../globalConstants/fonts/GothamMedium.ttf'
import { get, pick, values, merge, isFunction } from 'lodash';


Font.register({
  family: "Gotham",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: GothamBook,
    },
    {
      src: GothamBold,
      fontWeight: "bold",
    },
    {
      src: GothamLight,
      fontWeight: "light",
    },
    {
      src: GothamMedium,
      fontWeight: "medium",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 12,
    lineHeight: 1.2,
    fontFamily: 'Gotham',
  },
});

// Create Document Component
const ExportDocument = ({ report, store, constants }) => {
  const current = store.getState();
  const state = {
    ...current.container,
    isMeter: current.ui.units === 'meters',
    device: current.ui.currentDevice,
    scale: current.ui.scale,
  };

  const body = report.body.concat(...report.areas(state));

  return (
    <Document {...report.metadata}>
      <Page size="LETTER" style={styles.page} >
        {body.map((section, idx) =>
          <View key={`section-${section.id}`} wrap={section.wrap ?? false} >
            {section.content.map((child, index) => {
              const { element, label, content, consts = [] } = child;
              const Element = reportElements[element];
              const props = {
                label: isFunction(label) ? label(state) : label,
                content: isFunction(content) ? content(state) : get(state, content) ?? content,
                // fixme: below works only when values are objects. i.e. adding 'devices' sets the key to '0'
                constants: merge(...values(pick(constants, consts)))
              };
              return props.content ? <Element key={`subline-${index}`} {...props} /> : null;
            })
            }
          </View>
        )}
      </Page>
    </Document>
  )
};

export default ExportDocument;