import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import ReducersIndex from 'reduxModules/ducks';
import useDebounce from 'hooks/useDebounce';
import { getCurrentValue2 } from 'reduxModules/ducks/container';
import { isInError } from 'reduxModules/ducks/ui';
import { isObject, parseInt, clamp, get } from 'lodash';

const Entry = (props) => {
  let currentValue = useSelector(state => getCurrentValue2(state, props.dispatcher));
  const inError = useSelector(state => isInError(state, props));
  const { min = -Infinity, max = +Infinity } = props?.range || {};
  
  currentValue = isObject(currentValue) ? get(currentValue, props.name) : currentValue;
  const [value, setValue] = useState(currentValue || '');
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(currentValue || '')
  }, [currentValue]);

  const debounceDispatch = useDebounce(() => {
    if (props.dispatcher) 
    dispatch(ReducersIndex.container[props.dispatcher](props.name ? { ...{}, [props.name]: value } :
      props.isNumber ? clamp(parseInt(value), min, max) : value
    ));
  })

  const handleChange = (evt) => {
    const value = evt.target.value
    setValue(props.isNumber ? clamp(parseInt(value), min, max) : value);
    debounceDispatch();
  };

  return (
      <Input name={props.name} type={props.isNumber ? 'number' : 'text'} hasLabel onChange={handleChange} value={value} isNav={props.isNav} isEntry={props.isEntry ?? true} error={inError} />
  )
};

const SingleEntry = (props) => {
  return(
    <div className={`d-flex flex-wrap gap-1 ${props.className}`}>
    {props.label && <Label htmlFor={props.name} type={props.isNumber ? 'number' : 'text'}>{props.label}</Label>}
    <Entry {...props} />
  </div>
  )
};

const TextInput = (props) => <SingleEntry {...props} />;

const NumberInput = (props) => <SingleEntry {...props} isNumber={true} />;

const DoubleNumberInput = (props) => {
  const [first, second] = props?.ids ?? [];
  return (
    <div className={`d-flex flex-wrap gap-1 ${props.className}`}>
      {props.label && <Label className={`${props.isNav ? 'w-100' : ''}`} htmlFor={props.name} type={'text'} >{props.label}</Label>}
      <div className='hstack gap-2'>
        <Entry name={first} {...props} isNumber={true} isNav={false}/>
        {second && <span>X</span>}
        {second && <Entry name={second} {...props} isNumber={true} isNav={false}/>}
      </div>
    </div>
  )
};

export { TextInput, NumberInput, DoubleNumberInput };