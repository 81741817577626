import { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Label from "components/atoms/Label";
import Input from "components/atoms/Input";
import ReducersIndex from 'reduxModules/ducks';

import { getCurrentValue } from "reduxModules/ducks/container";

import isBoolean from "lodash/isBoolean";

const CheckBox = (props) => {
  const dispatch = useDispatch();
  const currentValue = useSelector(state => getCurrentValue(state, props.dispatcher));

  const [buttonActive, setButtonActive] = useState('');
  const items = props.items ?? ['yes', 'no'];

  useEffect(() => {
    const value = isBoolean(currentValue) ? currentValue ? 'yes' : 'no' : currentValue;
    value ? setButtonActive(value) : handleClick(null, items[0])
  }, [currentValue]);

  const handleClick = (evt, item) => {
    const value = item.replace('_', '');
    setButtonActive(value);
    if (props.dispatcher) dispatch(ReducersIndex.uiReducers[props.dispatcher](['yes', 'no'].includes(value) ? value === 'yes' : value ));
  };

  return (
    <Fragment>
      {items.map((item, index) => {
        const label = item.toString().replace('_', ' ');
        const checked = buttonActive === item.replace('_', '');
        return (
          <CheckBox.Wrapper key={`chk-${index}`} className={props.className}>
            <Input type='radio' id={item} name={props.name} value={item} />
            <Label className='text-capitalize hstack' htmlFor={item} onClick={(evt) => handleClick(evt, item)}>
              <CheckBox.Box checked={checked}>
                <CheckBox.Dot checked={checked}/>
              </CheckBox.Box>
              <span>{label}</span>
            </Label>
          </CheckBox.Wrapper>
        )
      })}


    </Fragment>
  )
};

CheckBox.Wrapper = styled.div.attrs(props => ({
  className: `vstack gap-1 ${props.className}`
}))`
`;

CheckBox.Box = styled.div.attrs(props => ({
  className: 'me-2'
}))`
  width: 22px;
  height: 22px;
  border: 1px solid var(--app-dark-gray);
  border-radius: 50%;
  padding-top: 2px
`;

CheckBox.Dot = styled.span.attrs(props => ({
  className: 'd-block m-auto'
}))`
  width: 16px;
  height: 16px;
  background-color: ${props => props.checked ? 'var(--app-red)' : '#fff'};
  border-radius: 50%;
`;

export default CheckBox