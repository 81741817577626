import toolIndex from "toolConstants";
import ReducersIndex from 'reduxModules/ducks';
import { getDeviceLayouts } from "reduxModules/ducks/room";
import { getArea, getAreaState } from "reduxModules/ducks/container";
import { isEmpty, isEqual } from "lodash";

// List of actions that should trigger a recalculation
const triggerActions = [
  'container/setDimensions',
  'container/setCeilingHeight',
  'container/setCeilingTiles',
  'container/setTable',
  'container/setAcoustics',
  'container/setMicrophones',
  'container/setSpeakers',
  'container/setCameras',
  // add speaker and camera changes that would trigger recalculation here note:
  'ui/clearModals' // This is a safety check for the default room 
];

// List of Actions that set te customized flag
const triggerCustomized = [
  'container/addDevice',
  'container/removeDevice',
  'ui/setDeviceIsMoving'
]

const calculateDevicesMiddleware = ({ dispatch, getState }) => next => action => {
  const state = getState();
  const constants = toolIndex[state.ui?.tool]?.constants;

  // Get flag to allow local caculation
  const localCalc = constants?.tool?.localCalc ?? false;


  // Set the customized flags when adding, removing or moving devices
  if (triggerCustomized.includes(action.type)) {
    let currentDevice = state.ui.currentDevice
    currentDevice = `${currentDevice}${currentDevice === 'camera' ? 's' : ''}`; 
    const customParameters = constants.customSettings[currentDevice];
    dispatch(ReducersIndex.container.setCustomized(customParameters));
  }








  // todo: this will be updted and or mocved to logic (reset room)
  if (triggerActions.includes(action.type) && localCalc) {

    // Get previous state to avoid excesive dispatches
    const areaDefined = !isEmpty(getArea(state));
    const previousLayout = areaDefined ? getDeviceLayouts(state) : {};

    // Let the next action go thru
    next(action);

    // resets
    // this section checks what is changing so the devices can reset (set qty=0) and be recalculated
    // This is still in the works !!
    const areaState = getAreaState(state)
    const { customized: { microphones: isMicCustom, speakers: isSpkrCustom, cameras: isCameraCustom }, microphones: { model } } = areaState



    // This resets microphones when any microphone setting comes
    // todo: this only triggers when the type changes 
    // This is temporary
    if (action.type === ReducersIndex.container.setMicrophones.type) {
      if (isMicCustom) {
        dispatch(ReducersIndex.container.setCustomized());
      }
    }

    // This resets speakers when any microphone setting comes
    // This is temporary
    if (action.type === ReducersIndex.container.setSpeakers.type) {
      if (isSpkrCustom) {
        dispatch(ReducersIndex.container.setCustomized());
      }
    }

    if (action.type === ReducersIndex.container.setCameras.type) {
      if (isCameraCustom) {
        dispatch(ReducersIndex.container.setCustomized())
      }
    }

    // this is not complete yet 
    switch (triggerActions.indexOf(action.type)) {
      // cases that reset always. These intentional fall through to 8
      case 0: // Room Dimensions 
      case 1: // Ceiling Height
      case 5: // microphones - style, height, coverage, value
        if ('qty' in action.payload || 'coverageDensity' in action.payload) break
      // falls through
      //case 8: // clearModals - safety. make certain than mics are calculated after existing modals
        // fixme here maybe use exit toroom instead 
                
        dispatch(ReducersIndex.container.setMicrophones({ qty: 0 }))
        break;

      // Cases that reset only if mics are not customized. These intentionally fall thru to 4
      case 2: // ceiling tile changes
      case 3: // Table changes
      case 4: // Acoustics
        
        if (!isMicCustom) dispatch(ReducersIndex.container.setMicrophones({ qty: 0 }))
        break;


      // Don't reset ever
      // case 5: // Speakers
      //   break;
      default:
      // Nothing happens
    };


    // Update mic coverage for ceiling mics only on table shape change
    // fixme: this should only apply to ceiling mics 
    if (action.type === 'container/setTable' && 'shape' in action.payload) {
      const newShape = Boolean(action.payload.shape);
      if (!isMicCustom) dispatch(ReducersIndex.container.setMicrophones({model: (model === 'tabletopmic' && !newShape ? 'ceilingmic' : model), coverage: { full_room: !newShape, focused: newShape } }))

      return // Stop propagation to prevent loops 
    };

    // Update spkr model and camera model on mic change when selecting bars 
    if (action.type === 'container/setMicrophones' && 'model' in action.payload) {

      // Get microphone type
      const { model: microphoneModel } = action.payload;
      const { microphones: { [microphoneModel.replace('mic', '_mic')]: { isBarMic = false } } } = constants.equipment;
      const changes = {};

      // Current speaker
      const speakerModel = state.container.areas[state.container.activeTab].speakers.model;
      const { speakers: { [speakerModel]: { isBarSpkr = false } } } = constants.equipment;
      const newSpeakerModel = (isBarMic && !isBarSpkr) ? 'barspkr' : (!isBarMic && isBarSpkr) ? 'ceilingspkr' : speakerModel;
      const deviceHeight = constants.equipment.speakers[newSpeakerModel].height(areaState);
      const deviceCoverage = constants.equipment.speakers[newSpeakerModel].coverage(areaState);
      if (newSpeakerModel !== speakerModel) {
        changes.speakers = { model: newSpeakerModel, height: deviceHeight, coverage: deviceCoverage, qty: 0, layout: [] };
      };

      // current camera 
      const cameraModel = state.container.areas[state.container.activeTab].cameras.model;
      const { cameras: { [cameraModel]: { isBarCamera } = { isBarCamera: false } } } = constants.equipment;
      const isVideoBar = microphoneModel === 'videobar';
      const newCameraModel = (isVideoBar && !isBarCamera) ? 'barCamera' : (!isVideoBar && isBarCamera) ? 'standAlone' : cameraModel;
      if (newCameraModel !== cameraModel) {
        changes.cameras = { model: newCameraModel, hasCamera: true, qty: 0, layout: [] };
      };

      // If any changes were made, dispatch and return to prevent potential loops
      if (!isEmpty(changes)) {
        dispatch(ReducersIndex.container.updateDevice(changes));
        return;
      };
    };



    // todo: Setting mic to videobar set camera to false
    // todo: audio/videobar change family to Parle


    // Using timeout allows the store to update properly before calculating 
    setTimeout(() => {
      const state = getState();
      const currentLayout = getDeviceLayouts(state)
      const layoutChanged = !isEqual(previousLayout, currentLayout) || action.type === 'ui/clearModals';
      if (layoutChanged) {
        dispatch(ReducersIndex.container.updateDevice(currentLayout))};
    }, 0);

  }

  next(action)



};

export default calculateDevicesMiddleware;