import styled, { keyframes } from 'styled-components';
import IconSprite from './IconSprite';
import { useDispatch, useSelector } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';
import { getErrorState } from 'reduxModules/ducks/ui';

const glow = keyframes`
    0% { opacity: 1}
    20% {opacity: 1}
  100% { opacity: 0.1 }
`;

const Attention = (props) => {
  const error = useSelector(getErrorState);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(ReducersIndex.uiReducers.openModal('warning'));
  };

  return (
    <Attention.Wrapper onClick={handleClick}>
      {error && <IconSprite icon={'attention'} size={'34px'} />}
    </Attention.Wrapper>
  );
};

Attention.Wrapper = styled.div.attrs(props => ({
  className: 'd-flex align-items-center',
}))`
  min-width: 34px;
  min-height: 34px;
  animation-name: ${glow};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in;
`;

export default Attention;