import { Fragment } from 'react';
import { Svg, G, Path } from '@react-pdf/renderer';
import Icons from 'globalConstants/Icons/Report';
import { keys, values } from 'lodash';

const FloorPlan = ({ content, constants }) => {
  const { inContainer, dimensions, loadingDocks, ghostDocks, areas: innerAreas, scale, bbox } = content;
  const areas = [...values(innerAreas).map(area => ({
    id: area.id,
    ...area.location,
    ...area.dimensions,
    walls: area.surroundingWalls,
    speakers: area.speakers,
    racks: area.rackArrays,
    ver: area.storageOrientation === 'horizontal'
  }))];

  // Generate the areas
  const rectangles = [...inContainer ? [dimensions] : [], ...areas];
  const lines = rectangles.flatMap(rect => {
    const { top: tw = true, right: rw = true, bottom: bw = true, left: lw = true } = rect.walls || {};
    return [...Array(2)].flatMap((x, index) => {
      const odd = index % 2;
      const sign = odd ? -1 : 1;
      const x0 = 1 + (inContainer * (rect.left || 0) + odd * rect.length) * scale;
      const y0 = 1 + (inContainer * (rect.top || 0) + odd * rect.width) * scale;
      const x1 = x0 + sign * rect.length * scale;
      const y1 = y0 + sign * rect.width * scale;
      return [{ d: `m${x0} ${y0}H${x1}`, dash: odd ? bw : tw }, { d: `m${x0} ${y0}V${y1}`, dash: odd ? rw : lw }]
    });
  });

  // Add the loading docks 
  const bay = constants.baySize * scale;
  // Horizontal calculation
  let qty = (bbox.width - 2) / bay;
  const hqty = (qty % 1 === 0 ? qty - 1 : parseInt(qty)) - (loadingDocks.left_docks + loadingDocks.right_docks);
  const hstart = (bbox.width - 2) / 2 - (hqty + loadingDocks.right_docks - loadingDocks.left_docks) * bay / 2;
  const horizontal = Array.from({ length: hqty }, (_, index) => hstart + index * bay);
  // Vertical calculation
  qty = (inContainer ? bbox.height - 4 : areas[0]?.width * scale) / bay;
  const vqty = (qty % 1 === 0 ? qty - 1 : parseInt(qty)) - (loadingDocks.top_docks + loadingDocks.bottom_docks); //(!sides.top_docks + !sides.bottom_docks); //
  const vstart = (inContainer ? bbox.height - 4 : areas[0]?.width * scale) / 2 - (vqty + loadingDocks.bottom_docks - loadingDocks.top_docks) * bay / 2;
  const vertical = Array.from({ length: vqty }, (_, index) => vstart + index * bay);

  // Merge docks
  const sides = inContainer ? loadingDocks : keys(loadingDocks).reduce((acc, key) => ({ ...acc, [key]: areas[0]?.id.includes(key) }), {});
  const docks = vertical.flatMap((pos, index) => {
    return [
      ...sides.left_docks && !ghostDocks.includes(`left-${index + 1}`) ? [{ x: 1, y: pos + 1, rotate: 90 }] : [],
      ...sides.right_docks && !ghostDocks.includes(`right-${index + 1}`) ? [{ x: (inContainer ? bbox.width - 2 : areas[0].length * scale), y: pos + 1, rotate: -90 }] : [] 
    ]
  }).concat(horizontal.flatMap((pos, index) => {
    return [
      ...sides.top_docks && !ghostDocks.includes(`top-${index + 1}`) ? [{ x: pos + 1, y: 1, rotate: 180 }] : [],
      ...sides.bottom_docks && !ghostDocks.includes(`bottom-${index + 1}`) ? [{ x: pos + 1, y: (inContainer ? bbox.height - 4 : areas[0].width * scale), rotate: 0 }] : [] 
    ]
  }));

  // Add speakers  
  // todo: this needs to be generalized for any/all devices
  const iconScale = 0.1304;
  const speakers = areas.flatMap(area => {
    const x0 = 1 + (inContainer ? area.left * scale : 0);
    const y0 = 1 + (inContainer ? area.top * scale : 0);
    return area.speakers.map(spkr => ({
      x: x0 + spkr.location.x * scale,
      y: y0 + spkr.location.y * scale,
      angle: spkr.horAngle - 90
    }))
  });

  // add the storage racks
  const { beam, depth } = constants.storage;
  const shortSide = depth * scale; // 48"
  const longSide = beam * scale // 96"

  const racks = areas.flatMap(area => {
    const x0 = 2 + (inContainer ? area.left * scale : 0);
    const y0 = 2 + (inContainer ? area.top * scale : 0);
    const xArray = area.racks?.xArray || [];
    const yArray = area.racks?.yArray || [];
    const ver = area.ver;
    return yArray.flatMap((y, i) =>
      xArray.flatMap((x, j) =>
        ({ d: `m${x0 + x * scale} ${y0 + y * scale} h${ver ? longSide : shortSide}v${ver ? shortSide : longSide}h${- (ver ? longSide : shortSide)}z` })
      )
    )
  })

  // note: drawing racks first so area lines are above 
  return (
    <Fragment>
      <Svg style={bbox} >
        {racks.map((rack, index) => <Path key={`rack-${index}`} {...rack} stroke="#a5aaad" />)}
        {lines.map((line, index) => <Path key={`line-${index}`} {...line} stroke="#000" strokeDasharray={line.dash ? 'none' : '12'} />)}
        {docks.map((dock, index) =>
          <G key={`dock-${index}`} transform={`translate(${dock.x} ${dock.y}) rotate(${dock.rotate}) translate(-${[-90, 180].includes(dock.rotate) * bay} 0) scale(${scale / 10})`} >
            <Icons.LoadingDock />
          </G>
        )}
        {speakers.map((speaker, index) =>
          <G key={`Icon-${index}`} transform={`translate(${speaker.x} ${speaker.y}) scale(${iconScale})`} >
            <Icons.Speaker />
            <Icons.SpeakerArrow angle={speaker.angle} />
          </G>
        )}
      </Svg>
    </Fragment>
  )
};

export default FloorPlan;