import styled from 'styled-components';
import icons from 'globalConstants/Icons';
import { iconThemes } from 'globalConstants/Themes';
import { useMemo } from 'react';

const SVGWrapper = styled.svg.attrs(props => ({
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 50 50",
  transform: props.transform
}))`
  height: ${props => props.size};
  min-width: ${props => props.size}
`;

const IconSprite = ({ state, icon, size, scale=1, usage }) => {
  const Icon = useMemo(() => icons[icon], [icon]);
  //const racks = usage === 'palletRacks'; // flag to add rack masks to icon
  return (
    <SVGWrapper className={icon} size={size} transform={`scale(${scale})`} ><Icon state={iconThemes[state]} center={false}/></SVGWrapper>
  )
}

export default IconSprite