import styled, { keyframes } from 'styled-components';

const speeds = {
  slow: 600,
  medium: 400,
  fast: 200,
};

const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

const fadeOut = keyframes`
from {opacity: 1;}
to {opacity: 0;}
`;


const Fade = ({
  duration = 'slow',
  delay = 0,
  children,
  show = true,
  callback = () => null,
  className = '',
}) => {  
  const speed = speeds[duration];  
  !show && setTimeout(callback, speed);
  return (
    <Wrapper className={className} show={show} speed={speed}
      style={{
        animationDuration: speed + 'ms',
        animationDelay: delay + 'ms',
      }}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${props => props.show? fadeIn : fadeOut};
    animation-fill-mode: backwards;
    visibility: ${props => props.show? 'visible' : 'hidden'};
    transition: visibility ${props => props.speed + 'ms'};
  }
`;

export default Fade;
