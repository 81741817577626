import styled from 'styled-components';

const Table = ({ content }) => {
  return (
    <Table.Scroll>
      <Table.Body>
        <colgroup>
          {content.colWidths.map((e, i) => <col key={`w-${i}`} style={{ width: `${e}%` }} />)}
        </colgroup>
        <tbody>
          <tr>
            {content.headers.map((title, index) => <th key={`title-${index}`} >{title}</th>)}
          </tr>
          {content.lines.map((item, index) => (
            <tr key={`spkr-${index}`}>
              {content.headers.map((header, index) => (
                <td key={`td-${index}`} className={header === 'description' ? 'text-start' : ''}>
                  {header === 'product' ? item[header].link !== '#' ?
                    <a href={item[header].link} target="_blank" rel="noopener noreferrer">{item[header].name}</a>
                    : item[header].name
                    : item[header]
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table.Body>
    </Table.Scroll>
  )
};

Table.Scroll = styled.div.attrs(props => ({
  className: 'overflow-auto'
}))`
max-height: 530px; 
`;

Table.Body = styled.table.attrs(props => ({
}))`
    table-layout: fixed;
    position: relative;
    width: 100%;
    font-size: 14px;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0;
    td, th {
      border-right: 1px solid var(--app-medium-gray);
      border-bottom: 1px solid var(--app-medium-gray);
      padding: 4px;
    }
    th {
      font-size: 16px;
      text-transform: uppercase;
      white-space: pre;
      background: #fff;
      position: sticky;
      top: 0
    }
    th:last-child, tr td:last-child {
      border-right: 0px
    }
    tr {
      :nth-of-type(even) {
        background-color: var(--app-light-gray)
      }
    }
    tr:last-child { 
      td {
      border-bottom: 0px
      }
    }
    a {
      color: var(--app-red);
      text-decoration: none
    }

  `;

export default Table;

