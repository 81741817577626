import { Fragment } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getConditionResult } from "reduxModules/ducks/container";
import { getDrawingBox, getIsMeter } from "reduxModules/ducks/ui";
import { distanceToString } from "utilities/format";


const LineDimension = (props) => {
  const {bbox, zoom} = useSelector(getDrawingBox);
  const isMeter = useSelector(getIsMeter);
  const activeTab = useSelector(state => state.container.activeTab)
  let { length, width } = useSelector(state => (((!zoom && state.container) || state.container.areas[activeTab]).dimensions)); 
  length = distanceToString(length, isMeter);
  width = distanceToString(width, isMeter);
  
  const area = props.children;
  const visible = useSelector(state => getConditionResult(state, {...props.show, area: area.id}));

  return (
    visible && <Fragment>
      {[length, width].map((dim, index) => {
        return (
          <LineDimension.Foreign key={`dim-${index}`} {...{ 'hor': index === 0, 'bbox': bbox }}>
            <LineDimension.DimWrapper>
              <LineDimension.DimInner>{dim}</LineDimension.DimInner>
            </LineDimension.DimWrapper>
          </LineDimension.Foreign>
        )
      })}
    </Fragment>
  )
};

LineDimension.Foreign = styled.foreignObject.attrs(props => ({
  x: props.hor ? props.bbox.cx : props.bbox.x1, //props.x,
  y: props.hor ? props.bbox.y1 : props.bbox.cy, //props.y,
  width: 140,
  height: 26,
  transform: `rotate(${props.hor ? 0 : -90}) translate(-70,-14) `
}))`
  transform-origin: ${props => `${props.x}px ${props.y}px`};
`;

LineDimension.DimWrapper = styled.div.attrs(props => ({
  className: 'd-flex align-items-center justify-content-center',
}))`

`;

LineDimension.DimInner = styled.span.attrs(props => ({
  className: 'd-inline-block align-item-middel px-2 '
}))`
  background: #fff;
  font-family: cursive;
  font-style: italic;
  user-select: none;
  color: var(--app-red)
`;

export default LineDimension;
