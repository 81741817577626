
import styled from "styled-components";
import { Fragment, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiReducers } from "reduxModules/ducks/ui";
import ToolContext from 'components/services/ToolContext';
import ElementsIndex from "components/elements";
import { getConditionResult } from "reduxModules/ducks/container";
import { getDrawingBox } from "reduxModules/ducks/ui";
import { every, find, isEmpty, isEqual, pick, values } from "lodash";

import { getAreas, getOuterBox } from "reduxModules/ducks/container";


const DrawManager = ({ container }) => {
  // Set the SVG BoundingBox depending on what's being drawn (container or area)

  const { bbox, zoom } = useSelector(getDrawingBox);
  const activeTab = useSelector(state => state.container.activeTab)

  // if not zoomed get the container else get the area 
  // const outer = useSelector(state => ((!zoom && state.container) || state.container.areas[activeTab]));
  const { length, width } = useSelector(getOuterBox) // outer.dimensions;



  const dispatch = useDispatch();
  // Set the drawing bounding box and scale
  useEffect(() => {
    const scale = Math.min(container.width / length, container.height / width) * .93
    const pixelWidth = length * scale;
    const pixelHeight = width * scale;
    const x0 = (container.width - pixelWidth) / 2;
    const y0 = (container.height - pixelHeight) / 2; //adjust here to add space for map legend!
    if (scale) {
      dispatch(uiReducers.setScale(scale));
      dispatch(uiReducers.setBoundingBox({
        width: pixelWidth,
        height: pixelHeight,
        x0: x0,
        y0: y0,
        x1: x0 + pixelWidth,
        y1: y0 + pixelHeight,
        cx: pixelWidth / 2 + x0,
        cy: pixelHeight / 2 + y0,
      }));
    };
  }, [length, width, container, dispatch])

  const toolContext = useContext(ToolContext);
    // fixme this is bad!!!
  const state = useSelector(state => state); 


  // checks to see if the SVG bounding box is defined before procedding

  const ready = !isEmpty(bbox) && every(values(bbox));


  const showContainer = useSelector(state => getConditionResult(state, { ...toolContext.containerCondition }));
  const showArea = useSelector(state => getConditionResult(state, { ...toolContext.areaCondition }));

  // fixme
  const areaArray = values(useSelector(getAreas));
  // console.log("areaArray: ", areaArray);
  // console.log("eq: ", isEqual(state.container.areas[activeTab], find(areaArray, v => v.id === activeTab)));


  if (ready) {
    // Reorder the areas to place active on top i.e. draw last 

    const index = areaArray.findIndex(x => x.id === activeTab);
    if (areaArray.length >= 1) areaArray.splice(areaArray.length - 1, 0, areaArray.splice(index, 1)[0]);

    // {showContainer && areaArray.map((area, index) => this was not working when using getarea array. In zoomed arae array retuirns one area so the otherws are ot drawn 


    return (
      <Fragment>

        <USC />
        {showContainer && !zoom && (toolContext.containerElements ?? []).map((element, index) => {
          const Element = ElementsIndex[element.element];
          return (
            <Element key={`${element.element}-${index}`} {...element} >{state.container}</Element>
          );
        })}

        {showContainer && (showArea ? [state.container.areas[activeTab]] : areaArray).map((area, index) => // this is used when zoomed so only the active area is visible
          <Group key={area.id} id={area.id}>
            {(toolContext.areaElements ?? []).map((element, index) => {
              const Element = ElementsIndex[element.element];
              return (
                <Element key={`${element.element}-${index}`} {...element} >{area}</Element>
              );
            })}
          </Group>
        )}

        {(toolContext.otherElements ?? []).map((element, index) => {
          const Element = ElementsIndex[element.element];
          return (
            <Element key={`${element.element}-${index}`} />
          );
        })}
      </Fragment>
    );
  }

  return null;
};

const Group = styled.g.attrs(props => ({
  id: props.id,
}))`
`;

const USC = () => (<g strokeWidth='1px' stroke={'var(--app-dark-gray)'} fontStyle='italic' fontFamily='auto' fontSize='medium' >
  <path d='m10 10a1 1 0 0 1 0 10a1 1 0 0 1 0-10zm40 5l-10-4q4 4 0 8zh-30m-10 40l4-10q-4 4-8 0zv-30' />
  <text x='43' y='30' >x</text>
  <text x='18' y='52'>y</text>
</g>)

export default DrawManager;