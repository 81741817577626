import styled from 'styled-components';
import { isInError } from 'reduxModules/ducks/ui';
import { useSelector } from 'react-redux';
import { capitalize, toNumber } from 'lodash';

const UnitInput = (props) => {
  const handleChange = (evt) => props.setValueString(evt.target.value);
  const handleBlur = (evt) => props.handleBlur(evt);

  const handleSpin = (evt) => {
    // console.log("spin: ", props.spinStep, evt.target.value , toNumber(evt.target.value));
    props.setValue(toNumber(evt.target.value))
  };


  const handleKeyDown = (evt) => props.handleKeyDown(evt);
  const inError = useSelector(state => isInError(state, props));
  return (
      <UnitInput.Wrapper isNav={props.isNav} error={inError}>
        <UnitInput.Text value={props.valueString} placeholder={props.placeholder ? props.name : undefined} onKeyDown={handleKeyDown} onChange={handleChange} onBlur={handleBlur}/>
        <UnitInput.Spin step={props.spinStep} value={props.value} onChange={handleSpin} />
      </UnitInput.Wrapper>
  )
};

UnitInput.Wrapper = styled.div.attrs(props => ({
}))`
  background-color: ${props => props.theme.inputBackground};
  max-width: ${props => props.isNav ? '100px' : '150px'};
  display: inline-block;
  border: ${props => props.error ? '1px solid var(--app-red)' : ''};
  border-radius: ${props => props.error ? '4px' : '0px'};
`

UnitInput.Text = styled.input.attrs(props => ({
  type: 'text',
  spellCheck: false,
  autoComplete: 'off',
  placeholder: capitalize(props.placeholder)
}))`
  float: left;
  width: 80%;
  border: none;
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackground};
  outline: none;
  min-width: 0px;
  ::placeholder {
    opacity: .5;
  }
`;

UnitInput.Spin = styled.input.attrs(props => ({
  type: 'number',
  autoComplete: 'off',
  step: props.step,
  tabIndex: -1
}))`
  width: 1em;
  border: none;
  border-radius: 4px;
  float: right;
  color: transparent;
  background-color: var(--app-light-gray);
  outline: none;
  min-width: 0px;
`;

export default UnitInput;