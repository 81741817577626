import styled from "styled-components";
import { useSelector } from "react-redux";
import { getConditionResult } from "reduxModules/ducks/container";
import { getDrawingBox } from "reduxModules/ducks/ui";

const RoofGrid = (props) => {
  const usingGrid = useSelector(state => state.container.roofGrid);
  const { x, y } = useSelector(state => state.container.roofGridSpacing);
  const { dx, dy } = useSelector(state => state.container.roofGridOffset);

  const { bbox, scale } = useSelector(getDrawingBox);;
  const visible = useSelector(state => getConditionResult(state, {...props.show}));
  const active = useSelector(state => getConditionResult(state, {...props.active}));

  const adjX = (dx ? dx : x) * scale;
  const adjY = (dy ? dy : y) * scale;

  const dot = 1;
  const x0 = bbox.x0 + adjX - dot;
  const y0 = bbox.y0 + adjY - dot;
  const xStep = x * scale;
  const yStep = y * scale;
  const width = bbox.width - adjX;
  const height = bbox.height - adjY;

  return (
    usingGrid && visible && <RoofGrid.Group active={active}>
      <pattern id="roof-grid-pattern" x={x0} y={y0} width={xStep} height={yStep} patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse">
        <circle cx={dot} cy={dot} r={dot} />
      </pattern>
      <rect x={x0} y={y0} width={width} height={height} fill="url(#roof-grid-pattern)"></rect>
    </RoofGrid.Group>
  )

};

RoofGrid.Group = styled.g.attrs(props => ({
  id: 'ceiling'
}))`
  fill: ${props => props.active ? 'var(--app-red)' : 'var(--app-custom-gray)'};
  shape-rendering: geometricprecision;
  opacity: ${props => props.active ? 1 : .5}
`;

export default RoofGrid;