const Coverage = {
  rackTemplate: (id, state) =>
    <g>
      <pattern id={id} width='.5' height='.3333' patternContentUnits='userSpaceOnUse'>
        <path d='m5 5 h5v10h-5z' stroke='none' fill={state.border_fill} fillOpacity='0.5' />
      </pattern>
      <rect fill={`url(#${id})`} width='20px' height='45px' />
      <rect fill={`url(#${id})`} x='25px' width='20px' height='45px' />
    </g>,

  general: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      {/* {racks && Coverage.rackTemplate('general', state)} */}
      <path d='m1 5l4-4m5 0l-9 9m0 5l14-14m5 0l-19 19m0 5l24-24m5 0l-29 29m0 5l34-34m5 0l-39 39m0 5l44-44m4 1l-47 47 
        m5 0l42-42m0 5l-37 37m5 0l32-32m0 5l-27 27m5 0l22-22m0 5l-17 17m5 0l12-12m0 5l-7 7m5 0l2-2' stroke={state.border_fill} />
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke} />
    </g>,

  picking_aisles: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      {Coverage.rackTemplate('pick', state)}
      <path d='m10 10l5-5m0 5l-5 5 m0 10l5-5m0 5l-5 5 m0 10l5-5m0 5l-5 5
        m25 -35l5-5m0 5l-5 5 m0 10l5-5m0 5l-5 5 m0 10l5-5m0 5l-5 5'
        fill='none' stroke={state.border_fill} />
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke} fill='none' />
    </g>,

  primary_aisles: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      {Coverage.rackTemplate('primary', state)}
      <path d='m5 20l5-5m5 0l-5 5m5 0l5-5m5 0l-5 5m5 0l5-5m5 0l-5 5m5 0l5-5m5 0l-5 5
        m-35 15l5-5m5 0l-5 5m5 0l5-5m5 0l-5 5m5 0l5-5m5 0l-5 5m5 0l5-5m5 0l-5 5'
        fill='none' stroke={state.border_fill} />
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke} fill='none' />
    </g>,

  connector_aisles: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      {Coverage.rackTemplate('connector', state)}
      <path d='m20 10l5-5m5 0l-10 10m0 5l10-10m0 5l-10 10m0 5l10-10m0 5l-10 10
        m0 5l10-10m0 5l-10 10m5 0l5-5'
        fill='none' stroke={state.border_fill} />
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke} fill='none' />
    </g>,

  none: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      {/* {racks && Coverage.rackTemplate('none', state)} */}
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke} fill='none' />
    </g>,

  full_room: (props) => Coverage.general({ ...props }),

  focused: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      <path d="M5 5l40 40m0-40l-40 40m0-5l15-15l-15-15m40 0l-15 15l15 15m0-5l-10-10l10-10
        m-40 0l10 10l-10 10m0-5l5-5l-5-5m40 0l-5 5l5 5m-5 15l-15-15l-15 15m0-40l15 15l15-15
        m-5 0l-10 10l-10-10m0 40l10-10l10 10m-5 0l-5-5l-5 5m0-40l5 5l5-5" stroke={state.border_fill} />
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke} />
    </g>,

  teacher: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      <path d="M5 5l7.5 7.5l7.5 -7.5m-5 0l-2.5 2.5l-2.5 -2.5m10 5l-7.5 7.5l-7.5 -7.5m0 5l7.5 7.5l7.5 -7.5
        m0 5l-5 5l5 5m-15 0l5 -5l-5 -5m0 15l7.5 -7.5l7.5 7.5m0 5l-7.5 -7.5l-7.5 7.5m0 5l7.5 -7.5l7.5 7.5
        m-5 0l-2.5 -2.5l-2.5 2.5" stroke={state.border_fill}/>
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke}/>
    </g>,

  students: ({ state }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0'>
      <path d="M30 5l2.5 2.5l2.5 -2.5m5 0l-7.5 7.5l-7.5 -7.5m-5 0l12.5 12.5l12.5 -12.5
      m0 5l-12.5 12.5l-12.5 -12.5m0 5l10 10l-10 10m0 -5l5 -5l-5 -5m25 0l-5 5l5 5
      m0 5l-10 -10l10 -10m0 25l-12.5 -12.5l-12.5 12.5m0 5l12.5 -12.5l12.5 12.5
      m-5 0l-7.5 -7.5l-7.5 7.5m5 0l2.5 -2.5l2.5 2.5" stroke={state.border_fill}/>
      <path d="M0 0h50v50H0z" strokeWidth="2" stroke={state.border_stroke}/>
    </g>
};

export default Coverage;