import Button from 'components/molecules/Button';
import { buttonThemes } from 'globalConstants/Themes';

const navClick = (e, link) => {
  e.preventDefault();
  window.location.href = link;
};

const ToolNav = ({ links }) => 
  <div className='vstack gap-3 mt-5 col-md-3 mx-auto'>
    {links.map((link, index) => <Button key={`b-${index}`} className={'h-auto py-2 rounded-1'} theme={buttonThemes['nav_red']} label={link.label} onClick={(e) => navClick(e, link.path)} /> )}
  </div>
;

export default ToolNav;

