import styled from "styled-components";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';
import Label from "components/atoms/Label";
import { getCurrentValue2 } from "reduxModules/ducks/container";
import { isArray } from "lodash";

const Fader = (props) => {
  const {value: currentValue, [props.range] : range} = useSelector(state => getCurrentValue2(state, props.dispatcher, props.name));
  const discrete = isArray(range);
  const faderProps = discrete ? { min: 0, max: range.length - 1, step: 1 } : range;

  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(discrete ? range.indexOf(currentValue) : currentValue);
  }, [discrete, currentValue, range]);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    if (props.dispatcher) {
      const newValue = {[props.name] :{value: discrete ? range[value] : value}}; 
      dispatch(ReducersIndex.container[props.dispatcher](newValue));
    };
  };

  return (
    <div className={`d-flex flex-row gap-1 ${props.className}`}>
      {props.label && <Label htmlFor={props.name} type={'text'}>{props.label}</Label>}
      <Fader.Outer >
        <Fader.Inner {...faderProps} width={`${discrete ? Math.min(range.length * 10, 90) : '90'}%`} value={value} onChange={handleChange} />
      </Fader.Outer>
    </div>
  )
};

export default Fader;

Fader.Outer = styled.div.attrs(props => ({
  className: 'flex-grow-1 flex-fill'
}))`
  height: '28px'
`;

Fader.Inner = styled.input.attrs(props => ({
  className: 'form-range',
  type: 'range',
}))`
  width: ${props => props.width ?? '90%'};
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
  }
  &::-webkit-slider-thumb {
    background: var(--app-red);
  }
  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background: color-mix(in srgb, var(--app-red) 50%, white);
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px color-mix(in srgb, var(--app-red) 50%, white);
    }
  }
`

