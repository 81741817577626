import { containerReducers } from "reduxModules/ducks/container";
import { uiReducers } from "reduxModules/ducks/ui";
import { getTotalStudents } from "./classroom";

const ReducersIndex = {
  container: containerReducers,
  uiReducers: uiReducers,
};

export const GettersIndex = {
  getTotalStudents
}

export default ReducersIndex;