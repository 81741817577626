const Family = { 
  // fixme
  family1: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='1' strokeMiterlimit='0'>
      <text x='0' y='30'>Family 1</text>
    </g>,

  family2: ({ state }) =>
    <g stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='1' strokeMiterlimit='0'>
      <text x='0' y='30'>Family 2</text>
    </g>,
};

export default Family;