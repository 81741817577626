import { Fragment } from 'react';
import { View } from '@react-pdf/renderer';
import FloorPlan from 'components/sections/FloorPlan';
import Canvas from 'components/sections/Canvas';
import Scale from 'components/sections/Scale';
import { values } from 'lodash';

const Plot = ({ content, constants }) => {
  const { dimensions, areas: innerAreas } = content;
  const areas = values(innerAreas);
  const inContainer = areas.length > 1;
  const scale = 532 / Math.max(dimensions.length, dimensions.width);
  const bbox = { width: 534, height: ((inContainer ? dimensions.width : areas[0].dimensions.width) * scale) + 4 };

  return (
    <Fragment>
      <View style={bbox} >
        <Canvas content={{ ...content, inContainer: inContainer, scale: scale }} />
        <FloorPlan content={{ ...content, inContainer: inContainer, scale: scale, bbox: bbox }} constants={constants} />
      </View>
      <Scale />
    </Fragment>
  )
};

export default Plot;

