  const Noise = { 
  noise_low: ({ state }) =>
    <path d="m5 25h5l2.5 -2.625 l5 5.25 l5 -10.5 l5 15 l5 -12.375 l5 9.375 l2.5 -4.125 h5" fill={state.icon_stroke} stroke={state.icon_stroke} />,

  noise_medium: ({ state }) =>
    <path d="m5 25h5l2.5 -3.5 l5 7 l5 -14 l5 20 l5 -16.5 l5 12.5 l2.5 -5.5 h5" fill={state.icon_stroke} stroke={state.icon_stroke} />,

  noise_high: ({ state }) =>
    <path d="m5 25h5l2.5 -5.25 l5 10.5 l5 -21 l5 30 l5 -24.75 l5 18.75 l2.5 -8.25 h5" fill={state.icon_stroke} stroke={state.icon_stroke} />,

  noise_extreme: ({ state }) =>
    <path d="m5 25h5l2.5 -7 l5 14 l5 -28 l5 40 l5 -33 l5 25 l2.5 -11 h5" fill={state.icon_stroke} stroke={state.icon_stroke} />,
  };

  export default Noise;