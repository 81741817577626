import ReducersIndex from "reduxModules/ducks";
import { isInside } from "utilities/geometric";


//const items = ['measure', 'block', 'bulkedit', 'move', 'add', 'remove', 'ghostchair', 'listen', 'ghostdock', 'undo']
// todo: undo
const actions = {
  floorplan: {
    area: [],
  },
  ceiling: {
    area: ['block']
  },
  furnishing: {
    area: ['bulkedit', 'add', 'remove', 'ghostchair']
  },
  acoustics: {
    area: ['position', 'level', 'distance', 'move', 'add', 'remove', 'listen']
  },
  microphones: {
    area: ['position', 'distance', 'move', 'add', 'remove', 'listen']
  },
  speakers: {
    area: ['position', 'level', 'move', 'add', 'remove']
  },
  equipmentlist: {}
};

export const conditions = {
  block: {parameter: ['ceilingTiles'], check: ([tiles]) => tiles.tile !== 'no_ceiling_tiles'},
  // todo: this only applies to rect, arch, round and oval
  // ghostchair: {parameter: ['table'], check: ([table]) => table.shape},
  level: {parameter: ['currentDevice', ], check: ([device]) => device === 'speakers'},
  distance:{parameter: ['currentDevice', ], check: ([device]) => device !== 'speakers'},
  add: {parameter: ['currentDevice', ], check: ([device]) => device !== 'cameras'},
  remove:{parameter: ['currentDevice', ], check: ([device]) => device !== 'cameras'},
  listen:{parameter: ['currentDevice', ], check: ([device]) => device === 'microphones'},
};

export const actionDispatchers = {
  // ghostdock: (_, dispatch, mouse) => {
  //   const target = document.getElementById('loadingdocks');
  //   const dock = Array.from(target.children).find(child => isInside(mouse, child.getBoundingClientRect()));
  //   if (dock) dispatch(ReducersIndex.container.setGhostDocks(dock.id));
  // },
  // remove: (evt, dispatch) => {
  //   const target = evt.target.closest('[class=speakers]')?.id;
  //   if (target) {
  //     const [area, _, speakerId] = target.split('-');
  //     dispatch(ReducersIndex.container.removeSpeaker({area: area, id: speakerId}));
  //   };
  // },
};

export default actions;