import styled from "styled-components";
import { useSelector } from "react-redux";
import { getConditionResult } from "reduxModules/ducks/container";
import { getBoundingBox } from "reduxModules/ducks/ui";


const Roof = (props) => {
  const bbox = useSelector(getBoundingBox);
  const roofType = useSelector(state => state.container.roofType);
  const slopeOrientation = useSelector(state => state.container.roofSlopeOrientation);
  const hor = /top|bottom|vertical/i.test(slopeOrientation);
  const start = +/right|top|vertical|horizontal/i.test(slopeOrientation);
  const end = +/left|bottom|vertical|horizontal/i.test(slopeOrientation);
  const slope = Array.from({ length: 3 }, (_, i) => (hor ? bbox.cx : bbox.cy) + (i - 1) * (hor ? bbox.width : bbox.height) * .4);

  const visible = useSelector(state => getConditionResult(state, {...props.show}));
  const active = useSelector(state => getConditionResult(state, {...props.active}));

  if (bbox.width * bbox.height > 0) {
    const props = {
      hor: hor,
      start: start,
      end: end,
      bBox: bbox
    };

    return (
      visible && <Roof.Group active={active}>
        {roofType === 'aframe' && <Roof.Path {...props} />}
        {roofType !== 'flat' && slope.map((pos, i) => <Roof.Arrow key={`arr${i}`} pos={pos} {...props} />)}
      </Roof.Group>
    )
  }
};

Roof.Group = styled.g.attrs(props => ({
  id: 'roof'
}))`
  fill: none;
  stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-dark-gray)'};
  stroke-width: 2;
`;

Roof.Path = styled.path.attrs(props => ({
  d: `m${props.hor ? props.bBox.x0 : props.bBox.cx} ${props.hor ? props.bBox.cy : props.bBox.y0}h${props.hor ? props.bBox.width : 0}v${props.hor ? 0 : props.bBox.height}`
}))`
`;

Roof.Arrow = styled.path.attrs(props => ({
  d: `${props.start ? 'm0 0 v-40 l5 15 c-5 -5 -5 -5 -10 0 l5 -15z' : ''} ${props.end ? 'm0 20 v40 l5 -15 c-5 5 -5 5 -10 0 l5 15z' : ''}`,
  transform: `
    translate(
      ${props.hor ? props.pos : props.bBox.x1 - props.bBox.width * (props.start + !props.end) * .5 + 10} 
      ${props.hor ? props.bBox.y1 - props.bBox.height * (!props.start + props.end) * .5 - 10 : props.pos}
    ) 
    rotate(${props.hor ? 0 : 90})`
}))`
  fill: var(--app-red);
  shape-rendering: geometricPrecision
`;

export default Roof
