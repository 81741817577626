// current below is useful for logging data from the store
// import { current } from "@reduxjs/toolkit";
import { clamp, min, max, values } from "lodash";

/**
 * Constants for the Conference Room Designer tool.
 * @constant
 * @type {Object}
 */
const constants = {
  tool: {
    ver: 'MDT-V2.0',
    fullName: 'Conference Room Designer',
    /**
     * The two parameters below are used in the current live version to redirect the tool to the current version in Cornerstone
     * If deploying to live, make certain these are uncommented!
     */
    name: 'conferenceroom',
    goto: 'https://support.biamp.com/Design_Tools/Conference_Room_Designer',
    path: {
      short: '/crd',
      long: 'conference_room_designer'
    },
    cookie: 'CRDSeenTutorial',
    container: false,
    areaName: 'Room',
    maximumAreas: 8,
    addAreaLabel: 'A ROOM',
    landingMenu: 'microphones',

    /**
     * localCalc is a flag to trigger (or not) local device calculations
     * This is required as WD relies on the API response for device arrays
     */
    localCalc: true, // flag to allow local calculation of devices 

    /**
     * Maximum devices specifies what's the limit of devices that can be addded to a design
     * Set here as different devices might have different limits
     */
    maximumDevices: {
      microphones: 64,
      speakers: 64,
      cameras: 1
    },

  },

  /**
   * Area-specific constants.
   * @type {Object}
   */
  area: {
    min: 1.8288, // minimum area dimension 6'
    max: 30.48, // maximum area DImension 100'
    // fixme: talkerHeight is a also used in CD so it should be moved to a global context or imported from here 
    talkerHeight: 1.2009,
    table2wall: 0.7,
  },

  /**
   * Ceiling-specific constants.
   * @type {Object}
   */
  ceiling: {
    min: 2.4384,
    max: 4.572
  },

  // fixme: ceilingTiles is a also used in CD so it should be moved to a global context or imported from here 
  /**
   * Ceiling tile dimensions in meters.
   * @type {Object.<string, {x: number, y: number}>}
   */
  ceilingTiles: {
    "no_ceiling_tiles": { x: 0, y: 0 },
    "2'_x_2'": { x: 0.6096, y: 0.6096 },
    "2'_x_4'": { x: 0.6096, y: 1.2192 },
    "4'_x_2'": { x: 1.2192, y: 0.6096 },
    "600mm_x_600mm": { x: 0.6, y: 0.6 }
  },

  // fixme: acoustics is a also used in CD so it should be moved to a global context or imported from here 
  /**
   * Acoustics quality levels.
   * @type {string[]}
   */
  acoustics: ['poor', 'fair', 'good', 'great', 'perfect'],

  /**
   * Table configurations for different shapes.
   * @type {Object.<string, Object>}
   */
  tables: {
    rectangle: {
      /**
       * Minimum dimensions for a rectangle table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 0.9144, width: 0.9144 },
      /**
       * Calculates default dimensions for a rectangle table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: length - 3.048, width: clamp(width - 3.048, 0.9144, 1.2192) }),
      endTable: false,
    },
    racetrack: {
      /**
       * Minimum dimensions for a racetrack table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 0.9144, width: 0.9144 },
      /**
       * Calculates default dimensions for a racetrack table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: length - 3.048, width: clamp(width - 3.048, 0.9144, 1.2192) }),
      endTable: false,
    },
    boat: {
      /**
       * Minimum dimensions for a boat table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 0.9144, width: 0.9144 },
      /**
       * Calculates default dimensions for a boat table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: length - 3.048, width: clamp(width - 3.048, 0.9144, (length - 3.048) * .13 + 1.143) }),
      endTable: false,
    },
    round: {
      /**
       * Minimum dimensions for a round table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 0.6096, width: 0.6096 },
      /**
       * Calculates default dimensions for a round table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: min([length, width]) - 3.048, width: min([length, width]) - 3.048 }),
      endTable: false,
    },
    bullet: {
      /**
       * Minimum dimensions for a bullet table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 0.9144, width: 0.9144 },
      /**
       * Calculates default dimensions for a bullet table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: length - 1.524, width: clamp(width - 3.048, 0.9144, 1.8288) }),
      endTable: true,
    },
    keystone: {
      /**
       * Minimum dimensions for a keystone table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 1.2192, width: 0.9144 },
      /**
       * Calculates default dimensions for a keystone table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: length - 1.524, width: max([width - 2.4384, 0.9144]) }),
      endTable: true,
    },
    ushape: {
      /**
       * Minimum dimensions for a ushape table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 0.9144, width: 0.9144 },
      /**
       * Calculates default dimensions for a ushape table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: length - 3.048, width: max([width - 3.048, 0.9144]) }),
      endTable: true,
    },
    vshape: {
      /**
       * Minimum dimensions for a vshape table.
       * @type {Object}
       * @property {number} length - Minimum length in meters.
       * @property {number} width - Minimum width in meters.
       */
      minimum: { length: 1.2192, width: 0.9144 },
      /**
       * Calculates default dimensions for a vshape table based on room dimensions.
       * @function
       * @param {Object} dimensions - The room dimensions.
       * @param {number} dimensions.length - The room length in meters.
       * @param {number} dimensions.width - The room width in meters.
       * @returns {Object} The default table dimensions.
       * @property {number} length - The calculated default length.
       * @property {number} width - The calculated default width.
       */
      default: ({ length, width }) => ({ length: length - 1.524, width: max([width - 2.4384, 0.9144]) }),
      endTable: true,
    },
  },

  /**
   * @constant
   * @name defaultPlots
   * @type {Object}
   * @description Defines the default plot types for different device categories in the Conference Room Designer.
   * 
   * @property {string} microphones - The default plot type for microphones, set to 'micDistance'.
   * @property {string} speakers - The default plot type for speakers, set to 'relativeAverage'.
   * @property {null} camera - The default plot type for cameras, set to null (indicating no default plot).
   */
  defaultPlots: {
    microphones: 'micDistance',
    speakers: 'relativeAverage',
    camera: 'cameraCoverage'
  },

  iconMultiplier: 0.00536,
  devices: ['microphones', 'speakers', 'camera'],
  hideDevices: ['floorplan', 'ceiling', 'furnishing', 'equipmentList'],

  /**
   * @name customSettings
   * @type {Object}
   * @description Specifies the values to be set in the Redux store when certain properties are customized.
   * These settings determine how the UI and store should respond when users make custom selections.
   * 
   * @property {Object} microphones - Custom settings for microphones
   * @property {Object} microphones['microphones.coverage'] - Coverage options for microphones
   * 
   * @property {Object} speakers - Custom settings for speakers
   * @property {Object} speakers['speakers.coverage'] - Coverage options for speakers
   */
  customSettings: {
    microphones: {
      'microphones.coverage': {
        full_room: false,
        focused: false,
      }
    },
    speakers: {
      'speakers.coverage': {
        full_room: false,
        focused: false,
      }
    },
    cameras: {
      'cameras.': true
    }
  },


  /**
   * Default overlap configuration.
   * Used in calculateMicPlacement helper function to calculate initial microphone quantity
   * @type {Object}
   * @returns {Object} An object with full_room and focused default values.
   */
  defOverlap: { // This values are used to determine the initial miccount 
    full_room: 1.6,
    focused: 1.3
  },

  equipment: {
    /**
     * Microphone configurations for different types.
     * @type {Object.<string, Object>}
     */
    microphones: {
      /**
       * Ceiling microphone configuration.
       * @type {Object}
       */
      ceiling_mic: {
        modelName: 'TCM-X',
        isCeilingMic: true,
        /**
         * Calculates the default height for a ceiling microphone.
         * Used in the setMicrophones reducer to set the initial microphone height.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {number} The calculated height, which is the minimum ceiling height.
         */
        height: (area) => area.ceilingHeight.min,
        /**
         * Determines the valid height range for a ceiling microphone.
         * Used in the setMicrophones reducer to clamp the microphone height.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {number[]} An array with two identical values representing the min and max height.
         */
        heightRange: (area) => Array(2).fill(area.ceilingHeight.min),
        /**
         * Determines the coverage settings for a ceiling microphone.
         * Used in the setMicrophones reducer to set the initial coverage or preserve existing coverage.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {Object} An object with focused and full_room boolean properties.
         */
        coverage: (area) => {
          const ck = values(area.microphones.coverage).some(x => x);
          const hasTable = Boolean(area.table.shape);
          return ck ? area.microphones.coverage : {
            focused: hasTable,
            full_room: !hasTable
          }
        },
        /**
         * Microphone data for different acoustic conditions.
         * @type {number[]}
         */
        micData: [1.8, 2.3, 3.0, 3.75, 5.0],
      },
      /**
       * Video bar microphone configuration.
       * @type {Object}
       */
      videobar: {
        modelName: 'VBC 2500',
        isBarMic: true,
        /**
         * Returns a constant height for a video bar microphone.
         * @function
         * @returns {number} The fixed height of the talker.
         */
        height: () => constants.area.talkerHeight,
        /**
         * Returns a fixed height range for a video bar microphone.
         * @function
         * @returns {number[]} An array with two identical values representing the fixed height.
         */
        heightRange: () => Array(2).fill(constants.area.talkerHeight),
        /**
         * Returns a fixed coverage setting for a video bar microphone.
         * @function
         * @returns {Object} An object with focused and full_room set to false.
         */
        coverage: () => ({
          focused: false,
          full_room: false
        }),
        /**
 * Microphone data for different acoustic conditions.
 * @type {number[]}
 */
        micData: [3.5, 4.3, 5.6, 7.0, 9.8],
      },
      /**
       * Audio bar microphone configuration.
       * @type {Object}
       */
      audiobar: {
        modelName: 'ABC 2500',
        isBarMic: true,
        /**
         * Returns a constant height for a audio bar microphone.
         * @function
         * @returns {number} The fixed height of the talker.
         */
        height: () => constants.area.talkerHeight,
        /**
         * Returns a fixed height range for a audio bar microphone.
         * @function
         * @returns {number[]} An array with two identical values representing the fixed height.
         */
        heightRange: () => Array(2).fill(constants.area.talkerHeight),
        /**
         * Returns a fixed coverage setting for a audio bar microphone.
         * @function
         * @returns {Object} An object with focused and full_room set to false.
         */
        coverage: () => ({
          focused: false,
          full_room: false
        }),
        /**
         * Microphone data for different acoustic conditions.
         * @type {number[]}
         */
        micData: [3.5, 4.3, 5.6, 7.0, 9.8],
      },
      /**
       * Pendant microphone microphone configuration.
       * @type {Object}
       */
      pendant_mic: {
        modelName: 'TCM-1',
        isCeilingMic: true,
        isPendantMic: true,
        /**
         * Calculates the default height for a pendant microphone.
         * Used in the setMicrophones reducer to set the initial microphone height.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {number} The calculated height, which is the minimum ceiling height.
         */
        height: (area) => Math.min(2.4384, area.ceilingHeight.min), // this needs to change 
        /**
         * Determines the valid height range for a pendant microphone.
         * Used in the setMicrophones reducer to clamp the microphone height.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {number[]} An array with two identical values representing the min and max height.
         */
        heightRange: (area) => [1.828, area.microphones.maxHeight],
        /**
         * Determines the coverage settings for a pendant microphone.
         * Used in the setMicrophones reducer to set the initial coverage or preserve existing coverage.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {Object} An object with focused and full_room boolean properties.
         */
        coverage: (area) => {
          const ck = values(area.microphones.coverage).some(x => x);
          const hasTable = Boolean(area.table.shape);
          return ck ? area.microphones.coverage : {
            focused: hasTable,
            full_room: !hasTable
          }
        },
        /**
         * Microphone data for different acoustic conditions.
         * @type {number[]}
         */
        micData: [1.5, 2.0, 2.4, 3.0, 4.0],
      },
      /**
       * Tabletop microphone configuration.
       * @type {Object}
       */
      tabletop_mic: {
        modelName: 'TTM',
        isTableMic: true,
        /**
         * Returns a constant height for a tabletop microphone.
         * @function
         * @returns {number} The fixed height of the talker.
         */
        height: () => constants.area.talkerHeight,
        /**
         * Returns an empty array for a tabletop microphone height range.
         * Used in the setMicrophones reducer to clamp the microphone height.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {Object} Always returns an empty array.
         */
        heightRange: () => [],
        /**
         * Returns a fixed coverage setting for a tabletop microphone.
         * @function
         * @returns {Object} An object with focused and full_room set to false.
         */
        coverage: () => ({
          focused: false,
          full_room: false
        }),
        /**
         * Microphone data for different acoustic conditions.
         * @type {number[]}
         */
        micData: [1.0, 1.3, 1.7, 2.1, 2.5],
      }
    },
    /**
     * Speaker configurations for different types.
     * @type {Object.<string, Object>}
     */
    speakers: {
      /**
       * Ceiling speaker configuration.
       * @type {Object}
       */
      ceilingspkr: {
        modelName: 'C-IC6',
        isCeilingSpkr: true,
        /**
         * Returns the icon identifier for a ceiling speaker.
         * @function
         * @returns {string} The icon identifier 'speaker'.
         */
        icon: () => 'speaker',
        /**
         * Calculates the height for a ceiling speaker.
         * Used in the setSpeakers reducer to set the speaker height.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {number} The calculated height, which is the minimum ceiling height.
         */
        height: (area) => area.ceilingHeight.min,
        /**
         * Determines the coverage settings for a ceiling speaker.
         * Used in the setSpeakers reducer to set the coverage.
         * @function
         * @returns {Object} An object with focused set to true and full_room set to false.
         */
        coverage: () => ({
          focused: true,
          full_room: false
        }),
        /**
         * The coverage angle of the ceiling speaker.
         * @type {number}
         */
        coverageAngle: 39,
        /**
         * The sensitivity of the ceiling speaker.
         * @type {number}
         */
        sensitivity: 86.6,
        /**
         * The polar data @100Hz for the C-IC6 speaker 
         */
        polarData: { 0: -0.9, 5: -0.4, 10: -0.9, 15: -1.5, 20: -1.35, 25: -1.3, 30: -1.3, 35: -1.95, 40: -1.85, 45: -2.1, 50: -2.75, 55: -2.25, 60: -3.25, 65: -3.6, 70: -2.85, 75: -2.85, 80: -4.5, 85: -7.2, 90: -9.3 }
      },
      /**
       * Bar speaker configuration.
       * @type {Object}
       */
      barspkr: {
        modelName: null,
        isBarSpkr: true,
        /**
         * Returns the icon identifier for a bar speaker based on the microphone model.
         * @function
         * @param {Object} area - The area object from the state.
         * @returns {string} The icon identifier based on the microphone model.
         */
        icon: (area) => area.microphones.model.replace('mic', '_mic'),
        /**
         * Returns the height for a bar speaker.
         * @function
         * @returns {number} The fixed height of the talker.
         */
        height: () => constants.area.talkerHeight,
        /**
         * Determines the coverage settings for a bar speaker.
         * @function
         * @returns {Object} An object with focused and full_room set to false.
         */
        coverage: () => ({
          focused: false,
          full_room: false
        }),
        /**
         * The coverage angle of the bar speaker.
         * @type {number}
         */
        coverageAngle: 90,
        /**
         * The sensitivity of the bar speaker.
         * @type {number}
         */
        sensitivity: 84,
        /**
         * The polar data @100Hz for the C-IC6 speaker 
         */
        polarData: { 0: 0, 5: -0.05, 10: -0.14, 15: -0.26, 20: -0.47, 25: -0.72, 30: -1.00, 35: -1.34, 40: -1.70, 45: -2.14, 50: -2.65, 55: -3.17, 60: -3.82, 65: -4.46, 70: -5.22, 75: -5.99, 80: -6.78, 85: -7.45, 90: -7.87 },
        /**
         * The target spl levels for different accooustic settings
         */
        targetSPL: [73, 73, 73, 71.5, 68]
      }
    },
    /**
     * NOT FULLY IMPLEMENTED YET
     * Camera configurations.
     * @type {Object.<string, Object>}
     */
    cameras: {
      /**
       * standAlone camera configuration.
       * @description: This is for the vidi_250 but eventually the vidi_280 as well
       * @type {Object}
       */
      standAlone: {
        modelName: 'Vidi 250',
        isBarCamera: false,
        /**
         * Returns the icon identifier for a ceiling speaker.
         * @function
         * @returns {string} The icon identifier 'speaker'.
         */
        icon: () => 'camera',
        /**
         * The coverage angle of the VIDI 250 camera.
         * @type {number}
         */
        coverageAngle: 56.5,
        /**
         * The coverage radius of the VIDI 250 camera.
         * @type {number}
         */
        coverageRadius: 5,
        /**
         * The limit radius of the VIDI 250 camera.
         * @type {number}
         */
        limitRadius: 9
      },

      /**
       * bar camera configuration.
       * @description: This is for the VBC 2500 which is the same specs as the Vidi 250. Eventually it will include the VBC 2800 which uses the Vidi 280 camera 
       * @type {Object}
       */
      barCamera: {
        modelName: null,
        isBarCamera: true,
        /**
         * Returns the icon identifier for a ceiling speaker.
         * @function
         * @returns {string} The icon identifier 'speaker'.
         */
        icon: (area) => area.microphones.model.replace('mic', '_mic'),
        /**
         * The coverage angle of the VIDI 250 camera.
         * @type {number}
         */
        coverageAngle: 56.5,
        /**
         * The coverage radius of the VIDI 250 camera.
         * @type {number}
         */
        coverageRadius: 5,
        /**
         * The limit radius of the VIDI 250 camera.
         * @type {number}
         */
        limitRadius: 9
      }

    }
  },

  /**
   * NOT IMPLEMENTED YET - PLACEHOLDER
   * Equipment list configurations.
   * @type {Object}
   */
  equipmentLists: {
    /**
     * Summary configuration for equipment lists.
     * @type {Object}
     */
    summary: {
      /**
       * Generates the title for the equipment list.
       * @function
       * @param {string} name - The name of the area or room.
       * @param {boolean} isEmpty - Whether the equipment list is empty.
       * @returns {string} The generated title.
       */
      title: (name, isEmpty) => `${name} ${isEmpty ? 'does not have equipment' : 'equipment list'}`,
      /**
       * Column widths for the equipment list table.
       * @type {number[]}
       */
      widths: [15, 20, 65],
      /**
       * Placeholder for table data generation function.
       * @function
       * @returns {Array} An empty array (function not implemented).
       */
      tableData: () => [],
      /**
       * Notes for the equipment list.
       * @type {string[]}
       */
      notes: []
    },
  },

  // This are the keys I want to use to save 
  saveKeys: {
    uiKeys: ['units', 'scale', 'savekey'],
    containerKeys: ['project'],
    areaKeys: ['id', 'name', 'dimensions', 'location', 'ceilingHeight', 'areaOrientation', 'door', 'ceilingTiles', 'table', 'acoustics', 'microphones', 'speakers', 'cameras', 'customized', 'productFamily'],
    // todo: add equipment list, request quote
  },

  // Extra save paramerters to be added on Save 
  extraSaveParameters: (area) => ({
  }),

  // Extra load paramters to be added onLoad
  extraLoadParameters: (area) => ({
  }),





  // placeholder - not implemented yet - used for recalculation
  containerParams: [],
  // placeholder - not implemented yet - used foe recalculation
  areaParams: [],

};

export default constants