import Racks from "components/elements/storageElements/Racks";
import Automatic from "components/elements/storageElements/Automatic";
import Manual from "components/elements/storageElements/Manual";
import Staging from "components/elements/storageElements/Staging";

export const storageElements = {
  palletRack: Racks,
  // automatedHandling: Automatic,
  // manualHandling: Manual,
  // pallet: Staging
};