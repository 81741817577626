import Conveyor from "components/elements/storageElements/conveyors/Conveyor";
import FortyFive from "components/elements/storageElements/conveyors/FortyFive";
import Ninety from "components/elements/storageElements/conveyors/Ninety";
import Split from "components/elements/storageElements/conveyors/Split";

export const conveyors = {
  conveyor: Conveyor,
  fortyFive: FortyFive,
  ninety: Ninety,
  split: Split

};