const constants = {
  tool: {
    ver: '1.0.1',
    fullName: 'Classroom Designer',
    /**
     * The two parameters below are used in the current live version to redirect the tool to the current version in Cornerstone
     * If deploying to live, make certain these are uncommented!
     */
    name: 'classroom',
    goto: 'https://support.biamp.com/Design_Tools/Classroom_Designer',
    path: {
      short: '/cd',
      long: 'classroom_designer'
    },
    cookie: 'CDSeenTutorial',
    container: false,
    areaName: 'Classroom',
    maximumAreas: 8,
    addAreaLabel: 'A CLASSROOM',
    // fixme
    landingMenu: 'floorplan',

    // fixme
    localCalc: false
  },

  area: {
    min: 4.572, // minimum area dimension
    // fixme: global
    talkerHeight: 1.2,
  },

  // fixme: global
  ceilingTiles: {
    "no_ceiling_tiles": { x: 0, y: 0 },
    "2'_x_2'": { x: 0.6096, y: 0.6096 },
    "2'_x_4'": { x: 0.6096, y: 1.2192 },
    "4'_x_2'": { x: 1.2192, y: 0.6096 },
    "600mm_x_600mm": { x: 0.6, y: 0.6 }
  },

  // fixme global
  acoustics: ['poor', 'fair', 'good', 'great', 'perfect'],

  // todo: the round and racetrack tables do have initial dimentions based on the number of chairs / not sure if I need to add or calculate and set on the fly when we get there 
  tables: {
    tablet: {
      default: { length: 1.024, width: 0.6016, studentsPerTable: 1 },
    },
    desk: {
      default: { length: 0.9842, width: 0.6553, studentsPerTable: 1 },
    },
    rectangle: {
      default: { length: 0.9842, width: 1.5240, studentsPerTable: 2 },
    },
    arch: {
      default: { length: 0.4245, width: null, studentsPerTable: null },
    },
    round: {
      default: { length: null, width: null, studentsPerTable: 5 },
    },
    racetrack: {
      default: { length: null, width: 1.9696, studentsPerTable: 8 },
    }
  },

  instructor: {
    lectern: {
      default: { length: 0.762, width: 0.9144 },
    },
    desk: {
      default: { length: 0.6096, width: 1.524, studentsPerTable: 1 },
    }
  },

  /**
   * @constant
   * @name defaultPlots
   * @type {Object}
   * @description Defines the default plot types for different device categories in the Classroom Designer.
   * 
   * @property {string} microphones - The default plot type for microphones, set to 'micDistance'.
   * @property {string} speakers - The default plot type for speakers, set to 'relativeAverage'.
   * @property {null} camera - The default plot type for cameras, set to null (indicating no default plot).
   */
  defaultPlots: {
    microphones: 'micDistance',
    speakers: 'relativeAverage',
    camera: null
  },

  devices: ['microphones', 'speakers'],

  equipment: {
    // fixme: thiese ae similar to room 
    // cleanup
    microphones: {
      ceiling_mic: {
        height: (state) => state.areas[state.activeTab].ceilingHeight.min,
        coverage: (state) => state.areas[state.activeTab].microphones.coverage ?? {
          full_room: false,
          teacher: true,
          students: true,
        },

        //'focused',
        //coverage: (state) => state.areas[state.activeTab].microphones.coverage ?? 'focused',
        // coverage: () => ({
        //   full_room: false,
        //   teacher: true,
        //   students: true,
        // }),
        maxDist: [1.8, 2.3, 3.0, 3.75, 5.0],
      },
      pendant_mic: {
        height: (state) => Math.min(2.4384, state.areas[state.activeTab].ceilingHeight.min),
        coverage: (state) => state.areas[state.activeTab].microphones.coverage ?? {
          full_room: false,
          teacher: true,
          students: true,
        },



        //coverage: (state) => state.areas[state.activeTab].microphones.coverage ?? 'focused',
        // coverage: () => ({
        //   full_room: false,
        //   teacher: true,
        //   students: true,
        // }),
        maxDist: [1.5, 2.0, 2.4, 3.0, 4.0],
      },
    },

    speakers: {
      "DX-IC10/D10": {
        name: "DX-IC10/D10",
        link: "D10",
        description: "10\" Ceiling speaker",
        type: 1,
        transducer: '10"',
        spkrCoverage: 35,
        spkrSensitivity: 97,
        taps: [200, 100, 50, 25],
        minHeight: 5.5,
        spkrPolarData: { 0: 0.0, 5: -0.202525, 10: -0.48468, 15: -0.82725, 20: -1.280345, 25: -1.58945, 30: -1.919795, 35: -2.27175, 40: -2.71805, 45: -3.336355, 50: -3.97334, 55: -4.669335, 60: -5.44638, 65: -6.174675, 70: -6.976945, 75: -7.888025, 80: -8.82779, 85: -9.7391, 90: -10.77863 },
      },
      "DX-IC8/D8": {
        name: "DX-IC8/D8",
        link: "D8",
        description: "8\" Ceiling speaker",
        type: 1,
        transducer: '8"',
        spkrCoverage: 38,
        spkrSensitivity: 94,
        taps: [120, 60, 30, 15],
        minHeight: 4.9,
        spkrPolarData: { 0: -0.10229, 5: -0.010105, 10: 0.0, 15: -0.10074, 20: -0.283015, 25: -0.198585, 30: -0.220255, 35: -0.358125, 40: -0.610945, 45: -0.956725, 50: -1.548865, 55: -2.08939, 60: -2.700725, 65: -3.376135, 70: -4.0855, 75: -4.87941, 80: -5.627105, 85: -6.668545, 90: -7.7371 },
      },
      "Desono C-IC6": {
        name: "Desono C-IC6",
        link: "C-IC6",
        description: "6\" Ceiling speaker",
        type: 2,
        transducer: '6.5"',
        spkrCoverage: 39,
        spkrSensitivity: 88,
        taps: [],
        minHeight: 2.4,
        spkrPolarData: { 0: -0.9, 5: -0.4, 10: -0.9, 15: -1.5, 20: -1.35, 25: -1.3, 30: -1.3, 35: -1.95, 40: -1.85, 45: -2.1, 50: -2.75, 55: -2.25, 60: -3.25, 65: -3.6, 70: -2.85, 75: -2.85, 80: -4.5, 85: -7.2, 90: -9.3 },
      },
      "DX-IC6/D6": {
        name: "DX-IC6/D6",
        link: "D6",
        description: "6\" Ceiling speaker",
        type: 1,
        transducer: '6.5"',
        spkrCoverage: 43,
        spkrSensitivity: 92,
        taps: [60, 30, 15, 7.5],
        minHeight: 2.4,
        spkrPolarData: { 0: -0.064775, 5: 0.0, 10: -0.09423, 15: -0.23045, 20: -0.307235, 25: -0.13012, 30: -0.174635, 35: -0.06371, 40: -0.20529, 45: -0.40879, 50: -0.688945, 55: -1.20836, 60: -1.55171, 65: -2.09809, 70: -2.72668, 75: -3.46858, 80: -4.37234, 85: -5.33642, 90: -6.51736 },
      },
      "CM60DTD": {
        name: "CM60DTD",
        link: "CM60DTD",
        description: "6\" Ceiling speaker",
        type: 1,
        transducer: '6.5"',
        spkrCoverage: 49,
        spkrSensitivity: 86,
        taps: [60, 30, 15, 7.5, 3.75],
        minHeight: 2.4,
        spkrPolarData: { 0: -0.1, 5: -0.2, 10: 0.0, 15: -0.6, 20: -0.5, 25: -1.0, 30: -1.1, 35: -1.4, 40: -1.5, 45: -2.0, 50: -1.9, 55: -2.4, 60: -2.3, 65: -2.5, 70: -2.6, 75: -2.9, 80: -3.1, 85: -2.5, 90: -2.4 },
      }
    },
  },

  equipmentLists: {
    summary: {
      title: (name, isEmpty) => `${name} ${isEmpty ? 'does not have equipment' : 'equipment list'}`,
      widths: [15, 20, 65],
      // todo: build the equipment table
      tableData: () => [],
      notes: []
      // tableData: (array) => array.reduce((acc, curr, index) => {
      //   const prod = acc.find(x => x.product.name === curr['modelName']);
      //   prod ? prod.quantity++ : acc.push({
      //     quantity: 1,
      //     product: { name: curr['modelName'], link: curr['modelName'] in constants.equipment ? constants.equipment[curr['modelName']].link : null },
      //     description: curr['modelName'] in constants.equipment ? constants.equipment[curr['modelName']].description : 'Description not available',
      //   });
      //   return acc
      // }, []),
      // notes: []
    },
    // todo: do we want to add positions detail tables as in WD?
  },
};

export default constants