import { forwardRef, Suspense } from 'react';
import { useSelector } from "react-redux";
import ControlBar from "components/organisms/ControlBar";
import ToolHeader from "components/organisms/ToolHeader";
import DesignContainer from "components/organisms/DesignContainer";
import NavContainer from "components/organisms/NavContainer";


const Page = forwardRef((props, ref) => {
  const projectName = useSelector(state => state.container.project.name);
  return (
    <div className='container-fluid d-flex flex-column h-100' >
      <Suspense>
        <ToolHeader ref={ref} project={projectName}></ToolHeader>
        <ControlBar />

        <div className={`row justify-content-${props.showChild ? 'between' : 'end'} h-100`}>

          {props.showChild && <DesignContainer />}
          <NavContainer />

        </div>
      </Suspense>
    </div>
  )
})

export default Page;