import styled, { ThemeProvider } from 'styled-components';
import { useState, useContext } from "react";
import Fade from "components/services/Fade";
import { TextInput } from "components/molecules/EntryInput";
import Button from "components/molecules/Button";
import { modalThemes } from 'globalConstants/Themes';
import { buttonThemes } from 'globalConstants/Themes';
import  ToolContext from 'components/services/ToolContext';


const SplasScreen = ({closeSplash}) => {
  //const tool = useSelector(state => state.ui.tool);
  const tool = useContext(ToolContext)
  
  const splash = tool.modals.launch;
  const useTheme = modalThemes['splash'];
  
  const [visibility, setVisibility] = useState(true);
  const handleClick = () => {
    setVisibility(false)
  };
  const dispatcher = 'setProject';
  return (
    <Fade className={'h-100'} duration={'slow'} show={visibility} callback={closeSplash}>
      <ThemeProvider theme={useTheme}>
      <SplasScreen.Background {...splash}>
        <SplasScreen.Content>
          <h1 className="fw-bold">{splash.title}</h1>
          <p className="lh-sm" >{splash.intro}</p>
          <p className="fw-bolder">Let's get started!</p>
          <TextInput className={'pt-1 pb-2'} name={'name'} label={'Project name'} dispatcher={dispatcher} />
          <TextInput className={'pt-1 pb-2'} name={'company'} label={'Company name'} dispatcher={dispatcher} />
          <TextInput className={'pt-1 pb-2'} name={'designer'} label={'Design engineer'} dispatcher={dispatcher} />
          <Button className={'mt-5 mx-auto h-auto p-2 rounded-1'} label={splash.button} theme={buttonThemes['nav_red']} onClick={handleClick} />
        </SplasScreen.Content>
      </SplasScreen.Background>
      </ThemeProvider>
    </Fade>
  )
};

SplasScreen.Background = styled.div.attrs(props => ({
  className: 'container-medium h-100 d-flex align-items-center justify-content-center',
}))`
background-image: url(${props => props.image});
background-size: cover;
background: ${props => !props.image && 'rgba(255, 255, 255, 0.5)'};
z-index: 1;
`;

SplasScreen.Content = styled.div.attrs(props => ({
  className: 'modal-content',
}))`
  background-color: #554F53;
  width: 55%;
  border-radius: 0;
  opacity: 0.9;
  color: #fff;
  padding: 24px;
`;

export default SplasScreen;
