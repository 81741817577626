/**
 * Menu configurations for the Conference Room Designer.
 * This module defines the structure and properties of menus for different views in the application.
 * Since most menus are very similar to modals, rather than redefine the same block, this configuration
 * uses blocks already defined as much as possible
 * @module conferenceRoomDesignerMenus
 */
import modals from "toolConstants/conferenceroomDesigner/modals";

/**
 * Menu configurations for various views in the Conference Room Designer.
 * @type {Object.<string, Object>}
 * 
 * @property {Object} floor_plan - Floor plan view menu configuration.
 * @property {Object} ceiling - Ceiling view menu configuration.
 * @property {Object} furnishing - Furnishing view menu configuration.
 * @property {Object} acoustics - Acoustics view menu configuration.
 * @property {Object} microphones - Microphones view menu configuration.
 * @property {Object} speakers - Speakers view menu configuration.
 * @property {Object} camera - Camera view menu configuration.
 * @property {Object} equipment_list - Equipment list view menu configuration.
 * 
 * @description
 * Each menu item typically includes:
 * - element: Defines the component used to render the menu item (mapped from componentIndex).
 * - name: Usually the key used when dispatching the variable to the Redux store.
 * - dispatcher: Points to the dispatch function used to update the store.
 * - condition: An object that defines when the menu item should be displayed or enabled.
 *   The condition object is passed to the getConditionResult function, which evaluates
 *   whether the condition is met based on the current state. The condition object typically
 *   includes:
 *   - parameter: An array of state properties to check.
 *   - check: A function that receives the specified state properties and returns a boolean.
 * The getConditionResult function (documented in container.js) is used to evaluate these conditions
 * and determine whether each menu item should be displayed or enabled based on the current state.
 * 
 * Additional properties may include condition, label, range, theme, bubbles, etc.
 * 

 */
const menus = {
  floor_plan: {
    area: [...modals.area.body.slice(1, -2)],
    showDevice: false
  },
  ceiling: {
    area: [
      modals.area.body[4],
      {
        condition: { parameter: ['ceilingTiles'], check: ([tiles]) => tiles.tile !== 'no_ceiling_tiles' },
        element: 'doubleNumberInput',
        label: 'Number of Tiles',
        range: { min: 1 },
        ids: ['qty.x', 'qty.y'],
        dispatcher: 'setCeilingTiles',
        showDevice: false

      }
    ],
    showDevice: false
  },
  furnishing: {
    area: [
      { ...modals.table.body[0], theme: 'furnitureGroupNav' },
      ...modals.table.body.slice(1, 3),
    ],
    showDevice: false
  },
  acoustics: {
    area: [{ ...modals.devices.body[3] }],
    showDevice: false
  },
  microphones: {
    area: [
      {
        ...modals.devices.body[0],
        labels: false,
        bubbles: {
          ceiling_mic: 'Ceiling Microphone',
          videobar: 'Conferencing Video Bar',
          audiobar: 'Conferencing Audio Bar',
          pendant_mic: 'Pendant Microphone',
          tabletop_mic: 'Tabletop Microphone'
        }
      },
      {
        ...modals.devices.body[1]
      },
      {
        condition: { parameter: ['microphones', 'customized'], check: ([microphones, customized]) => !customized.microphones && microphones.model.includes('mic') && microphones.coverageDensity.jumpPoints.length >= 2 },
        element: 'fader',
        label: 'Coverage Density',
        name: 'coverageDensity',
        range: 'jumpPoints',
        dispatcher: 'setMicrophones'
      },
      {
        ...modals.devices.body[2],
        labels: false,
        theme: 'buttonGroupBigNav',
        bubbles: {
          full_room: 'Full Room',
          focused: 'Focused'
        }
      }
    ],
    showDevice: true
  },
  speakers: {
    area: [
      {
        ...modals.devices.body[4],
        labels: false,
        theme: 'buttonGroupBigNav',
        bubbles: {
          full_room: 'Full Room',
          focused: 'Focused'
        }
      }
    ],
    showDevice: true
  },
  camera: {
    area: [{
      condition: { parameter: ['cameras'], check: ([cameras]) => cameras.model !== 'videobar' },
      element: 'selector',
      className: 'ms-2',
      name: 'camera',
      label: 'Model',
      placeHolder: 'test',
      items: ['vidi250'],
      dispatcher: 'setCameras'
    },
    ],
    showDevice: true

  },
  equipment_list: {
    area: [
      {
        condition: { parameter: ['microphones'], check: ([microphones]) => microphones.model !== 'videobar' },
        element: 'slider',
        label: 'Camera',
        dispatcher: 'setCameras',
        name: 'hasCamera',
      },
      {
        condition: { parameter: ['microphones'], check: ([microphones]) => !microphones.model.includes('bar') },
        element: 'selector',
        placeHolder: 'Select DSP Family',
        items: ['Tesira', 'Devio'],
        label: 'Product Family',
        dispatcher: 'setProductFamily',
      }
    ],
    showDevice: true
  }

}

export default menus;