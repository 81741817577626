import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AppHeader from 'components/atoms/AppHeader';
import ToolNav from 'components/organisms/ToolNav';
import toolIndex from 'toolConstants';
import { values } from 'lodash';

const App = ({hide}) => {
  const {pathname, search} = useLocation();
  const tool = values(toolIndex).find(tool => pathname.includes(tool.constants.tool.path.long)); 
  const links = values(toolIndex).map(tool => ({path: tool.constants.tool.path.long, label: tool.constants.tool.fullName}));
  const title = tool?.constants.tool.fullName || 'DESIGN TOOLS';
  const {groups: { toolFwd } = {}} = /(?<=key=)(?<toolFwd>[a-z]*)(?=-[\w\d])/.exec(search) || {};

  const navigate = useNavigate();

  useEffect(() => {
    if(!toolFwd || pathname.includes(toolFwd)) return;
    navigate({
      pathname: pathname === '/' ? toolFwd : pathname,
      search: search
    })
  }, [toolFwd]);

  return (
    <div className='d-flex flex-column vh-100'>
      {!hide && <AppHeader>{title && `BIAMP ${title.toUpperCase()}`}</AppHeader>}
      {pathname === '/' && <ToolNav links={links}/>}
      <Outlet/>
    {/* //todo make the below footer an element */}
      <div className="footer justify-content-center bg-dark" id="footer" style={{color: '#fff', fontSize: '12px', lineHeight: '30px'}}>
        <div className='d-flex justify-content-center align-items-center'>
        <p className='my-auto'>2023 Biamp®</p>
        <div className="vr mx-3 my-1"></div>
        <a href='https://www.biamp.com/legal/privacy-policy' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', cursor: 'pointer'}}>
          <span style={{color: '#fff', textDecoration: 'none'}}>Privacy</span>
          </a>
        <div className="vr mx-3 my-1"></div>
        <a href='https://www.biamp.com/legal/terms-of-use' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', cursor: 'pointer'}}>
        <span style={{color: '#fff', textDecoration: 'none'}}>Terms of Use</span>
        </a>
        </div>
      </div>

      
    </div>
)};

export default App;

