import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { conveyors } from "components/elements/storageElements/conveyors";
import { useEffect } from "react";
import ReducersIndex from "reduxModules/ducks";
import { getDrawingBox } from "reduxModules/ducks/ui";

const Manual = ({ active, area }) => {
  const {bbox, scale, zoom} = useSelector(getDrawingBox);
  const { length, width } = area.dimensions
  const { left, top } = area.location;
  const x0 = bbox.x0 + left * scale * (!zoom) + 1.5;
  const y0 = bbox.y0 + top * scale * (!zoom) + 1.5;
  const hor = area.storageOrientation === 'horizontal';

  // Determine area quadrant 
  const { length: contLength, width: contWidth } = useSelector(state => state.container.dimensions);
  const right = left + length / 2 > contLength / 2;
  const bottom = top + width / 2 > contWidth / 2;

  // Calculate conveyors depending on size
  const ga = hor ? length : width;
  const gb = hor ? width : length;
  let array = [];
  let qty;

  if (ga < 1.5 || gb <= .9) { // area is too small for conveyors
    //return
    qty = 0
  } else if (ga <= 3.32 || gb <= 5.6) { //10.8`

    qty = area.pickingAisles ? area.pickingAisles-2 : Math.floor((gb - .02) / 3.8288); // 0.9 + 1.8288 + 0.9 + 0.2
    const spacing = qty === 0 ? (gb - .9) / 2 : (gb - 2 * qty + .2) / qty + .9;
    const convLength = ga;

    if (qty === 0) {
      array.push(
        { type: 'conveyor', props: { ver: hor, full: false, x: hor ? 0 : spacing, y: hor ? spacing : 0, length: convLength } },
        )
    }

    for (let i = 0; i < qty; i++) {
      const a = i * (spacing + 1.1);
      const b = a + spacing;
      array.push(
        { type: 'conveyor', props: { ver: hor, full: false, x: hor ? 0 : a, y: hor ? a : 0, length: convLength } },
        { type: 'conveyor', props: { ver: hor, full: false, x: hor ? 0 : b, y: hor ? b : 0, length: convLength } }
      )
    }

  } else if (ga <= 7.44 || gb <= 8.53 ) {
    qty = area.pickingAisles ? area.pickingAisles-2 : Math.floor((gb - 1.76) / 3.8288); // 0.9 + 1.8288 + 0.9 + 0.2
    const spacing = (gb - 2 * qty - 1.76) / qty + .9;
    const convLength = ga - 2.15;

    for (let i = 0; i < qty; i++) {
      const a = i * (spacing + 1.1);
      const b = a + spacing;
      array.push(
        { type: 'conveyor', props: { ver: hor, full: false, x: hor ? 0 : a, y: hor ? a : 0, length: convLength } },
        { type: 'fortyFive', props: { ver: hor, x: hor ? convLength : a, y: hor ? a : convLength, flipV: !hor } },
        { type: 'conveyor', props: { ver: !hor, full: false, x: hor ? convLength + .6 : a + 1.4, y: hor ? a + 1.4 : convLength + .6, length: spacing - 1.1 } },
        { type: 'conveyor', props: { ver: hor, full: false, x: hor ? 0 : b, y: hor ? b : 0, length: convLength } },
        { type: 'fortyFive', props: { ver: hor, x: hor ? convLength : b, y: hor ? b : convLength, flipV: !hor } },
      )
    };
    array.push({ type: 'fortyFive', props: { ver: hor, x: hor ? convLength + .57 : length - 1.46, y: hor ? width - 1.46 : convLength + .57, flipH: true, flipV: hor, cap: true } })

  } else {
    // marker
    qty = area.pickingAisles ? area.pickingAisles-2 : Math.floor((gb - 4.58) / 3.8288); // 0.9 + 1.8288 + 0.9 + 0.2
    const spacing = (gb - 2 * qty - 4.58) / qty + .9;
    const convLength = ga - 6.4

    for (let i = 0; i < qty; i++) {
      const a = i * (spacing + 1.1);
      const b = a + spacing;
      array.push(
        { type: 'conveyor', props: { ver: hor, full: false, x: hor ? 0 : a + 1.96, y: hor ? a + 1.96 : 0, length: convLength } },
        { type: 'fortyFive', props: { ver: hor, x: hor ? convLength : a + 1.96, y: hor ? a + 1.96 : convLength, flipV: !hor } },
        { type: 'ninety', props: { ver: hor, x: hor ? convLength + .6 : 0, y: hor? 0 : convLength + .6, flipH: true } },
        { type: 'ninety', props: { ver: hor, x:hor ? convLength + .6 + 2.26 : 0, y: hor? 0 : convLength + .6 + 2.26, flipH: !hor, flipV: !hor } },
        { type: 'conveyor', props: { ver: !hor, full: false, x: hor ? convLength + .6 : a + 1.4 + 1.96, y: hor ? a + 1.4 + 1.96 : convLength + .6, length: spacing - 1.1 } },
        { type: 'conveyor', props: { ver: hor, full: false, x: hor ? 0 : b + 1.96, y: hor ? b + 1.96 : 0, length: convLength } },
        { type: 'fortyFive', props: { ver: hor, x: hor ? convLength : b + 1.96, y: hor ? b + 1.96 : convLength, flipV: !hor } },
      )
    };
    array.push(
      { type: 'ninety', props: { ver: hor, x: hor ? convLength + .6 : length - 2.32, y: hor ? width - 2.32 : convLength + .6, flipH: hor, flipV: hor } },
      { type: 'ninety', props: { ver: hor, x: hor ? convLength + .6 + 2.26 : length - 2.32, y: hor ? width - 2.32 : convLength + .6 + 2.26, flipH: false, flipV: true } },
      { type: 'split', props: { ver: !hor, full: false, lin: .5, lout: (gb - 2.6 - 6) / 2, lcont: gb - 2.6 - 5.5,
          x: hor ? length - 2.18 : 2.26, y: hor ? 2.26 : width - 2.18, flipH: hor, flipV: true
        }
      }
    )


  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (area.pickingAisles === 0) dispatch(ReducersIndex.container.setPickingAisles(qty + 2));
  })
  
  console.log("area: ", area.name, scale);
  const transform = `translate(${x0}, ${y0}) scale(${scale}) scale(${1 - 2 * right}, ${1 - 2 * bottom}) translate(-${right * (length - .06)}, -${bottom * (width - .06)})`;

  return (
    <Manual.Group id={`${area.id}-manual`} active={active} transform={transform}>
      {array.map((element, i) => {
        const Element = conveyors[element.type]
        return <Element key={`${element.type}-${i}`} {...element.props} />
      })}
    </Manual.Group>
  )
};


Manual.Group = styled.g.attrs(props => ({
  id: props.id,
  transform: props.transform
}))`
    fill: none;  
    stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-custom-gray)'};
    ${'' /* shape-rendering: crispedges; */}
  `;

export default Manual