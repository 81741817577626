// This are reducers to add dynamically to the store
import { createSelector, current } from "@reduxjs/toolkit";
import { getAreaBoxes, getAreaState, getCurrentValue2 } from "reduxModules/ducks/container";
import toolIndex from "toolConstants";
import { closest, side } from "utilities/mathematic";
import { calculateCoordinates, getOverlaps } from "utilities/geometric";
import { clamp, isEqual, mapValues, max, merge, min, set, transform, values, indexOf, findKey, round, toPairs, pick, mapKeys, has, ceil, inRange, find, every, some, minBy, sortBy, trimEnd, omit, isEmpty } from "lodash";

import createCachedSelector from "re-reselect";
import { getDrawingBox, getScale } from "./ui";

const roomSlice = {
  reducers: {
    /**
     * Sets the area orientation.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new area orientation ('lengthwise' or 'widthwise').
     */
    setAreaOrientation: (state, action) => {
      state.areas[state.activeTab].areaOrientation = action.payload
    },
    /**
     * Updates the door properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new door properties to be updated.
     */
    setDoor: (state, action) => {
      const area = state.areas[state.activeTab]
      const currentDoor = area.door;
      area.door = { ...currentDoor, ...action.payload }
    },
    /**
     * Sets the ceiling tiles properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new ceiling tiles properties.
     */
    setCeilingTiles: (state, action) => {
      const initTile = { ...toolIndex[state.currentTool].initialState.area(state).ceilingTiles };
      const payload = transform({ ...action.payload }, (result, value, key) => set(result, key.split('.'), value));
      const { tile, checked, qty, } = payload;
      const currentCeiling = tile ? { ...initTile, tile: tile } : state.areas[state.activeTab].ceilingTiles;
      if (checked) currentCeiling.checked = checked;
      if (qty && !isEqual(qty, currentCeiling.qty)) {
        currentCeiling.checked = [];
        payload.transform = { x: 0, y: 0 };
      };
      state.areas[state.activeTab].ceilingTiles = merge({}, { ...currentCeiling }, payload);
    },
    /**
     * Sets the table properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new table properties.
     */
    setTable: (state, action) => {
      const area = state.areas[state.activeTab]
      const initTable = { ...toolIndex[state.currentTool].initialState.area(state).table };
      let payload = transform({ ...action.payload }, (result, value, key) => set(result, key.split('.'), value));
      let { shape = null, size = {}, chair, isGhosted } = payload;
      let currentTable = area.table.shape === null && shape ? initTable : area.table;
      const defaultTable = toolIndex[state.currentTool].constants.tables[shape ?? currentTable.shape] ?? {};
      currentTable = shape ? {
        ...initTable,
        shape: shape,
        size: defaultTable.default(current(area.dimensions))
      } : currentTable;

      if (chair) {
        if (isGhosted && currentTable.ghostChairs.includes(chair)) {
          currentTable.ghostChairs.splice(currentTable.ghostChairs.indexOf(chair), 1)
        } else if (!isGhosted && !currentTable.ghostChairs.includes(chair)) {
          currentTable.ghostChairs.push(chair);
        }
        payload = omit(payload, ['chair', 'isGhosted'])
      };

      let emptyGhostCHairs = false;
      if (shape && !isEmpty(size)) {

        payload.transform = initTable.transform;
        emptyGhostCHairs = true;
        const maximum = mapValues(area.dimensions, x => x - 1.4);
        if (currentTable.shape === 'round') {
          payload.size = {
            length: size.length,
            width: size.length
          };
        } else if (currentTable.shape === 'bullet') {
          payload.size = {
            length: size.length ? clamp(size.length, currentTable.size.width / 2 + size.length * 0.03, maximum.length) : currentTable.size.length,
            width: size.width ? clamp(size.width, defaultTable.minimum.width, min([maximum.width, currentTable.size.length * 1.94])) : currentTable.size.width
          };
        }
        else if (currentTable.shape === 'boat') {
          const adjustedLength = size.width ? currentTable.size.length : max([defaultTable.minimum.length, size.length]);
          const adjustedWidth = size.length ? currentTable.size.width : max([defaultTable.minimum.width, size.width]);
          payload.size = {
            length: clamp(size.length ?? currentTable.size.length, defaultTable.minimum.length, maximum.length),
            width: adjustedLength > adjustedWidth * 5 ? adjustedLength * .2 : adjustedWidth
          };
        } else {
          payload.size = mapValues(size, (v, k) => clamp(v, defaultTable.minimum[k], currentTable.shape === 'round' ? min(values(maximum)) : maximum[k]));
        };
      };
      const newTable = merge({}, { ...currentTable }, payload);
      newTable.ghostChairs = emptyGhostCHairs ? [] : currentTable.ghostChairs;
      area.table = newTable;
    },
    /**
     * Sets the microphones properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new microphones properties.
     */
    setMicrophones: (state, action) => {
      const area = state.areas[state.activeTab];
      const currentMics = area.microphones;
      const newMic = { ...action.payload };
      const micChange = 'model' in newMic;
      const model = (micChange ? newMic : currentMics).model.replace('mic', '_mic');
      const defMic = toolIndex[state.currentTool].constants.equipment.microphones[model];
      newMic.height = micChange ? defMic.height(area) : clamp((newMic.height ?? currentMics.height), ...(defMic.heightRange(area)));
      newMic.coverage = micChange && !('coverage' in newMic) ? defMic.coverage(area) : (newMic.coverage ?? currentMics.coverage);
      if (has(newMic, 'coverageDensity.value')) newMic.qty = newMic.coverageDensity.value;
      newMic.coverageDensity = { ...currentMics.coverageDensity, ...newMic?.coverageDensity };
      area.microphones = { ...currentMics, ...newMic }
    },
    /**
     * Sets the acoustics property.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new acoustics value.
     */
    setAcoustics: (state, action) => {
      state.areas[state.activeTab].acoustics = action.payload
    },
    /**
     * Sets the speakers properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new speakers properties.
     */
    setSpeakers: (state, action) => {
      const area = state.areas[state.activeTab];
      const currentSpkrs = area.speakers;
      const newSpeaker = { ...action.payload };
      const spkrChange = 'model' in newSpeaker;
      const model = (spkrChange ? newSpeaker : currentSpkrs).model;
      const defSpkr = toolIndex[state.currentTool].constants.equipment.speakers[model];
      newSpeaker.coverage = spkrChange ? defSpkr.coverage(area) : (newSpeaker.coverage ?? currentSpkrs.coverage);
      state.areas[state.activeTab].speakers = { ...currentSpkrs, ...newSpeaker }
    },
    /**
     * Updates properties for a specific device (microphones, speakers, etc.).
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - An object with device names as keys and their new properties as values.
     */
    updateDevice: (state, action) => {
      const area = state.areas[state.activeTab];
      toPairs(action.payload).forEach(([device, properties]) => {
        const currentDevice = area[device];
        if ('layout' in properties) {
          const newLayout = values(properties.layout).map((device, index) => ({ ...currentDevice.layout[index], ...device }));
          properties.layout = newLayout;
        };
        area[device] = { ...currentDevice, ...properties }
      });
    },

    /**
     * Adds a new device to the current area (microphones, speakers, etc.).
     * @param {Object} state - The current state of the room.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The payload of the action
     * @param {string} action.payload.device - The type of device being added (e.g., 'speakers', 'microphones')
     * @param {Object} action.payload.location - The location coordinates for the new device
     * 
     * @description
     * This function adds a new device to the layout of the currently active area. It performs the following actions:
     * 1. Retrieves the current area based on the active tab.
     * 2. Extracts the device type and location from the action payload.
     * 3. Gets the current model for the specified device type.
     * 4. Adds a new device object to the layout array of the specified device type.
     * 5. Increments the quantity count for the device type.
     * 
     * @example
     * // Dispatch an action to add a new microphone
     * dispatch(addDevice({
     *   device: 'microphones',
     *   location: { x: 10, y: 20, z: 3 }
     * }));
     * 
     */
    addDevice: (state, action) => {
      const area = state.areas[state.activeTab];
      const { device, location } = action.payload;
      const model = area[device].model;
      area[device].layout.push({
        modelName: model,
        location
      })
      area[device].qty += 1;
    },

    /**
     * Removes a specific device from the current active area in the room.
     * @param {Object} state - The current state of the room.
     * @param {Object} action - The Redux action object
     * @param {Object} action.payload - The payload of the action
     * @param {string} action.payload.device - The type of device to remove (e.g., 'speakers', 'microphones')
     * @param {number} action.payload.id - The index of the device to remove from the layout array
     * 
     * @example
     * // Dispatch an action to remove a device
     * dispatch(removeDevice({ device: 'speakers', id: 2 }));
     * 
     * @description
     * This reducer function removes a specific device from the layout of the currently active area.
     * It updates both the layout array and the quantity count for the specified device type.
     * The function assumes that the device type exists in the area object and has a 'layout' array
     * and a 'qty' property. It's typically used in response to user actions like clicking to remove
     * a device in the room designer interface.
     * 
     * @note This function mutates the state directly, which is the recommended approach
     * when using Redux Toolkit's createSlice.
     */
    removeDevice: (state, action) => {
      const area = state.areas[state.activeTab];
      const { device, id } = action.payload;
      area[device].layout.splice(id, 1);
      area[device].qty -= 1;
    },

    /**
     * Sets the cameras properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new cameras properties.
     */
    setCameras: (state, action) => {
      const initialCameras = { ...toolIndex[state.currentTool].initialState.area(state).cameras };
      const newCamera = { ...action.payload };
      if (!newCamera.hasCamera) {
        state.areas[state.activeTab].cameras = {
          hasCamera: false,
          coverageRadius: 0,
          model: '',
          layout: [],
          qty: 0
        };
      } else {
        state.areas[state.activeTab].cameras = { ...initialCameras }
      }
    },
    /**
     * Sets the product family.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new product family.
     */
    setProductFamily: (state, action) => {
      state.areas[state.activeTab].productFamily = action.payload
    },
  },
}


export default roomSlice;

// fixme this doesn't need to export - exporting just for testing in Devices
/**
 * Selector to get coverage boxes for the current area.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {Object} An object containing coverage boxes for 'full_room' and 'focused' scenarios.
 */
export const getCoverageBoxes = createSelector(
  state => getAreaState(state),
  state => toolIndex[state.container.currentTool].constants,
  (area, constants) => {
    const { dimensions, location, table } = area;
    const { area: { table2wall }, tables } = constants;
    const endTable = tables[table?.shape]?.endTable ?? false;
    return {
      full_room: {
        length: dimensions.length,
        width: dimensions.width,
        left: location.left,
        top: location.top,
        spkrAdj: 0
      },
      focused: {
        length: table.shape ? table.size.length + table2wall * (endTable ? .5 : 1) : dimensions.length,
        width: table.shape ? table.size.width + table2wall * (endTable ? .5 : 1) : dimensions.width,
        left: location.left + table.shape ? (dimensions.length - table.size.length) * .5 - table2wall * (endTable ? 0.25 : .5) : 0,
        top: location.top + table.shape ? (dimensions.width - table.size.width) * .5 - table2wall * (endTable ? 0.25 : .5) : 0,
        spkrAdj: table.shape ? 0 : 1
      }
    }
  }
);

/**
 * Creates a coordinate transformer function based on the current area and table transform.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {Function} A function that transforms coordinates based on the current area and table position.
 */
const createCoordinateTransformer = createSelector(
  state => getAreaBoxes(state),
  state => getCurrentValue2(state, "table.transform"),
  (areaBoxes, tableTransform) => {
    const areaBox = areaBoxes[0];
    const angle = tableTransform.a * Math.PI / 180;
    const cosAngle = Math.cos(angle);
    const sinAngle = Math.sin(angle);

    const transformPoint = ({ x, y }) => {
      const dx = x - areaBox.cx;
      const dy = y - areaBox.cy;
      return {
        x: areaBox.cx + (dx * cosAngle - dy * sinAngle) + tableTransform.x,
        y: areaBox.cy + (dx * sinAngle + dy * cosAngle) + tableTransform.y
      };
    };
    return transformPoint;
  }
);

/**
 * Selector to get device layouts for microphones and speakers.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {Object} An object containing layouts for microphones and speakers, including coverage density, layout coordinates, and quantities.
 */
export const getDeviceLayouts = createSelector(
  state => getAreaState(state),
  getCoverageBoxes,
  createCoordinateTransformer,
  state => getCenters(state),
  state => getScale(state),
  state => toolIndex[state.container.currentTool].constants,

  (area, coverageBoxes, transformer, centers, scale, constants) => {
    const { microphones, speakers, cameras, table, customized, ceilingTiles: { tile } } = area;
    const { transform: tableTransform = { x: 0, y: 0, a: 0 } } = table;
    const { microphones: isMicCustom, speakers: isSpkrCustom, cameras: isCameraCustom } = customized;
    const hasTiles = tile !== "no_ceiling_tiles";
    const { equipment, acoustics, area: { talkerHeight } } = constants;

    // Calculate microphone layout
    // fisme: added this temporary stop in case mics are not defined
    if (isEmpty(microphones)) return
    const micModel = microphones.model.replace('mic', '_mic');
    const micMaxDist = equipment.microphones[micModel].micData[indexOf(acoustics, area.acoustics)];
    const { isCeilingMic = false, isTableMic = false } = equipment.microphones[micModel]
    const micRadius = round(side(micMaxDist, (microphones.height - talkerHeight)), 4);
    const micCoverageBox = coverageBoxes[findKey(microphones.coverage)];
    const micOverlaps = getOverlaps(micCoverageBox, micRadius);

    // Calculate initial microphone placement
    let { coords: micCoords, qty: micQty, tableJumpPoints = null } = calculateMicPlacement(area, micOverlaps, micCoverageBox, micRadius, scale, constants);

    // Translate and/or Rotate microphone coordinates to align with the center of the table if microphone coverage is set to focused and table has been moved
    const isMicFocused = microphones.coverage.focused;
    const isTableTransformed = some(tableTransform);
    if ((isMicFocused || isTableMic) && isTableTransformed) {
      micCoords = transformCoordinates(micCoords, transformer, 'location');
    };

    // If there are ceiling tiles, microphone is in the ceiling and have not been customized, attempt to center in tiles 
    let copyOfCenters = centers ? centers.map(center => ({ ...center })) : null;
    let micPlacementError = 0
    if (hasTiles && isCeilingMic && !isMicCustom && copyOfCenters) {
      for (const mic of micCoords) {
        if (!every(mic.location, objValue => objValue)) continue
        // Get  the target tile to place the microphone
        const finalTile = findClosestTile(copyOfCenters, mic.location);
        // If a tile was found, center mic in tile and block adjacent 
        if (finalTile) {
          mic.location = { x: finalTile.x, y: finalTile.y };
          copyOfCenters = blockTiles(copyOfCenters, finalTile);
        } else {
          // If not solution is found, leave mic whefe it is and increase error count
          micPlacementError++;
        }
      };
    };

    if (micPlacementError > 0) console.log("Couldn't center: ", micPlacementError, " microphones");

    // Calculate speaker layout based on microphone placement
    const spkrModel = speakers.model;
    const { coverageAngle, isCeilingSpkr = false } = equipment.speakers[spkrModel];
    const speakerHeight = area.ceilingHeight.min;
    const spkrRadius = round((speakerHeight - talkerHeight) * Math.tan(coverageAngle * Math.PI / 180), 4);
    const spkrCoverageBox = coverageBoxes[findKey(speakers.coverage)];
    const spkrOverlaps = getOverlaps(spkrCoverageBox, spkrRadius)
    const spkrOverlap = spkrOverlaps.overlaps[0];

    // Calculate speaker placement considering microphone placement
    let spkrCoords = calculateSpeakerPlacement(area, spkrOverlap, spkrCoverageBox, spkrRadius, micQty, constants); //  spkrCoverageBox, spkrRadius, spkrOverlaps, micPlacement);

    // Translate / Rotate speakers coordinates to align with the center of the table if speaker coverage is set to focused and table has been moved
    const isSpkrFocused = speakers.coverage.focused;
    if (isSpkrFocused && isTableTransformed) {
      spkrCoords = transformCoordinates(spkrCoords, transformer, 'location');
    };
    // If there are ceiling tiles and speakers have not been customized, attempt to center in tiles 
    let spkrPlacementError = 0
    if (hasTiles && isCeilingSpkr && !isSpkrCustom && copyOfCenters) {
      for (const spkr of spkrCoords) {
        if (!every(spkr.location, objValue => objValue)) continue
        // Get  the target tile to place the speaker
        const finalTile = findClosestTile(copyOfCenters, spkr.location);
        // If a tile was found, center speaker 
        if (finalTile) {
          spkr.location = { x: finalTile.x, y: finalTile.y };
        } else {
          // If not solution is found, leave speaker where it is and increase error count
          spkrPlacementError++;
        }
      };
    };

    if (spkrPlacementError > 0) console.log("Couldn't center: ", spkrPlacementError, " speakers");

    // Calculate camera layout
    let cameraCoords = calculateCameraPlacement(area);

    return {
      // centers: copyOfCenters, // to display centers on ceiling uncomment this line and check ;ines to uncomment in ceiling
      microphones: {
        micDistanceLegendMax: ceil(micMaxDist * 2),
        micMaxRadius: micRadius,
        coverageDensity: {
          value: micCoords.length,
          jumpPoints: tableJumpPoints ?? micOverlaps.jumpPoints,
          overlaps: micOverlaps.overlaps,
        },
        layout: isMicCustom ? microphones.layout : micCoords,
        qty: isMicCustom ? microphones.qty : micCoords.length
      },
      speakers: {
        coverageDensity: {
          value: spkrCoords.length,
          jumpPoints: spkrOverlaps.jumpPoints,
          overlaps: spkrOverlaps.overlaps
        },
        layout: isSpkrCustom ? speakers.layout : spkrCoords,
        qty: isSpkrCustom ? speakers.qty : spkrCoords.length
      },
      cameras: {
        layout: isCameraCustom ? cameras.layout : cameraCoords,
        qty: isCameraCustom ? cameras.qty : cameraCoords.length
      }
    };
  }
);

/**
 * Selector to get tile quantities for the ceiling.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {Object} An object containing actual, draw, and maximum tile quantities for x and y directions.
 */
export const getTileQuantities = createSelector(
  state => getCurrentValue2(state, 'dimensions'),
  state => getCurrentValue2(state, 'ceilingTiles'),
  state => toolIndex[state.container.currentTool].constants.ceilingTiles,
  (area, tiles, constants) => {
    const { length, width } = area;
    const { tile, qty } = tiles;

    // Set tile size depending on selection. If no tile is selected, use 2x2 for ref grid
    const hasTiles = tile !== "no_ceiling_tiles";
    const tileSize = constants[hasTiles ? tile : "2'_x_2'"];

    // Calculate the maximum number of tiles that fit in the room 
    const maxX = ceil(length / tileSize.x);
    const maxY = ceil(width / tileSize.y);

    // Check that qty is within max
    let { x = 0, y = 0 } = qty;
    x = x ? clamp(x, Math.min(1, maxX), maxX) : hasTiles ? maxX : 0;
    y = y ? clamp(y, Math.min(1, maxY), maxY) : hasTiles ? maxY : 0;

    // Set draw qunatitities. If using full tile coverage, adds 1 tile to the perimeter to the grid can be moved
    const drawX = x + (x && x === maxX ? 2 : 0);
    const drawY = y + (y && y === maxY ? 2 : 0);

    return {
      actualTileQty: {
        x: x,
        y: y
      },
      drawTileQty: {
        x: drawX,
        y: drawY
      },
      maxTileQty: {
        x: maxX + 2,
        y: maxY + 2
      }
    }
  }
);

/**
 * Selector to get the centers of ceiling tiles.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {Array} An array of objects representing the centers of ceiling tiles, including their positions and states.
 */
const getCenters = createSelector(
  state => getAreaBoxes(state),
  state => getCurrentValue2(state, 'ceilingTiles'),
  getTileQuantities,
  state => toolIndex[state.container.currentTool].constants.ceilingTiles,
  (areaBoxes, ceilintTiles, tileQty, constants) => {
    const areaBox = areaBoxes[0];
    const { length, width, top, left } = areaBox;
    const { tile, transform: ceilingTransform, checked } = ceilintTiles;
    const { actualTileQty: actual, drawTileQty: draw, maxTileQty: max } = tileQty;

    // Set tile size depending on selection. If no tile is selected, use 2x2 for ref grid
    const hasTiles = tile !== "no_ceiling_tiles";
    const tileSize = constants[hasTiles ? tile : "2'_x_2'"];

    // calculate tileoffset for cases where part of the tile is outside the room 
    const tileOffset = mapValues({ x: length, y: width }, (v, k) => clamp((v / (2 * tileSize[k]) - (max[k] - 2) / 2), -1, 0));

    // Set the general mask pattern for each tile - this also centers the tile in the room if there are no tiles
    let masks = [{ x: tileSize.x * (tileOffset.x + .5 * hasTiles), y: tileSize.y * (tileOffset.y + .5 * hasTiles), mask: 0 }];
    if (tileSize.x > tileSize.y) masks = [{ x: tileSize.x * (tileOffset.x + .25), y: tileSize.y * (tileOffset.y + .5), mask: -1 }, { x: tileSize.x * (tileOffset.x + .75), y: tileSize.y * (tileOffset.y + .5), mask: 1 }];
    if (tileSize.x < tileSize.y) masks = [{ x: tileSize.x * (tileOffset.x + .5), y: tileSize.y * (tileOffset.y + .25), mask: -1 }, { x: tileSize.x * (tileOffset.x + .5), y: tileSize.y * (tileOffset.y + .75), mask: 1 }];

    // Adjust the tile and set the maxxed flags 
    const maxedX = max.x === draw.x;
    const maxedY = max.y === draw.y;

    const adjX = maxedX ? 1 : max.x - 2 - draw.x;
    const adjY = maxedY ? 1 : max.y - 2 - draw.y;

    let gridOffsetX = ceil(adjX / 2) + ((max.x - actual.x) % 2 === 0 ? 0 : .5);
    let gridOffsetY = ceil(adjY / 2) + ((max.y - actual.y) % 2 === 0 ? 0 : .5);

    // Final tile qty
    const qtyX = min([draw.x, max.x]) + (2 * adjX);
    const qtyY = min([draw.y, max.y]) + (2 * adjY);

    const tileCenters = [];
    for (let index = 0; index < (qtyX * qtyY); index++) {
      const idY = Math.floor(index / qtyX);
      const idX = index - idY * qtyX;

      // Actual tile index 
      const rx = idX - (maxedX ? 0 : ceil(adjX / 2) * 2);
      const ry = idY - (maxedY ? 0 : ceil(adjY / 2) * 2);

      const blocked = find(checked, { x: rx, y: ry });

      // Adjust the mask to the current position
      const adjustedMasks = masks.map((mask) => {
        const dx = mask.x + tileSize.x * (idX - gridOffsetX);
        const dy = mask.y + tileSize.y * (idY - gridOffsetY);
        return {
          ...mask,
          x: dx + ceilingTransform.x,
          y: dy + ceilingTransform.y,
          rx,
          ry,
          id: `tile_${rx}_${ry}_${mask.mask}`,
          blocked: blocked ? (blocked.mask === 0 || blocked.mask === mask.mask) : false
        }
      });
      // Filter masks that are outside the room 
      const roomFiltered = adjustedMasks.filter(mask => inRange(mask.x, left + .01, left + length) && inRange(mask.y, top + .01, top + width));
      // Filter tiles that have been blocked


      // const blocked = find(checked, { x: rx, y: ry });
      //const blockedFiltered = roomFiltered.filter(mask => !(blocked && (blocked.mask === 0 || mask.mask === blocked.mask)));
      // Add Centers 
      tileCenters.push(...roomFiltered);
    };
    // if Tiles are vertical - sort by position 
    return (tileSize.x < tileSize.y) ? sortBy(tileCenters, tile => tile.y) : tileCenters;
  }
);

/**
 * Cached selector to get transformed tile centers based on the current device and coverage.
 * @function
 * @param {Object} state - The Redux state.
 * @param {string} device - The device type ('microphones' or 'speakers').
 * @returns {Array} An array of transformed tile center coordinates.
 */
export const getTileCenters = createCachedSelector(
  getCenters,
  createCoordinateTransformer,
  state => getCurrentValue2(state, "table.transform"),
  state => getCurrentValue2(state, "ceilingTiles.tile") === "no_ceiling_tiles",
  (state, device) => getCurrentValue2(state, `${device}.coverage`).focused,

  (centers, transformer, tableTransform, noTiles, isFocused) => {
    // Calculations are only required when there's a table and it has been transformed
    const hastable = some(tableTransform);
    if (noTiles && hastable && isFocused) {
      return transformCoordinates(centers, transformer);
    } else {
      return centers;
    };
  }
)(
  (state, device) => `${device}:${getCurrentValue2(state, `${device}.coverage`)}:${getCenters}`
);


/**
 * @function getDeviceAdjustmentAndAim
 * @description A memoized selector that calculates coordinate adjustments and aim points for devices, particularly for bar speakers and cameras.
 * 
 * @param {Object} state - The Redux state object
 * @param {string} deviceType - The type of device ('speakers', 'cameras', etc.)
 * @param {Object} device - The device object for which to calculate adjustments
 * @param {string} device.modelName - The model name of the device
 * @param {Object} device.location - The location information for the device
 * 
 * @returns {Object} An object containing adjusted coordinates and aim point for the device
 * @property {string} modelName - The model name of the device
 * @property {Object} location - The adjusted location coordinates
 * @property {number} location.x - The adjusted x-coordinate
 * @property {number} location.y - The adjusted y-coordinate
 * @property {Object} aimPoint - The calculated aim point for the device
 * @property {number} aimPoint.x - The x-coordinate of the aim point
 * @property {number} aimPoint.y - The y-coordinate of the aim point
 * 
 * @example
 * const result = getDeviceAdjustmentAndAim(state, 'cameras', { modelName: 'CameraModel1', location: { x: 10, y: 20 } });
 * // Returns { modelName: 'CameraModel1', location: { x: 10, y: 20 }, aimPoint: { x: 15, y: 25 } } (example values)
 * 
 * @description
 * This selector function calculates coordinate adjustments and aim points for devices, with a focus on bar speakers and cameras.
 * It takes into account the device's position relative to the room's center and edges to determine
 * if and how the device's coordinates should be adjusted. It also calculates an appropriate aim point.
 * 
 * The function uses the following process:
 * 1. Retrieves drawing box information, table details, and equipment constants from the Redux state.
 * 2. Checks if the device is a bar speaker or a camera.
 * 3. For bar speakers and cameras, calculates the device's angle relative to the room's center.
 * 4. Determines which side of the room the device is on based on this angle.
 * 5. Applies appropriate coordinate adjustments based on the device's position.
 * 6. Calculates the aim point:
 *    - For cameras and non-bar speakers, aims at the center of the table if present, or the center of the room if no table.
 *    - For bar cameras, adjusts the aim point based on the device's position along the wall.
 * 
 * The selector is memoized for performance, using the device type, model name, and location as cache keys.
 */
export const getDeviceAdjustmentAndAim = createCachedSelector(
  getDrawingBox,
  state => getCurrentValue2(state, 'table'),
  state => toolIndex[state.container.currentTool].constants.equipment,
  (state, deviceType, device) => ({ deviceType, device }),
  (drawingBox, table, equipment, deviceObj) => {
    const { deviceType, device: { modelName, location } } = deviceObj;
    const isCamera = deviceType === 'cameras';
    const { bbox, scale } = drawingBox;
    const { x0, y0, cx, cy } = bbox;
    const { shape, transform: tableCenter } = table;
    const hasTable = Boolean(shape);
    const adjustment = { x: 0, y: 0 };
    const barFence = .134;
    const { isBarSpkr = false, isBarCamera = false } = equipment?.[deviceType]?.[modelName] ?? {};
    // Calculate djustment 
    let aimPoint;
    if (isBarSpkr || isCamera) {
      const { x, y } = mapValues(location, (value, key) => value * scale + bbox[`${key}0`]);
      aimPoint = { ...location };

      // Calculate the angle of the device in degrees
      let angle = Math.atan2(y - cy, x - cx) * (180 / Math.PI) + 180;
      angle = angle % 360;

      // Calculate the room diagonal angle
      const roomDiagonal = Math.atan2(y0 - cy, x0 - cx) * (180 / Math.PI) + 180;

      // Determine barSide and calculate adjustments
      if (angle <= roomDiagonal) { // left side
        adjustment.x = -barFence;
        aimPoint.x += barFence;
      } else if (angle <= 180 - roomDiagonal) { // top side
        adjustment.y = -barFence;
        aimPoint.y += barFence;
      } else if (angle <= 180 + roomDiagonal) { // right side
        adjustment.x = barFence;
        aimPoint.x -= barFence;
      } else if (angle <= 360 - roomDiagonal) { // bottom side
        adjustment.y = barFence;
        aimPoint.y -= barFence;
      } else { // left side (wrap aroound)
        adjustment.x = -barFence;
        aimPoint.x += barFence;
      };
    };
    const adjustedLocation = mapValues(location, (value, key) => value + (isCamera ? 0 : adjustment[key]))

    if (isCamera && !isBarCamera) {
      aimPoint.x = (cx - x0) / scale + (hasTable ? tableCenter.x : 0);
      aimPoint.y = (cy - y0) / scale + (hasTable ? tableCenter.y : 0);
    };

    return {
      modelName,
      location: adjustedLocation,
      aimPoint
    };
  }
)(
  (state, deviceType, device) => {
    const { modelName = 0, location = {} } = device
    return `${deviceType}:${modelName}:${values(location?.location).join(':')}`
  }
);


// HELPER FUNCTIONS - 
/**
 * Generates an array of jump points for microphone placement.
 * @function getJumpPoints
 * @param {number} maxMics - The maximum number of microphones that can be placed.
 * @param {boolean} [isFlat=false] - Indicates if the placement surface is flat.
 * @returns {number[]} An array of jump points for microphone quantity.
 */
const getJumpPoints = (maxMics, isFlat = false) => {
  if (isFlat) {
    return Array.from({ length: maxMics }, (_, i) => i + 1);
  } else {
    return [1, ...Array.from({ length: Math.floor(maxMics / 2) }, (_, i) => (i + 1) * 2)]
  }
};

/**
 * Calculates microphone placement based on room parameters and microphone types.
 * @function calculateMicPlacement
 * @param {Object} area - The area object containing room and microphone properties.
 * @param {Object} area.microphones - Microphone configuration for the area.
 * @param {string} area.microphones.model - The model of the microphone.
 * @param {Object} area.microphones.coverage - Coverage settings for the microphones.
 * @param {number} area.microphones.qty - Total number of microphones (if pre-defined).
 * @param {Object} area.customized - Customization flags for the area.
 * @param {boolean} area.customized.microphones - Whether microphone placement has been customized.
 * @param {Object} area.location - Location of the area within the room.
 * @param {Object} area.dimensions - Dimensions of the area.
 * @param {Object} area.table - Table configuration (for table microphones).
 * @param {Object} micOverlaps - Object containing overlap and jump point data for microphones.
 * @param {number[]} micOverlaps.overlaps - Array of overlap values.
 * @param {number[]} micOverlaps.jumpPoints - Array of jump points for microphone quantity.
 * @param {Object} coverageBox - The coverage box dimensions.
 * @param {number} coverageRadius - The coverage radius of the microphone.
 * @param {number} scale - The scale factor for coordinate calculations.
 * @param {Object} constants - Constants used in the calculation.
 * @param {Object} constants.equipment - Equipment-specific constants.
 * @param {Object} constants.equipment.microphones - Microphone-specific constants.
 * @param {Object} constants.defOverlap - Default overlap settings.
 * @param {Object} constants.tables - Table-specific constants.
 * 
 * @returns {Object} An object containing the calculated quantity and coordinates of microphones.
 * @property {Object} qty - The quantity of microphones in x and y directions (for ceiling mics).
 * @property {Array<Object>} coords - Array of microphone coordinates and models.
 * @property {number[]} [tableJumpPoints] - Jump points for table microphones (only for table mics).
 * 
 * @description
 * This function calculates microphone placement based on the type of microphone (ceiling, table, or bar)
 * and the room configuration. It handles different scenarios:
 * - For ceiling microphones, it calculates a grid placement.
 * - For table microphones, it calculates placement along a path on the table.
 * - For bar microphones, it places a single microphone at a fixed position.
 * The function also handles customized placements and ensures minimum distance between table microphones.
 */
const calculateMicPlacement = (area, micOverlaps, coverageBox, coverageRadius, scale, constants) => {
  const { model, coverage, qty: totalMics } = area.microphones;
  const isCustomized = area.customized.microphones;
  const { location: { top, left }, dimensions: { width } } = area;
  const { overlaps, jumpPoints } = micOverlaps;
  const { isCeilingMic = false, isTableMic = false, isBarMic = false } = constants.equipment.microphones[model.replace('mic', '_mic')];
  const { defOverlap } = constants;
  let coords = [];
  let qty = { x: 1, y: 1 };
  if (isCeilingMic) {
    const micQty = totalMics > 0 ? totalMics : jumpPoints[overlaps.indexOf(closest(overlaps, defOverlap[findKey(coverage)]))];
    if (!isCustomized) {
      // calculate microphone spacing and quantity
      const spacing = mapValues({ x: 0, y: 0 }, () => coverageRadius * overlaps[jumpPoints.indexOf(micQty)]);
      qty = {
        x: max([1, round(coverageBox.length / spacing.x)]),
        y: max([1, round(coverageBox.width / spacing.y)])
      };
      // Calculate the mics coordinates 
      coords = calculateCoordinates(model, coverageBox, qty, spacing);
    };
  } else if (isTableMic) {
    const { endTable } = constants.tables[area.table.shape];
    const micPath = document.getElementById('micPath');
    let micPathLength = micPath?.getTotalLength() ?? 0;
    const { width, height } = micPath.getBBox();
    const isFlat = width * height === 0;

    // Esrtimate the maximum number of mics 
    let maxMics = Math.max(1, Math.floor(micPathLength / scale));
    maxMics = isFlat ? Math.ceil((endTable ? 1 : 2) + maxMics) / 2 : maxMics;

    // Get the gumPoints 
    const jumpPoints = getJumpPoints(maxMics, isFlat || endTable);

    // Estimate ideal starting number 
    let micQty = totalMics > 0 ? totalMics : (micPathLength * (isFlat ? .5 : 1)) / (1.34 * coverageRadius * scale) + (isFlat ? 1 : 0);
    micQty = closest(jumpPoints, micQty);

    // Calculagte microphone spacing
    const spacing = micQty > 1 ? (micPathLength * (isFlat ? .5 : 1)) / (micQty - ((isFlat || endTable) ? 1 : 0)) : 0;

    const areaCenter = {
      x: (area.location.left + area.dimensions.length) / 2,
      y: (area.location.top + area.dimensions.width) / 2
    };
    const tableCenter = {
      x: area.table.size.length / 2,
      y: area.table.size.width / 2
    }

    // Calculate Microphones 
    let micCoords = [];
    if (micPathLength > 0) {
      if (micQty === 1) {
        const halfWayPoint = micPath.getPointAtLength(micPathLength / 2);
        const middlePoint = {
          x: halfWayPoint.x / scale - tableCenter.x + areaCenter.x,
          y: halfWayPoint.y / scale - tableCenter.y + areaCenter.y
        }
        micCoords.push({
          model,
          location: {
            x: isFlat ? areaCenter.x : middlePoint.x,
            y: isFlat ? areaCenter.y : middlePoint.y
          }
        })
      } else {
        micCoords = Array.from({ length: micQty }, (_, i) => {
          const point = micPath.getPointAtLength(i * spacing);
          return {
            model,
            location: {
              x: point.x / scale - tableCenter.x + areaCenter.x,
              y: point.y / scale - tableCenter.y + areaCenter.y
            }
          };
        });
      };
    };

    // Merge close mics, TTM mics can't be closer than 1 meter
    micCoords.forEach((elem, index, array) => {
      const other = array.find(test =>
        Math.abs(test.location.x - elem.location.x) < 1 &&
        Math.abs(test.location.y - elem.location.y) < 1 &&
        test !== elem
      );

      if (other) {
        if (Math.abs(elem.location.x - other.location.x) <= 0.001) {
          elem.location.x = other.location.x = (elem.location.x + other.location.x) / 2;
        };
        if (Math.abs(elem.location.y - other.location.y) <= 0.001) {
          elem.location.y = other.location.y = (elem.location.y + other.location.y) / 2;
        };
      };
    });

    // Clean up duplicates
    micCoords = micCoords.filter((elem, index, array) =>
      index === array.findIndex(test =>
        Math.abs(test.location.x - elem.location.x) <= 0.001 && Math.abs(test.location.y - elem.location.y) <= 0.001
      )
    );

    return {
      coords: micCoords,
      qty: micCoords.length,
      tableJumpPoints: jumpPoints
    };
  } else if (isBarMic) {
    if (!isCustomized) {
      coords = [
        {
          modelName: model,
          location: {
            x: left + .134,
            y: top + width / 2
          }
        }];
    };
  };
  return { qty, coords };
};


/**
 * Calculates loudspeaker placement based on room parameters and constants.
 * @function
 * @param {Object} area - The area object containing room and speaker properties.
 * @param {number} spkrOverlap - The overlap value for speakers.
 * @param {Object} coverageBox - The coverage box dimensions.
 * @param {number} coverageRadius - The coverage radius of the speaker.
 * @param {Object} micQty - The quantity of microphones.
 * @param {Object} constants - Constants used in the calculation.
 * @returns {Array} An array of calculated speaker coordinates.
 */
const calculateSpeakerPlacement = (area, spkrOverlap, coverageBox, coverageRadius, micQty, constants) => {
  const { model, coverage } = area.speakers;
  const { speakers: isSpeakerCustom } = area.customized;
  const { location: { top, left }, dimensions: { width }, table: { shape } } = area;
  const { isCeilingSpkr = false, isBarSpkr = false } = constants.equipment.speakers[model];
  let spacing = mapValues({ x: 0, y: 0 }, () => coverageRadius * spkrOverlap);

  /**
   * Calculates the initial quantity of speakers based on coverage box dimensions and spacing.
   * @function
   * @inner
   * @returns {Object} An object with x and y properties representing the number of speakers in each direction.
   */
  const getSpeakerQuantity = () => {
    const box = mapKeys(pick(coverageBox, ['length', 'width']), (_, k) => k === 'length' ? 'x' : 'y');
    return mapValues(box, (dim, key) => {
      let count = max([1, round(dim / spacing[key]) - coverageBox.spkrAdj]);
      if (count === 1) return 1;
      const edgeToFirstSpeaker = dim / 2 - count / 2 * spacing[key] + spacing[key] / 2;
      return edgeToFirstSpeaker >= spacing[key] / 2 ? count : count - 1;
    });
  };

  /**
   * Adjusts the speaker quantity and spacing based on microphone quantity and room characteristics.
   * @function
   * @inner
   * @param {Object} spkrQty - The initial speaker quantity object with x and y properties.
   * @returns {Object} An object containing the adjusted quantity and spacing for speakers.
   * @property {Object} qty - The adjusted quantity of speakers with x and y properties.
   * @property {Object} spacing - The adjusted spacing between speakers with x and y properties.
   */
  const adjustSpeakerQuantity = (spkrQty) => {
    const { x: xMics, y: yMics } = micQty;
    let { x: xSpkrs, y: ySpkrs } = spkrQty;

    // Determine if table is in portrait orientation (taller than wide)
    const isPortrait = coverageBox.width > coverageBox.length;

    // Helper to adjust spacing after changing speaker count
    const updateSpacing = (dimension) => {
      if (shape && findKey(coverage) === "focused") {
        spacing[dimension] = coverageBox[dimension === 'x' ? 'length' : 'width'] / (dimension === 'x' ? xSpkrs : ySpkrs);
      }
    };

    // For single microphone case
    if (xMics * yMics === 1) {
      if (xSpkrs === 1 && ySpkrs === 1) {
        if (isPortrait) {
          ySpkrs += 1;
          updateSpacing('x');
        } else {
          xSpkrs += 1;
          updateSpacing('y');
        }
      }
    }

    // For two microphone case
    else if (xMics * yMics === 2) {
      const spkrCount = xSpkrs * ySpkrs;
      if (spkrCount === 2) {
        if (isPortrait) {
          xSpkrs += 1;
          updateSpacing('y');
        } else {
          ySpkrs += 1;
          updateSpacing('x');
        }
      }
      else if (spkrCount === 3) {
        if (isPortrait) {
          ySpkrs -= 1;
          xSpkrs += 1;
          updateSpacing('x');
        } else {
          xSpkrs -= 1;
          ySpkrs += 1;
          updateSpacing('y');
        }
      }
    }

    // For all other cases
    else {
      if (yMics * ySpkrs === 1) {
        if (isPortrait && xSpkrs > xMics) {
          xSpkrs -= 1;
          ySpkrs += 1;
          updateSpacing('y');
        } else if (!isPortrait && ySpkrs > yMics) {
          ySpkrs -= 1;
          xSpkrs += 1;
          updateSpacing('x');
        }
      }
    }

    return {
      qty: { x: xSpkrs, y: ySpkrs },
      spacing
    };
  };

  let coords = [];
  if (isCeilingSpkr) {
    if (!isSpeakerCustom) {
      const initialQty = getSpeakerQuantity();
      const { qty: adjustedQty, spacing: adjustedSpacing } = adjustSpeakerQuantity(initialQty);
      coords = calculateCoordinates(model, coverageBox, adjustedQty, adjustedSpacing);
    };
  } else if (isBarSpkr) {
    if (!isSpeakerCustom) {
      coords = [
        {
          modelName: model,
          location: {
            x: left + .134,
            y: top + width / 2
          }
        }];
    };
  }
  return coords
};

/**
 * Calculates camera placement based on room parameters and constants.
 * @function
 * @param {Object} area - The area object containing room and microphone properties.
 * @param {Object} constants - Constants used in the calculation.
 * @returns {Object} An object containing the calculated quantity and coordinates of microphones.
 */
const calculateCameraPlacement = (area) => {
  const { model, hasCamera } = area.cameras;
  const isCameraCustom = area.customized.cameras;
  const { location: { top, left }, dimensions: { width } } = area;
  let coords = [];
  if (hasCamera && !isCameraCustom) {
    coords = [
      {
        modelName: model,
        location: {
          x: left + .134,
          y: top + width / 2
        }
      }]
  } else {
    coords = []
  };
  return coords;
};

/**
 * Transforms an array of coordinates using a transformer function.
 * @function
 * @param {Array} coordinates - The array of coordinates to transform.
 * @param {Function} transformer - The function to use for transformation.
 * @param {string} [coordProperty=null] - The property name of the coordinate object, if applicable.
 * @returns {Array} The array of transformed coordinates.
 */
const transformCoordinates = (coordinates, transformer, coordProperty = null) => {
  return coordinates.map(item => {
    if (coordProperty && typeof item === 'object' && item.hasOwnProperty(coordProperty)) {
      return {
        ...item,
        [coordProperty]: transformer(item[coordProperty])
      };
    } else if (typeof item === 'object' && 'x' in item && 'y' in item) {
      return {
        ...item,
        ...transformer(item)
      };
    } else {
      return item; // Return unchanged if it doesn't match expected structure
    }
  });
};

/**
 * Finds the closest ceiling tile to a given set of coordinates.
 * @function
 * @param {Array} centers - An array of tile center coordinates.
 * @param {Object} coordinates - The x and y coordinates to find the closest tile to.
 * @returns {Object|undefined} The closest tile object or undefined if no suitable tile is found.
 */
const findClosestTile = (centers, { x, y }) => {
  // Get the grid size
  const qtyX = centers.filter(center => center.y === centers[0].y).length;
  const qtyY = centers.filter(center => center.x === centers[0].x).length;

  // Find the closest tile
  const tile = minBy(centers, center => Math.hypot((center.x - x), (center.y - y)));

  // get the index and tile id
  const index = centers.indexOf(tile);
  const idY = Math.floor(index / qtyX);
  const idX = index - idY * qtyX;

  /**
   * Calculates the center point of a set of tile centers and adds it to the array.
   * This is particularly useful for rectangular tiles.
   * @function
   * @inner
   * @param {Array} tileCenters - An array of tile center objects, each with x and y coordinates.
   * @returns {Array} The input array with an additional center point added.
   */
  const getCenter = (tileCenters) => {
    const sumX = tileCenters.reduce((sum, coord) => sum + coord.x, 0);
    const sumY = tileCenters.reduce((sum, coord) => sum + coord.y, 0);
    const middleX = sumX / tileCenters.length;
    const middleY = sumY / tileCenters.length;
    tileCenters.push({ x: middleX, y: middleY, blocked: tileCenters.some(tile => tile.blocked) });
    return tileCenters
  };

  // If tile is blocked, find closest tile around 
  if (tile.blocked) {
    // calculate the offset between mic and tile center 
    const offsetX = x - tile?.x;
    const offsetY = y - tile?.y;

    // Determine which surrounding tiles to check, 0.24384 is 40% of the mask size (i.e. .4 * 0.6096). Thi determines what adjacent tiles to consider
    const xAdj = Math.abs(offsetX) > .24384 ? (offsetX > 0 ? [0, 1] : [-1, 0]) : [-1, 1];
    const yAdj = Math.abs(offsetY) > .24384 ? (offsetY > 0 ? [0, 1] : [-1, 0]) : [-1, 1];

    // Create array of tiles to check
    const tilesToCheck = [];
    for (let y = max([idY + yAdj[0], 0]); y <= min([idY + yAdj[1], qtyY - 1]); y++) {
      for (let x = max([idX + xAdj[0], 0]); x <= min([idX + xAdj[1], qtyX - 1]); x++) {
        const index = y * qtyX + x
        if (!centers[index].blocked) tilesToCheck.push(centers[index]);
      };
    };

    // If there are no tiles to check, there's no solution, return 
    if (tilesToCheck.length === 0) return

    // find the closest tile in tilesToCheck that is not blocked
    let targetTile = minBy(tilesToCheck.filter(tile => !tile.blocked), tile => Math.hypot((tile.x - x), (tile.y - y)));

    // Check how many centers does the tile have. This is to refine position in rectangular tiles
    let tileCenters = tilesToCheck.filter(tile => tile.rx === targetTile.rx && tile.ry === targetTile.ry && !tile.blocked);

    // If there are more than one center it implies is  a rectangular tile. Add the center point and check which is closest
    if (tileCenters.length === 1) {
      return tileCenters.pop();
    } else if (tileCenters.length > 1) {
      tileCenters = getCenter(tileCenters)
      return minBy(tileCenters.filter(tile => !tile.blocked), tile => Math.hypot((tile.x - x), (tile.y - y)))
    };
  };
  // If the tile is not blocked check correct center if tile is rectangular
  if (tile.mask === 0) {
    return tile;
  } else {
    const closest = centers.filter(center => trimEnd(center.id, "_-10") === trimEnd(tile.id, "_-10"));
    const closeCenters = getCenter(closest)
    return minBy(closeCenters.filter(tile => !tile.blocked), tile => Math.hypot((tile.x - x), (tile.y - y)))
  };
};

/**
 * Blocks tiles around a used tile in the ceiling grid.
 * @function
 * @param {Array} centers - An array of tile center coordinates.
 * @param {Object} tile - The tile object around which to block other tiles.
 * @returns {Array} The updated array of tile centers with blocked tiles marked.
 */
const blockTiles = (centers, tile) => {
  // Get the grid size
  const qtyX = centers.filter(center => center.y === centers[0].y).length;

  /**
   * Blocks tiles around a given base index in the centers array.
   * @function
   * @inner
   * @param {number} baseIndex - The index of the central tile to block around.
   * @param {boolean} [isHorizontal=true] - Whether to block horizontally adjacent tiles.
   * @param {boolean} [isVertical=true] - Whether to block vertically adjacent tiles.
   */
  const block = (baseIndex, isHorizontal = true, isVertical = true) => [[0, 0], [-1, 0], [1, 0], [0, -1], [0, 1]].map(([x, y]) => {
    const index = baseIndex + (isVertical ? x : 0) + (qtyX * (isHorizontal ? y : 0));
    if (inRange(index, 0, centers.length)) centers[index].blocked = true;
    return null
  });

  // If the tile has an id, get the index and block adjacents
  // If not, find the ids of tile and block
  if (tile.id) {
    const baseIndex = centers.indexOf(tile);
    block(baseIndex);
  } else {
    const closest = minBy(centers, center => Math.hypot((center.x - tile.x), (center.y - tile.y)));
    const closeSet = centers.filter(tile => trimEnd(tile.id, "_-10") === trimEnd(closest.id, "_-10"));
    const indices = closeSet.map(tile => centers.indexOf(tile));
    const isHorizontal = Math.abs(indices[0] - indices[1]) === 1;
    indices.map(baseIndex => block(baseIndex, isHorizontal, !isHorizontal));
  };
  return centers
};













