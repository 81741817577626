// These are dialog patterns that repeat. passed as dialog it gets merged so it can be customized
const baseDialogs = {
  spinner: {
    header: 'Spinner',
    body: [
      {
        element: 'plainText',
        type: 'p',
        content: 'Spinner text'
      },
      {
        element: 'spinner',
        extend: true
      }
    ],
    footer: []
  },

  warning: {
    header: 'Warning!',
    body: [
      {
        element: 'errorContent',
        extend: true
      }
    ],
    footer: [
      {
        label: 'OK',
        target: '',
        dispatcher: { name: 'closeWarning'} //, value: true }
      },
    ]
  },
};

// These are global dialogs 
const dialogs = {
  ...baseDialogs,
  // Based dialog overrides
  calculating: {
    dialog: 'spinner',
    header: 'Calculating...',
    body: [{ content: 'Calculating speaker layout...' }]
  },
  saving: {
    dialog: 'spinner',
    header: 'SAVING...',
    body: [{ content: 'Saving file to server...' }]
  },
  loading: {
    dialog: 'spinner',
    header: 'LOADING...',
    body: [{ content: 'Loading file...' }]
  },
  exporting: {
    dialog: 'spinner',
    header: 'EXPORTING...',
    body: [{ content: 'Generating PDF Content...' }]
  },

  // Other common dialogs
  oldRevision: {
    header: 'Warning!',
    body: [
      {
        element: 'valueContent',
        parameter: 'setRevision',
        content: ['Requested revision ', ' is not the latest. Which revision would you like to load?'],
        extend: true
      },
      {
        element: 'checkBox',
        className: 'ms-2',
        items: ['requested_revision', 'latest_revision'],
        extend: true,
        dispatcher: 'setRequested'
      },
    ],
    footer: [
      {
        label: 'OK',
        target: '',
        dispatcher: { name: 'setReload' },
      },
    ]
  },

  unknowRevision: {
    header: 'Warning!',
    body: [
      {
        element: 'plainText',
        type: 'p',
        content: "Requested revision doesn't exist. Would you like to load the latest revision?",
        extend: true
      },
      {
        element: 'checkBox',
        className: 'ms-2',
        extend: true,
        dispatcher: 'setIsLatest'
      },
    ],
    footer: [
      {
        label: 'OK',
        target: '',
        dispatcher: { name: 'setLoadLatest' },
      },
    ]
  },

  saveSuccesful: {
    header: 'Save succesful!',
    body: [
      {
        element: 'plainText',
        type: 'p',
        content: "Project has been saved and save parameters have been copied to the URL in your browser's address bar and the clipboard.",
        extend: true
      },
    ],
    footer: [
      {
        label: 'OK',
        target: '',
      }
    ]
  },

  resetCustomization: {
    dialog: 'warning',
    footer: [
      {
        label: 'CANCEL',
        target: '',
        dispatcher: { name: 'updateArea', value: false}
      },
      {
        label: 'OK',
        target: '',
        dispatcher: { name: 'updateArea', value: true }
      },
    ]
  },





}

export default dialogs