import { useRef, useEffect, useMemo } from "react";
import {debounce} from "lodash";

// Adds a little debounce to entry inputs to prevent false error triggering
const useDebounce = (callback) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = callback;
  }, [callback]);
  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };
    return debounce(func, 500);
  }, []);
  return debouncedCallback;
};

export default useDebounce