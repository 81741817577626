/**
 * Initial state configuration for the Conference Room Designer.
 * @module initialState
 */

/**
 * Generates the initial state for a conference room area.
 * @function
 * @param {Object} state - The current state (unused in this function).
 * @returns {Object} An object representing the initial state of a conference room area.
 */

const initialState = {
  /**
   * Creates an initial area object for a conference room.
   * @function
   * @param {Object} state - The current state (unused in this function).
   * @returns {Object} An object containing the initial properties for a conference room area.
   */
  area: (state) => {
    return ({
      id: "",
      name: "",
      areaType: "room",
      dimensions: { length: 6.096, width: 4.2672 },
      location: { left: 0, top: 0 },
      ceilingHeight: { min: 2.8956, max: 2.8956 },

      /**
       * areaOrientation is not currently used for other than the UI. This might change or deleted
       */
      areaOrientation: 'lengthwise',

      // Door default configuration
      door: {
        side: 3,
        scale: [1, 1],
        angle: 0,
        x: null,
        y: null,
      },

      // Ceiling tiles default configuration
      ceilingTiles: {
        tile: "no_ceiling_tiles",
        qty: { x: 0, y: 0 },
        transform: { x: 0, y: 0, a: 0 },
        checked: []
      },

      // Table default configuration
      table: {
        shape: null,
        size: { length: 0, width: 0 },
        transform: { x: 0, y: 0, a: 0 },
        ghostChairs: [],
      },

      // Room acoustics default configuration
      acoustics: 'good',

      // Microphones default configuration
      microphones: {
        model: 'ceilingmic',
        coverage: {
          full_room: true,
          focused: false,
        },
        height: 2.8956,
        maxHeight: 4.0284, // default value at good acoustics
        micDistanceLegendMax: 6, // default value at given init state
        micMaxRadius: 2.4755, // default value at given init state
        coverageDensity: {
          value: 1,
          jumpPoints: [],
          overlaps: []
        },
        qty: 0,
        layout: []
      },

      // Speakers default configuration
      speakers: {
        model: 'ceilingspkr',
        coverage: {
          full_room: false,
          focused: true,
        },
        height: 2.8956,
        coverageDensity: {
          value: 1,
          jumpPoints: [],
          overlaps: []
        },
        qty: 0,
        layout: []
      },

      // Cameras default configuration
      cameras: {
        model: 'standAlone',
        coverageRadius: 5,
        coverageAngle: 56.5,
        hasCamera: true,
        qty: 0,
        layout: []
      },

      // Other room properties 
      productFamily: 'Tesira',

      // note: temporary flags - don't remove just yet
      recalculate: false,
      remap: false,

      // Customization flags 
      customized: {
        microphones: false,
        speakers: false,
      }
      
    })
  }
};

export default initialState;