import { pdf } from '@react-pdf/renderer';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useContext, useEffect, useState } from 'react';
import Button from "components/molecules/Button";
import ExportDocument from "components/templates/ExportDocument";
import ReducersIndex from "reduxModules/ducks";
import { getCurrentValue, getRecalculate, getRemap } from "reduxModules/ducks/container";
import { buttonThemes } from "globalConstants/Themes";
import ToolContext from 'components/services/ToolContext';

const Export = (props) => {
  const dispatch = useDispatch();
  const store = useStore();
  const context = useContext(ToolContext);
  const report = context.reportDocument;
  const constants = context.constants;
  const tab = useSelector(state => state.ui.tabs[0].id);

  const recalculate = useSelector(getRecalculate);
  const remap = useSelector(getRemap);
  const saved = useSelector(state => getCurrentValue(state, 'savekey'))
  const [exportReady, setExportReady] = useState(false);
  const [resave, setResave] = useState(false);

  useEffect(() => {
    if (exportReady) {
      dispatch(ReducersIndex.uiReducers.setActiveTab(tab));
      dispatch(ReducersIndex.uiReducers.setCurrentView('speakers'));
      dispatch(ReducersIndex.uiReducers.setShowMap('directSPL'));
      if (!saved) {
        props.setSilentSave(true);
      } else if (recalculate || remap ) {
        setResave(true);
      } else {
        if(resave) props.setSilentSave(true);
        setTimeout(openPdf, 100);
        setResave(false);
      };
    };
  }, [recalculate, remap, exportReady, saved]);

  const openPdf = async () => {
    dispatch(ReducersIndex.uiReducers.openModal('exporting'));
    const blob = await pdf(<ExportDocument report={report} store={store} constants={constants} />).toBlob();
    const pdfURL = URL.createObjectURL(blob);
    const newWin = window.open(pdfURL, '_blank');
    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      alert('The export document was blocked by your browser.\nPlease allow pop-ups from this page')
    }
    setExportReady(false)
    dispatch(ReducersIndex.uiReducers.closeModal('exporting'));
  };

  return (
    <Button theme={buttonThemes.nav_red} label={"EXPORT"} onClick={() => setExportReady(true)} />
  )
};

export default Export;