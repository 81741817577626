import 'bootstrap/dist/css/bootstrap.min.css';
// import "bootstrap/dist/js/bootstrap.bundle.min";
import './style.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { storeInit } from 'reduxModules/storeConfig';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import Main from 'components/templates/Main';
import toolIndex from 'toolConstants';

import { values } from 'lodash';

const store = storeInit(); // Adding a preloaded state, replaces the initial state 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />} >
            {values(toolIndex).map((tool, _) => <Route path={tool.constants.tool.path.long} key={`${tool.constants.tool.name}-long`} element={<Main tool={tool} />} />)}
          </Route>
          <Route path='/' element={<App hide />}  >
            {values(toolIndex).map((tool, _) => <Route path={tool.constants.tool.path.short} key={`${tool.constants.tool.name}-short`} element={<Main tool={tool} />} />)}
            <Route path='*' element={<h1 className='text-center mt-5'>The Design Tool you're requesting does not exist!</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
);
