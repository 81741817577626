import styled from 'styled-components';
import Logo from 'globalConstants/Icons/Logo';

const Spinner = () =>
  <Spinner.Wrapper>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <Spinner.Spin>
      <span className="visually-hidden">Loading...</span>
    </Spinner.Spin>
  </Spinner.Wrapper>

Spinner.Wrapper = styled.div.attrs(props => ({
  className: 'd-flex justify-content-center py-3',
}))`
  position: relative
`;

Spinner.Spin = styled.div.attrs(props => ({
  className: 'spinner-border',
  role: 'status'
}))`
  color: #000;
  width: 4rem;
  height: 4rem;
  border-width: .2rem
`;

const LogoWrapper = styled.div.attrs(props => ({
  className: 'inner-logo',
}))`
vertical-align: middle;
    position: absolute;
    transform: scale(.5);
    top: -26px;
    left: 265px;
`;

export default Spinner;

