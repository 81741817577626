import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReducersIndex from "reduxModules/ducks";
import Button from "components/molecules/Button";
import Payload from "components/services/Payload";
import { buttonThemes } from "globalConstants/Themes";
import useSetURLparams from "hooks/useSetURLParams";
import useFetch from "hooks/useFetch";
import { getRecalculate, getRemap } from "reduxModules/ducks/container";

const Save = (props) => {
  const {silentSave, setSilentSave, setSaved} = props;
  const tab = useSelector(state => state.ui.tabs[0].id);
  const recalculate = useSelector(getRecalculate);
  const remap = useSelector(getRemap);
  const [click, setClick] = useState(false)
  
  useEffect(() => {
    if (click) {
      if (recalculate || remap ) {
        dispatch(ReducersIndex.uiReducers.setActiveTab(tab));
        dispatch(ReducersIndex.uiReducers.setCurrentView('speakers'));
        dispatch(ReducersIndex.uiReducers.setShowMap('directSPL'));
      } else {
        handleClick();
        setClick(false);
      };
    }
  }, [recalculate, remap, click]);

  const payload = Payload('saveKeys');
  const { connecting, response, error, handleClick } = useFetch("https://supportdata.biamp.com/py/save.py", payload);
  useSetURLparams();

  const dispatch = useDispatch();

  // Handle silent save
  const [silent, setSilent] = useState(false);
  useEffect(() => {
    if (silentSave) {
      setSilent(true);
      setSaved(false) // new
      handleClick();
    }
  }, [silentSave]);

  useEffect(() => {
    if (!silent) {
      setSilentSave(false)
      setSaved(true) // new
    }
  }, [silent])



  // Show saving dialog
  useEffect(() => {
    if (connecting && !silent) {
      dispatch(ReducersIndex.uiReducers.openModal('saving'));
  };
  // eslint-disable-next-line
  }, [connecting])

  // Handle the response
  useEffect(() => {
    if (response) {
      dispatch(ReducersIndex.uiReducers.closeModal('saving'));
      const receivedPayload = JSON.parse(response);

      if (receivedPayload.success) {
        dispatch(ReducersIndex.uiReducers.setParams(JSON.parse(response)));
        if (!silent) dispatch(ReducersIndex.uiReducers.openModal('saveSuccesful'));
      } else {
        alert(`An error occurred while sending data to the server. Error code: ${receivedPayload.errornumber} ${receivedPayload.errortext}.`);
      };
      setSilent(false);
    };
  // eslint-disable-next-line
  }, [response])

  // Copy URL to clipboard 
  const isDone = useSelector(state => state.ui.modals.find(modal => modal.modalName === 'saveSuccesful' && modal.modalOpen));
  useEffect(() => {
    if (isDone) navigator.clipboard.writeText(window.location.href);
  }, [isDone]); 

  // Handle network errors 
  // todo: this is curently using standard alert. should use an error modal
  useEffect(() => {
    if (error) {
      dispatch(ReducersIndex.uiReducers.closeModal('saving'));
      alert('An unknown error ocurred while sending data to the server.');
      setSilent(false);
    };
  // eslint-disable-next-line
  }, [error]);

  // removed handleclick
  return (
    <Button theme={buttonThemes.nav_black} label={"SAVE"} onClick={() => setClick(true)} />
  )
}

export default Save;