import styled from "styled-components";
import { useSelector } from "react-redux";
import { getConditionResult } from "reduxModules/ducks/container";
import { getDrawingBox } from "reduxModules/ducks/ui";

const Floorplan = (props) => {
  const area = props.children;

  const {bbox, scale, zoom} = useSelector(getDrawingBox);
  const activeTab = useSelector(state => state.container.activeTab)

  let lines = [];
  let isArea;
  if (area) {
    isArea = area.id === activeTab;
    const { length, width } = area.dimensions;
    const { left = 0, top = 0 } = area?.location || {};
    const { top: tw = true, right: rw = true, bottom: bw = true, left: lw = true } = area?.surroundingWalls || {};
    const { x0: OriginX, y0: OriginY } = bbox
    if (length * width > 0) {
      lines = [...Array(2)].flatMap((x, index) => {
        const odd = index % 2;
        const sign = odd ? -1 : 1;
        const x0 = OriginX + (!zoom * left + odd * length) * scale;
        const y0 = OriginY + (!zoom * top + odd * width) * scale;
        const x1 = x0 + sign * length * scale; 
        const y1 = y0 + sign * width * scale;
        return [{ d: `m${x0} ${y0}H${x1}`, dash: odd ? bw : tw, bg: odd ? bbox.y1 - y1 - y0 <= 1 : bbox.y0 - y0 <= 1 },
         { d: `m${x0} ${y0}V${y1}` , dash: odd ? rw : lw , bg: odd ? bbox.x1 -x1 - x0 <= 1: bbox.x0 - x0 <= 1}]
      });
    };
  };

  const visible = useSelector(state => getConditionResult(state, {...props.show, area: area.id}));
  const active = useSelector(state => getConditionResult(state, {...props.active, area: area.id}));

  return (
    visible && <Floorplan.Group id={`${area.id || 'container'}-floorplan`}>
      {active && lines.map((line, index) => <Floorplan.Back key={`line-${index}-back`} {...line} />)}
      {lines && lines.map((line, index) => <Floorplan.Path key={`line-${index}`} {...line} active={active} isArea={isArea}/>)}
    </Floorplan.Group>
  )
};

Floorplan.Group = styled.g.attrs(props => ({
  id: props.id
}))`
  fill: none;
  stroke-width: 2;
  shape-rendering: crispedges
`;

Floorplan.Back = styled.path.attrs(line => ({
  d: line.d
}))`
  stroke: #fff;
  opacity: ${props => props.bg? '.5' : '1'};
`;

Floorplan.Path = styled.path.attrs(line => ({
  d: line.d
}))`
  stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-dark-gray)'};
  stroke-dasharray: ${props => props.dash ? 0 : 12};
  opacity: ${props => props.bg && props.isArea ? '1' : '1'};
`;

export default Floorplan
