import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import ReducersIndex from 'reduxModules/ducks';
import styled from "styled-components";
import Button from "components/molecules/Button";
import Attention from 'components/atoms/Attention';
import Tutorial from 'components/services/Tutorial';
// import { buttonThemes } from "globalConstants/Themes";

// todo: pencil action
// todo: question action
// todo: request quote action
const ToolHeader = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  
  // note: temporary uintil release 
  

  return (
  <ToolHeader.Wrapper ref={ref}>
    <h4 className={'col text-center py-0 my-1 fw-bold'} >{props.project}</h4>
    <div className="col-sm-auto hstack gap-1 p-0 align-items-unset">
      <Attention />
      <Button name={'edit'} onClick={() => dispatch(ReducersIndex.uiReducers.openModal('editProject'))}/>

      <Tutorial />
      {/* <Button name={'help'} state={'button_active'} onClick={() => alert(`Current API Version: ${apiVersion}`)}/> */}

      {/* <Button theme={buttonThemes.nav_red} label={'REQUEST QUOTE'} onClick={() => alert('you clicked the quote!')}/> */}
    </div>
  </ToolHeader.Wrapper>
)});

ToolHeader.Wrapper = styled.div.attrs(props => ({
  className: 'row justify-content-between',
}))`
  max-height: 38px;
  border: none;
  background: var(--app-light-gray);
  font-size: 18px
`;

export default ToolHeader;