import {lazy } from 'react';


import Floorplan from "components/elements/Floorplan";
import LineDimension from "components/elements/LineDimension";
import Door from "components/elements/Door";
// import Ceiling from "components/elements/Ceiling";
// import Furniture from 'components/elements/Furniture';


// import LoadingDocks from "components/elements/LoadingDocks";
import Roof from "components/elements/Roof";
import RoofGrid from "components/elements/RoofGrid";
import Storage from "components/elements/Storage";
import {Speakers, Microphones, Horns, Cameras} from "components/elements/Devices";
import Overlaps from "components/elements/Overlaps";




const ElementsIndex = {
  floorplan: Floorplan,
  lineDimension: LineDimension,
  door: Door,

  ceiling: lazy(() => import("components/elements/Ceiling")),
  table: lazy(() => import("components/elements/Table")),

  loadingDocks: lazy(() => import("components/elements/LoadingDocks")), //LoadingDocks,

  roof: Roof,
  roofgrid: RoofGrid,
  storage: Storage,
  overlaps: Overlaps,
  horns: Horns,
  speakers: Speakers,
  microphones: Microphones,
  cameras: Cameras

};

export default ElementsIndex;

