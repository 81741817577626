import styled from 'styled-components';

const Input = (props) => {
  const type = props.type ?? 'text';
  const lock = type === 'text' && !props.isEntry
  props = {
    ...props,
    type: type,
    width: props.isEntry || lock ? ['text', 'number'].includes(type) ? '70%' : '15%' : '0%',
    height: props.isEntry || lock ? 'auto' : '0px',
    padding: !props.isEntry && '0px',
    lock: lock,
  };
  return (
    <Input.InputField {...props} />
  )
};

Input.InputField = styled.input.attrs(props => ({
  type: props.type,
  name: props.name,
  maxLength: '32',
  autoComplete: 'off',
  tabIndex: !['text', 'number'].includes(props.type) || props.lock ? -1 : 0,
  readOnly: props.lock,
  min: 0,
}))`
  width: ${props => props.width};
  max-width: ${props => props.type === 'number' ? props.isNav ? '70px': '100px' : 'auto'};
  height: ${props => props.height};
  line-height: ${props => props.theme.lineHeight};
  margin: 0px;
  outline: none;
  display: block;
  padding: ${props => props.padding};
  background: ${props => props.lock ? 'white' : props.theme.inputBackground ?? 'white'};
  border: ${props => props.error ? '1px solid var(--app-red)' : 'none'};
  border-radius: ${props => props.error ? '4px' : '0px'};
`;

export default Input;
