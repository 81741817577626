// import styled, { ThemeProvider } from 'styled-components';
import { useState, useEffect, useRef, cloneElement } from 'react';
import Button from "components/molecules/Button";
import { DotButtons } from 'components/molecules/ButtonGroup';

import Modal from 'components/templates/Modal';

const content = [
  {
    content: "This is just plain text inside the carousel whic represents the text I'd display in the tutorial "
  }, {
    content: "Second slide: this is also plain text inside the carousel"
  }, {
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  }
]




const Carousel = (props) => {

  const Cloned = cloneElement(<Modal modalName={'warehouse'} />)


  const items = Array.from(content, (_, i) => i.toString());

  const [slide, setSlide] = useState(items[0]);
  const myRef = useRef(null);

  // Reset the carrousel on load
  useEffect(() => {
    if (myRef.current) {
      const children = Array.from(myRef.current.children);
      children.map((child, index) => child.className = `carousel-item ${index === 0 ? 'active' : ''}`);
      setSlide(items[0])
    };
  }, [props.show]);

  // Get the slide number when slidding 
  const handleClick = (evt) => {
    if (myRef.current) {
      const children = Array.from(myRef.current.children);
      const slide = children.findIndex(x => x.classList.contains('active'));
      setSlide(slide.toString());
    };
  };



  return (

    <div id={props.id} className="carousel" data-bs-ride={false} data-bs-wrap={false} >

      <div className='vstack'>

        <div className='hstack'>

          <Button name={'left'} target={`#${props.id}`} slide={"prev"} onClick={(e) => handleClick(e)} />

          <div ref={myRef} className="carousel-inner mx-5" >

            {content.map((item, index) => (
              <div key={`item-${index}`} className="carousel-item">
                {/* {index === 0 ? Cloned : <div className="d-block ">{item.content}</div>} */}
                {/* <div className="d-block ">{index === 0 ? MM : item.content}</div> */}
                <div className="d-block ">{item.content}</div>
              </div>
            ))}

          </div>

          <Button name={'right'} target={`#${props.id}`} slide={"next"} onClick={(e) => handleClick(e)} />

        </div>

        <DotButtons target={`#${props.id}`} items={items} current={slide} />

      </div>

    </div>
  )
};

export default Carousel;

