import ReducersIndex from "reduxModules/ducks";
import { isInside } from "utilities/geometric";

const actions = {
  floorplan: {
    container: ['ghostdock'],
  },
  acoustics: {
    container: ['position', 'level'],
    area: ['position', 'level']
  },
  designpriority: {
    area: ['position', 'level', 'remove']
  },
  speakers: {
    container: ['position', 'level'],
    area: ['position', 'level']
  },
  equipmentlist: {}
};

export const conditions = {
  position: { parameter: ['coverage', 'activeTab'], check: ([coverage, activeTab]) => activeTab === '_warehouse' || !coverage.none },
  level: { parameter: ['coverage', 'activeTab'], check: ([coverage, activeTab]) => activeTab === '_warehouse' || !coverage.none },
  remove: { parameter: ['coverage'], check: ([coverage]) => !coverage.none}
};

export const actionDispatchers = {
  ghostdock: (_, dispatch, mouse) => {
    const target = document.getElementById('loadingdocks');
    const dock = Array.from(target.children).find(child => isInside(mouse, child.getBoundingClientRect()));
    if (dock) dispatch(ReducersIndex.container.setGhostDocks(dock.id));
  },
  remove: (evt, dispatch) => {
    const target = evt.target.closest('[class=speakers]')?.id;
    if (target) {
      const [area, _, speakerId] = target.split('-');
      dispatch(ReducersIndex.container.deleteSpeaker({area: area, id: speakerId}));
    };
  },
};

export default actions;
