

//NEW CODE
import { configureStore, combineReducers, combineSlices } from "@reduxjs/toolkit";
import { Middlewares } from "reduxModules/middleware";
import container from "reduxModules/ducks/container";
import ui from "reduxModules//ducks/ui";

// Note: This code allows inseerting new slices (see ceiling slice and code in main). It does not add the new stuff to existing slice


const staticReducers = {
  container: container,
  ui: ui,
};

const createReducer = (asyncReducers) => combineReducers({
  ...staticReducers,
  ...asyncReducers
})


export const storeInit = (preloadedState) => {
  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(Middlewares),
    preloadedState,
    //devTools: process.env.NODE_ENV !== "PRODUCTION",
  });

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {}

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.asyncReducers))
  }

  return store;
};