import styled from "styled-components";
import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/molecules/Button";
import EditableButton from "components/molecules/EditableButton";
import UnitSwitch from "components/molecules/UnitSwitch";

import ToolContext from "components/services/ToolContext";
import { buttonThemes } from "globalConstants/Themes";
import ReducersIndex from 'reduxModules/ducks';

import Save from "components/services/Save";
import Export from "components/services/Export";

const ControlBar = () => {
  const tool = useContext(ToolContext);
  const tabs = useSelector(state => state.ui.tabs);
  const activeTab = useSelector(state => state.ui.activeTab);
  const [silentSave, setSilentSave] = useState(false);
  const [saved, setSaved] = useState(false)

  useEffect(() => {
    setSaved(false)
  }, [])
  // up to here 


  const dispatch = useDispatch();

  const handleTabClick = (evt, tab) => {
    dispatch(ReducersIndex.uiReducers.setActiveTab(tab));
    if (tool.constants.tool.containerName && tabs.at(0).id === tab) dispatch(ReducersIndex.uiReducers.setZoom(false));
  };

  const handleAdd = (evt) => {
    dispatch(ReducersIndex.container.addArea());
    dispatch(ReducersIndex.uiReducers.openModal('area'));
  };

  const showAdd = useSelector(state => state.ui.allowAddArea);   

  return (
    <ControlBar.Wrapper>
      <div className={"col-9 hstack gap-0 p-0 fw-bold mw-75"}>
        {tabs.map((tab, index) => (
          <EditableButton key={`${tab.id}-${index}`} id={tab.id} active={activeTab === tab.id} onClick={(e) => handleTabClick(e, tab.id)} />
        ))}
        {showAdd && (<Button className="fs-6" theme={buttonThemes.nav_light} label={`ADD ${tool.constants.tool.addAreaLabel} +`} onClick={handleAdd} />
        )}
      </div>
      <div className="col-3 hstack gap-0 p-0 d-flex justify-content-end fs-6">
        {/* todo: all these need their actions  */}
        <UnitSwitch nomargin />

        {/* <Button theme={buttonThemes.nav_light} label={"RESET"} onClick={() => alert("you clicked reset!")} /> */}
        
        <Save silentSave={silentSave} setSilentSave={setSilentSave} setSaved={setSaved}/>
        <Export setSilentSave={setSilentSave} saved={saved} />
      </div>
    </ControlBar.Wrapper>
  );
};
ControlBar.Wrapper = styled.div.attrs((props) => ({
  className: "row justify-content-between",
}))`
    max-height: 38px;
    border: 1px solid #000;
    border-style: solid none solid none;
    font-size: 22px;
`;

export default ControlBar;

// const searchNestedObject = (obj, key) => {
//   let result = [];

//   const search = (obj, key) => {
//     for (const k in obj) {
//       if (k === key) {
//         result.push({ [k]: obj[k] });
//       } else if (typeof obj[k] === "object") {
//         search(obj[k], key);
//       }
//     }
//   }

//   search(obj, key);
//   return result;
// }
