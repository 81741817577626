  const SystemUsage = {
  paging_bgm: ({ state }) =>
    <g strokeLinecap="round" stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='2'>
      <circle cx='6' cy='6' r='1' />
      <path d='m11 6a5 5 0 0 1-5 5m0 4a9 9 0 0 0 9-9m4 0a13 13 0 0 1-13 13m-1 20c.5-6 14.5-6 15 0m-8-13.5a1 1 0 0 1 0 6 1 1 0 0 1 0 -6
      m18 13.5c.5-6 14.5-6 15 0m-8-13.5a1 1 0 0 1 0 6 1 1 0 0 1 0 -6m-22 19c1-8 19-8 20 0m-10-17.5a1 1 0 0 1 0 8 1 1 0 0 1 0 -8' fill='none' />
      <path d='m25 20a.6 .4 0 0 1-3.6 0 .6 .4 0 0 1 3.6 0v-8h-.4v7.2zm7.2-3a.6 .4 0 0 1-3.6 0 .6 .4 0 0 1 3.6 0v-8h-.4v7.2zm0-8.4l-7.6 3.6v-1.5l7.6-3.6v2.4
      m6 10.5a.6 .4 0 0 1-3.6 0 .6 .4 0 0 1 3.6 0v-8h-.4v7.2zm0.5-8 l2.2 -1.2v-1.5l-3.1 1.5v2.4z' />
    </g>,

  paging: ({ state }) =>
    <g strokeLinecap="round" stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='2'>
      <circle cx='6' cy='6' r='1' />
      <path d='m11 6a5 5 0 0 1-5 5m0 4a9 9 0 0 0 9-9m4 0a13 13 0 0 1-13 13m-1 20c.5-6 14.5-6 15 0m-8-13.5a1 1 0 0 1 0 6 1 1 0 0 1 0 -6
      m18 13.5c.5-6 14.5-6 15 0m-8-13.5a1 1 0 0 1 0 6 1 1 0 0 1 0 -6m-22 19c1-8 19-8 20 0m-10-17.5a1 1 0 0 1 0 8 1 1 0 0 1 0 -8' fill='none' />
    </g>,

  bgm: ({ state }) =>
    <g strokeLinecap="round" stroke={state.icon_stroke} fill={state.icon_fill} strokeWidth='2'>
      <circle cx='6' cy='6' r='1' />
      <path d='m11 6a5 5 0 0 1-5 5m0 4a9 9 0 0 0 9-9m4 0a13 13 0 0 1-13 13' fill='none' />
      <path d='m17 40a.75 .5 0 0 1-4.5 0 .75 .5 0 0 1 4.5 0v-10h-1v9zm9-3.75a.75 .5 0 0 1-4.5 0 .75 .5 0 0 1 4.5 0v-10h-1v9zm0-10.5l-10 4.5v-2.5l10-4.5v3
      m8 14.25a.75 .5 0 0 1-4.5 0 .75 .5 0 0 1 4.5 0v-10h-1v9zm-1-9.5l5 -2.25v-1.25l-5 2.25v1.5' />
    </g>,
  };

  export default SystemUsage;