  const Walls = {
  leftwall: ({ state }) =>
    <path d="m5 5v40" strokeWidth='3' stroke={state.icon_stroke} shapeRendering={'crispEdges'} />,

  topwall: ({ state }) =>
    <path d="m0 8h50" strokeWidth='3' stroke={state.icon_stroke} shapeRendering={'crispEdges'} />,

  rightwall: ({ state }) =>
    <path d="m45 5v40" strokeWidth='3' stroke={state.icon_stroke} shapeRendering={'crispEdges'} />,

  bottomwall: ({ state }) =>
    <path d="m0 42h50" strokeWidth='3' stroke={state.icon_stroke} shapeRendering={'crispEdges'} />,
  };

  export default Walls;