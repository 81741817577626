/**
 * @module DeviceDrawing
 * @description Module for rendering various audio devices (speakers, microphones, horns) in a SVG context.
 */

import styled from "styled-components";
import { useMemo, useContext, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getDrawingBox } from "reduxModules/ducks/ui";
import { getConditionResult, getCurrentValue2 } from "reduxModules/ducks/container";
import ReducersIndex from 'reduxModules/ducks';
import ToolContext from "components/services/ToolContext";
import icons from "globalConstants/Icons";
import { iconThemes } from "globalConstants/Themes";
import useMousePosition from "hooks/useMousePosition";
import { clamp, has, mapKeys } from "lodash";

/**
 * @function Devices
 * @description Renders and manages interactive device icons with move functionality.
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the device group
 * @param {string} props.type - Type of device ('speakers', 'microphones')
 * @param {string} props.model - Model of the device
 * @param {string} props.icon - Icon identifier
 * @param {Object} props.origin - Origin coordinates {left, top}
 * @param {Object} props.devices - Device data (object or layout array)
 * @param {Object} props.show - Condition for showing the devices
 * @param {Object} props.active - Condition for setting devices as active
 * @returns {React.Element} SVG group element containing interactive device icons
 */
const Devices = (props) => {
  const { bbox, scale, zoom, iconAdj } = useSelector(getDrawingBox);
  const { id, type, model, origin: { left, top }, devices, icon, show, active: isActive } = props;
  const deviceType = icon;
  const x0 = bbox.x0 + left * scale * (!zoom);
  const y0 = bbox.y0 + top * scale * (!zoom);

  const Icon = useMemo(() => icons[icon], [icon]);
  const iconSize = 46;
  const iconScale = Math.max(10 / iconSize, scale * iconAdj); //wd = 10 & 50 CRD=180 

  const layout = has(devices, 'layout') ? devices.layout : devices;
  const layoutArray = layout.map((dev, index) => ({
    id: `${id}-${type}-${index}`,
    transform: `translate(${x0 + dev.location.x * scale}, ${y0 + dev.location.y * scale}) scale(${iconScale})`,
    angle: dev.horAngle !== undefined ? dev.horAngle - 90 : 0,
  }))

  const visible = useSelector(state => getConditionResult(state, { ...show, area: id }));
  const active = useSelector(state => getConditionResult(state, { ...isActive, area: id }));

  const Arrow = icons['speakerArrow'];
  const shoudShowArrow = devices.length > 0 ? 'horAngle' in devices[0] : false;

  // Get the current action to set whether the icons will receive pointer events or not
  const currentAction = useSelector(state => getCurrentValue2(state, 'action'));
  const selectedDevice = useSelector(state => getCurrentValue2(state, 'selectedDevice'))
  const isIconClickable = active && ['remove', 'move'].includes(currentAction);
  const state = iconThemes[active ? 'button_active' : 'button_disabled-dim'];
  const selectedState = iconThemes['button_selected'];

  // Move device functionality starts here
  const devicesRef = useRef(null);
  const [moving, setMoving] = useState(false); 
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const mouse = useMousePosition(true, true);
  const context = useContext(ToolContext);
  const dispatch = useDispatch();

  // If device is a bar or camera , keep it stuck to the walls 
  const { isBarMic = false, isBarSpkr = false } = context.constants.equipment?.[type]?.[model] ?? {};
  const isCamera = type === 'cameras';

  /**
   * @function getMap
   * @description Initializes and retrieves the reference map for devices.
   * @returns {Map} Map object for storing device references
   */
  const getMap = () => {
    if (!devicesRef.current) {
      devicesRef.current = new Map();
    };
    return devicesRef.current
  };

  /**
   * @function getTranslateValues
   * @description Extracts translate values from a transform string.
   * @param {string} transformString - SVG transform attribute string
   * @returns {Object|null} Object with x and y translate values, or null if not found
   */
  const getTranslateValues = (transformString) => {
    const match = transformString.match(/translate\(([^)]+)\)/);
    if (match && match[1]) {
      const values = match[1].split(',').map(Number);
      return { x: values[0], y: values[1] };
    }
    return null;
  };

  /**
   * @function setTranslateValues
   * @description Updates the translate values of an SVG element.
   * @param {SVGElement} node - The SVG element to update
   * @param {number} x - New x coordinate
   * @param {number} y - New y coordinate
   */
  const setTranslateValues = (nodes, x, y) => {
    nodes.forEach(node => {
      const currentTransform = node.getAttribute('transform');
      const scaleMatch = currentTransform.match(/scale\(([^)]+)\)/);
      const scaleValue = scaleMatch ? scaleMatch[1] : '1';
      const newTransform = `translate(${x}, ${y}) scale(${scaleValue})`;
      node.setAttribute('transform', newTransform);
    });
  };

  /**
   * @function limitMove
   * @description Limits the movement of a device within the room boundaries.
   * @param {number} x - Proposed x coordinate
   * @param {number} y - Proposed y coordinate
   * @returns {Object} Object with limited x and y coordinates
   */
  const limitMove = (x = 0, y = 0) => {
    // Limit range of motion to keep device inside room
    const fence = iconSize * iconScale * .5;
    x = clamp(x - offset.x, bbox.x0 + fence, bbox.x1 - fence - 2);
    y = clamp(y - offset.y, bbox.y0 + fence, bbox.y1 - fence - 2);

    // // If device is a bar or camera , keep it stuck to the walls 
    // const {isBarMic = false, isBarSpkr = false} = context.constants.equipment[type][model];
    // const isCamera = type === 'cameras';

    // Room diagonal - angle of the diagonal line that goes from the top-left corenr to the center of the room
    const roomDiagonal = Math.atan2(bbox.y0 - bbox.cy, bbox.x0 - bbox.cx) / (Math.PI / 180) + 180;

    if (isBarMic || isBarSpkr || isCamera) {
      // Angle of the coordinates with respect to the center
      const angle = Math.atan2((y - bbox.cy), (x - bbox.cx)) / (Math.PI / 180) + 180;
      switch (true) {
        case angle <= roomDiagonal: // Left Side
          x = bbox.x0 + fence;
          break;
        case angle <= 180 - roomDiagonal: // Upper Side
          y = bbox.y0 + fence;
          break;
        case angle <= 180 + roomDiagonal: // Right Side
          x = bbox.x1 - fence - 2;
          break;
        case angle <= 360 - roomDiagonal: // Lower Side
          y = bbox.y1 - fence - 2;
          break;
        case angle <= 360 + roomDiagonal: // Left Side
          x = bbox.x0 + fence;
          break;
        default:
          x = bbox.x0 + fence;
          break;
      };
    };
    return { x, y }
  };

  /**
   * @function onPointerDown
   * @description Handles the pointer down event for device movement.
   * @param {PointerEvent} e - The pointer event
   * @param {Object} device - The device object being moved
   */
  const onPointerDown = (e, device) => {
    if (currentAction !== 'move') return;
    // Get the device reference
    const map = getMap();
    const node = map.get(device);
    // Set pointer event to device
    node.setPointerCapture(e.pointerId);
    // Calculate mouse to device offset
    const { left, right, top, bottom } = node.getBoundingClientRect();
    setOffset({ x: e.clientX - (right + left) / 2, y: e.clientY - (top + bottom) / 2 });
    // Get the device ready to move
    setMoving(true);
    dispatch(ReducersIndex.uiReducers.setSelectedDevice(device.id))
    dispatch(ReducersIndex.uiReducers.setDeviceIsMoving(true));
    e.stopPropagation();
  };

  /**
   * @function onPointerMove
   * @description Handles the pointer move event for device movement.
   * @param {PointerEvent} e - The pointer event
   * @param {Object} device - The device object being moved
   */
  const onPointerMove = (e, device) => {
    if (!moving) return;
    // Get the device reference
    const map = getMap();
    let nodes = [map.get(device)];
    // Get currenbnt mouse position
    let { x = 0, y = 0 } = mouse ?? {};
    if (!x || !y) return;
    // Apply move limits
    ({ x, y } = limitMove(x, y));
    // if device is a bar, move the counter element 
    if (isBarMic || isBarSpkr) {
      const mirrorId = nodes[0].id.replace(type, isBarMic ? 'speakers' : 'microphones');
      const mirrorNode = document.getElementById(mirrorId);
      nodes.push(mirrorNode);
    };
    // Mode the devices
    setTranslateValues(nodes, x, y);
  };

  /**
   * @function onPointerUp
   * @description Handles the pointer up event, finalizing device movement.
   * @param {PointerEvent} e - The pointer event
   * @param {Object} device - The device object being moved
   */
  const onPointerUp = (e, device) => {
    if (currentAction !== 'move') return;
    // Get the device reference
    const map = getMap();
    const node = map.get(device);
    // Release popinter events 
    node.releasePointerCapture(e.pointerId);
    // Get  the index and position of the moved device 
    const { x, y } = getTranslateValues(node.getAttribute('transform'));
    const index = parseInt(node.id.split('-').pop());
    const location = {
      x: (x - x0) / scale,
      y: (y - y0) / scale
    };
    // Get the custom parameters and clean the object keys 
    let customParameters = context.constants.customSettings[type];
    customParameters = mapKeys(customParameters, (_, key) => key.split('.').pop());
    // Create the updated layout
    const updatedLayout = {
      [type]: {
        ...devices,
        ...customParameters,
        layout: devices.layout.map((item, i) => i === index ? { ...item, location: location } : item)
      }
    };

    /**
     * Updates the layout for single unit devices (audio bars or video bars).
     * This function moves all components of the device to a new position.
     *
     * @param {Object} updatedLayout - The current layout object being updated.
     * @param {string} type - The type of the device being moved (e.g., 'microphones', 'speakers').
     * @param {boolean} isBarMic - Indicates if the device is a bar microphone.
     * @param {boolean} isBarSpkr - Indicates if the device is a bar speaker.
     * @param {string} deviceType - The type of the device ('audiobar' or 'videobar').
     * @returns {Object} The updated layout object.
     */
    const newPosition = updatedLayout[type].layout[0].location;
    if (isBarMic || isBarSpkr || deviceType === 'videobar') {
      const componentsToUpdate = ['speakers', 'microphones'];
      if (deviceType === 'videobar') {
        componentsToUpdate.push('cameras');
      };
      componentsToUpdate.forEach(component => {
        updatedLayout[component] = { layout: [{ location: newPosition }] };
      });
    };
    setMoving(false);
    dispatch(ReducersIndex.uiReducers.setSelectedDevice(null))
    dispatch(ReducersIndex.uiReducers.setDeviceIsMoving(false));
    dispatch(ReducersIndex.container.updateDevice(updatedLayout));
  };

  return (
    visible && <Devices.Group id={`${id}-${type}`} isIconClickable={isIconClickable}>
      <defs>
        <clipPath id="clip-path">
          <rect x={bbox.x0 + 2} y={bbox.y0 + 2} width={bbox.width - 4} height={bbox.height - 4} />
        </clipPath>
      </defs>
      {layoutArray && layoutArray.map((device, i) => <g
        key={device.id}
        className={type}
        id={device.id}
        ref={
          (node) => {
            const map = getMap();
            if (node) {
              map.set(device, node);
            } else {
              map.delete(device);
            };
          }
        }
        transform={device.transform}
        onPointerDown={(e) => onPointerDown(e, device)}
        onPointerMove={(e) => onPointerMove(e, device)}
        onPointerUp={(e) => onPointerUp(e, device)}
      >
        <Icon state={device.id === selectedDevice ? selectedState : state} />
        {shoudShowArrow && <Arrow state={state} angle={device.angle} />}
      </g>
      )}
    </Devices.Group>
  )
};

/**
 * @component Devices.Group
 * @description Styled component for the device group
 */
Devices.Group = styled.g.attrs(props => ({
  id: props.id,
  clipPath: `url(#clip-path)`

}))`
  pointer-events: ${props => props.isIconClickable ? 'auto' : 'none'};
`;

/**
 * Component for rendering horn speakers.
 * @function
 * @param {Object} props - Component props
 * @param {Object} props.children - Area data
 * @returns {React.Element} Devices component configured for horns
 */
const Horns = (props) => {
  const area = props.children;
  return (
    <Devices {...props} id={area.id} type={'speakers'} icon={'speaker'} origin={area.location} devices={area.speakers ?? []} />
  )
};

/**
 * Component for rendering speakers.
 * @function
 * @param {Object} props - Component props
 * @param {Object} props.children - Area data
 * @returns {React.Element} Devices component configured for speakers
 */
const Speakers = (props) => {
  const area = props.children;
  const { speakers } = area;
  const spkrType = speakers.model;
  const context = useContext(ToolContext);
  const icon = context.constants.equipment.speakers[spkrType].icon(area);
  return (
    <Devices {...props} id={area.id} type={'speakers'} model={spkrType} icon={icon} origin={area.location} devices={speakers} />
  )
};

/**
 * Component for rendering microphones.
 * @function
 * @param {Object} props - Component props
 * @param {Object} props.children - Area data
 * @returns {React.Element} Devices component configured for microphones
 */
const Microphones = (props) => {
  const area = props.children;
  const { microphones } = area;
  const micType = microphones.model.replace('mic', '_mic');
  return (
    <Devices {...props} id={area.id} type={'microphones'} model={micType} icon={micType} origin={area.location} devices={microphones} />
  )
};

/**
 * Component for rendering camera.
 * @function
 * @param {Object} props - Component props
 * @param {Object} props.children - Area data
 * @returns {React.Element} Devices component configured for speakers
 */
const Cameras = (props) => {
  const area = props.children;
  const { cameras } = area;
  const context = useContext(ToolContext);
  if (!cameras.hasCamera) return
  const icon = context.constants.equipment.cameras[cameras.model].icon(area);
  return (
    <Devices {...props} id={area.id} type={'cameras'} model={cameras.model} icon={icon} origin={area.location} devices={cameras} />
  )
};

export { Speakers, Microphones, Horns, Cameras }
/**
 * @note
 * 1. The Horns and Speakers components are similar in functionality but differ in two key aspects:
 *    - Data source: They retrieve device data from different properties of the area object.
 *    - Icon selection: 
 *      - Horns always use the 'speaker' icon.
 *      - Speakers determine the icon based on the current device selected, using the `icon` function 
 *        from the equipment constants.
 * 2. This separation allows for flexible rendering of different types of speaker devices while 
 *    maintaining a consistent interface.
 * 3. The Microphones component follows a similar pattern but is tailored for microphone devices.
 * 4. All components utilize the generic Devices component for actual rendering, providing a 
 *    consistent look and behavior across different device types.
 */
