const Devices = {
  ceiling_mic: ({ state, center = true }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0' transform={center ? 'translate(-24 -24)' : ''}>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g fill="none" stroke={state.icon_stroke}>
        <path d="M35.7 30.5a5.6 5.7 0 0 0 0 -11.4h-21.4a5.6 5.7 0 0 0 0 11.4z" />
        <path d="M32.5 29.8v-6 m-3 0v6 m-3 0v-6 m-3 0v6 m-3 0v-6 m-3 0v6" strokeWidth="1.5" />
      </g>
    </g>,

  pendant_mic: ({ state, center = true }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0' transform={center ? 'translate(-24 -24)' : ''}>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g fill="none" stroke={state.icon_stroke}>
        <path d="M35.7 32.4a5.6 5.7 0 0 0 0 -11.4h-21.4a5.6 5.7 0 0 0 0 11.4z" />
        <path d="M32.4 31.6v-5.6 m-3 0 v5.6 m-3 0 v-5.6 m-3 0 v5.6 m-3 0v-5.6 m-3 0 v5.6 M24.8 20.2V8.2" strokeWidth="1.5" />
      </g>
    </g>,

  tabletop_mic: ({ state, center = true }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0' transform={center ? 'translate(-24 -24)' : ''}>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g fill="none" stroke={state.icon_stroke}>
        <path d="M20 40.2h10 m-5 -1v-4m8.4 -8.2c-2 9.6 -14.8 9.6 -16.8 0m3 -11.6a5.4 5.4 0 0 1 10.8 0v10a5.4 5.4 0 1 1 -10.8 0v-10z" />
        <path d="M29.4 15h-5.4m0 2h5.4m0 2h-5.4m0 2h5.4m0 2h-5.4m0 2h5.4" strokeWidth="1.5" />
      </g>
    </g>,

  videobar: ({ state, center = true }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0' transform={center ? 'translate(-24 -24)' : ''}>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g fill="none" stroke={state.icon_stroke}>
        <path d="m9 19 h32 a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-32a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2z" />
        <g strokeWidth="1.5">
          <path d="m22.5 22.5 h5a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1z" />
          <circle cx="14" cy="25" r="3.5" />
          <circle cx="36" cy="25" r="3.5" />
        </g>
      </g>
    </g>,

  audiobar: ({ state, center = true }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0' transform={center ? 'translate(-24 -24)' : ''}>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <g fill="none" stroke={state.icon_stroke}>
        <path d="m9 19 h32 a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-32a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2z" />
        <g strokeWidth="1.5">
          <circle cx="14" cy="25" r="3.5" />
          <circle cx="36" cy="25" r="3.5" />
        </g>
      </g>
    </g>,

  speaker: ({ state, center = true }) =>
    <g fill='none' strokeWidth='1' strokeMiterlimit='0' transform={center ? 'translate(-24 -24)' : ''}>
      <circle cx="25" cy="25" r="23" fill={state.border_fill} stroke={state.border_stroke} />
      <path d="M26.7 12v25.9l-9.2-7.4h-7.7v-11.2h7.7zm7 2.6a25.3 25.3 0 0 1 0 20.8m-3.4-2.7a22 22 0 0 0 0-15.4m6.7-5.4a29.4 29.4 0 0 1 0 26.2"
        stroke={state.icon_stroke} />
    </g>,
  speakerArrow: ({ state, angle }) =>
    <path d='m60 0l-30-12q8 12 0 24z' stroke={state.border_stroke} fill={state.border_stroke} strokeWidth='1' transform={`rotate(${angle})`} />,

  // Speaker Orientation Icons
  speakerOrientation: ({ state, vertical, start = false, end = false }) =>
    <g stroke={state.icon_stroke} strokeWidth='2' fill={state.icon_fill} transform={`${vertical ? 'rotate(90) translate(0 -50)' : ''}`}>
      <path d={`m25 18 a1 1 0 0 0 0 14 a1 1 0 0 0 0 -14`} fill='none' />
      {((!start && !end) || start) && <path d="m2 25 l14-5q-5 5 0 10" stroke='none' />}
      {((!start && !end) || end) && <path d="m48 25 l-14-5 q5 5 0 10" stroke='none' />}
    </g>,

  aimAuto: ({ state }) => <text x='1' y='32' fill={state.icon_fill} stroke='none' fontFamily="monospace" fontSize="1.4em">AUTO</text>,
  // eslint-disable-next-line
  aimVerticalOut: ({ state }) => <Devices.speakerOrientation state={state} vertical />,
  // eslint-disable-next-line
  aimHorizontalOut: ({ state }) => <Devices.speakerOrientation state={state} />,
  // eslint-disable-next-line
  aimRight: ({ state }) => <Devices.speakerOrientation state={state} end />,
  // eslint-disable-next-line
  aimBottom: ({ state }) => <Devices.speakerOrientation state={state} vertical end />,
  // eslint-disable-next-line
  aimLeft: ({ state }) => <Devices.speakerOrientation state={state} start />,
  // eslint-disable-next-line
  aimTop: ({ state }) => <Devices.speakerOrientation state={state} vertical start />,
};


export default Devices;