const Roof = {
  aframe: ({ state }) =>
    <path d="m25 17.5l-25 12h25l-11-7v7h36l-25-12v12l11-7v7" fill='none' stroke={state.icon_stroke} strokeWidth='2' strokeMiterlimit="0" />,

  slope: ({ state }) =>
    <path d="m.5 25.5l49-12v7l-12.2-4v7l-12.2-4v7l-12.2-4v7l-12.2-4v7l49-12" fill='none' stroke={state.icon_stroke} strokeWidth='2' strokeMiterlimit="0" />,

  flat: ({ state }) =>
    <path d="m.5 20.5h49v8h-49zl8 8v-8l8 8v-8l8 8m.5 0v-8m.5 8l8-8v8l8-8v8l8-8" fill='none' stroke={state.icon_stroke} strokeWidth='2' strokeMiterlimit="0" />,

  // Orientation Icons
  roofSlope: ({ state, vertical, start, end, tick = true }) =>
    <g stroke={state.icon_stroke} strokeWidth='2' fill={state.icon_fill} transform={`${vertical ? 'rotate(90) translate(0 -50)' : ''}`}>
      <path d={`m4 25${start ? '' : 'l15 5c-5-5-5-5 0-10l-15 5'}h42${end ? '' : 'l-15 5c5-5 5-5 0-10l15 5'}m${start ? '-42' : end ? '0' : '-21'}-8v${tick ? '16' : '0'}`} />,
    </g>,
  // eslint-disable-next-line
  vertical: ({ state }) => <Roof.roofSlope state={state} vertical />,
  // eslint-disable-next-line
  horizontal: ({ state }) => <Roof.roofSlope state={state} />,
  // eslint-disable-next-line
  slopeRight: ({ state }) => <Roof.roofSlope state={state} start />,
  // eslint-disable-next-line
  slopeBottom: ({ state }) => <Roof.roofSlope state={state} vertical start />,
  // eslint-disable-next-line
  slopeLeft: ({ state }) => <Roof.roofSlope state={state} end />,
  // eslint-disable-next-line
  slopeTop: ({ state }) => <Roof.roofSlope state={state} vertical end />,
};

export default Roof;