import styled from 'styled-components';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavButtons } from 'components/molecules/ButtonGroup';
import NavSection from 'components/organisms/NavSection';
import ToolContext from 'components/services/ToolContext';
import ReducersIndex from 'reduxModules/ducks';
import { useEffect } from 'react';
import { getErrorState } from 'reduxModules/ducks/ui';
import { keys } from 'lodash';


const NavContainer = () => {
  const tool = useContext(ToolContext);
  const showing = useSelector(state => state.ui.showing);
  const items = keys(tool.menus).filter(key => showing in tool.menus[key]);
  const active = useSelector(state => state.ui.currentView);
  const dispatch = useDispatch()
  

  const error = useSelector(getErrorState);
  const exit = useSelector(state => state.ui.exitToRoom);

  useEffect(() => {
    if (!items.map(item => item.replace('_', '')).includes(active)) dispatch(ReducersIndex.uiReducers.setCurrentView(items[0].replace('_', '')));
  }, [items, active, dispatch])

  useEffect(() => {
    if (exit && error) {
      dispatch(ReducersIndex.uiReducers.openModal('warning'));
    };
    // eslint-disable-next-line
  }, [active]);

  return (
    <NavContainer.Wrapper>
      <NavContainer.Title>View:</NavContainer.Title>
      <NavButtons nav theme={'navGroup'} activeState={'nav_active'} inactiveState={'nav_inactive'} items={items} labels={true} dispatcher={'setCurrentView'}>
        <NavSection />
      </NavButtons>
    </NavContainer.Wrapper>
  )
};

NavContainer.Wrapper = styled.div.attrs(props => ({
  className: 'col-2 p-0 '
}))`
`;

NavContainer.Title = styled.div.attrs(props => ({
  className: 'mb-1'
}))`
  color: var(--app-custom-gray);
  font-size: 16px
`;

export default NavContainer;



