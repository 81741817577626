import styled, { css } from 'styled-components';

const setClass = ({hasIcon, label}) => hasIcon && label ? 'pe-2' : hasIcon ? 'px-0' : 'px-2';

const Label = styled.label.attrs(props => ({
  className: `d-flex ${props.type === 'button' ? setClass(props) : ''} ${props.className}`
})
)`
  flex-flow: ${props => props.theme.vertical ? 'column' : 'row'};
  overflow: ${props => props.theme.overflow || 'hidden'};
  text-align: ${props => props.theme.labelAlign || 'center'}; 
  justify-content: ${props => props.theme.labelAlign || 'center'};
  width: ${props => props.theme.labelWidth};
  cursor: ${props => props.type !== 'text' && 'pointer'};
  ${props => ['text', 'number', 'select'].includes(props.type) &&
    css`
      &:after {
        content: ": ";
        white-space: pre;
        display: contents
      };
    `
  };
`;

const GroupLabel = (props) => <Label type='text' label={props.label}>{props.label}</Label>

export default Label;
export {Label, GroupLabel}

