import { Fragment, cloneElement, useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Label from "components/atoms/Label";
import Button from "components/molecules/Button";
import BulletList from "components/atoms/BulletList";
import { buttonThemes } from "globalConstants/Themes";
import ReducersIndex from 'reduxModules/ducks';
import { getArea, getConditionResult, getCurrentValue, getCurrentValue2 } from "reduxModules/ducks/container";
import { findKey, isArray, keys, values } from "lodash";

// cleanup
const ButtonGroup = (props) => {
  // fixme theme must be in constants
  const useTheme = buttonThemes[props.theme || "buttonGroupDef"] || props.theme;
  const buttonClass = props.nav ? 'text-uppercase fs-6 fw-normal' : 'text-capitalize';

  // Tooltip stuff 
  const [toolTip, setToolTip] = useState('');
  const [delay, setDelay] = useState('0s');

  // detect active camera
  const activeTab = useSelector(state => state.container.activeTab);
  const hasCamera = useSelector(state => state.container.areas[activeTab]?.cameras?.hasCamera);
  const hasVideoBar = useSelector(state => state.container.areas[activeTab]?.microphones?.model === 'videobar');

  if (props.tooltips) {
    const handleEnter = (evt, item) => {
      setToolTip(props.tooltips[item]);
      setDelay('0s');
    };
    const handleLeave = () => setDelay('.5s');
    props = {
      ...props,
      handleEnter: handleEnter,
      handleLeave: handleLeave,
    }
  };

  const getState = (item) => props.getState(item);
  // console.log("props: ", props.items);
  return (
    <div className={`d-flex flex-wrap align-items-center gap-1 ${props.className ? props.className : ''}`}>
      {props.label && (<Label htmlFor={props.name} type={"text"}>{props.label}</Label>)}
      <div className={`${props.nav ? 'vstack' : 'd-flex flex-wrap'} gap-${props.gap || 1}`}>
        {props.items.map((item, index) => {
          const state = getState(item)
          const background = props.checked(item) ? useTheme.checked : useTheme.unchecked;
          const color = props.checked(item) ? useTheme.label_checked : useTheme.label_unchecked;
          const mouseEnter = props.handleEnter ? (e) => props.handleEnter(e, item) : null;
          const click = (e) => props.handleClick(e, item);
          const bubble = props.bubbles ? props.bubbles[item] : '';
          const btnProps = {
            type: 'checkbox',
            className: buttonClass,
            theme: useTheme,
            name: item,
            label: props.labels ? item : "",
            state: state,
            bubble: bubble,
            onClick: click,
            target: props.target,
            slideTo: index,
            slide: props.slide,
            useIcon: props.useIcon
          }
          {/* Hide camera button if no camera present in room and item. */}
          if(item === 'camera') {
            if(!hasCamera && !hasVideoBar) return null;
          }
          return (
            <Fragment key={`${item}-${index}`} >
              <ThemeProvider theme={{ background: background, color: color }} >
                <ButtonGroup.Wrapper onMouseEnter={mouseEnter} onMouseLeave={props.handleLeave} margin={props.bullets}>
                  {/* marker: passing usage for icon change */}
                  {/* <Button target={props.target} type={'checkbox'} className={buttonClass} theme={useTheme} name={item} label={props.labels ? item : ""} state={state} bubble={bubble} onClick={click} /> */}
                  <Button {...btnProps} />
                  {props.bullets && <BulletList content={props.bullets[index]}/>}
                </ButtonGroup.Wrapper>
              </ThemeProvider>
              {props.children && cloneElement(props.children, { item: item })}
            </Fragment>
          );
        })}

      </div>
      {!props.isNav && props.tooltips && <ButtonGroup.ToolTip delay={delay}>{toolTip}</ButtonGroup.ToolTip>}
    </div>
  );
};

ButtonGroup.Wrapper = styled.div.attrs(props => ({
  className: `d-flex align-items-center my-${props.margin ? 3 : 0}`
}))`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.color};
`;

ButtonGroup.ToolTip = styled.div.attrs(props => ({
  className: 'ms-2'
}))`
  color: var(--app-red);
  opacity: ${props => props.delay === '0s' ? 1 : 0};
  transition: ${props => `${props.delay === '0s' ? '0.2s' : '0.5s'} opacity ease ${props.delay}`};
`;

const SelectButtons = (props) => {
  const dispatch = useDispatch();
  const currentValue = useSelector(state => getCurrentValue2(state, props.dispatcher));
  const [buttonActive, setButtonActive] = useState({});
  useEffect(() => {
    const newValue = currentValue ?
      props.items.reduce((acc, curr) => { return ((acc[curr] = currentValue[findKey(currentValue, (_, key) => curr.includes(key))]), acc) }, {}) : {}
    setButtonActive(newValue)
    // console.log("currentValue: ", currentValue);
  }, [currentValue, props.items]);
  const handleClick = (evt, item) => {
    // console.log("click: ", item, props.dispatcher, );
    setButtonActive({ ...buttonActive, [item]: !buttonActive[item] });
    if (props.dispatcher) dispatch(ReducersIndex.container[props.dispatcher](item))
  };
  const getState = (item) => buttonActive[item] ? props.activeState || "button_selected" : props.inactiveState || "button_active";
  const checked = (item) => buttonActive[item];
  props = {
    ...props,
    handleClick: handleClick,
    getState: getState,
    checked: checked,
  };
  return <ButtonGroup {...props} />;
};

const ToggleButtons = (props) => {
  const dispatch = useDispatch();
  // cleanup
  // const currentValue = useSelector(state => getCurrentValue(state, props.dispatcher));
  const currentValue = useSelector(state => getCurrentValue2(state, props.dispatcher, props.name))
  // marker
  const currentUse = useSelector(state => getConditionResult(state, {...props.keyCondition ?? {check: () => null }}));
  const [buttonActive, setButtonActive] = useState();
  // marker
  const items = isArray(props.items) ? props.items : props.items[currentUse ?? keys(props.items)[0]]
  // console.log("currentuse: ", currentUse, items);
  useEffect(() => {
    // cleanup
    // const current = props.name ? (currentValue[props.name] === undefined ? currentValue : currentValue[props.name]) : currentValue;
    setButtonActive(currentValue)
  });

  const handleClick = (evt, item) => {
    const dispatcher = props.dispatcher ? ReducersIndex[props.nav ? 'uiReducers' : 'container'][props.dispatcher] : null;
    const newItem = props.deselect && item === buttonActive ? null : item.replace('_', '');
    const newValue = props.name ? { ...{}, [props.name]: newItem } : newItem;
    if (dispatcher) dispatch(dispatcher(newValue));

    setButtonActive(newItem);
  };
  const getState = (item) => {
    // console.log("getting: ", item, buttonActive, buttonActive === item.replace('_', ''), props.activeState);
    return buttonActive === item.replace('_', '') ? props.activeState || "button_selected" : props.inactiveState || "button_active";
  }
    const checked = (item) => buttonActive === item.replace('_', '');
  props = {
    ...props,
    handleClick: handleClick,
    getState: getState,
    checked: checked,
    items: items || [],
  };
  return <ButtonGroup {...props} />;
};

const ToggleSelect = (props) => {
  const dispatch = useDispatch();
  // cleanup
  // const currentValue = useSelector(state => getCurrentValue(state, props.dispatcher));
  // console.log("currentValue: ", currentValue);
  // const test = useSelector(state => getCurrentValue2(state, props.dispatcher, props.name));
  // console.log("test: ", props.name, test);
  // marker
  const currentValue = useSelector(state => getCurrentValue2(state, props.dispatcher, props.name))
  // fixme
  // const currentUse = useSelector(state => getCurrentValue(state, 'areaUsage'));

  // console.log("p: ", props.keyCondition);
  const currentUse = useSelector(state => getConditionResult(state, {...props.keyCondition ?? {check: () => null }}));
  // console.log("cur: ", currentUse);
  // console.log("items: ", props.items);

  const [buttonActive, setButtonActive] = useState({});
  const items = isArray(props.items) ? props.items : props.items[currentUse ?? keys(props.items)[0]]
  // console.log("items: ", items);
  useEffect(() => {
    const newValue = currentValue ?
      items.reduce((acc, curr) => { return ((acc[curr] = currentValue[findKey(currentValue, (_, key) => curr.includes(key))]), acc) }, {}) : {};
    setButtonActive(newValue);
  }, [currentValue, items]);
  const defaultState = items?.reduce((acc, curr) => ({ ...acc, [curr]: false }), {});

  const toggles = (props.toggle ?? props.items).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}); // defines which items toggle the others 
  // Conditional to disable buttons while keeping the last value - used for coverage undo
  const override = useSelector(state => getConditionResult(state, {...props.activeCondition ?? {check: () => false }}));
  const handleClick = (evt, item) => {
    const isToggle = (props.toggle ?? props.items).includes(item)
    const newItem = isToggle ? { ...defaultState, [item]: true } : { ...buttonActive, ...toggles, [item]: !buttonActive[item] };
    if (items.every(x => !(newItem[x]))) newItem[props.noSelect] = true
    setButtonActive(newItem);
    const newValue = props.name ? { ...{}, [props.name]: newItem } : newItem;
    if (props.dispatcher) dispatch(ReducersIndex.container[props.dispatcher](newValue));
  };
  const getState = (item) => override ? 'button_disabled' : buttonActive[item] ? props.activeState || "button_selected" : props.inactiveState || "button_disabled";
  const checked = (item) => buttonActive[item];
  props = {
    ...props,
    handleClick: handleClick,
    getState: getState,
    checked: checked,
    items: items || [],
  };
  return <ButtonGroup {...props} />;
};

const DotButtons = (props) => {
  const currentValue = props.current;
  const [buttonActive, setButtonActive] = useState(currentValue);

  useEffect(() => {
    setButtonActive(currentValue)
  }, [currentValue]);

  const handleClick = (evt, item) => setButtonActive(item);

  const getState = (item) => buttonActive === item ? "dot_selected" : "dot_active";
  const checked = (item) => buttonActive === item;
  props = {
    ...props,
    className: 'justify-content-center mt-4',
    gap: 2,
    theme: 'dotGroup',
    handleClick: handleClick,
    getState: getState,
    checked: checked,
  };
  return <ButtonGroup {...props} />;
};

const NavButtons = (props) => <ToggleButtons {...props} nav />

const FamilyButtons = (props) => {
  props = {
    ...props,
    labels: true,
    theme: 'familyGroup',
    inactiveState: 'acoustic_inactive',
    items: keys(props.items),
    bullets: values(props.items),
  }
  return (
    <ToggleButtons {...props} />
  )
}


export default ButtonGroup;
export { SelectButtons, ToggleButtons, ToggleSelect, DotButtons, NavButtons, FamilyButtons };
