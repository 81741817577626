import { isArray, isPlainObject, camelCase, keys } from "lodash"

// Converts all object keys to camelcase
export const camelizeObject = (obj) => {
  if (isArray(obj)) {
    return obj.map(v => camelizeObject(v))
  } else if (isPlainObject(obj)) {
    return keys(obj).reduce((result, key) => ({
      ...result,
      [camelCase(key)]: camelizeObject(obj[key])
    }), {})
  }
  return obj
};

//fixme
// Gets a string from a dispatcher to get the parameter name
// First trims everything on the left of a slash, then removes the 'set' string and returns the camelCase value
export const getStoreProp = (string) => string && string.replace(/(^.*\/)/, '').replace('set', '').split('.').map(sub => camelCase(sub)).join('.')

// {
//   // string && camelCase(string.replace(/(^.*\/)/, '').replace('set', ''))
//   const subs = string && string.replace(/(^.*\/)/, '').replace('set', '').split('.')
//   subs.map(sub => camelCase(sub));
//   return subs.join('.')

// }
// string && camelCase(string.replace(/^[^A-Z]+/, ''))
