import { View, Text, Link, StyleSheet } from '@react-pdf/renderer';
import { startCase } from 'lodash';

const styles = StyleSheet.create({
  detail: {
    flexDirection: 'row',
    justifyContent: 'start',
  },
});

export const StringField = ({ label, content, vf = false }) =>
  <View style={styles.detail}>
    <Text>{`${startCase(label)}: `}</Text>
    <Text>{vf ? content : startCase(content)}</Text>
  </View>;

export const LinkField = ({ label }) =>
  <View style={styles.detail}>
    <Text>{`${startCase(label)}: `}</Text>
    <Link src={window.location.href}>Click Here</Link>
  </View>;

export const ValueField = ({ label, content }) => <StringField label={label} content={content} vf={true} />