import styled from "styled-components";
import { useSelector } from "react-redux";
import { getScale } from "reduxModules/ducks/ui";

const Conveyor = ({ ver = true, x = 0, y = 2.7, length = 7, full = true, double = false }) => {
  const scale = useSelector(getScale);
  // Rails
  const wth = full ? 1.3 : .6; // with of the rolls 
  const width = wth + double * (wth + .5); // double width is used for the split 
  const rails = `m${x} ${y + width + .3}h${length}v-.15h-${length}zm0-${width + .3}h${length}v.15h-${length}z`;
  // Rollers
  const qty = parseInt((length - 0.04) / .24); // each roll ocupies .24 
  if (qty < 1) return;
  const start = x + (length - (qty * .24)) / 2 + .06;
  const rollers = `m${start} ${y + .15}v${width}${`m.12 0v-${width}m.12 0v${width}`.repeat(qty - 1)}m.12 0v-${width}`;
  const midRolls = `m${start + .06} ${y + .15}v${width}${`m.24 -${width}v${width}`.repeat(qty - 1)}`;
  const showMids = scale >= 30; //scale limit to show/hide midRoll lines
  // split track option
  const split = `m${x + .06} ${y + .76 + full * .69}l${length - .36 - !full * .24} ${.9 + full * .7}v.15l-${length - .36 - !full * .24}
  -${.9 + full * .7}zm${length - .12} ${0} l-.1 0-.2.2 0 .1.2 .2.1 0z`
  // Transformations 
  const transform = ver ? '' : `rotate(${ver ? 0 : 90}) translate(${y - x }, -${y + x + width + .30})`;
  
  // console.log("pixels: ", (.15 + wth) * scale); marker


  return (
    <Conveyor.Group transform={transform} >
      <Conveyor.Path path={rails} />
      <Conveyor.Path path={rollers} showMids={showMids} />
      {showMids && <Conveyor.Path path={midRolls} showMids={showMids} dash={true} />}
      {double && <Conveyor.Path path={split} fill={'#fff'} />}
    </Conveyor.Group>
  )
};

Conveyor.Group = styled.g.attrs(props => ({
  transform: props.transform
}))`
  stroke-width: 1;
`;

Conveyor.Path = styled.path.attrs(props => ({
  d: props.path,
}))`
  vector-effect: non-scaling-stroke;
  fill: ${props => props.fill || 'none'};
  stroke-width: ${props => props.showMids && (props.showMids ? 1 : .5)};
  stroke-dasharray: ${props => props.dash ? '6 1.5 1 1.5' : ''};
  shape-rendering: crispedges;
`;

export default Conveyor
