/**
 * @module MouseHandler
 * @description A component that wraps the UI to handle all mouse interactions in the application.
 * It manages mouse events, dispatches actions, and renders measurement tools based on the current action.
 */

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useContext } from "react";
import useMousePosition from "hooks/useMousePosition";
import MeasuringTape from "components/elements/MeasuringTape";
import { getDrawingBox, getCurrentDevice} from "reduxModules/ducks/ui";
import ToolContext from 'components/services/ToolContext';

/**
 * MouseHandler component
 * 
 * @function
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to be wrapped
 * @returns {React.Element} Wrapped UI with mouse event handling
 * 
 * @example
 * <MouseHandler>
 *   <YourAppComponents />
 * </MouseHandler>
 */
const MouseHandler = ({ children }) => {
  const currentAction = useSelector(state => state.ui.action);
  const drawingBox = useSelector(getDrawingBox);
  const currentDevice = useSelector(getCurrentDevice);

  const [action, setAction] = useState();
  useEffect(() => {
    setAction(currentAction);
    setMouseActive(false);
  }, [currentAction]);

  const [mouseActive, setMouseActive] = useState(false);
  const [anchor, setAnchor] = useState({ x: 0, y: 0 });
  const [target, setTarget] = useState({ x: 0, y: 0 });
  const mouse = useMousePosition();

  const actionDispatchers = useContext(ToolContext).actionDispatchers;
  const dispatch = useDispatch();
  const handlers = {
    onMouseDown: (evt) => {
      if (mouse) {
        if (action in actionDispatchers) {
          actionDispatchers[action](evt, dispatch, mouse, drawingBox, currentDevice);  
        };
        setMouseActive(true);
        setAnchor(mouse);
        setTarget(mouse);
      }
    },
    onMouseMove: (e) => {
      if (mouse) setTarget(mouse);
    },
    onMouseUp: (e) => {
      setMouseActive(false)
    }
  };

  const props = {
    action: action,
    anchor: anchor,
    target: target,
    mouseActive: mouseActive
  };

  return (
    <MouseHandler.Wrapper {...handlers} pointer={mouseActive && (action === 'measure')}>
      {children}
      {action === 'measure' && <MeasuringTape {...props} />}
    </MouseHandler.Wrapper>
  )
}

/**
 * Styled wrapper for the MouseHandler
 * 
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.pointer - Whether to show pointer cursor
 */
MouseHandler.Wrapper = styled.g.attrs(props => ({
  className: 'MouseHandler'
}))`
  cursor: ${props => props.pointer ? 'pointer' : 'default'};
  ${'' /* fixme: could this be the proble? */}
  pointer-events: bounding-box;
`;

export default MouseHandler;
/**
 * @typedef {Object} MouseHandlerState
 * @property {string} action - Current mouse action
 * @property {boolean} mouseActive - Whether the mouse is actively engaged in an action
 * @property {Object} anchor - Starting point of a mouse action {x, y}
 * @property {Object} target - Current point of a mouse action {x, y}
 */

/**
 * @typedef {Object} MouseHandlerContextData
 * @property {Object} actionDispatchers - Object containing action dispatcher functions
 */

/**
 * @typedef {Object} MouseHandlerProps
 * @property {React.ReactNode} children - Child components to be wrapped
 */

/**
 * The MouseHandler component manages mouse interactions for the application. It:
 * 
 * 1. Tracks the current mouse action from the Redux store.
 * 2. Manages local state for mouse activity, anchor point, and target point.
 * 3. Uses the useMousePosition hook to get real-time mouse coordinates.
 * 4. Dispatches actions based on the current mouse action and device.
 * 5. Renders a MeasuringTape component when the 'measure' action is active.
 * 
 * The component wraps its children in a styled g element that handles mouse events.
 * It uses the ToolContext to access action dispatchers specific to the current tool.
 * 
 * @note
 * - The component adjusts the cursor style based on the current action.
 * - It uses the SVG bounding box to adjust mouse coordinates relative to the SVG.
 * - Action dispatchers are called on mouseDown events if the action exists in the context.
 */