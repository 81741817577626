import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';

import { Static} from 'components/molecules/BubbleContent';

const Info = (props) => {
  return (
    <ToolBubble {...props} >
      {Static && <Static />}
    </ToolBubble>
  )
};

const ToolBubble = styled(Tooltip).attrs(props => ({
  //float: true,
  offset: 7.5,
  delayShow: 100,
}))`
  background-color: var(--app-dark-gray);
  color: #fff;
  opacity: .8;
  z-index: 10;
  padding: 0;
  padding: 4px 8px;
`;

export default Info;