
const ControlBar = {
  edit: ({ state = { icon_fill: '#000' } }) =>
    <path d="m13 33l-.5 4 4-.5zm1.7-2.8l12-12 4.5 4.5-12 12zm15-15l-.5.5 4.5 4.5.5-.5-4.5-4.5a1.5 1.5 0 1 1 4.5 4.5z"
      fill={state.icon_fill} stroke={state.icon_fill} />,

  help: ({ state }) =>
    <g strokeWidth="2.5" >
      <circle cx="25" cy="25" r="17" fill={state.border_fill} stroke={state.border_stroke} />
      <text x='17.5' y='34.5' fontFamily='gotham-book' fontSize='28px' fill={state.icon_fill} stroke='none'>?</text>
    </g>,

  delete: () =>
    <g fill='none' stroke='#000'>
      <path d="m11 12a14 5 0 1 0 28 0a14 5 0 1 0-28 0l4 25a10 5 0 1 0 20 0l4-25" strokeWidth='2' />
      <path d="m11.5 14.5l23.5 23.5m-20 0l23.5-23.5m-10 2.5l-14 14 10.5 10.5 10.5-10.5-14-14-7.5 7.5 16.5 16.5m-2-24l7.5 7.5-16.5 16.5" strokeWidth='1' />
    </g>,

  duplicate: () =>
    <path d="m11 7 v28h4v-24h20v4h-16v28h20v-28h-4m-4-4v-4h-20.5m4.5 28v4h4m3-20h14m0 4h-14m0 4h14m0 4h-14m0 4h14m0 4h-14" fill='none' stroke='#000' />,

  attention: () =>
    <g fill='#000' stroke='#000'>
      <path d='m10 40 h30 a 3 3 0 0 0 2.6 -4.5 l -15 -26 a 3 3 0 0 0 -5.2 0 l -15 26 a 3 3 0 0 0 2.6 4.5z' fill='#eed202' strokeWidth='2' />
      <path d='m22 18.5 a3 3 0 0 1 6 0 l -2 10 a1 1 0 0 1 -2 0z' />
      <path d='m23.5 35 a1 1 0 0 1 3 0 a1 1 0 0 1 -3 0z ' />
    </g>

};



export default ControlBar;