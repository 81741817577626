import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useLayoutEffect } from "react";
import { getConditionResult, getCurrentValue2 } from "reduxModules/ducks/container";
import { getDrawingBox } from "reduxModules/ducks/ui";
// import icons from "globalConstants/Icons";
import { Move } from "globalConstants/Icons/Actions";
import { iconThemes } from "globalConstants/Themes";
import ReducersIndex from 'reduxModules/ducks';
import useMousePosition from "hooks/useMousePosition";

import { assignWith, mapValues, round, } from "lodash";

// todo: move this to contants?
const DoorSize = {
  height: 0.9144,
  width: 0.03175,
  margin: 0.0254
};

const Door = (props) => {
  const areaId = useSelector(state => getCurrentValue2(state, 'activeTab'));
  const { bbox, scale } = useSelector(getDrawingBox);
  const currentDoor = useSelector(state => getCurrentValue2(state, 'door'));
  const pxSize = mapValues(DoorSize, (v) => v * scale);

  const door = {
    ...currentDoor,
    x: currentDoor.x * scale + bbox.x0,
    y: currentDoor.y * scale + bbox.y0
  };

  // Handle transformations 
  const dispatch = useDispatch()
  const [transform, setTransform] = useState({ x: 0, y: 0, scale: [1, 1], angle: 0 });
  const updateTransform = (args) => setTransform(prevState => ({ ...prevState, ...args }));

  // Adjust door if room dimensions change to keep it inside 
  useLayoutEffect(() => {
    if (pxSize.height >= bbox.width || pxSize.height >= bbox.height) return;
    let x, y
    switch (door.side) {
      case 0:
        x = bbox.x0 + pxSize.height + pxSize.margin;
        y = Math.min(Math.max(bbox.y0 + pxSize.margin, door.y), bbox.y1 - pxSize.height - pxSize.margin);
        break;
      case 1:
        x = Math.min(Math.max(bbox.x0 + pxSize.margin, door.x), bbox.x1 - pxSize.height - pxSize.margin);
        y = bbox.y0 + pxSize.height + pxSize.margin;
        break;
      case 2:
        x = bbox.x1 - pxSize.height - pxSize.margin;
        y = Math.min(Math.max(bbox.y0 + pxSize.margin, door.y), bbox.y1 - pxSize.height - pxSize.margin);
        break;
      case 3:
        x = Math.min(Math.max(bbox.x0 + pxSize.margin, door.x), bbox.x1 - pxSize.height - pxSize.margin);
        y = bbox.y1 - pxSize.height - pxSize.margin;
        break;
      default:
        x = transform.x !== 0 ? transform.x : bbox.x0 + pxSize.margin;
        y = transform.y !== 0 ? transform.y : bbox.y1 - pxSize.height - pxSize.margin;
    };
    updateTransform({ side: door.side, x: x, y: y, scale: door.scale, angle: door.angle })
    dispatch(ReducersIndex.container.setDoor({ x: round((x - bbox.x0) / scale, 4), y: round((y - bbox.y0) / scale, 4) }));
  }, [bbox.x0, bbox.y0, bbox.x1, bbox.y1]);


  const roomDiagonal = Math.atan2(bbox.cy - bbox.y0, bbox.cx - bbox.x0 ) * 180 / Math.PI
  const mouse = useMousePosition(true, true)
  const [moving, setMoving] = useState(false);
  const [offset, setOffset] = useState({x: 0, y: 0});

  const handlers = {
    onPointerDown: (e) => {
      doorIconRef.current.setPointerCapture(e.pointerId);
      const parent = e.target.closest('.action');
      const {left, right, top, bottom} = parent.getBoundingClientRect();
      setOffset({x: e.clientX - (right + left)/2, y: e.clientY - (top + bottom)/2});
      setMoving(true);
      dispatch(ReducersIndex.uiReducers.setAction('moveDoor'));
      e.stopPropagation();
    },
    onPointerMove: (e) => {
      if (!moving) return
      moveDoor();
    },
    onPointerUp: (e) => {
      doorIconRef.current.releasePointerCapture(e.pointerId);
      setMoving(false);
      dispatch(ReducersIndex.uiReducers.setAction());
      dispatch(ReducersIndex.container.setDoor(assignWith({ ...transform }, { x: bbox.x0, y: bbox.y0 }, (a, b) => round((a - b) / scale, 4))));
    }
  };

  const moveDoor = (evt) => {
    let { x = 0, y = 0 } = mouse ?? {};
    if (!x || !y) return;
    const angle = Math.atan2((y - bbox.cy), (x - bbox.cx)) / (Math.PI / 180) + 180;
    x -= pxSize.height * .25 + offset.x;
    y -= pxSize.height * .75 + offset.y;
    let side, scale = [1, 1], rotate = 0;
    switch (true) {
      case angle <= roomDiagonal:
        side = 0;
        scale = [-1, -1];
        rotate = -90;
        x = bbox.x0 + pxSize.height + pxSize.margin;
        y = Math.max(bbox.y0 - pxSize.height / 2 + pxSize.margin, y) + pxSize.height / 2;
        break;
      case angle <= 90:
        side = 1;
        scale = [1, -1];
        x = Math.max(bbox.x0 + pxSize.margin, x);
        y = bbox.y0 + pxSize.height + pxSize.margin;
        break;
      case angle <= 180 - roomDiagonal:
        side = 1;
        scale = [-1, -1];
        x = Math.min(bbox.x1 - pxSize.height / 2 - pxSize.margin, x) + pxSize.height / 2;
        y = bbox.y0 + pxSize.height + pxSize.margin;
        break;
      case angle <= 180:
        side = 2;
        scale = [-1, 1];
        rotate = -90;
        x = bbox.x1 - pxSize.height - pxSize.margin;
        y = Math.max(bbox.y0 - pxSize.height / 2 + pxSize.margin, y) + pxSize.height / 2;
        break;
      case angle <= 180 + roomDiagonal:
        side = 2;
        rotate = -90;
        x = bbox.x1 - pxSize.height - pxSize.margin;
        y = Math.min(bbox.y1 - pxSize.height - pxSize.margin, y) + pxSize.height;
        break;
      case angle <= 270:
        side = 3;
        scale = [-1, 1];
        x = Math.min(bbox.x1 - pxSize.height / 2 - pxSize.margin, x) + pxSize.height / 2;
        y = bbox.y1 - pxSize.height - pxSize.margin;
        break;
      case angle <= 360 - roomDiagonal:
        side = 3;
        x = Math.max(bbox.x0 + pxSize.margin, x);
        y = bbox.y1 - pxSize.height - pxSize.margin;
        break;
      case angle <= 360:
        side = 0;
        scale = [-1, 1];
        rotate = 90;
        x = bbox.x0 + pxSize.height + pxSize.margin;
        y = Math.min(bbox.y1 - pxSize.height - pxSize.margin, y) + pxSize.height;
        break;
      default:
        side = 3;
    };
    updateTransform({ side: side, x: x, y: y, scale: scale, angle: rotate });
  };

  const active = useSelector(state => getConditionResult(state, { ...props.active, area: areaId }));
  const doorIconRef = useRef();

  return (
    <Door.Group id={`${areaId}-door`} active={active} transform={transform ?? {}}>
      <Door.Rect scale={scale} active={active} />
      <Door.Arc scale={scale} active={active} />
      {active && <Door.Move ref={doorIconRef} scale={scale} pointer={moving} {...handlers} >
        <Move state={iconThemes['button_selected']} center />
      </Door.Move>}
    </Door.Group>
  )
};

Door.Group = styled.g.attrs(props => ({
  transform: `translate(${props.transform.x}, ${props.transform.y}) rotate(${props.transform.angle}) scale(${props.transform.scale}) `
}))`
  fill: none;
  stroke-width: 2;
  shape-rendering: crispedges;
  pointer-events: ${props => props.active ? 'bounding-box' : 'none'};
`;

Door.Rect = styled.rect.attrs(({ scale }) => ({
  width: DoorSize.width * scale,
  height: DoorSize.height * scale,
  x: 2,
  y: 2,
}))`
stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-dark-gray)'};
fill: ${props => props.active ? 'var(--app-red)' : 'var(--app-dark-gray)'};
`;

Door.Arc = styled.path.attrs(({ scale }) => {
  const b = (DoorSize.height - DoorSize.width) * scale - 2;
  return ({
    d: `M${DoorSize.width * scale}, 2a${b} ${b} 0 0 1 ${b} ${DoorSize.height * scale - 2}`
  })
}
)`
shape-rendering: geometricprecision;
stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-dark-gray)'};
fill: none;
`;

Door.Move = styled.g.attrs(({ scale, pointer }) => ({
  transform: `translate(${DoorSize.height * scale * 0.25}, ${DoorSize.height * scale * 0.75}) scale(${DoorSize.height * scale * 0.006})`
}))`
cursor: ${props => props.pointer ? 'all-scroll' : 'pointer'};
:hover {
    cursor: 'pointer';
  };
`

export default Door;

