// This are functions that only apply to WD so it's best to keep here
import constants from "toolConstants/warehouseDesigner/constants";
import initialState from "toolConstants/warehouseDesigner/initalState";
import { getAvailableArea } from "reduxModules/ducks/container";
import { parseDistance } from "utilities/format";
import { assign, keys, some, values } from "lodash";

const { defMountingMin, defMountingMax, palletRackMinDim } = constants.area;

// Called from container, splits area setup depending on whether we're adding a dock area or warehouse area 
export const setArea = (container, area) => {
  switch (area.areaType) {
    case 'dock':
      return setDockArea(container, area);
    default:
      return setWarehouseArea(container, area);
  };
};

// Sets the next area to add based on the largest available space 
const setWarehouseArea = (container, area) => {
  const areaDims = getAvailableArea(container);
  assign(area, areaDims);
  const height = container.roofHeight.min;
  area.ceilingHeight = { min: height, max: height };
  area.mountingHeight = { min: height - defMountingMin, max: height - defMountingMax };
  return area;
};

// Sets initial dock area 
const setDockArea = (container, dock) => {
  // set dims first 
  const docks = container.loadingDocks;
  const travelLane = container.travelLane.width;
  const side = dock.id.split('_')[0];
  const lr = ['left', 'right'].includes(side);
  // Set dimensions and location
  dock.dimensions.length  = parseDistance(lr ? travelLane :container.dimensions.length);
  dock.dimensions.width = parseDistance(lr ? container.dimensions.width - (docks.top_docks + docks.bottom_docks) * travelLane : travelLane);
  dock.location.left = parseDistance(side === 'right' ? container.dimensions.length - travelLane : 0);
  dock.location.top = parseDistance(lr ? docks.top_docks * travelLane : side === 'top' ? 0 : container.dimensions.width - travelLane);
  const height = container.roofHeight.min;
  dock.ceilingHeight = { min: height, max: height };
  dock.mountingHeight = { min: height - defMountingMin, max: height - defMountingMax };
  dock.storageReady = true;
  dock.areaUsage = 'trafficArea';
  dock.coverage = keys(dock.coverage).reduce((acc, key) => ({...acc, [key] : key === 'general'}), {});
  return dock
};




export const resetArea = (container, parameter = null) => {

  const activeArea = container.areas[container.activeTab];
  const initState = initialState.area();

  const customized = some(activeArea.customized)
  // get a list of keys and add the customized keys?
  // add the aisles with a function

  if (customized) {

    keys(activeArea.customized).forEach(key => activeArea[key] = initState[key]); 

    activeArea.speakerLayout = initState.speakerLayout;
    activeArea.speakers = initState.speakers;
    activeArea.customized = initState.customized;

    if (parameter === 'storageOrientation') { // both true and paramter 
      activeArea.pickingAisles = initState.pickingAisles;
      activeArea.primaryAisles = initState.primaryAisles;
      activeArea.connectorAisles = initState.connectorAisles;
      // activeArea.aislesArray = initState.aislesArray;
    }
    // marker moved here to prevent error when area gets smaller and is customized
    activeArea.aislesArray = initState.aislesArray;

  } else {
    //the key list is fixed below
    if (values(activeArea.dimensions).some(x => x <= palletRackMinDim)) {
      activeArea.areaUsage = 'trafficArea';
    };
    const usage = activeArea.areaUsage;
    activeArea.coverage = {
      picking_aisles: usage === 'palletRack', 
      primary_aisles: false,
      connector_aisles: false, 
      general: usage !== 'palletRack',
      none: false,
    };
  
    activeArea.pickingAisles = initState.pickingAisles;
    activeArea.primaryAisles = initState.primaryAisles;
    activeArea.connectorAisles = initState.connectorAisles;
    activeArea.aislesArray = initState.aislesArray;
    activeArea.rackFill = initState.rackFill;


    activeArea.systemPrimaryUsage = initState.systemPrimaryUsage;
    activeArea.budget = initState.budget;
    activeArea.speakerAiming = initState.speakerAiming;
    activeArea.speakerLayout = initState.speakerLayout;
    activeArea.speakers = initState.speakers;
    activeArea.speakerModel = null;
    activeArea.customized = initState.customized;


  }

}