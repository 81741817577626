import toolIndex from "toolConstants";
import { inRange } from 'utilities/mathematic.js';
import ReducersIndex from "reduxModules/ducks";
import { toFeet } from "utilities/format";
import { keys, values } from "lodash";

/*
ERROR CODES 
400: network

1000: Warehouse dims - should stop drawing
1010: Loading docks - should stop drawing docks
1020: Travel lane - stop docks?

1100: Roof height
1110: Roof grid

1200: Area Dims
1210: ceiling height
1220: mounting height

1300: Area Usage
1320: picking Aisles
1330: Primary Aisles

1400: Noise 




1600: overlaps && outside warehouse
*/

const validate = {
  dimensions: (dispatch, state) => {
    const { units, activeTab: activeArea } = state.ui
    const isMeter = units === 'meters';
    const inArea = keys(state.container.areas).indexOf(activeArea) !== -1;
    const { length, width } = (state.container.areas[activeArea] || state.container).dimensions;
    const { min, max = null } = toolIndex[state.container.currentTool].constants[inArea ? 'area' : 'container'];
    const e1 = inRange(length, min, max);
    const e2 = inRange(width, min, max);
    const errorCode = inArea ? 1200 : 1000;
    const errorParams = [...(e1 ? ['length'] : []), ...(e2 ? ['width'] : [])];
    const name = toolIndex[state.container.currentTool].constants.tool[inArea ? 'areaName' : 'containerName'];
    if (e1 || e2) {
      if (e1 === e2 || e1 === 0 || e2 === 0) {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `${name} Dimensions can't be ${(e1 || e2) < 0 ? 'smaller' : 'larger'} than ${isMeter ? ((e1 || e2) < 0 ? min.toFixed(1) : max.toFixed(1)) : ((e1 || e2) < 0 ? toFeet(min) : toFeet(max))} ${isMeter ? 'Meters' : 'Feet'} `
        }))
      } else {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `${name} Dimensions must be between ${isMeter ? min.toFixed(1) + ' and ' + max.toFixed(1) : toFeet(min) + ' and ' + toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
        }))
      };
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }));
      // Parameters below are dimension dependant so need to validate from here
      if (!inArea) validate.loadingDocks(dispatch, state);
      if (!inArea) validate.travelLane(dispatch, state);
      if (inArea) validate.areaUsage(dispatch, state, false);
    };
  },

  loadingDocks: (dispatch, state) => {
    const { dimensions: { length, width }, travelLane: { width: travelLane }, currentTool } = state.container;
    const { area: { min: minAreaDim }, container: { baySize: bayWidth } } = toolIndex[currentTool].constants;
    const adj = bayWidth - minAreaDim;
    const docks = state.container.loadingDocks;
    const [horizontal, vertical] = [
      (docks.left_docks + docks.right_docks),
      (docks.top_docks + docks.bottom_docks)
    ];
    const e1 = inRange(length, horizontal * travelLane + (vertical > 0 ? horizontal * adj + bayWidth : minAreaDim));
    const e2 = inRange(width, vertical * travelLane + (horizontal > 0 ? vertical * adj + bayWidth : minAreaDim));
    const errorCode = 1010;
    if (e1 || e2) {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
        msg: `Warehouse ${(e1 && e2) ? '' : e1 ? 'length' : 'width'} is too small to fit selected Docks Location`
      }))
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    };
    validate.travelLane(dispatch, state, false);
  },

  travelLane: (dispatch, state, flag = true) => {
    const isMeter = state.ui.units === 'meters';
    const { travelLane: { width: travelLane }, dimensions: { length, width }, currentTool, loadingDocks } = state.container;
    const { min } = toolIndex[currentTool].constants.container.travelLane;
    const max = Math.max(min, Math.min(length, width) / 3);
    const hasDocks = values(loadingDocks || {}).some(dock => dock);
    const e1 = hasDocks && inRange(travelLane, min, max);
    const errorCode = 1020;
    if (e1) {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
        params: ['width'],
        msg: `Travel Lane Width can't be ${e1 < 0 ? 'smaller' : 'larger'} than ${isMeter ? e1 < 0 ? min.toFixed(1) : max.toFixed(1) : e1 < 0 ? toFeet(min) : toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
      }))
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    };
    if (flag) validate.loadingDocks(dispatch, state);
  },

  roofHeight: (dispatch, state) => {
    const isMeter = state.ui.units === 'meters';
    const { roofHeight: { min: currentMin, max: curentMax }, currentTool } = state.container;
    const { min, max } = toolIndex[currentTool].constants.roof;
    const e1 = inRange(currentMin, min, max);
    const e2 = inRange(curentMax, min, max);
    const errorCode = 1100;
    const errorParams = [...(e1 ? ['min'] : []), ...(e2 ? ['max'] : [])];
    if (e1 || e2) {
      if (e1 === e2 || e1 === 0 || e2 === 0) {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `Roof Height can't be ${e1 < 0 ? 'smaller' : 'larger'} than ${isMeter ? e1 < 0 ? min.toFixed(1) : max.toFixed(1) : e1 < 0 ? toFeet(min) : toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
        }))
      } else {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `Roof Height must be between ${isMeter ? min.toFixed(1) + ' and ' + max.toFixed(1) : toFeet(min) + ' and ' + toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
        }))
      };
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    };
    // fixme this needs to trigger errors in all areas 
  },

  roofGridSpacing: (dispatch, state) => {
    const isMeter = state.ui.units === 'meters';
    const { dimensions: { length, width }, roofGridSpacing: { x, y }, currentTool } = state.container;
    const { min } = toolIndex[currentTool].constants.roofGrid;
    const e1 = inRange(x, min, length / 2);
    const e2 = inRange(y, min, width / 2);
    const errorCode = 1110;
    const errorParams = [...(e1 ? ['x'] : []), ...(e2 ? ['y'] : [])];
    if (e1 || e2) {
      if (e1 === e2 || e1 === 0 || e2 === 0) {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `Roof Grid Spacing can't be ${(e1 || e2) < 0 ? 'smaller' : 'larger'} than ${isMeter ? (e1 || e2) < 0 ? min.toFixed(1) : ((e1 ? length : width) / 2).toFixed(1) : (e1 || e2) < 0 ? toFeet(min) : toFeet((e1 ? length : width) / 2)} ${isMeter ? 'Meters' : 'Feet'}`
        }))
      } else {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `Roof Grid Spacing must be between ${isMeter ? min.toFixed(1) : toFeet(min)} ${isMeter ? 'Meters' : 'Feet'} 
          and half the corresponding Warehouse Dimension`
        }))
      }
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    };

  },

  openCeiling: (dispatch, state) => {
    const errorCode = 1210;
    const { error: {[errorCode] : err}, activeTab: activeArea } = state.ui;
    const { ceilingHeight: { min: ceilingHeight }, openCeiling } = state.container.areas[activeArea];
    const { roofHeight: { min: max }} = state.container;
    // console.log("error: ", err);
    // console.log("ceilingHeight: ", ceilingHeight, openCeiling);
    // console.log("max: ", max);
  },

  ceilingHeight: (dispatch, state) => {
    const { units, activeTab: activeArea } = state.ui;
    const isMeter = units === 'meters';
    const { ceilingHeight: { min: ceilingHeight }, openCeiling } = state.container.areas[activeArea];
    const { roofHeight: { min: max }, currentTool } = state.container;
    const { ceilingMin: min } = toolIndex[currentTool].constants.area;
    const e1 = !openCeiling && inRange(ceilingHeight, min, max);
    const errorCode = 1210;
    const errorParams = e1 ? ['min'] : [];
    const name = toolIndex[currentTool].constants.tool.areaName;
    if (e1) {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
        params: errorParams,
        msg: `${name} Ceiling can't be ${e1 < 0 ? 'lower' : 'higher'} than ${isMeter ? e1 < 0 ? min.toFixed(1) : max.toFixed(1) : e1 < 0 ? toFeet(min) : toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
      }))
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    }
  },

  mountingHeight: (dispatch, state) => {
    const { units, activeTab: activeArea } = state.ui
    const isMeter = units === 'meters';
    const { mountingHeight: { min: mountingMin, max: mountingMax }, openCeiling, ceilingHeight } = state.container.areas[activeArea];
    const { roofHeight, currentTool } = state.container;
    const { mountingMin: min } = toolIndex[currentTool].constants.area;
    const { min: max } = openCeiling ? roofHeight : ceilingHeight;
    const e1 = inRange(mountingMin, min, max);
    const e2 = inRange(mountingMax, min, max);
    const errorCode = 1220;
    const errorParams = [...(e1 ? ['min'] : []), ...(e2 ? ['max'] : [])];
    const name = toolIndex[currentTool].constants.tool.areaName;
    if (e1 || e2) {
      if (e1 === e2 || e1 === 0 || e2 === 0) {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `${name} Mounting Height can't be ${(e1 || e2) < 0 ? 'lower' : 'higher'} than ${isMeter ? (e1 || e2) < 0 ? min.toFixed(1) : max.toFixed(1) : (e1 || e2) < 0 ? toFeet(min) : toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
        }))
      } else {
        dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          params: errorParams,
          msg: `${name} Mounting Height must be between ${isMeter ? min.toFixed(1) + ' and ' + max.toFixed(1) : toFeet(min) + ' and ' + toFeet(max)} ${isMeter ? 'Meters' : 'Feet'}`
        }))
      }
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }));
      // Mounting height affects usage 
      validate.areaUsage(dispatch, state, false);
    }
    // fixme, Mounting height affects rack storage so need to check this here as well. 

    // validate.areaUsage(dispatch, state, 'height');

  },

  areaUsage: (dispatch, state, flag = true) => {
    const { activeTab: activeArea} = state.ui
    const { dimensions: { length, width }, mountingHeight: { min: height }, areaUsage } = state.container.areas[activeArea];
    const { currentTool } = state.container;
    const { usage: {[areaUsage] : {minLength = null, maxLength = null, minWidth = null, maxWidth = null, minHeight = null, MaxHeight = null, fallback } = {}}, tooltips} = toolIndex[currentTool].constants.area;

    // dim constraints 
    const e1 = inRange(length, minLength, maxLength) || inRange(width, minWidth, maxWidth);
    // height constraints 
    const e2 = inRange(height, minHeight, MaxHeight);
    const errorCode = 1300;
    const name = toolIndex[currentTool].constants.tool.areaName;
    const rest = `, switching Area Usage to ${tooltips[fallback]}`;
    if (e1 || e2) {
      if (areaUsage !== fallback) {
        !flag && dispatch(ReducersIndex.container.setAreaUsage(fallback ?? 'automatedHandling')) // this is chaning the store but not updating the button (on self)
        flag && dispatch(ReducersIndex.uiReducers.setError({
          code: errorCode,
          msg: `${name} is too small for ${tooltips[areaUsage]}${flag ? '' : rest}`,
          reset: !flag //true
        }))
      };
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    };
  },

  pickingAisles: (dispatch, state) => {
    const { activeTab: activeArea, scale } = state.ui;
    const { dimensions: { length, width }, storageOrientation, pickingAisles } = state.container.areas[activeArea];
    const horizontal = storageOrientation === 'horizontal';
    const { currentTool } = state.container;
    const { minPickSize } = toolIndex[currentTool].constants.area.storage;
    const max = Math.floor(((horizontal ? width : length) - 3 / scale) / minPickSize);
    const e1 = inRange(pickingAisles, 1, max);
    const errorCode = 1320;
    const name = toolIndex[currentTool].constants.tool.areaName
    if (e1) {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
        msg: `${name} can't have more than ${max} Picking Aisles`
      }))
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    };
  },

  primaryAisles: (dispatch, state) => {
    const { activeTab: activeArea, scale } = state.ui;
    const { dimensions: { length, width }, storageOrientation, primaryAisles } = state.container.areas[activeArea];
    const horizontal = storageOrientation === 'horizontal';
    const { currentTool } = state.container;
    const { minPrimarySize } = toolIndex[currentTool].constants.area.storage;
    const max = Math.floor(((horizontal ? length : width) - 3 / scale) / minPrimarySize);
    const e1 = inRange(primaryAisles, 0, max);
    const errorCode = 1330;
    const name = toolIndex[currentTool].constants.tool.areaName
    if (e1) {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
        msg: `${name} can't have more than ${max} Primary Aisles`
      }))
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: errorCode,
      }))
    };
  },

  noiseLevel: (dispatch, state) => {
    const { activeTab: activeArea } = state.ui;
    const { noiseLevel } = state.container.areas[activeArea];
    const e1 = inRange(noiseLevel, 40, 95)
    const { currentTool } = state.container;
    const name = toolIndex[currentTool].constants.tool.areaName
    if (e1) {
      dispatch(ReducersIndex.uiReducers.setError({
        code: 1400,
        msg: `${name} noise level can't be ${e1 < 0 ? 'below' : 'above'} ${e1 < 0 ? '40' : '95'}dBA`
      }))
    } else {
      dispatch(ReducersIndex.uiReducers.setError({
        code: 1400,
      }))
    }
  },

  overlaping: (dispatch, state) => {
    const overlaping = state.ui.overlaping;
    dispatch(ReducersIndex.uiReducers.setError({
      code: '1600',
      msg: overlaping.length > 0 ? overlaping : null
    }));
  },


};



export default validate;





