// import Label from 'components/atoms/Label';
import { Label } from 'components';
import  { GettersIndex} from 'reduxModules/ducks'
import { useSelector } from 'react-redux';

const Output = (props) => {
  const value = useSelector(state => GettersIndex[props.getter](state));

  return (
    <div className={`d-flex flex-wrap gap-1 ${props.className}`}>
      {props.label && <Label type={'text'}>{props.label}</Label>}
      <div>{value}</div>
    </div>
  )
};



export default Output
