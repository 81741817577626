import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReducersIndex from "reduxModules/ducks";
import useFetch from "hooks/useFetch";

import Payload from "components/services/Payload";

import useSetURLparams from "hooks/useSetURLParams";

import isBoolean from "lodash/isBoolean";
import { getErrorState, getReload } from "reduxModules/ducks/ui";



const Load = ({params, setProjectLoaded, setSplashOpen, target, setFirstTimeUser}) => {
  
  
  const [load, setLoad] = useState(params.includes('savekey'));
  const [payload, setPayload] = useState(Payload());
  const [reset, setReset] = useState(false);

  const errorState = useSelector(getErrorState);
  const reload = useSelector(getReload); 
  const isLatest = useSelector(state => state.ui.isLatest);
  useSetURLparams();
   
  const { connecting, response, error, fetchData } = useFetch("https://supportdata.biamp.com/py/load.py", payload);
  
  const dispatch = useDispatch();

  // Hide the splash
  useEffect(() => {
    if (load) setSplashOpen(false);
  // eslint-disable-next-line
  }, [load]);

  // Show loading dialog
  useEffect(() => {
    if (connecting) dispatch(ReducersIndex.uiReducers.openModal('loading'));
  // eslint-disable-next-line
  }, [connecting]);

  // Fetch data 
  useEffect(() => {
    if (load) fetchData();
  // eslint-disable-next-line
  }, [load, payload]);

  // Handle the response
  useEffect(() => {
    if (response) {
      dispatch(ReducersIndex.uiReducers.closeModal('loading'));
      setLoad(false);
      const receivedPayload = JSON.parse(response);

      if (receivedPayload.success) {
        dispatch(ReducersIndex.container.setProjectData(receivedPayload));
        setFirstTimeUser(false)
        if (!receivedPayload.latestrevision) {
          dispatch(ReducersIndex.uiReducers.openModal('oldRevision'));
        } else {
          setProjectLoaded(true);
        };
      } else {
        if (receivedPayload.errornumber !== 7) {
            // todo: this is curently using standard alert. should use an error modal
          dispatch(ReducersIndex.uiReducers.closeModal('loading'));
          alert(`An error occurred while sending data to the server. Error code: ${receivedPayload.errornumber} ${receivedPayload.errortext}.`);
          setReset(true);
        } else {
          dispatch(ReducersIndex.uiReducers.openModal('unknowRevision'));
        };
      };
    };
  // eslint-disable-next-line
  }, [response]);


  // Handle reload 
  useEffect(() => {
    if (isBoolean(reload)) {
      if (reload) {
        const {revision, ...rest} = payload;
        setPayload(rest);
        setLoad(true);
        dispatch(ReducersIndex.uiReducers.setReload(false));
      } else {
        if (isLatest) {
          setReset(true);
          setProjectLoaded(true);
        } else {
          setProjectLoaded(true);
        };
      };
    };
  // eslint-disable-next-line
  }, [reload]);

  // handle UI reset 
  // note: this is temporary to handle UI reset when somethinggoes wrong. Might change 
  useEffect(() => {
    if (reset ) {
      dispatch(ReducersIndex.container.setRecalculate({flag: false}));
      dispatch(ReducersIndex.uiReducers.setExitToRoom(false));
      dispatch(ReducersIndex.uiReducers.openModal(target));
      setProjectLoaded(true);
    }
  // eslint-disable-next-line
  }, [reset, errorState]);

  // Handle network errors 
  // todo: this is curently using standard alert. should use an error modal
  useEffect(() => {
    if (error) {
      dispatch(ReducersIndex.uiReducers.closeModal('loading'));
      alert('An unknown error ocurred while sending data to the server.');
      setReset(true);
    };
  // eslint-disable-next-line
  }, [error]);
}

export default Load;