/**
 * Modal configurations for the Conference Room Designer.
 * This module defines the structure and properties of modals used in the application.
 * @module conferenceRoomDesignerModals
 */
import dialogs from "globalConstants/dialogs";
import constants from "toolConstants/conferenceroomDesigner/constants";
import { side } from "utilities/mathematic";
import { keys, round, indexOf } from "lodash";

/**
 * Modal configurations for various stages in the Conference Room Designer.
 * @type {Object.<string, Object>}
 * 
 * @property {Object} launch - Configuration for the launch modal.
 * @property {Object} editProject - Configuration for the project editing modal.
 * @property {Object} area - Configuration for the room design modal.
 * @property {Object} table - Configuration for the table design modal.
 * @property {Object} devices - Configuration for the device configuration modal.
 * @property {Object} family - Configuration for the product family selection modal.
 * 
 * @description
 * Each modal configuration typically includes:
 * - header: The title of the modal.
 * - body: An array of form elements to be displayed in the modal.
 * - footer: An array of action buttons for the modal.
 * 
 * Form elements in the body array often include:
 * - element: The type of form element to render (e.g., 'textInput', 'dimensionInput', 'toggleButtons').
 * - name: The key used when dispatching the variable to the Redux store.
 * - label: The display label for the form element.
 * - dispatcher: The name of the dispatch function used to update the store.
 * - condition: An object that defines when the form element should be displayed or enabled.
 *   The condition object is passed to the getConditionResult function, which evaluates
 *   whether the condition is met based on the current state. The condition object typically
 *   includes:
 *   - parameter: An array of state properties to check.
 *   - check: A function that receives the specified state properties and returns a boolean.
 * 
 * Additional properties may include items, theme, errorCode, infoCode, etc., depending on the specific form element.
 * 
 * The getConditionResult function (documented elsewhere) is used to evaluate these conditions
 * and determine whether each form element should be displayed or enabled based on the current state.
 */
const modals = {
  ...dialogs,
  launch: {
    image: 'images/Conference_Room_Designer_splash.jpg',
    title: 'Welcome to the Biamp Conference Room Designer!',
    intro: `You will be guided through a series of steps to design your room, place your furniture, and
    get customized placement plan for Biamp speakers, cameras and microphones.`,
    button: 'BEGIN ROOM DESIGN',
    target: 'area',
  },

  editProject: {
    dispatcher: 'setProject',
    header: 'EDIT PROJECT DETAILS',
    body: [
      {
        element: 'textInput',
        name: 'name',
        label: 'Project Name'
      },
      {
        element: 'textInput',
        name: 'company',
        label: 'Company Name'
      },
      {
        element: 'textInput',
        name: 'designer',
        label: 'Design engineer'
      },
    ],
    footer: [
      {},
      {
        label: 'RETURN TO ROOM >',
        target: ''
      }
    ]
  },

  area: {
    menu: 'floorplan',
    header: 'DESIGN YOUR ROOM',
    body: [
      {
        element: 'textInput',
        name: 'name',
        label: 'Room Name',
        dispatcher: 'setName'
      },
      {
        element: 'dimensionInput',
        label: 'Room Dimensions',
        ids: ['length', 'width'],
        dispatcher: 'setDimensions',
        errorCode: 1000,
      },
      {
        element: 'dimensionInput',
        label: 'Ceiling Height',
        ids: ['min'],
        dispatcher: 'setCeilingHeight',
        errorCode: 1010,
      },
      {
        element: 'toggleButtons',
        label: 'Room Orientation',
        items: ['lengthwise', 'widewise'],
        dispatcher: 'setAreaOrientation'
      },
      {
        element: 'selector',
        label: 'Ceiling Tile Dimension',
        name: 'tile',
        items: keys(constants.ceilingTiles),
        dispatcher: 'setCeilingTiles'
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {},
      {
        label: 'CONTINUE TO ADD TABLE >',
        target: 'table',
      }
    ]
  },

  table: {
    menu: 'furnishing',
    header: 'DESIGN YOUR TABLE',
    body: [
      {
        element: 'toggleButtons',
        extend: true,
        label: 'Table Shape',
        items: keys(constants.tables),
        deselect: true,
        name: 'shape',
        theme: 'furnitureGroup',
        activeState: 'furniture_active',
        inactiveState: "furniture_inactive",
        dispatcher: 'setTable'
      },
      {
        condition: { parameter: ['table'], check: ([table]) => table.shape && table.shape !== 'round' },
        element: 'dimensionInput',
        label: 'Table Dimensions',
        ids: ['size.length', 'size.width'],
        dispatcher: 'setTable'
      },
      {
        condition: { parameter: ['table'], check: ([table]) => table.shape && table.shape === 'round' },
        element: 'dimensionInput',
        label: 'Table Diameter',
        ids: ['size.length'],
        dispatcher: 'setTable'
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {
        label: '< BACK',
        color: 'var(--app-medium-gray)',
        target: 'area'
      },
      {
        label: 'CONTINUE TO CONFIGURE DEVICES >',
        target: 'devices'
      }
    ]
  },

  devices: {
    menu: 'microphones',
    header: 'CONFIGURE YOUR DEVICES',
    body: [
      {
        element: 'toggleButtons',
        extend: true,
        label: 'Mic Style',
        name: 'model',
        items: {
          withTable: keys(constants.equipment.microphones).filter(mic => mic !== 'tabletop_mic'),
          withoutTable: keys(constants.equipment.microphones),
          withTableHigh: keys(constants.equipment.microphones).filter(mic => !['ceiling_mic', 'tabletop_mic'].includes(mic)),
          withoutTableHigh: keys(constants.equipment.microphones).filter(mic => mic !== 'ceiling_mic')
        },
        keyCondition: {
          parameter: ['table', 'ceilingHeight', 'acoustics'], check: ([table, ceiling, acc]) => {
            const micData = constants.equipment.microphones.ceiling_mic.micData;
            const acoustics = constants.acoustics;
            const talkerHeight = constants.area.talkerHeight;
            const max = round(side(micData[indexOf(acoustics, acc)], 1) + talkerHeight, 4);
            return `with${table.shape ? 'out' : ''}Table${ceiling.min > max ? 'High' : ''}`
          }
        },
        labels: true,
        theme: 'deviceGroup',
        activeState: 'device_selected',
        dispatcher: 'setMicrophones',
        infoCode: 1100
      },
      {
        condition: { parameter: ['microphones'], check: ([microphones]) => microphones.model === 'pendantmic' },
        className: 'ms-2',
        element: 'dimensionInput',
        label: 'Microphone Height',
        ids: ['height'],
        dispatcher: 'setMicrophones',
        infoCode: 1110
      },
      {
        condition: { parameter: ['table', 'microphones'], check: ([table, microphones]) => table.shape && ['ceilingmic', 'pendantmic'].includes(microphones.model) },
        element: 'toggleSelect',
        extend: true,
        label: 'Mic Coverage',
        // todo: get the toggles from constants 
        items: ['full_room', 'focused'],
        labels: true,
        noSelect: 'focused',
        name: 'coverage',
        theme: 'buttonGroupBig',
        activeState: 'coverage_active',
        inactiveState: "coverage_inactive",
        dispatcher: 'setMicrophones',
      },
      {
        element: 'toggleButtons',
        label: 'Room Acoustics',
        items: constants.acoustics, //['poor', 'fair', 'good', 'great', 'perfect'],
        labels: true,
        theme: 'acousticGroup',
        inactiveState: 'acoustic_inactive',
        dispatcher: 'setAcoustics'
      },
      {
        // cleanup
        condition: { parameter: ['microphones'], check: ([microphones]) => !['videobar', 'audiobar'].includes(microphones.model) },
        element: 'toggleSelect',
        extend: true,
        label: 'Speaker Coverage',
        // todo: grab from constants 
        items: ['full_room', 'focused'],
        labels: true,
        noSelect: 'focused',
        name: 'coverage',
        theme: 'buttonGroupBig',
        activeState: 'coverage_active',
        inactiveState: "coverage_inactive",
        dispatcher: 'setSpeakers'
      },
      {
        condition: { parameter: ['microphones'], check: ([microphones]) => microphones.model !== 'videobar' },
        element: 'slider',
        label: 'Camera',
        dispatcher: 'setCameras',
        name: 'hasCamera',
      },
      {
        condition: { parameter: ['cameras'], check: ([cameras]) => cameras.model !== 'videobar' },
        element: 'selector',
        className: 'ms-2', 
        name: 'camera',
        label: 'Model',
        placeHolder: 'test',
        items: ['vidi250'],
        dispatcher: 'setCameras'
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {
        label: '< BACK',
        color: 'var(--app-medium-gray)',
        target: 'table'
      },
      {
        label: 'CONTINUE TO ROOM>',
        target: ''
      }
    ]
  },
}

export default modals;
