import { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { keys, values, head, toUpper } from 'lodash';

const styles = StyleSheet.create({
  line: (index = 1) => ({
    flexDirection: 'row',
    justifyContent: 'start',
    borderBottomColor: '#a5aaad',
    borderBottomWidth: 1,
    backgroundColor: index % 2 === 0 ? '#e6e7e8' : '#fff'
  }),

  header: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#d7282f',
    textAlign: 'center',
    borderRightWidth: 1,
    borderRightColor: '#a5aaad',
    borderBottomWidth: 0,
    
  },

  cell: {
    fontSize: 10,
    paddingTop: 2,
    borderRightWidth: 1,
    borderRightColor: '#a5aaad',
    borderBottomWidth: 0,
  },

  footnoteTitle: {
        fontSize: 8,
        color: "#d7282f",
        paddingTop: 4,
        paddingLeft: 10
      },
      footnote: {
        fontSize: 8,
        color: "#d7282f",
        paddingLeft: 20
      },
});


const Table = ({ content, constants }) => {
  const {areas, device, isMeter} = content;
  const {tableData, widths, notes } = values(constants).pop();
  const listData = values(areas).flatMap(area => area[device]);
  const data = tableData(listData, isMeter);
  const headers = keys(head(data));

  return (
    <Fragment>
      <View style={styles.line()} wrap={false}>
        {headers.map((title, index, { length }) => {
          const style = [styles.header, {
            width: `${widths[index]}%`, 
            borderRightColor: `${index < length - 1 ? '#a5aaad' : '#fff'}` 
            }]
          return (<Text key={`title-${index}`} style={style} >{title === '#' ? '#' : toUpper(title)}</Text>)
        })}
      </View>

      {data.map((item, index) => {
        const style = styles.line(index);  
        return (
          <View key={`line-${index}`} style={style} wrap={false} >
            {headers.map((title, jndex, { length }) => {
              const style = [styles.cell, { 
                width: `${widths[jndex]}%`, 
                borderRightColor: `${jndex < length - 1 ? '#a5aaad' : '#fff'}`,
                textAlign: `${title === 'description' ? 'start' : 'center'}`
                 }];
              const cellContent = title === '#' ? index + 1 : title === 'product' ? item[title].name : item[title];
              return (<Text key={`cell-${index}-${jndex}`} style={style} >{cellContent}</Text>)
            }
            )}
          </View>
        )
      })}

      {notes.length > 0 && 
      <View wrap={false}>
        <Text style={styles.footnoteTitle}>Notes:</Text>
        {notes.map((note, index) => <Text key={`note=${index}`} style={styles.footnote}>{note}</Text>)}
      </View>}
    </Fragment>
  )
};

export default Table;