import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { uiReducers, getSVGBoundingBox } from 'reduxModules/ducks/ui';
import DrawManager from 'components/services/DrawManager';
import MouseHandler from 'components/services/MouseHandler';

const SVGContainer = (props) => {
  // Get dimension of the container
  const refContainer = useRef();
  const dispatch = useDispatch();
  const [container, setContainer] = useState(useSelector(getSVGBoundingBox));

  useEffect(() => {
    if (refContainer.current) {
      const updateSize = () => {
        const bBox = refContainer.current.getBoundingClientRect()
        setContainer({
          width: bBox.width, 
          height: bBox.height
        })
        dispatch(uiReducers.setSvgBoundingBox({ top: bBox.top, left: bBox.left, width: bBox.width, height: bBox.height }));
      };
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }
  }, [refContainer.current]);

  // Removed container from wrapper and svg
  return (
    <SVGContainer.Wrapper ref={refContainer} >
      <SVGContainer.SVG >
        <MouseHandler>
          <DrawManager container={container} />
        </MouseHandler>
      </SVGContainer.SVG>
    </SVGContainer.Wrapper>
  )
};


SVGContainer.Wrapper = styled.div.attrs(props => ({
  className: 'flex-grow-0'
}))`
  ${'' /* min-height: 100% */}
  height: 100%
`;

SVGContainer.SVG = styled.svg.attrs(props => ({
  xmlns: "http://www.w3.org/2000/svg",
  // viewBox: `0 0 ${props.container.width || 0} ${props.container.height || 0}`,
}))`
  width: 100%;
  height: 100%
  ${'' /* width: ${props => `${props.container.width}px`};
  height: ${props => `${props.container.height}px`}; */}
`;

export default SVGContainer;




