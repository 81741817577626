import styled from 'styled-components';
import { useSelector } from "react-redux";
import PlainText from 'components/atoms/PlainText';
import Table from "components/molecules/Table";
import toolIndex from 'toolConstants';
import { getIsMeter, getErrorState, getCurrentDevice} from "reduxModules/ducks/ui";
import { getCurrentValue, getDeviceList } from 'reduxModules/ducks/container';
import { keys, isEmpty } from 'lodash';

const EquipmentListManager = () => {
  const equipmentLists = toolIndex[useSelector(state => state.ui.tool)].constants.equipmentLists;
  const errorTitle = "can't display equipment list until design errors are cleared";
  const error = useSelector(getErrorState);

  const name = useSelector(state => getCurrentValue(state, 'name'));
  const listType = useSelector(state => getCurrentValue(state, 'showTable'));
  // fixme this needs to pas something right?
  // const device = equipmentLists[listType].device();
  const device = useSelector(getCurrentDevice);

  const listData = useSelector(state => getDeviceList(state, device));
  const dataEmpty = isEmpty(listData);

  const tableTitle = error ? errorTitle : equipmentLists[listType].title(name, dataEmpty);

  const isMeter = useSelector(getIsMeter);
  const tableData = equipmentLists[listType].tableData(listData, isMeter);
  const tableContent = {
    colWidths: equipmentLists[listType].widths,
    headers: tableData[0] ? keys(tableData[0]) : [],
    lines: tableData
  };

  const tableNotes = equipmentLists[listType].notes;

  return (
    <EquipmentListManager.Container>
      <PlainText className={`text-uppercase fw-bold mb-2 ${error ? 'text-center' : ''}`} size={22} color={'var(--app-red)'} content={tableTitle} />
      {!error && !dataEmpty && <Table content={tableContent} />}
      {!error && !dataEmpty && tableNotes.length > 0 && <PlainText className='ms-1' size={12} color={'var(--app-red)'} content={'Notes:'} />}
      {!error && !dataEmpty && tableNotes.map((note, index) => <PlainText key={`note-${index}`} className='ms-4' size={12} color={'var(--app-red)'} content={note} />)}
    </EquipmentListManager.Container>
  )
};

EquipmentListManager.Container = styled.div.attrs(props => ({
  id: 'equipmentlist',
  className: 'd-flex flex-column h-100 mt-5 m-auto'
}))`
  width: 90%;
`;

export default EquipmentListManager;