import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerSubTitle: {
    paddingTop: 5,
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#000'
  }, 

  title: {
    paddingTop: 14,
    alignItems: 'flex-end',
    fontSize: 18,
    fontWeight: 'bold',
  },

  subTitle: {
    paddingTop: 8,
    paddingBottom: 4,
    fontSize: 14,
    fontWeight: 'bold',
  },


});

export const HeaderSubTitle = ({ content }) => (<Text style={styles.headerSubTitle} >{content}</Text>);
export const Title = ({ content }) => (<Text style={styles.title} >{content}</Text>);
export const SubTitle = ({ content }) => (<Text style={styles.subTitle} >{content}</Text>);

