/**
 * @module BubbleContent
 * @description Provides content for tooltip bubbles in the Master Designer application.
 */
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, memo, useContext } from "react";
import ReducersIndex from "reduxModules/ducks";
import useMousePosition from 'hooks/useMousePosition';
import { getDrawingBox, getIsMeter,getCurrentDevice } from 'reduxModules/ducks/ui';
import { getArea, getPlotProps, getRelativeAveragePlotData } from 'reduxModules/ducks/container';
import { bilinearInterpolate } from "utilities/mathematic.js";
import { isInside } from 'utilities/geometric';
import { distanceToString } from 'utilities/format';
import { isFinite, round, capitalize, values, mapValues, isArray, minBy, isEmpty, trimEnd } from 'lodash';
import ToolContext from 'components/services/ToolContext';

/**
 * @function Position
 * @description Renders the position and details of a device at the current mouse position.
 * This component is memoized for performance optimization.
 * 
 * @returns {React.Component} The rendered Position component
 */
const Position = memo((props) => {
  const { bbox: { x0, y0 }, scale, zoom, svgbox: { top, left } } = useSelector(getDrawingBox);
  let deviceType = useSelector(getCurrentDevice);
  /**
 * @note: The current implementation of CRD uses a single camera so the menu item is 'camera' while the object is ready to support 
 * multiple cameras. Once that feature is enables and the menu item (label) changes to 'cameras' this override will not be necesary 
 */
  deviceType = `${deviceType}${deviceType === 'camera' ? 's' : ''}`;
  const pos = useMousePosition();
  const x = (pos?.x - x0) / scale;
  const y = (pos?.y - y0) / scale;

  const inArea = useSelector(getArea);
  const { id, location: areaOrigin, name: areaName, [deviceType]: devices, microphones } = useSelector(state => values(state.container.areas).filter(area => {
    return isInside([x + (zoom ? area.location.left : 0), y + (zoom ? area.location.top : 0)], { ...area.location, ...area.dimensions }) && (!inArea || inArea === area.id)
  }).pop() ?? {});

  const deviceContainer = document.getElementById(`${id}-${deviceType}`);
  const device = Array.from(deviceContainer ? deviceContainer.children : []).find(child => isInside([pos?.x + left, pos?.y + top], child?.getBoundingClientRect()))?.id;

  const [area, , deviceId] = device ? device.split('-') : [];
  const context = useContext(ToolContext);
  const equipment = context.constants.equipment;

  /**
   * @function getDeviceData
   * @description Retrieves and formats device data based on the current device type and ID.
   * @returns {Object|undefined} An object containing device data, or undefined if no data is available.
   * @property {string} modelName - The name of the device model.
   * @property {Object} location - The location coordinates of the device.
   * @property {number} location.x - The x-coordinate of the device.
   * @property {number} location.y - The y-coordinate of the device.
   * @property {number|null} location.z - The z-coordinate of the device (if applicable).
   */
  const getDeviceData = () => {
    if (devices && deviceId) {
      if (isArray(devices)) {
        return devices[deviceId]
      } else {
        const defaultDevice = equipment[deviceType][devices.model.replace('mic', '_mic')];
        const { isBarSpkr = false, isBarCamera = false, isPendantMic = false } = defaultDevice;
        const modelName = ((isBarSpkr || isBarCamera) ? equipment.microphones[microphones.model.replace('mic', '_mic')] : defaultDevice).modelName;
        const location = {
          ...devices.layout[deviceId].location,
          z: isPendantMic ? devices.height : null
        }
        return ({ modelName, location })
      };
    };
  };

  const deviceData = getDeviceData()

  const dispatch = useDispatch();

  /**
   * @description Effect to update bubble content visibility based on device data and area.
   */
  useEffect(() => {
    if ((!inArea || inArea === area) && deviceData) {
      dispatch(ReducersIndex.uiReducers.setBubbleContent(true));
    } else {
      dispatch(ReducersIndex.uiReducers.setBubbleContent(false));
    };
    // eslint-dissable-next-line
  }, [deviceData, x0, y0, top, left, scale, inArea, areaOrigin, zoom, dispatch, area])

  const isMeter = useSelector(getIsMeter);

  const { modelName, location, horAngle, verAngle, powerTap70V, gain, delay } = deviceData ?? {};
  const deviceLocation = mapValues(location, distance => distance ? distanceToString(distance, isMeter) : null);
  const devId = `${areaName} ${deviceType.substring(0, deviceType.length - 1)} ${+deviceId + 1}`;

  return (
    <Wrapper>
      <div>{capitalize(devId)}</div>
      <div>Model: {modelName}</div>
      <div>X: {deviceLocation.x}, Y: {deviceLocation.y}{deviceLocation.z ? `, Z: ${deviceLocation.z}` : ''}</div>
      {horAngle !== undefined && <div>Hor Angle: {horAngle}deg</div>}
      {verAngle !== undefined && <div>Ver Angle: {verAngle}deg</div>}
      {powerTap70V !== undefined && <div>70V Tap: {powerTap70V} Watts</div>}
      {gain !== undefined && <div>Gain: {gain} dB</div>}
      {delay !== undefined && <div>Delay: {delay} ms</div>}
    </Wrapper>
  )
});



/**
 * @function Level
 * @description Renders the level information for the current mouse position in a heatmap.
 * This component is memoized for performance optimization.
 * 
 * @returns {React.Component} The rendered Level component
 */
const Level = memo((props) => {
  const { bbox: { x0, y0 }, scale, zoom } = useSelector(getDrawingBox);

  /**
   * @description Calculates the mouse position relative to the drawing area
   */
  const pos = useMousePosition();
  const x = (pos?.x - x0) / scale;
  const y = (pos?.y - y0) / scale;

  /**
   * @description Selects the current area based on mouse position
   */
  const inArea = useSelector(getArea);
  const {id, location, speakerLayout } = useSelector(state => values(state.container.areas).filter(area => {    
    return isInside([x + (zoom ? area.location.left : 0), y + (zoom ? area.location.top : 0)], { ...area.location, ...area.dimensions }) && (!inArea || inArea === area.id)
  }).pop() ?? {});

  // const speakerLayout = spkrLayout ?? speakers?.layout ?? [];

  /**
   * @description Retrieves plot properties from Redux store
   */
  const {label, source, units, roundTo = 1} = useSelector(getPlotProps);
  let heatmap = useSelector(getRelativeAveragePlotData);
  let mapArray = heatmap?.mapArray;

  /**
   * @description Calculates the pixel level at the current mouse position
   */
  let pixelLevel;
  if (id) {
    if (source) {
      heatmap = speakerLayout?.filter(map => isInside([x + (zoom ? location.left : 0), y + (zoom ? location.top : 0)], {...map.bbox})).pop();
      mapArray = heatmap?.[source];
    };
    if (mapArray) pixelLevel = bilinearInterpolate({ x: x - (zoom ? 0 : location.left), y: y - (zoom ? 0 : location.top) }, mapArray, heatmap.receiverSpacing, heatmap.receiverXOffset, heatmap.receiverYOffset) - (source ? 0 : heatmap.averageTarget);
  };

  /**
   * @description Effect to update bubble content visibility based on pixel level
   */
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ReducersIndex.uiReducers.setBubbleContent(isFinite(pixelLevel) ? Boolean(pixelLevel) : false));
  }, [pixelLevel, dispatch]);

  return (
    <Wrapper>
      <div className='text-capitalize'>{`${label}: ${round(pixelLevel, roundTo)} `}
        <span style={{ textTransform: 'initial' }}>{units}</span>
      </div>
    </Wrapper>
  )
});

// todo: this static content should be somewhere else so the static compoent simple gets a child component to render rather than explicitly have the data
// todo: this is currently working as it's the only place were it's used so far
const Static = (props) => {
  // this neefds to set the anchor
  return (
    <Wrapper>
      <div>Speech Transmission Index (STI)</div>
      <div style={{ 'whiteSpace': 'pre-wrap' }}>{'An estimate of how well speech reproduced by\nthe sound system in the room will be understood'}</div>
      <style>{"th, td{border:1px solid #fff; width: 120px};"}</style>
      <table >
        <thead>
          <tr>
            <th>Quality</th>
            <th>STI Value</th>
            {/* <th>intelligibility of Syllables</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>BAD</td>
            <td>0 - 0.3</td>
            {/* <td>0 - 35%</td> */}
          </tr>
          <tr>
            <td>POOR</td>
            <td>0.3 - 0.45</td>
            {/* <td>35% - 50%</td> */}
          </tr>
          <tr>
            <td>FAIR</td>
            <td>0.45 - 0.6</td>
            {/* <td>50% - 65%</td> */}
          </tr>
          <tr>
            <td>GOOD</td>
            <td>0.6 - 0.75</td>
            {/* <td>65% - 90%</td> */}
          </tr>
          <tr>
            <td>EXCELLENT</td>
            <td>0.75 - 1</td>
            {/* <td>90% - 100%</td> */}
          </tr>
        </tbody>
      </table>
    </Wrapper>
  )
}


/**
* @function Distance
* @description A memoized component that displays the distance to the nearest device (camera/microphone) 
* from the current mouse position.
* 
* @returns {React.Element} Component displaying distance information in a tooltip bubble
* 
* @description
* This component:
* 1. Tracks mouse position relative to the drawing area
* 2. Finds the nearest device of specified type in the current area
* 3. Calculates distance to that device
* 4. Updates bubble content visibility based on distance
* 5. Sets selected device in Redux store
* 
* @note 
* Special handling for camera devices:
* Current implementation supports single camera while being ready for multiple cameras.
* Adds 's' to 'camera' device type to match data structure until menu labels are updated.
*/
const Distance = memo((props) => {
  const { bbox: { x0, y0 }, zoom, scale } = useSelector(getDrawingBox);
  let deviceType = useSelector(getCurrentDevice);
  deviceType = `${deviceType}${deviceType === 'camera' ? 's' : ''}`;
  const pos = useMousePosition();
  const x = (pos?.x - x0) / scale;
  const y = (pos?.y - y0) / scale;

  const inArea = useSelector(getArea);
  const { id, [deviceType]: { layout } = {} } = useSelector(state => values(state.container.areas).filter(area => {
    return isInside([x + (zoom ? area.location.left : 0), y + (zoom ? area.location.top : 0)], { ...area.location, ...area.dimensions }) && (!inArea || inArea === area.id)
  }).pop() ?? {});

  const closestDevice = isEmpty(layout) ? null : minBy(layout, device => Math.hypot(device.location.x - x, device.location.y - y));
  const deviceId = id ? `${id}-${deviceType}-${layout?.indexOf(closestDevice)}` : null;
  const distance = closestDevice ? Math.hypot(closestDevice.location.x - x, closestDevice.location.y - y) : 0;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ReducersIndex.uiReducers.setBubbleContent(distance > 0.1 ? Boolean(distance) : false));
    dispatch(ReducersIndex.uiReducers.setSelectedDevice(deviceId));
  }, [id, distance, deviceId, dispatch]);

  const isMeter = useSelector(getIsMeter);

  return (
    <Wrapper>
      <div className='text-capitalize'>{`distance to nearest ${trimEnd(deviceType, 's')}: ${distanceToString(distance, isMeter)} `}
      </div>
    </Wrapper>
  )
});








/**
 * Styled component for the Level wrapper.
 * @component
 */
const Wrapper = styled.div.attrs(props => ({
}))`
  font-size: 10px;
  padding:0px;
`;

const bubbles = {
  position: {
    element: Position,
  },
  level: {
    element: Level,
  },
  Static: {
    element: Static
  },
  distance: {
    element: Distance
  }
}

export { Position, Level, Static, Distance }
export default bubbles
