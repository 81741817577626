import styled from "styled-components";
import { useSelector } from "react-redux";
import { getDrawingBox } from "reduxModules/ducks/ui";

const Automatic = ({ active, area }) => {

  // const theState = useSelector(state => state.container)
  // console.log("theState: ", JSON.stringify(theState));
  const {bbox, scale, zoom} = useSelector(getDrawingBox);

  const { left, top } = area.location;
  const x0 = bbox.x0 + left * scale * (!zoom);
  const y0 = bbox.y0 + top * scale * (!zoom);
  const hor = area.storageOrientation === 'horizontal';

  return (
    <Group active={active} scale={scale} x={x0} y={y0} >
      
      {conveyor({ ver: hor, sh: scale >= 20, x: 0,y:0, l: 7 })} 
      {ninety({ sh: scale >= 20, x: 7, y: 0 })}


      {palletWrapper({ ver: hor, sh: scale >= 20, x: 0, y: 1 })}
      {ninety({ sh: scale >= 20, x: 7, y: 10 })}

      

      {ninety({ sh: scale >= 20, x: 5.2, y: 1.8})}

      {conveyor({ ver: !hor, sh: scale >= 20, x: 8.25,y:3.05, l: 1.8 })} 

      {conveyor({ ver: hor, sh: scale >= 20, x: 0,y:1.8, l: 5.2 })} 
      {arm({ver: hor, sh: scale >= 20, x: 7, y: 0 })}

      {conveyor({ ver: hor, sh: scale >= 20, x: 0,y:5.7, l: 5.2 })}
      {ninety({ sh: scale >= 20, x: 5.2, y: 5.7})}
      {split({ ver: !hor, sh: scale >= 20, x: 4.9, y: 8.6, l: 7, fv: true })}

      {conveyor({ ver: hor, sh: scale >= 20, x: 0,y:11.4, l: 5.2 })}
      {ninety({ sh: scale >= 20, x: 5.2, y: 11.4})}
      {split({ ver: !hor, sh: scale >= 20, x: 4.9, y: 14.3, l: 7, fv: true })}

      {split({ ver: hor, sh: scale >= 20, x: 0, y: 0, l: 7 })}


    </Group>
  )
};

const Group = styled.g.attrs(props => ({
  id: props.id,
}))`
    fill: none;  
    stroke: ${props => props.active ? 'var(--app-red)' : 'var(--app-custom-gray)'};
    shape-rendering: crispedges;
    transform: ${props => `translate(${props.x}px, ${props.y}px) scale(${props.scale}) `};
  `;

// pallet wrapper size 700x440 adjusted to 700x700
const palletWrapper = ({ ver=true, sh, x = 0, y = 0 }) => {
  const rollers = `m.08.15v1.3 ${'m.12 0v-1.3m.12 0v1.3'.repeat(28)} m.12 0v-1.3`;
  const midRolls = `m.14 1.6v1.3 ${'m.24 0v-1.3m.24 0v1.3'.repeat(14)}`;
  return (
    <g transform={ver ? '' : `rotate(${ver ? 0 : 90}) translate(${y - x} -${y + x + 7})`} stroke-width='1'>
      <path id='r' d={`m${x} ${y + 2.5}h1.2v-1.2h4.6v1.2h1.2m0 2h-1.2v1.2h-4.6v-1.2h-1.2 m0-.2h7v-.15h-7zm0-1.6h7v.15h-7`} vectorEffect={'non-scaling-stroke'} />
      <path id='r' d={`m${x} ${y + 2.7}${rollers}`} stroke-width={sh ? 1 : .5} vectorEffect={'non-scaling-stroke'} />
      {sh && <path d={`m${x} ${y + 1.25}${midRolls}`} stroke-dasharray='6 1.5 1 1.5' vectorEffect={'non-scaling-stroke'} />}
      <path d={`m${x + 4.8} ${y + 1.7}v.3h-.2v-.3h-2.2v.3h-.2v-.3h.2v3.6h-.2v-.3h.2v.3h2.4v-.3h-.2v.3v-3.6zm-.35.1v.2h-.2v-.2h.2v3.4h-.2v-.2h.2v.2h-1.9v-.2h.2v.2h-.2v-3.4h.2v.2h-.2v-.2z
        m-1.7 .4l.75 1.05h-.32h1.27v.5h-.6l.6 .85-.2 .16-.71-1.01h.32h-1.31v-.5h.63l-.63-.9z`} fill='#fff' vectorEffect={'non-scaling-stroke'} />
    </g>
  )
};

// conveyor with adjustable length. width is 1.6  
// default y centers with pallet
const conveyor = ({ ver = true, sh, x = 0, y = 2.7, l = 7, full = true, d = false }) => { // use y=3.05 toalligh with wrapper 
  const wth = full ? 1.3 : .6;
  const width = wth + d * (wth + .5);
  const qty = parseInt((l - 0.04) / .24);
  if (qty < 1) return;
  const start = (l - (qty * .24)) / 2 + .06;
  const rollers = `m${start}.15v${width}${`m.12 0v-${width}m.12 0v${width}`.repeat(qty - 1)} m.12 0v-${width}`;
  const midRolls = `m${start + .06} 0 v${width} ${`m.24 -${width}v${width}`.repeat(qty - 1)} `;
  const adj = (l - 1.6) / 2;
  return (
    <g transform={ver ? '' : `rotate(${ver ? 0 : 90}) translate(${y - x - adj} -${y + x + l - adj})`} stroke-width='1'>
      <path d={`m${x} ${y + width + .3}h${l}v-.15h${-l}zm0-${width + .3}h${l}v.15h${-l}z`} vectorEffect={'non-scaling-stroke'} />
      <path d={`m${x} ${y}z${rollers}`} stroke-width={sh ? 1 : .5} vectorEffect={'non-scaling-stroke'} />
      {sh && <path id='mr' d={`m${x} ${y + .15}${midRolls}`} strokeDasharray='6 1.5 1 1.5' vectorEffect={'non-scaling-stroke'} />}
      {d && <path d={`m${x + .06} ${y + .76 + full * .69} l${l - .36 - !full * .24} ${.9 + full * .7} v.15 l-${l - .36 - !full * .24} -${.9 + full * .7}z m${l - .12} ${0}l-.1 0-.2.2 0 .1.2 .2.1 0z`} fill="#fff" vectorEffect={'non-scaling-stroke'} />}
    </g>
  )
};


const ninety = ({ sh, x = 0, y = 0, full = true, fh = false, fv = false }) => {
  const adj = full * .7;
  let rollers = `m.06.15v${.6 + adj}m.12 0v-${.6 + adj}`;
  let midRolls = `m.12.15v${.6 + adj}`;
  for (let alpha = Math.PI / 18; alpha < Math.PI / 2; alpha += Math.PI / 18) {
    const xv = Math.cos(alpha);
    const yv = Math.sin(alpha);
    rollers += `M${x + (1.5 * xv) - (.06 * yv)} ${y + (2.25 + adj) - (1.5 * yv) - (.06 * xv)}l ${(.6 + adj) * xv} ${-(.6 + adj) * yv}m${.12 * yv} ${.12 * xv}l${-(.6 + adj) * xv} ${(.6 + adj) * yv}`;
    midRolls += `M${x + (1.5 * xv)} ${y + (2.25 + adj) - (1.5 * yv)}l ${(.6 + adj) * xv} ${-(.6 + adj) * yv}`;
  };
  rollers += `m1.18 1.3h${.6 + adj}m0 .12h-${.6 + adj}`;
  midRolls += `m${full ? 1.015 : 1.15} ${1.96 + adj} h${.6 + adj}`;
  return (
    <g transform={`scale(${1 - 2 * fh}, ${1 - 2 * fv}) translate(-${fh * (2 * x + 2.95)} -${fv * (2 * y + 2.95)})`} stroke-width='1' shape-rendering='geometricprecision' >
      <path d={`m${x} ${y}h.2a${2.2 + adj} ${2.2 + adj} 0 0 1 ${2.05 + adj} ${2.05 + adj}v.2h-.15v-.2a${2.1 + adj} ${2.1 + adj} 0 0 0 -${1.9 + adj} -${1.9 + adj}h-.2z
        m0 ${.9 + adj}a1.35 1.35 0 0 1 1.35 1.35h.15a1.5 1.5 0 0 0 -1.5 -1.5z`} vectorEffect={'non-scaling-stroke'} />
      <path d={`m${x} ${y}${rollers} }`} stroke-width={sh ? 1 : .5} vectorEffect={'non-scaling-stroke'} />
      {sh && <path d={`m${x} ${y}${midRolls}`} stroke-dasharray='6 1.5 1 1.5' vectorEffect={'non-scaling-stroke'} />}
    </g>
  )
}


const split = ({ ver = true, sh, x = 0, y = 0, full = true, lin = 2, lsplit = 3, lcont = 2, lout = 2, fh = false, fv = false }) => {
  const l = lin + lsplit + Math.max(lcont, lout);
  const adj = (l - 3.4) + !full * (ver ? fh : fv) * 1.4;
  // scale(${1 - 2 * fh}, ${1 - 2 * fv}) translate(-${fh * (2 * x + 7)} -${fv * (2 * y + 3.4)}) 
  // y -= fh * 1
  return (
    <g transform={`${ver ? '' : `rotate(${ver ? 0 : 90}) translate(${y - x - 0} -${y + x + l - adj}) `} 
    scale(${1 - 2 * fv}, ${1 - 2 * fh}) translate(-${fv * (2 * x + l)} -${fh * (2 * y + l - adj)}) `} stroke-width='1'>
      {conveyor({ sh: sh, x: x, y: y, full: full, l: lin, d: false })}
      {conveyor({ sh: sh, x: x + lin, y: y, full: full, l: lsplit, d: true })}
      {conveyor({ sh: sh, x: x + lin + lsplit, y: y, full: full, l: lcont, d: false })}
      {conveyor({ sh: sh, x: x + lin + lsplit, y: y + 1.1 + full * .7, full: full, l: lout, d: false })}
    </g>
  )
}

const arm = ({ ver=true, sh, x = 0, y = 2.7, l = 7 }) => {

  return (
    <g stroke-width='1' >
      <path d={`m${x} ${y} h7 v7 h-7z`} vectorEffect={'non-scaling-stroke'}/>
    </g>
  )
}




















export default Automatic