import { useContext, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/templates/Modal';
import ToolContext from 'components/services/ToolContext';
import ReducersIndex from 'reduxModules/ducks';

const ModalManager = ({top}) => {
  const modals = useSelector(state => state.ui.modals)
  const currentModal = modals.find(modal => modal.modalOpen)?.modalName;
  const menu = useContext(ToolContext).modals[currentModal]?.menu;
  const dispatch = useDispatch();
  useEffect(() => {
    if (menu) dispatch(ReducersIndex.uiReducers.setCurrentView(menu));
  }, [menu, dispatch]);
  return (
    <Fragment>
      {modals.map((modal, index) => <Modal key={`modal-${index}`} modalName={modal.modalName} top={top}/>)}
    </Fragment>
  )
};

export default ModalManager;