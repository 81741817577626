import { G, Path, Circle } from '@react-pdf/renderer';

const Icons = {
  LoadingDock: () =>
    <Path d="m6.096 0h-3.048v-3.048h3.048zv-24.384h24.384v24.384zl24.384-24.384m-24.384 0l24.384 24.384h3.048v-3.048h-3.048" stroke={"#000"}/>,

  Speaker: () =>
    <G fill='none' strokeWidth='1' strokeMiterlimit='0' transform="translate(-24 -24)">
      <Circle cx="25" cy="25" r="23" fill={'#e6e7e8'} stroke={'#d7282f'} />
      <Path d="M26.7 12v25.9l-9.2-7.4h-7.7v-11.2h7.7zm7 2.6a25.3 25.3 0 0 1 0 20.8m-3.4-2.7a22 22 0 0 0 0-15.4m6.7-5.4a29.4 29.4 0 0 1 0 26.2"
        stroke={'#2d2f38'} />
    </G>,
  SpeakerArrow: ({ angle }) =>
    <Path d='m60 0l-30-12q8 12 0 24z' stroke={'#d7282f'} fill={'#d7282f'} strokeWidth='1' transform={`rotate(${angle})`} />,
}


export default Icons;

// 'button_active': {
//   'border_stroke': 'var(--app-red)',
//   'border_fill': 'var(--app-light-gray)',
//   'icon_stroke': 'var(--app-dark-gray)',
//   'icon_fill': ' var(--app-dark-gray)',
//   border: '1px solid transparent'
// },

// :root {
//   --app-red: #d7282f;
//   --app-medium-gray: #74777a;
//   --app-light-gray: #e6e7e8;
//   --app-dark-gray: #2d2f38;
//   --app-custom-gray: #a5aaad;
// }