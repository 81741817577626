import styled from 'styled-components';

const Select = (props) => {
  return (
    <Select.Wrapper onChange={props.onChange} value={props.value || props.placeHolder} dim={!props.value} isNav={props.isNav}>
      { props.placeHolder && !props.value && <option disabled>{props.placeHolder}</option> }
      { props.items.map((item, index) => <option key={item+index} value={item}>{String(item).replaceAll('_', ' ').concat(props.unit || '')}</option>)}
    </Select.Wrapper>
  )
};

Select.Wrapper = styled.select.attrs(props => ({
  className: 'text-capitalize',
}))`
  border: none;
  background-color: var(--app-light-gray);
  height: 26px;
  outline: none;
  width: auto;
  min-width: ${props => props.isNav ? '80px': '140px'};;
  border-radius: 0px;
  opacity: ${props => props.dim ? .5 : 1}
`;


export default Select;
