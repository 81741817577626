import constants from "toolConstants/classroomDesigner/constants";
import { keys } from "lodash";

const modals = {
  launch: {
    image: 'images/Classroom_Designer_splash.jpg',
    title: 'Welcome to the Biamp Classroom Designer!',
    intro: `You will be guided through a series of steps to design your classroom, place your
      furniture, and get customized placement plan for Biamp speakers and microphones.`,
    button: 'BEGIN CLASSROOM DESIGN',
    target: 'classroom', //'classroom',
  },

  editProject: {
    dispatcher: 'setProject',
    header: 'EDIT PROJECT DETAILS',
    body: [
      {
        element: 'textInput',
        name: 'name',
        label: 'Project Name',
      },
      {
        element: 'textInput',
        name: 'company',
        label: 'Company Name'
      },
      {
        element: 'textInput',
        name: 'designer',
        label: 'Design engineer'
      }
    ],
    footer: [
      {},
      {
        label: 'RETURN TO CLASSROOM >',
        target: ''
      }
    ]
  },

  classroom: {
    menu: 'floorplan',
    header: `DESIGN YOUR CLASSROOM `,
    body: [
      {
        element: 'textInput',
        name: 'name',
        label: 'Classroom Name',
        dispatcher: 'setName'
      },
      {
        element: 'dimensionInput',
        label: 'Classroom Dimensions',
        ids: ['length', 'width'],
        dispatcher: 'setDimensions',
        // todo: dimension validation
        // errorCode: 1000,
      },
      {
        element: 'dimensionInput',
        label: 'Ceiling Height',
        ids: ['min'],
        dispatcher: 'setCeilingHeight',
        // todo: ceiling height vallidation
        // errorCode: 1100,
      },
      {
        element: 'toggleButtons',
        label: 'Classroom Orientation',
        items: ['lengthwise', 'widewise'],
        // note: defined in room
        dispatcher: 'setAreaOrientation'
      },

      {
        element: 'selector',
        label: 'Ceiling Tile Dimension',
        name: 'tile',
        items: keys(constants.ceilingTiles),
        // note: defines in room
        dispatcher: 'setCeilingTiles'
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {},
      {
        label: 'CONTINUE TO ADD FURNITURE >',
        target: 'furniture'
      }
    ],

  },

  furniture: {
    menu: 'furnishing',
    header: 'DESIGN YOUR FURNITURE',
    body: [
      {
        element: 'toggleButtons',
        extend: true,
        label: 'Table Shape',
        items: keys(constants.tables),
        name: 'shape',
        theme: 'furnitureGroup',
        activeState: 'furniture_active',
        inactiveState: "furniture_inactive",
        dispatcher: 'setTables'
      },
      // note: Here in classroom we're using setTables / tables (plurar) to differentiate from CRD
      {
        condition: { parameter: ['tables'], check: ([tables]) => !['round', 'racetrack'].includes(tables.shape) },
        element: 'numberInput',
        label: 'Number of Rows',
        name: 'rows',
        dispatcher: 'setTables'
      },
      {
        condition: { parameter: ['tables'], check: ([tables]) => !['round', 'racetrack'].includes(tables.shape) },
        element: 'numberInput',
        label: 'Tables Per Row',
        name: 'tablesPerRow',
        dispatcher: 'setTables'
      },
      {
        condition: { parameter: ['tables'], check: ([tables]) => ['round', 'racetrack'].includes(tables.shape) },
        element: 'numberInput',
        label: 'Number of Tables',
        name: 'totalTables',
        dispatcher: 'setTables'
      },
      {
        condition: { parameter: ['tables'], check: ([tables]) => ['rectangle', 'round', 'racetrack'].includes(tables.shape) },
        element: 'numberInput',
        label: 'Students per Table',
        name: 'studentsPerTable',
        dispatcher: 'setTables'
      },
      {
        element: 'output',
        label: 'Total Students',
        getter: 'getTotalStudents',
      },
      {
        element: 'toggleButtons',
        label: 'Instructor',
        items: keys(constants.instructor), //['lectern', 'desk'],
        labels: true,
        useIcon: false,
        name: 'shape',
        theme: 'acousticGroup',
        inactiveState: 'acoustic_inactive',
        dispatcher: 'setInstructor'
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {
        label: '< BACK',
        target: 'classroom',
        color: 'var(--app-medium-gray)'
      }, {
        label: 'CONTINUE TO CONFIGURE DEVICES >',
        target: 'devices'
      }
    ]
  },

  // marker
  devices: {
    menu: 'microphones',
    header: 'CONFIGURE YOUR DEVICES',
    body: [
      {
        element: 'toggleButtons',
        extend: true,
        label: 'Mic Style',
        items: keys(constants.equipment.microphones),
        labels: true,
        name: 'model',
        theme: 'deviceGroup',
        activeState: 'device_selected',
        dispatcher: 'setMicrophones'
      },      
      {
        condition: { parameter: ['microphones'], check: ([microphones]) => microphones.model === 'pendantmic'},
        className: 'ms-2',
        element: 'dimensionInput',
        label: 'Microphone Height',
        ids: ['height'],
        dispatcher: 'setMicrophones',
        // todo: mic height vallidation
      },
      {
        element: 'toggleSelect',
        extend: true,
        label: 'Mic Coverage',
        // todo get from contants 
        items: ['full_room', 'teacher', 'students'],
        labels: true,
        toggle: ['full_room'],
        noSelect: 'full_room',
        name: 'coverage',
        theme: 'buttonGroupBig',
        activeState: 'coverage_active',
        inactiveState: "coverage_inactive",
        dispatcher: 'setMicrophones',
      },
      {
        // todo: mode acc to constants
        element: 'toggleButtons',
        label: 'Room Acoustics',
        items: constants.acoustics, // ['poor', 'fair', 'good', 'great', 'perfect'],
        labels: true,
        theme: 'acousticGroup',
        inactiveState: 'acoustic_inactive',
        dispatcher: 'setAcoustics'
      },
      {
        element: 'toggleSelect',
        extend: true,
        label: 'Speaker Coverage',
        items: ['full_room', 'focused'],
        labels: true,
        noSelect: 'focused',
        name: 'coverage',
        theme: 'buttonGroupBig',
        activeState: 'coverage_active',
        inactiveState: "coverage_inactive",
        dispatcher: 'setSpeakers'
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {
        label: '< BACK',
        color: 'var(--app-medium-gray)',
        target: 'furniture'
      },
      {
        label: 'CONTINUE TO CLASSROOM >',
        target: ''
      }
    ]
  },


}

export default modals;