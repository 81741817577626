
import { useDispatch, useSelector } from 'react-redux';
import Button from "components/molecules/Button";
import ReducersIndex from 'reduxModules/ducks';

const Tutorial = (props) => {
  const apiVersion = useSelector(state => state.ui.apiVersion) // temp 

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(ReducersIndex.uiReducers.openModal('tutorial'))
    //alert(`Current API Version: ${apiVersion}`)
  }

  return (
    <Button name={'help'} state={'button_active'} onClick={handleClick} />
  )
}

export default Tutorial;


  // // Tutorial template
  // var tutorialContext = {
  //   id: "tutorial",
  //   nav: true,
  //   lines: ["emptydiv", "emptydiv", "progress"],
  //   args: [{
  //     id: "tutorial-line1"
  //   }, {
  //     id: "tutorial-line2"
  //   }, {
  //     id: "progress-dots",
  //     label: false,
  //     index: Array(12).fill(0)
  //   }]
  // };

  // DOTS
//   <div class="modal-content progress-dots">
// {{#each args.index}}
// <span class = "dots point-me" data-index={{@index}}></span>
// {{/each}}
// </div>


  // // Open tutorial
  // $("#tutorial").click(function (evt) {
  //   var openModal = $(".cd-modal:visible").attr("id");
  //   $("#tutorial-modal .cd-modal-next").attr("data-to", "#" + openModal);
  //   $("#" + openModal).fadeOut("slow");
  //   isModalOpen = false;
  //   $("#tutorial-modal").fadeIn("slow");
  //   isTutorialOpen = true;
  //   $(".dots").removeClass("dot-active");
  //   $("#tutorial-modal .navleft.fa.fa-chevron-left, #tutorial-modal .navright.fa.fa-chevron-right").show()
  //   // Hide footer if first timer
  //   if (isFirstTimeUser) {
  //     $("#tutorial-modal .cd-modal-next").first().hide()
  //   };
  //   // add the highlight
  //   $("<span>").attr({
  //     class: "spotlight"
  //   }).appendTo("#tutorial-modal")

  //   // set room parameters for tutorial
  //   // Get or set tutorial room data
  //   if (currentRoom.isNotValid()) {
  //     sampleRoom = true;
  //     tutorialData = [[], ["Bldg 304 Room 5E", "50'", "45'", "10'", "lenghtwise", "3"], ["rectangle"]];
  //   } else {
  //     sampleRoom = false;
  //     tutorialData = [[], [currentRoom.name, toFeet(currentRoom.length).toString() + "'", toFeet(currentRoom.width).toString() + "'", toFeet(currentRoom.height).toString() + "'", currentRoom.orientation, currentRoom.tileSelection.toString()]];
  //     tutorialData.push([currentRoom.table.shape, currentRoom.rows, currentRoom.tablesRow, currentRoom.table.studentsTable]);
  //   };

  //   // trigger first step
  //   $(".dots[data-index = '0']").addClass("dot-active");
  //   tutorialStep(0);
  // });

  // // Navigate next
  // $(".navright").click(function (evt) {
  //   // Handle the dot
  //   var step = $(".dot-active").data("index") != undefined ? $(".dot-active").data("index") : -1;
  //   step++;
  //   $(".dot-active").removeClass("dot-active");
  //   if ($(".dots[data-index = " + step + "]").length > 0) {
  //     $(".dots[data-index = " + step + "]").addClass("dot-active");
  //     tutorialStep(step, evt);
  //   } else {
  //     $("#tutorial-modal").fadeOut("slow");
  //     $(".spotlight").remove();
  //     $(".cd-modal").css("background", "rgba(255,255,255,.5)");
  //     isTutorialOpen = false;
  //     // set the cookie
  //     resetCookie()
  //     // reset room if first time
  //     if (isFirstTimeUser || currentRoom.name == "Bldg 304 Room 5E") {
  //       $("#reset").trigger("click", [false])
  //     }
  //     isFirstTimeUser = false;
  //     $("dialog_ok").show();
  //     $(".cd-modal-next").fadeIn("slow");
  //   };

  // });

  // // Navigate back
  // $(".navleft").click(function (evt) {
  //   // Handle the dot
  //   var step = $(".dot-active").data("index") != undefined ? $(".dot-active").data("index") : -1;
  //   step--;
  //   if (step >= 0) {
  //     $(".dot-active").removeClass("dot-active");
  //     $(".dots[data-index = " + step + "]").addClass("dot-active");
  //   } else {
  //     step = 0;
  //   };
  //   tutorialStep(step, evt);
  // });

  // // Navigate by dots
  // $(".dots").click(function (evt) {
  //   $(".dots").removeClass("dot-active");
  //   $(this).addClass("dot-active");
  //   tutorialStep($(this).data("index"), evt);
  // });

  // // Typewritter effect 
  // function typeWriter(fields, data, elemIndex = 0, charIndex = 0) {
  //   var elem = $(fields[elemIndex]).is("distance-input") ? $(fields[elemIndex].shadowRoot).find(":input[type='text']")[0] : fields[elemIndex];
  //   if (charIndex < data[elemIndex].length) {
  //     $(elem).val($(elem).val() + data[elemIndex].charAt(charIndex));
  //     charIndex++;
  //     setTimeout(function () { typeWriter(fields, data, elemIndex, charIndex) }, 50);
  //   } else {
  //     charIndex = 0;
  //     elemIndex++;
  //     if (elemIndex < fields.length) {
  //       setTimeout(function () { typeWriter(fields, data, elemIndex, charIndex) }, 75);
  //     };
  //   };
  // };

  // // Tutorial steps
  // function tutorialStep(step, evt) {
  //   // Clear the content
  //   $(".empty-div").html("");
  //   if (step < 2) { $(".spotlight").removeClass("is-visible") } else { $(".spotlight").addClass("is-moving") };
  //   $(".cd-modal").css("background", "rgba(255,255,255,.5)");

  //   // Set the room if some steps have been skipped
  //   if (step >= 2 && currentRoom.isNotValid()) {
  //     createRoom(tutorialData[1]);
  //     addTable(tutorialData[2]);
  //   };

  //   // Do the step thing
  //   switch (step) {
  //     case 0:
  //       $("#tutorial-line1").html("Before you start designing, we want to take you through a quick tutorial that highlights the features of this clasroom designer tool.");
  //       break;
  //     case 1:
  //       $("#tutorial-line1").html($("#classroom-modal").find(".cd-modal-container").clone().css({ transform: "scale(.50)", margin: "-80px -75px" }));
  //       $("#tutorial-line2").html("Enter your room information into the four pop up dialogs. Don't worry if you make mistakes, we can change these parameters later.");
  //       $("#navtoggleclassroom").prop('checked', true).trigger("click")
  //       // Animate entries 
  //       var fields = $("#tutorial-line1 #classroom_name").add("#tutorial-line1 distance-input");
  //       fields.each(function (i, e) {
  //         $(e).is("distance-input") ? $(e.shadowRoot).find(":input[type='text']").val("") : $(e).val("");
  //       });
  //       setTimeout(function () { typeWriter(fields, tutorialData[step]) }, 500);
  //       if (isFirstTimeUser || currentRoom.isNotValid()) {
  //         setTimeout(function () { createRoom(tutorialData[step]) }, 2800);
  //       } else {
  //         var timeout = tutorialData[step].join(' ').length * 50 + 800;
  //         setTimeout(function () { setTiles(tutorialData[step]) }, timeout);
  //       };
  //       break;
  //     case 2:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtoggleclassroom").prop('checked', true).trigger("click");
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#classroombutton") }, 500);
  //       $("#tutorial-line1").html("In the FLOORPLAN menu, you can edit room parameters and change room orientation. You can also move the door or whiteboard as needed.");
  //       break;
  //     case 3:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtoggleceiling").prop('checked', true).trigger("click");
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#ceilingbutton") }, 600);
  //       $("#tutorial-line1").html("The CEILING Menu allows you to modify the arrangement of ceiling tiles in the classroom. In this mode, you can also select tiles that are blocked. The calculator will avoid placing mics or speakers on blocked ceiling tiles.");
  //       break;
  //     case 4:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtogglefurnishing").prop('checked', true).trigger("click");
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#furnishingbutton") }, 600);
  //       $("#tutorial-line1").html("The FURNISHING menu allows you to modify the table shape as well as the arrangement. In this mode, you can also adjust spacing betwen tables, move, add or delete tables and remove chairs.");
  //       break;
  //     case 5:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtoggleacoustics").prop('checked', true).trigger("click");
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#acousticsbutton") }, 600);
  //       $("#tutorial-line1").html("The ACOUSTICS menu allows you to estimate the acoustic quality of your room. The calculator uses this to adjust the quantity and spacing of microphones.");
  //       break;
  //     case 6:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtogglemics").prop('checked', true).trigger("click");
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#micsbutton") }, 500);
  //       $("#tutorial-line1").html('The MICS menu allows you to select the desired microphone type and coverage. "FULL ROOM" covers the entire room while "TEACHER" and "STUDENTS" can be selected together or separate for a more focused coverage.');
  //       break;
  //     case 7:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtogglespeakers").prop('checked', true).trigger("click");
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#speakersbutton") }, 500);
  //       $("#tutorial-line1").html("The SPEAKERS menu allows you to select whether the speakers should cover the entire room, or just the area likely to contain people. In this mode you can also override the speaker recommendation.");
  //       break;
  //     case 8:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtoggleequipmentlist").prop('checked', true).trigger("click");
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#equipmentlistbutton") }, 600);
  //       $("#tutorial-line1").html("The EQUIPMENT LIST menu lists the recommended equipment for the room. Here you can also add extra inputs or outputs and other options. You can also override the product family, amplifier family and speaker selection.");
  //       break;
  //     case 9:
  //       $(".navtoggle").prop('checked', false)
  //       $("#navtogglemics").prop('checked', true).trigger("click");
  //       setTimeout(function () { spotlight(".mouseaction-togglebuttons") }, 300);
  //       $("#tutorial-line1").html("You can also customize your design by selecting an action from the mouse action buttons. Each menu item will show different editing options. Here you can MEASURE, MOVE, ADD, DELETE or LISTEN to what the microphone will sound like.");
  //       break;
  //     case 10:
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#addroom") }, 500);
  //       $("#tutorial-line1").html("You can add more rooms to your project by clicking ADD A ROOM.");
  //       break;
  //     case 11:
  //       // Enable spotlight
  //       setTimeout(function () { spotlight("#getquote") }, 500);
  //       $("#tutorial-line1").html("Click REQUEST QUOTE to have a Biamp expert contact you about your project, click SAVE to generate a link to share your design or click EXPORT to create a PDF overview of your design parameters.");
  //       break;
  //   };
  //   $(".empty-div").parent().css("flex-basis", "34px");
  //   $(".empty-div:empty").parent().css("flex-basis", "0px");
  // };

  // // Create new room if none exists
  // function createRoom(data) {
  //   $("#classroom_name").val(data[0]);
  //   $(".classroomlength").not(":last").each(function (i, e) { e.value(parseInt(data[1])) });
  //   $(".classroomwidth").not(":last").each(function (i, e) { e.value(parseInt(data[2])) });
  //   $(".classroomheight").not(":last").each(function (i, e) { e.value(parseInt(data[3])) });
  //   validate();
  //   setTiles(data);
  // };

  // // Set the tiles 
  // function setTiles(data) {
  //   $(".ceilingtile option:selected").prop("selected", false);
  //   $(".ceilingtile option[value=" + data[5] + "]").prop("selected", true);
  //   validate();
  // };

  // // Add table if none exists
  // function addTable(data) {
  //   $(".changetableshape[value=" + data[0] + "]").prop("checked", true).trigger(data.length == 1 ? "click" : "");
  //   if (data.length > 1) {
  //     $(".number_of_rows").each(function (i, e) { $(e).val(data[1]) }) //.val(data[1])//.trigger("change")
  //     $(".tables_per_row").each(function (i, e) { $(e).val(data[2]) }) //.val(data[2])//.trigger("change")
  //     $(".students_per_table").each(function (i, e) { $(e).val(data[3]) }) //.val(data[3])//.trigger("change")
  //   }
  //   updateTableEntry();
  //   validate();
  // };

  // // Spotlight to highlight area
  // function spotlight(target) {
  //   var width = $(target).width() + 10;
  //   var height = (target == "#getquote" ? 78 : $(target).height()) + 10;
  //   var position = $(target).offset();
  //   var parent = $("#classroomdesigner").offset() // NEEDED FOR CORNERSTONE
  //   $(".spotlight").addClass("is-visible").css({
  //     width: width,
  //     height: height,
  //     transform: "translate(" + (position.left - parent.left - 5) + "px, " + (position.top - parent.top - 5) + "px)"
  //   });
  //   $(".cd-modal").css("background", "rgba(255,255,255,0)");
  //   $(".spotlight").removeClass("is-moving")
  // };



  // CSS stuf

  // .progress-dots {
  //   text-align: center;
  //   display: block;
  //   width: 100%;
  // }
  
  // span.dots {
  //   background: var(--app-light-gray);
  //   border-radius: 50%;
  //   display: inline-block;
  //   width: 8px;
  //   height: 8px;
  //   margin: 0 3px;
  // }
  
  // span.dots.dot-active {
  //   background: var(--app-red);
  // }
  
  // .spotlight {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   box-shadow: inset #fff 0 0 0 0px, var(--app-medium-gray) 0 0 0 2000px;
  //   border-radius: 5px;
  //   opacity: 0;
  //   transition: all .3s cubic-bezier(.6,.4,0,1),opacity .15s ease .1s;
  // }
  
  // .is-visible {
  //   opacity: 0.6;
  //   transition: all .3s cubic-bezier(.6,.4,0,1),opacity .15s ease;
  // }
  
  // .is-moving {
  //   background-color: var(--app-medium-gray);
  //   transition: background-color .1s ease;
  // }








  // COOKIE STUFF

  // Reset Cookie to six month 
// function resetCookie() {
//   var d = new Date();
//   var exp = d.getDate();
//   d.setMonth(d.getMonth() + 6); // Expire in 6 months
//   if (d.getDate() != exp) {
//     d.setDate(0);
//   };
//   var expires = "expires=" + d.toUTCString();
//   document.cookie = "CDSeenTutorial=true;" + expires + ";path=/";
// }

  // // Check for first time user cookie and set the sate
  // isFirstTimeUser = !document.cookie.includes("CDSeenTutorial");

  // // If not first time user, renew the cookie expiration date to six months from now
  // if (!isFirstTimeUser) { resetCookie() };