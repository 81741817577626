import errorMiddleware from "./errorMiddleware";
import connectorMiddleware from "./connectorMiddleware";
import callerMiddleware from "./callerMiddleware";
import calculateDevicesMiddleware from "./calculateDevicesMiddleware";

export const Middlewares = [
    errorMiddleware,
    connectorMiddleware,
    calculateDevicesMiddleware,
    callerMiddleware
];
