/**
 * View configurations for the Conference Room Designer.
 * This module defines the structure and properties of different view elements in the application.
 * @module conferenceRoomDesignerViews
 */

/**
 * Area elements configuration.
 * @type {Array.<Object>}
 * @description Defines the elements to be rendered at the area level.
 * Each element includes conditions for when it should be active, shown, or considered filled.
 * These conditions are evaluated using the getConditionResult function.
 */
const areaElements = [
  /**
   * Floorplan element configuration.
   * @type {Object}
   * @property {string} element - The type of element ('floorplan').
   * @property {Object} active - Condition for when the floorplan should be active.
   */
  {
    element: 'floorplan',
    active: { parameter: ['currentView'], check: ([view]) => view === 'floorplan' }  // add view === 'floorplan if show only on floorplan
  },

  /**
   * Line dimension element configuration.
   * @type {Object}
   * @property {string} element - The type of element ('lineDimension').
   * @property {Object} show - Condition for when the line dimension should be shown.
   */
  {
    element: 'lineDimension',
    show: { parameter: ['currentView'], check: ([view]) => view === 'floorplan' },
  },

  /**
   * Table element configuration.
   * @type {Object}
   * @property {string} element - The type of element ('table').
   * @property {Object} active - Condition for when the table should be active.
   * @property {Object} filled - Condition for when the table should be considered filled.
   * @description
   * The active, show, and filled parameters in these configurations are used in conjunction 
   * with the getConditionResult function documented in container.js. This function is typically 
   * called from the element itself or a parent element to determine the element's state 
   * (active, visible, or filled) based on the current application state.
   */
  {
    element: 'table',
    active: { parameter: ['currentView'], check: ([view]) => view === 'furnishing' },
    filled: { parameter: ['currentView'], check: ([view]) => ['floorplan', 'ceiling', 'furnishing'].includes(view) }
  },

  /** Door element configuration.
    * @type {Object}
    * @property {string} element - The type of element ('door').
    * @property {Object} active - Condition for when the door should be active.
    */
  {
    element: 'door',
    active: { parameter: ['currentView'], check: ([view]) => view === 'floorplan' }
  },

  /**
   * Ceiling element configuration.
   * @type {Object}
   * @property {string} element - The type of element ('ceiling').
   * @property {Object} show - Condition for when the ceiling should be shown.
   * @property {Object} active - Condition for when the ceiling should be active.
   */  
  {
    element: 'ceiling',
    show: { parameter: ['ceilingTiles'], check: ([tiles]) => tiles.qty.x * tiles.qty.y !== 0 },
    active: { parameter: ['currentView'], check: ([view]) => view === 'ceiling' }
  },

  /**
   * Microphones element configuration (for speakers view).
   * @type {Object}
   * @property {string} element - The type of element ('microphones').
   * @property {Object} show - Condition for when the microphones should be shown in speakers view.
   * @property {Object} active - Condition for when the microphones should be active.
   */  

  // bottom mics in speaker or acoustic and speaker
  {
    element: 'microphones',
    show: {parameter: ['currentView', 'currentDevice'], check: ([view, device]) => (['acoustics', 'microphones', 'speakers'].includes(view) && device === "speakers") || (view === 'acoustics' && device === 'null') },
    active: {parameter: ['currentDevice'], check: ([device]) => device === 'microphones'}
  },

  /**
   * Speakers element configuration.
   * @type {Object}
   * @property {string} element - The type of element ('speakers').
   * @property {Object} show - Condition for when the speakers should be shown.
   * @property {Object} active - Condition for when the speakers should be active.
   */  
  {
    element: 'speakers',
    show: {parameter: ['currentView'], check: ([view]) => ['acoustics', 'microphones', 'speakers'].includes(view) },    
    active: {parameter: ['currentDevice'], check: ([device]) => device === 'speakers'}
    
  },

  /**
   * Microphones element configuration (for microphones view).
   * @type {Object}
   * @property {string} element - The type of element ('microphones').
   * @property {Object} show - Condition for when the microphones should be shown in microphones view.
   * @property {Object} active - Condition for when the microphones should be active.
   */  

  // top mics 
  {
    element: 'microphones',
    show: {parameter: ['currentView', 'currentDevice'], check: ([view, device]) => ['acoustics', 'microphones', 'speakers'].includes(view) && device === 'microphones' },
    active: {parameter: ['currentDevice'], check: ([device]) => device === 'microphones'}
  },

  /**
   * Camera element configuration (for camera view).
   * @type {Object}
   * @property {string} element - The type of element ('camera').
   * @property {Object} show - Condition for when the camera should be shown.
   * @property {Object} active - Condition for when the camera should be active.
   */  
    {
      element: 'cameras',
      show: {parameter: ['currentDevice'], check: ([device]) => device === 'cameras'},
      active: {parameter: ['currentDevice'], check: ([device]) => device === 'cameras'}
    },
];


export { areaElements };