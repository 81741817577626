/**
 * Logic module for the Conference Room Designer.
 * This module contains functions for managing and resetting area states.
 * @module conferenceRoomDesignerLogic
 */

import initialState from "toolConstants/conferenceroomDesigner/initalState";

/**
 * Resets an area to its initial state, with the option to reset specific parameters.
 * 
 * @function
 * @param {Object} container - The container object holding all areas.
 * @param {string|null} [parameter=null] - Specific parameter to reset. If null, resets all parameters.
 * 
 * @description
 * This function resets the active area in the container to its initial state.
 * It preserves the current ceiling tile selection while resetting other ceiling tile properties.
 * Other area properties may be reset based on the implementation (not shown in the provided code).
 * 
 * @example
 * // Reset all parameters of the active area
 * resetArea(containerState);
 * 
 * // Reset only a specific parameter (e.g., 'table')
 * resetArea(containerState, 'table');
 */
export const resetArea = (container, parameter = null, device = null) => {
  const activeArea = container.areas[container.activeTab];
  const initState = initialState.area();

  // reset the ceiling tiles
  const { tile } = activeArea.ceilingTiles;
  const ceilingTiles = initState.ceilingTiles;
  activeArea.ceilingTiles = {...ceilingTiles, tile: tile};

  // console.log("resetting: ", parameter, device );

  // reset customization
  if (device) activeArea.customized[device] = false


  // Additional reset logic would be implemented here
  // This might include resetting other properties based on the 'parameter' argument
  // or resetting all properties if 'parameter' is null
  
};


